import { FC, useEffect } from 'react'

import type { BackdropProps } from './Backdrop.d'

const Backdrop:FC<BackdropProps> = ({
  childrenRef,
  onPress,
  popoverRef,
  visible,
}) => {
  useEffect(() => {
    const handler = (event: any) => {
      if (
        !popoverRef?.current?.contains(event.target)
        && !childrenRef?.current?.contains(event.target)
        && visible
      ) {
        onPress()
      }
    }

    document.addEventListener('mousedown', handler)

    return () => document.removeEventListener('mousedown', handler)
  }, [visible, onPress, popoverRef, childrenRef])

  return null
}
export default Backdrop
