import { gql } from '@apollo/client'

export const GET_CONTRACTOR_BY_SLUG = gql`
  query GetContractorBySlug($slug:String!, $locale: SiteLocale ) {
    contractor(filter: {slug: {eq: $slug}}) {
      slug
      email
      minder {
        firstName
        lastName
        calendly
        home {
          city
        }
        picture {
          url
            format
          responsiveImage(
            imgixParams: { fit: crop, w: 200, h: 200, auto: format }
          ) {
            # HTML5 src/srcset/sizes attributes
            srcSet
            webpSrcSet
            sizes
            src

            # size information (post-transformations)
            width
            height
            aspectRatio

            # SEO attributes
            alt
            title

            # background color placeholder or...
            bgColor

            # blur-up placeholder, JPEG format, base64-encoded
            base64
          }
        }
      }
      minderType
      groupSessions
      individualSessions
      contentProducer
      bio(locale: $locale)
      expertises {
        title(locale: $locale)
        key
      }
      languages {
        code
      }
    }
  }
`
