import cleanDeep from 'clean-deep'
import { httpsCallable } from 'firebase/functions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RECOMBEE_API_KEY, RECOMBEE_APP_ID } from 'react-native-dotenv'
// eslint-disable-next-line import/no-unresolved
import { ApiClient, RecommendItemsToItem, RecommendItemsToUser } from 'recombee-js-api-client'

import { useFunctions } from '~/hooks/firebase'
import { supportedLanguages } from '~/i18n/languages'
import { RecommendItemsToUserOptions, UseRecommendationScenario } from '~/vendors/recombee/recombee'

import { mapLocalisedRecommendation } from '../vendors/recombee/recombee.helpers'
import { getQueryWithLanguageFilter } from './recombee.helpers'
import { useUser } from './user.context'

export const useRecombeeClient = () => {
  const [client, setClient] = useState()

  useEffect(() => {
    if (!client) {
      setClient(new ApiClient(RECOMBEE_APP_ID, RECOMBEE_API_KEY))
    }
  }, [client])

  return client
}

const getRecommendationFn = (scenario: UseRecommendationScenario) => {
  switch (scenario) {
  case 'Home_ForYou':
  case 'Home_Popular':
  case 'Recently_Viewed':
  case 'Home_Picks':
  case 'Favorites':
    return RecommendItemsToUser
    break
  case 'Popular_Items':
  case 'Similar_Items':
    return RecommendItemsToItem
  default:
    throw new Error(`unknown scenario ${scenario}`)
  }
}

/**
 * Hook for https://docs.recombee.com/api.html#request-recommend-items-to-user
 */
export const useRecommendedItems = (
  count = 5,
  scenario: UseRecommendationScenario,
  options?: Omit<RecommendItemsToUserOptions, 'scenario'>,
) => {
  // console.log('-> useRecommendedItems for scenario', scenario)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { i18n } = useTranslation()
  const client = useRecombeeClient()

  const { user } = useUser()

  useEffect(() => {
    const fetchRecommendations = async () => {
      // console.log('-> useRecommendedItems / fetchRecommendations')
      setLoading(true)
      const queryOptions: RecommendItemsToUserOptions = cleanDeep({
        ...options,
        returnProperties: true,
      })
      // console.log('Starting recommendation request with options', queryOptions)

      const newOptions = getQueryWithLanguageFilter({ options: queryOptions }, i18n).options

      try {
        const item = await client.send(new (getRecommendationFn(scenario))(user?.uid, count, newOptions))
        // console.log(`Recommendation ${item.recommId} request results`, item.recomms)
        const res = mapLocalisedRecommendation(item, i18n.language, supportedLanguages)
        // console.log(`res`, res)

        setData(res)
        setLoading(false)
      } catch (error2) {
        console.error(error2)
        setError(error2)
        setLoading(false)
      }
    }
    if (client) {
      fetchRecommendations()
    }

    // purposly ignored 'client', 'i18n', and 'options'
  }, [client, count, scenario, user?.uid, i18n.language])

  return {
    loading,
    error,
    data,
  }
}

type UseBatchRecommendedItemsInputType = Array<{
  count: number
  scenario: UseRecommendationScenario
  options?: RecommendItemsToUserOptions
}>

export const useBatchRecommendedItems = (input: UseBatchRecommendedItemsInputType) => {
  // console.log('-> useBatchRecommendedItems', input)
  const [res, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const { i18n } = useTranslation()

  const client = useRecombeeClient()
  const { user } = useUser()

  const functions = useFunctions('us-central1')

  useEffect(() => {

    const fetchRecommendations = async () => {
      // console.log('-> fetchRecommendations')
      setLoading(true)
      const newInput = cleanDeep(input.map((params) => getQueryWithLanguageFilter(params, i18n)))
      try {
        // console.log('Will launch proxied request...')
        const getBatchreqRes = await httpsCallable(functions, 'httpGetBatchRecommendations')({ uid: user?.uid, input: newInput })
        const requestedRecommendations = getBatchreqRes.data
        if (requestedRecommendations !== undefined) {
          const formattedRecommendations = requestedRecommendations.map((item) => mapLocalisedRecommendation(item, i18n.language, supportedLanguages))
          setData(formattedRecommendations)
        } else {
          // console.log('undefined req recommendations data')
        }
        setLoading(false)
      } catch (error3) {
        console.error(error3)
        setError(error)
        setLoading(false)
      }
    }

    if (client && input?.length > 0) {
      fetchRecommendations()
    }
    // purposly ignored 'client', 'error', 'functions', 'i18n', and 'input'
  }, [client, user?.uid, i18n.language])

  // console.log('-> useBatchRecommendedItems', res)
  return {
    loading,
    data: res,
    error,
  }
}
