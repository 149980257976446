import classNames from 'classnames'

import { View } from '~/elements'
import { useDatoSvg } from '~/hooks/dato.assets'

export const CtaBackground = ({ assetId, tw }) => {

  const bgData = useDatoSvg(assetId)
  // const illustrationData = useDatoSvg('27327970')
  const bgSvgData = bgData?.data?.upload
  // const frontSvgData = illustrationData?.data?.upload
  const nextTw = classNames('absolute h-full w-full top-0 left-0 overflow-hidden', tw)

  return (
    <View tw={nextTw}>
      {bgSvgData && <img
        id="bg-svg"
        alt={bgSvgData.alt}
        src={bgSvgData.url}
        className='object-cover h-full w-full rounded-2xl' />}
    </View>
  )
}
