import axios from 'axios'
import LottieView from 'lottie-react-native'
import { useEffect, useState } from 'react'
import { Style, useTailwind } from 'tailwind-rn'

import { useDatoLottie } from '~/hooks/dato.assets'

/**
 * Fetch lottie data from Dato CMS and return it as a Lottie View
 */
export const Animation = ({ itemId, tw, ...props }) => {
  const [lottieData, setLottieData] = useState()
  const tailwind = useTailwind()
  const [styleTw, setStyleTw] = useState<null | Style>(null)
  const { data } = useDatoLottie(itemId)

  useEffect(() => {
    let componentMounted = true
    const loadLottieData = async () => {
      const res = await axios.get(data.upload.url)
      // console.log(`res`, res)
      if (componentMounted) {
        setLottieData(res.data)
      }
    }
    if (data) {
      loadLottieData()
    }
    return () => {
      componentMounted = false
    }
  }, [data])

  // to avoid seeing the animation when not resized yet
  useEffect(() => {
    if (Object.keys(tailwind(tw)).length !== 0) {
      setStyleTw(tailwind(tw))
    }
  }, [tailwind(tw)]) // watch out: can sometimes make it lose its animated rythm

  if (!lottieData || !styleTw) {
    return null
  }

  return <LottieView source={lottieData} {...props} style={styleTw} />
}
