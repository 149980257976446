import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { FC, useEffect, useState } from 'react'
import { MINDDAY_DATO_TYPEFORM_PPTY } from 'react-native-dotenv'
import { WebView } from 'react-native-webview'

import { useTypeformBySlug } from '~/hooks/dato.typeforms'
import { useCurrentUserNewEvaluation } from '~/hooks/firestore.evaluations'
import { useUser } from '~/hooks/user.context'
import { EvaluationsStackParamList } from '~/navigators/EvaluationsStackParamList'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
// import { getNormalizedDomain } from './RegistrationFormScreen'

export type NewTypeformScreenProps = StackScreenProps<EvaluationsStackParamList, 'NewTypeform'>

const mapFormSlugToExpectedEvaluationSlug = (slug) => {
  switch (slug) {
  case 'user-orientation':
    return 'how-I-feel'
  default:
    return slug
  }
}

/**
 * Wrap a typeform in a webview, and wait for new test record being available for user before returning to main screen
 */
export const NewTypeformScreen: FC<NewTypeformScreenProps> = ({ route }) => {
  const { slug, redirect } = route.params
  const linkTo = useLinkTo()

  // console.log('-> NewTypeformScreen', slug)

  const { user } = useUser()

  const [evaluationId, setEvaluationId] = useState()
  const [formUrl, setFormUrl] = useState('')

  // listener for a new test to be posted
  const newTestListener = useCurrentUserNewEvaluation(mapFormSlugToExpectedEvaluationSlug(slug))

  // Fetch typeform properties from Dato CMS
  const { loading, error, data } = useTypeformBySlug(slug)

  // We go back to main screen as soon as a new test has been listed, unless redirection url provided
  useEffect(() => {
    if (evaluationId) {
      // console.log('we\'ve captured a form submission', evaluationId)
      if (redirect) {
        // console.log('will redirect to requested screen')
        linkTo(redirect)
      } else {
        // console.log('will navigate to evaluations screen')
        linkTo(`/evaluations?evaluationId=${evaluationId}`)
      }
    }

    // purposly ignored linkTo
  }, [evaluationId, redirect])

  useEffect(() => {
    if (data?.form) {
      setFormUrl(`https://form.typeform.com/to/${data.form[MINDDAY_DATO_TYPEFORM_PPTY]}?typeform-welcome=0&typeform-medium=embed-snippet#uid=${user?.uid}`)
      /**
       * Note according to the form it could be
       * uid
       * name (is it useful - doubt it)
       * campaign_id (end-of track)
       * meeting_id (end of meeting)
       */
      // For some forms, we need to add extra data
      // if (slug === 'user-orientation') url += `&campaign_id=${campaignDoc?.id}`
    }
  }, [data])

  // valid campaign for that user
  // const { data: campaignDoc } = useLatestAuthorizedCampaignByDomain(getNormalizedDomain(user.email))

  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  // we record in state when we get a confirmation
  if (newTestListener.data && !evaluationId) {
    // console.log('new test captured:', newTestListener.data.id)
    setEvaluationId(newTestListener.data.id)
    // const doc = newTestListener.data
  }

  // console.log('Typeform url', formUrl)

  if (!formUrl) {
    return null
  }

  return (
    <WebView
      // originWhitelist={['*']}
      source={{
        // html,
        uri: formUrl,
      }}
    />
  )
}
