import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { View } from 'dripsy'
import { useTailwind } from 'tailwind-rn'

import { faPrefixes } from './faConstants'
import { IconProps } from './IconProps.d'

/**
 * A wrapper for either Heroicons or Fontawesome
 *
 * Original doc for FontAwesome React component: https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react#features
 */
export const Icon = ({
  pack = 'fa', name, variant = 'solid', size, sizeNum, tw, ...props
}: IconProps) => {
  const tailwind = useTailwind()
  // console.log('Web Icon', name, size, sizeNum)
  //   if (pack === 'heroicons') {
  //     // const heroiconStyle = style === 'solid' ? 'solid' : 'outline'
  //     // const heroiconName = startCase(name.replace('-', ' ').toLowerCase()).replace(' ', '')
  //     // console.log('heroiconName', heroiconName)

  //     // const Heroicon = require(`~/fonts/heroicons/${heroiconStyle}/${heroiconName}`)

  //     return <ArrowNarrowRight {...props} />
  //   }

  if (pack === 'fa') {
    // console.log('icon name', name)

    // If size explicitely given, it sets fontSize and size is then 1em
    const sizeEnum = size
    let nextSizeNum = sizeNum
    const iconStyle = {}

    const twStyle = tailwind(tw)
    // console.log(`twStyle`, twStyle)

    /**
     * Interpret Tailwind classes
     */
    if (tw) {
      // we get icon size from height
      if (twStyle.height && !sizeNum) {
        nextSizeNum = twStyle.height
        delete twStyle.height
      }

      // Text color
      if (twStyle.color && !iconStyle.color) {
        iconStyle.color = twStyle.color
        delete twStyle.color
      }

      // Background color
      if (twStyle.backgroundColor && !iconStyle.backgroundColor) {
        iconStyle.backgroundColor = twStyle.backgroundColor
        delete twStyle.backgroundColor
      }
    }

    if (nextSizeNum && !sizeEnum) {
      // console.log('Explicit icon size', sizeNum)
      iconStyle.fontSize = sizeNum
    }

    return <FontAwesomeIcon icon={[faPrefixes[variant], name]} size={sizeEnum} style={iconStyle} {...props} />
  }

  return null
}
