/* eslint-disable global-require */
// https://necolas.github.io/react-native-web/docs/text/

import cleanDeep from 'clean-deep'
import { FC } from 'react'
import { Text as RneText } from 'react-native-elements'
import Markdown from 'react-native-markdown-display'
import { useTailwind } from 'tailwind-rn'

import { useMediaQuery } from '~/hooks/mediaQuery'
import {
  getLineHeight, getRelevantLineHeightClass, getRelevantTextSizeClass,
} from '~/utils/tailwind/tw.texts.helpers'

import { TextProps } from './Text.d'
import {
  getCategoryClassNames, getFontFamily, getVariantClassNames, markdownStyleKeyList,
} from './Text.helpers'

/**
 * We map our model to RNE
 * TODO: direct mapping to custom H elements
 */
export const Text: FC<TextProps> = (props) => {
  const {
    category, variant, tw, markdown, ...rest
  } = props

  const mq = useMediaQuery()
  const tailwind = useTailwind()

  let newTw = tw || ''

  if (category) {
    newTw = `${getCategoryClassNames(category)} ${newTw}`
  }

  if (variant) {
    newTw = `${getVariantClassNames(variant)} ${newTw}`
  }

  // console.log('tw', newTw)

  const fontFamilyResult = getFontFamily(newTw, mq)
  const { fontFamily } = fontFamilyResult
  newTw = fontFamilyResult.newTw

  // console.log('fontFamily', fontFamily)

  let lineHeight
  if (newTw.includes('leading')) {
    // directly get relevant class for mq
    const leadingClass = getRelevantLineHeightClass(newTw, mq)
    // console.log('leadingClass', leadingClass)

    // get relevant font size as reference
    const fontSize = getRelevantTextSizeClass(newTw, mq)
    // console.log('fontSize', fontSize)
    lineHeight = getLineHeight(fontSize, leadingClass)
    // console.log('lineHeight', lineHeight)

    // remove all leading- classes
    const regex = /(leading-)[^\s]*/g
    newTw = newTw.replaceAll(regex, '')
    // console.log('tw after', newTw)
  }

  // console.log('tw after', newTw)

  const newStyleProp = { ...tailwind(newTw), ...cleanDeep({ fontFamily, lineHeight }) }
  // console.log('addStyleProp', newStyleProp)

  // console.log('newStyleProp', newStyleProp)
  // console.log('rest', rest)

  switch (category) {
  case 'h1':
    return <RneText h1 h1Style={newStyleProp} {...rest} />
  case 'h2':
    return <RneText h2 h2Style={newStyleProp} {...rest} />
  case 'h3':
    return <RneText h3 h3Style={newStyleProp} {...rest} />
  case 'h4':
    return <RneText h4 h4Style={newStyleProp} {...rest} />
  default: {
    if (markdown) {
      // Note: we could refine markdown styling - See https://www.npmjs.com/package/react-native-markdown-display/v/5.0.0#style
      const mdStyle = markdownStyleKeyList.reduce((acc, key) => ({ ...acc, [key]: newStyleProp }), {})
      // We currently have an issue with text not wraping on web...

      return <Markdown style={mdStyle} mergeStyle {...rest} />
    }

    return <RneText style={newStyleProp} {...rest} />
  }
  }
}

Text.defaultProps = {
  category: undefined,
  variant: undefined,
  tw: '',
}
