// import { useLinkTo } from '@react-navigation/native'
import { openBrowserAsync } from 'expo-web-browser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native'

// import { useAuth } from 'reactfire'
import {
  Button, Icon, Text, View,
} from '~/elements'

type ErrorScreenProps = {
  handleBackToSignIn?: () => void
  error ?: Error
}

export const ErrorScreen:FC<ErrorScreenProps> = ({ error, resetError }) => {
  console.error(error)
  // const linkTo = useLinkTo()
  const { t } = useTranslation(['common'])
  // const auth = useAuth()

  return (
    <SafeAreaView>
      <View tw="flex-col justify-around w-full p-16">

        <View tw="items-center mb-12">
          <Icon name="circle-info" sizeNum={60} />
          <Text tw="text-3xl font-semibold text-center">{t('common:errorPage.genericTitle')}</Text>
          <Text tw="font-normal text-center text-sm">{t('common:errorPage.genericDescription')}</Text>
        </View>
        <View tw="max-w-3xl">
          <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.reset')}
            onPress={() => {
              resetError()
            } }
          />
          {/* <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.comeBackHome')}
            onPress={() => {
              linkTo('/')
            } }
          /> */}
          <Button
            variant="outline"
            tw="mt-4"
            title={t('common:errorPage.contactUs')}
            onPress={() => {
              openBrowserAsync('mailto:hello@mindday.com')
            }}
          />
        </View>
      </View>

    </SafeAreaView>
  )
}
