import classNames from 'classnames'
import { FC } from 'react'

import { View } from '~/elements'

type FullOverlayContainerProps = {
  tw?: string
  innerContainerTw?:string
}

export const FullOverlayContainer: FC<FullOverlayContainerProps> = ({
  tw, children, innerContainerTw,
}) => {
  const newTw = classNames('md:flex-row md:justify-center min-h-full', tw)
  // console.log(newTw)

  return (
    <View
      testID="main-container"
      tw={newTw}
    >
      <View tw={classNames('w-full md:max-w-6xl md:flex-row', innerContainerTw)}>
        {children}
      </View>
    </View>
  )
}
