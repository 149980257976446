import { useQuery } from '@apollo/client'
import sum from 'lodash/sum'
import { useTranslation } from 'react-i18next'

import { GetContentByIdQuery } from '~/__generated__/GetContentByIdQuery'
import { GET_ALL_CONTENTS, GET_CONTENT_BY_ID } from '~/queries/dato.content'
import { computeReadMetadata, ContentContextType } from '~/utils/content.helpers'

export const useContentById = (contentId: string) => {
  const { i18n } = useTranslation()

  return useQuery<GetContentByIdQuery>(GET_CONTENT_BY_ID, { variables: { contentId, locale: i18n.language } })
}

export const useAllContents = () => {
  const { i18n } = useTranslation()

  return useQuery(GET_ALL_CONTENTS, { variables: { locale: i18n.language } })
}

export const useFormattedContentById = (contentId: string): ContentContextType => {
  const { data, loading, error } = useContentById(contentId)

  if (data) {

    const res = {
      data: { ...data.content }, loading, error,
    }

    const metadata = computeReadMetadata(res.data.content)

    // console.log('metadata', metadata)
    const toc = res.data.content.map((item, index) => ({ ...item, metadata: metadata[index] }))
    // console.log('toc', toc)

    res.data = {
      ...res.data,
      toc,
      metadata: {
        words: sum(metadata.map((item) => item.words)),
        minutes: sum(metadata.map((item) => item.minutes)),
        seconds: sum(metadata.map((item) => item.seconds)),
      },
    }

    return res
  }

  return { data: null, loading, error }

}
