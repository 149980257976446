import AsyncStorage from '@react-native-async-storage/async-storage'
import { useEffect, useState } from 'react'

export const localStorageKeys = {
  MAGIC_EMAIL: 'magicEmailLastSent',
  CAROUSEL_EVALUATION: 'hasUserSeenEvaluationPlayerCarousel',
  WIZARD_ONBOARDING: 'hasUserSeenOnboardingTaskList',
  CAROUSEL_WELCOME: 'hasSeenWelcomeCarousel',
  EMAIL: 'userEmail',
  EMAIL_STRATEGY: 'signInEmailStrategy',
}

export type AsyncStorageKey = typeof localStorageKeys[keyof typeof localStorageKeys];

type LocalStorageType = string | boolean| number | null | undefined

type AsyncStorageResponse = {
  loading:boolean
  value: LocalStorageType
  setItem: (newValue: LocalStorageType) => void
}

const isNumeric = (str) => {
  if (typeof str !== 'string') {
    return false
  } // we only process strings!

  // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  // ...and ensure strings of whitespace fail
  // eslint-disable-next-line no-restricted-globals
  return !isNaN(str) && !isNaN(parseFloat(str))
}

export const useAsyncStorage = (storageKey:AsyncStorageKey): AsyncStorageResponse => {

  const [loading, setLoading] = useState<boolean>(true)

  const [value, setStateValue] = useState<LocalStorageType>()

  const updateStateFromStorage = async (updateLoading = true) => {

    // console.log('updateStateFromStorage', storageKey)

    if (updateLoading) {
      setLoading(true)
    }
    const item = await AsyncStorage.getItem(storageKey)
    // console.log('item in storage:', item)

    let parsedItem: LocalStorageType = item

    if (item === null) {
      parsedItem = null
    } else if (item === 'true') {
      parsedItem = true
    } else if (item === 'false') {
      parsedItem = false
    } else if (isNumeric(item)) {
      parsedItem = parseFloat(item)
    }

    // console.log('from local storage', parsedItem, typeof parsedItem)

    setStateValue(parsedItem)

    if (updateLoading) {
      setLoading(false)
    }

  }

  /**
   * Record timestamp in local storage
   */
  const setItem = async (val: LocalStorageType) => {

    if (val !== value) {
      setLoading(true)
      if (!val) {
        // console.log('will remove local value')
        await AsyncStorage.removeItem(storageKey)
      } else {
        // console.log('will set local value to', val)
        await AsyncStorage.setItem(storageKey, val.toString())
      }
      // update read value
      await updateStateFromStorage(false)
      setLoading(false)
    } else {
      // console.log('skipping writing to local storage as same value already')
    }
  }

  useEffect(() => {
    // console.log('initial read from local storage')
    updateStateFromStorage()

    // purposly ignored updateStateFromStorage
  }, [])

  // console.log('loading', loading)
  // console.log('result', value)

  return {
    value,
    loading,
    setItem,
  }
}
