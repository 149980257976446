import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetFormByKeyQuery } from '~/__generated__/GetFormByKeyQuery'
import { GET_FORM } from '~/queries/dato.webForms'

export const useWebFormByKey = (formId: string) => {
  const { i18n } = useTranslation()

  return useQuery<GetFormByKeyQuery, { key:string, locale: string }>(GET_FORM, { variables: { key: formId, locale: i18n.language || 'en' } })
}
