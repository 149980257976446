import { gql } from '@apollo/client'

// Notes we should use fragments but not quite sure about how to pass variables

// $imgWidth: FloatType, $imgHeight: FloatType

export const GET_CONTENT_BY_ID = gql`
  query GetContentByIdQuery($contentId: ItemId!, $locale: SiteLocale) {
    content(filter: { id: { eq: $contentId }, title: {isBlank:false} }, locale: $locale) {
      id
      title(locale: $locale)
      _status
      _publishedAt
      _publicationScheduledAt
      coverMain {
        url
        format
        height
        width
      }
      coverMainFormat
      coverBackground {
        url
        format
        height
        width
      }
      coverBackgroundColor
      groups {
        id
        slug
        _allTitleLocales {
          value
          locale
        }
        backgroundColor
        background {
          url
          format
          title
          alt
          height
          width
        }
        illustration {
          url
          format
          title
          alt
          height
          width
        }
        groupType
      }
      excerpt(locale: $locale)
      author {
        firstName
        lastName
        slug
        picture {
          format
          url
          responsiveImage(
           imgixParams: {  auto: format, w: 100, h: 100 }
            ) {
              srcSet
              webpSrcSet
              sizes
              src
              width
              height
              aspectRatio
              alt
              title
              bgColor
              base64
            }
        }
      }
      content(locale: $locale) {
        ... on ContentPageRecord {
          id
          title
          content {
            value
            blocks {
              id
              media {
                id
                url
                format
                title(locale: $locale)
                alt
                height
                width
              }
            }
          }
        }
        ... on ContentSectionRecord {
          id
          theme
          title
        }
        ... on ContentCheckpointRecord {
          id
          title
          content {
            value
          }
        }
        ... on ContentScaleRecord {
          id
          name
          before
          after
          item {
            key
            title(locale: $locale)
            values {
              key
              label(locale: $locale)
              value
            }
          }
        }
        ... on ContentToolRecord {
          id
          name
          item {
            slug
            title
            id
            linkedItem {
              id
              slug
              title
              form {
                id
                title(locale: $locale)
                typeform {
                  id
                  slug
                  title
                  typeformId(locale: $locale)
                  typeformIdAll
                  typeformIdDev(locale: $locale)
                }
              }
            }
          }
        }
        ... on ContentIllustrationRecord {
          id
          description
          illustration {
            url
            format
            title(locale: $locale)
            alt
            height
            width
          }
        }
      }
    }
  }
`

// cf https://www.datocms.com/docs/content-delivery-api/images-and-videos#responsive-images

export const GET_GROUP_WITH_CONTENT = gql`
  query GetAllContentByGroupQuery($groupId: ItemId, $locale: SiteLocale) {
    allContents(filter: {groups: {anyIn: [$groupId]}, title: {isBlank:false}}, locale: $locale) {
      title(locale: $locale)
      id
      author {
        firstName
        lastName
        picture {
          responsiveImage(imgixParams: {h: "80", w: "80"}) {
            bgColor
            srcSet
            src
            title
            webpSrcSet
          }
        }
      }
      excerpt(locale: $locale)
      _status
      _publishedAt
      _publicationScheduledAt
      coverMain {
        url
        format
        height
        width
      }
      coverMainFormat
      coverBackground {
        url
        format
        height
        width
      }
      coverBackgroundColor
    }
    contentGroup(filter: {id: {eq: $groupId}}) {
      title(locale: $locale)
      description(locale: $locale)
      backgroundColor
      background {
        url
        format
        title
        alt
        height
        width
      }
      illustration {
        url
        format
        title
        alt
        height
        width
      }
    }
  }
`

export const GET_ALL_CONTENTS = gql`
  query content($locale: SiteLocale) {
    allContents {
      id
      title(locale: $locale)
      excerpt(locale: $locale)
    }
  }
`
