import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { Button, Text } from '~/elements'
import { SvgById } from '~/elements/images/SvgById'

/**
 * Initial screen for when user has no conversation yet
 */
export const ChatNoConversationScreen = () => {

  const { t } = useTranslation(['chat'])

  return (
    <MainContainer scrollEnabled>
      <BasicSectionContainer tw="items-center mt-12">
        <SvgById assetId={'21800783'} />
        <Text tw="mt-24 text-center text-xl">{t('chat:empty.title')}</Text>
        <Text tw="mt-2 px-12 text-center text-gray-500">{t('chat:empty.description')}</Text>
        <Button tw="mt-8" title={t('chat:empty.cta')} to="/conversations/new" color="brand"/>
      </BasicSectionContainer>
    </MainContainer>

  )
}
