import { FC } from 'react'

import { View } from '~/elements'

import { DoubleSvgImageType } from './DoubleSvgImageType'

/**
 * Background adjusts its width to the container component and defines its height via initial aspectRatio
 */
const LandscapeBackground = ({ aspectW, aspectH, image }) => {
  if (!image) {
    return null
  }
  const backgroundAspectRatio = image.width / image.height
  const backgroundVerticalMargin = (aspectW - aspectH) / 2 / aspectH
  const bgVerticalPosition = `-${Math.round(backgroundVerticalMargin * 100)}%`

  return (
    <View
      style={{
        aspectRatio: backgroundAspectRatio,
        width: '100%',
        position: 'absolute',
        top: bgVerticalPosition,
        bottom: bgVerticalPosition,
        left: 0,
        right: 0,
      }}
    >
      <img
        alt={image.alt}
        width="100%"
        height="100%"
        draggable={false}
        src={image.url}
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
        }} />
    </View>
  )
}

/**
 * Front illustration adjusts its height to the container component and defines its width via initial aspectRatio
 */
const LandscapeForeground = ({ image, format, padding }) => {
  if (!image) {
    return null
  }

  const imgStyle = {}

  // Container will usually respect the original aspect ratio
  let illustrationAspectRatio = image.width / image.height

  // if portrait mode, we do not respect the shape and will force square container
  if (image.height > image.width) {
    illustrationAspectRatio = 1

    // we also add a negative vertical margin so has to properly position the illustration within the square
    const verticalMargin = (image.height - image.width) / image.height
    // console.log('verticalMargin', verticalMargin)
    const verticalPositionOfffset = `-${Math.round(verticalMargin * 100)}%`
    // console.log('verticalPositionOfffset', verticalPositionOfffset)
    if (format.includes('-top')) {
      imgStyle.marginTop = verticalPositionOfffset
    } else {
      imgStyle.marginBottom = verticalPositionOfffset
    }
  }

  return (
    <View tw="self-center"
      style={{
        position: 'absolute',
        aspectRatio: illustrationAspectRatio,
        top: padding,
        bottom: padding,
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        alt={image.alt}
        width="100%"
        draggable={false}
        src={image.url}
        style={imgStyle}
      />
    </View>
  )
}

export const Landscape: FC<DoubleSvgImageType> = ({
  aspectW, aspectH, illustration, background, children, format,
}) => (
  <>
    <LandscapeBackground aspectW={aspectW} aspectH={aspectH} image={background}/>
    <LandscapeForeground image={illustration} format={format}/>
    {children}

  </>
)

LandscapeForeground.defaultProps = {
  padding: 0,
}
