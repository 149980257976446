import { FC } from 'react'

import { Pressable } from '..'
import { Flag, FlagProps } from '../images/Flag'

type PressableFlagProps = FlagProps & {
    onPress?: () => void
    disabled?:boolean
};
export const PressableFlag: FC<PressableFlagProps> = ({
  onPress, tw, disabled, ...props
}) => <Pressable disabled={disabled} onPress={onPress} tw={tw}><Flag {...props} /></Pressable>

PressableFlag.defaultProps = {
  disabled: false,
}
