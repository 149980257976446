import classNames from 'classnames'
import { StreamMessage, useTranslationContext, useUserRole } from 'stream-chat-react'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import { Text, View } from '~/elements'

import { messageMeTw, messageTw } from './styles.tw'

// str-chat__message--deleted
// @see https://github.com/GetStream/stream-chat-css/blob/b769255eb76a3d28dd5fc67215db9e4b5c9ea5e3/src/styles/Message.scss#L621
const messageDeletedTw = 'flex-col justify-start'

// str-chat__message--deleted-inner
const messageDeletedInnerTw = 'bg-brand-lightest rounded-full px-2 py-1.5 text-xs text-brand-lighter'

export type MessageDeletedProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  message: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>;
};

export const MessageDeleted = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: MessageDeletedProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {

  const { message } = props
  // console.log('-> Message deleted', message.type)

  const { t } = useTranslationContext('MessageDeleted')

  const { isMyMessage } = useUserRole(message)

  const messageClasses = isMyMessage ? classNames(messageTw, messageMeTw) : classNames(messageTw)

  return (
    <View
      // tw="bg-brand-base"
      tw={classNames(messageClasses, messageDeletedTw)}
      // className={`${messageClasses} str-chat__message--deleted ${message.type} `}
      testID={'message-deleted-component'}
      key={message.id}
    >
      <Text tw={messageDeletedInnerTw}>{t('This message was deleted...')}</Text>
    </View>
  )
}
