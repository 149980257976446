import {
  memo, useEffect, useRef, useState,
} from 'react'
import {
  ChannelListProps as RnChannelListProps,
  ChannelPreview,
  ChannelSearch,
  ChatDown,
  EmptyStateIndicator,
  LoadMorePaginator,
  MAX_QUERY_CHANNELS_LIMIT,
  moveChannelUp,
  useChannelDeletedListener,
  useChannelHiddenListener,
  useChannelTruncatedListener,
  useChannelUpdatedListener,
  useChannelVisibleListener,
  useChatContext,
  useConnectionRecoveredListener,
  useMessageNewListener,
  useMobileNavigation,
  useNotificationAddedToChannelListener,
  useNotificationMessageNewListener,
  useNotificationRemovedFromChannelListener,
  usePaginatedChannels,
  useUserPresenceChangedListener,
} from 'stream-chat-react'

import { View } from '~/elements'
import { Avatar } from '~/elements/avatar/Avatar'

import { LoadingChannels } from '../Loading/LoadingChannels'
import { ChannelListMessenger } from './ChannelListMessenger'

export type ChannelListProps = RnChannelListProps

/**
 * A wrapper for the Stream Chat ChannelList component.
 * See https://getstream.io/chat/docs/sdk/react/core-components/channel_list/
 */

const DEFAULT_FILTERS = {}
const DEFAULT_OPTIONS = {}
const DEFAULT_SORT = {}

const UnMemoizedChannelList = (props) => {
  const {
    additionalChannelSearchProps,

    allowNewMessagesFromUnfilteredChannels,
    channelRenderFilterFn,
    customActiveChannel,
    filters,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
    List = ChannelListMessenger,
    lockChannelOrder,
    onAddedToChannel,
    onChannelDeleted,
    onChannelHidden,
    onChannelTruncated,
    onChannelUpdated,
    onChannelVisible,
    onMessageNew,
    onRemovedFromChannel,
    options,
    Paginator = LoadMorePaginator,
    Preview,
    sendChannelsToList = false,
    setActiveChannelOnMount = true,
    showChannelSearch = false,
    sort = DEFAULT_SORT,
    watchers = {},
  } = props

  const {
    channel,
    client,
    closeMobileNav,
    // customTw,
    navOpen = false,
    setActiveChannel,
  } = useChatContext('ChannelList')

  const channelListRef = useRef<HTMLDivElement>(null)
  const [channelUpdateCount, setChannelUpdateCount] = useState(0)

  /**
   * Set a channel with id {customActiveChannel} as active and move it to the top of the list.
   * If customActiveChannel prop is absent, then set the first channel in list as active channel.
   */
  const activeChannelHandler = async (
    channels,
    setChannels,
  ) => {
    if (!channels.length || channels.length > (options?.limit || MAX_QUERY_CHANNELS_LIMIT)) {
      return
    }

    if (customActiveChannel) {
      let customActiveChannelObject = channels.find((chan) => chan.id === customActiveChannel)

      if (!customActiveChannelObject) {
        [customActiveChannelObject] = await client.queryChannels({ id: customActiveChannel })
      }

      if (customActiveChannelObject) {
        setActiveChannel(customActiveChannelObject, watchers)

        const newChannels = moveChannelUp({
          activeChannel: customActiveChannelObject,
          channels,
          cid: customActiveChannelObject.cid,
        })

        setChannels(newChannels)
      }

      return
    }

    if (setActiveChannelOnMount) {
      setActiveChannel(channels[0], watchers)
    }
  }

  /**
   * For some events, inner properties on the channel will update but the shallow comparison will not
   * force a re-render. Incrementing this dummy variable ensures the channel previews update.
   */
  const forceUpdate = () => setChannelUpdateCount((count) => count + 1)

  const {
    channels, hasNextPage, loadNextPage, setChannels, status,
  } = usePaginatedChannels(
    client,
    filters || DEFAULT_FILTERS,
    sort || DEFAULT_SORT,
    options || DEFAULT_OPTIONS,
    activeChannelHandler,
  )

  const loadedChannels = channelRenderFilterFn ? channelRenderFilterFn(channels) : channels

  useMobileNavigation(channelListRef, navOpen, closeMobileNav)

  useMessageNewListener(setChannels, lockChannelOrder, allowNewMessagesFromUnfilteredChannels)
  useNotificationMessageNewListener(
    setChannels,
    onMessageNew,
    allowNewMessagesFromUnfilteredChannels,
  )
  useNotificationAddedToChannelListener(
    setChannels,
    onAddedToChannel,
    allowNewMessagesFromUnfilteredChannels,
  )
  useNotificationRemovedFromChannelListener(setChannels, onRemovedFromChannel)
  useChannelDeletedListener(setChannels, onChannelDeleted)
  useChannelHiddenListener(setChannels, onChannelHidden)
  useChannelVisibleListener(setChannels, onChannelVisible)
  useChannelTruncatedListener(setChannels, onChannelTruncated, forceUpdate)
  useChannelUpdatedListener(setChannels, onChannelUpdated, forceUpdate)
  useConnectionRecoveredListener(forceUpdate)
  useUserPresenceChangedListener(setChannels)

  useEffect(() => {
    const handleEvent = (event) => {
      if (event.cid === channel?.cid) {
        setActiveChannel()
      }
    }

    client.on('channel.deleted', handleEvent)
    client.on('channel.hidden', handleEvent)

    return () => {
      client.off('channel.deleted', handleEvent)
      client.off('channel.hidden', handleEvent)
    }
  }, [channel?.cid])

  const renderChannel = (item) => {
    const previewProps = {
      activeChannel: channel,
      Avatar,
      channel: item,
      channelUpdateCount, // forces the update of preview component on channel update
      key: item.id,
      Preview,
      setActiveChannel,
      watchers,
    }

    return <ChannelPreview {...previewProps} />
  }

  //   const chatTw = customTw?.chat || 'flex-col'
  //   const channelListTw = customTw?.channelList || 'str-chat-channel-list'
  //   const navigationTw = navOpen ? 'str-chat-channel-list--open' : ''
  //   const windowsEmojiTw = useImageFlagEmojisOnWindows && navigator.userAgent.match(/Win/)
  //     ? 'str-chat--windows-flags'
  //     : ''

  return (
    <View
      // className={`${chatTw} ${channelListTw} ${theme} ${navigationTw} ${windowsEmojiTw}`}
    //   ref={channelListRef}
    >
      {showChannelSearch && <ChannelSearch {...additionalChannelSearchProps} />}
      <List
        error={status.error}
        loadedChannels={sendChannelsToList ? loadedChannels : undefined}
        loading={status.loadingChannels}
        LoadingErrorIndicator={LoadingErrorIndicator}
        LoadingIndicator={LoadingIndicator}
        setChannels={setChannels}
      >
        {!loadedChannels?.length ? (
          <EmptyStateIndicator listType='channel' />
        ) : (
          <Paginator
            hasNextPage={hasNextPage}
            loadNextPage={loadNextPage}
            refreshing={status.refreshing}
          >
            {loadedChannels.map(renderChannel)}
          </Paginator>
        )}
      </List>
    </View>

  )
}

/**
 * Renders a preview list of Channels, allowing you to select the Channel you want to open
 */
export const ChannelList = memo(UnMemoizedChannelList) as typeof UnMemoizedChannelList
