import { LinearGradient } from 'expo-linear-gradient'
import {
  FC, useRef,
} from 'react'
import { useActive, useFocus, useHover } from 'react-native-web-hooks'
import { useTailwind } from 'tailwind-rn'

import { EventuallyPressable } from '~/elements/containers/EventuallyPressable'
import { getLinearGradientStartEndPoint } from '~/elements/containers/gradient.helpers'
import { getPositionRelatedClasses } from '~/utils/tailwind/tw.containers.helpers'
import {
  getGradientDirection, getGradientEndColor, getGradientRelatedClasses, getGradientStartColor,
} from '~/utils/tailwind/tw.gradients.helpers'

export const GradientCard: FC<{
  tw: string,
}> = (props) => {
  // console.log('GradientButton')
  const {
    tw,
  } = props

  const tailwind = useTailwind()
  const ref = useRef()
  const hover = useHover(ref)
  const focus = useFocus(ref)
  const active = useActive(ref)

  const interactionState = { active, hover, focus }
  // console.log('interactionState', interactionState)

  // We convert gradient classes into a set of props for LinearGradient component
  const { gradientTw, nonGradientTw } = getGradientRelatedClasses(tw)

  const { positionTw, nonPositionTw } = getPositionRelatedClasses(nonGradientTw)
  // console.log('gradientTw', gradientTw)
  // console.log('nonPositionTw', nonPositionTw)

  const gradientDirection = getGradientDirection(gradientTw)
  // console.log('gradientDirection', gradientDirection)
  const { startPoint, endPoint } = getLinearGradientStartEndPoint(gradientDirection, 0.3, 0)
  // console.log('startPoint', startPoint)
  // console.log('endPoint', endPoint)

  const startColor = getGradientStartColor(gradientTw, interactionState) as string
  const endColor = getGradientEndColor(gradientTw, interactionState) as string
  // console.log('startColor', startColor)
  // console.log('endColor', endColor)

  return (
    <EventuallyPressable {...props} tw={positionTw}>
      <LinearGradient
        style={tailwind(nonPositionTw)}
        colors={[startColor, endColor]}
        locations={[0.1, 0.9]} // restrict gradient to in-between these location values
        start={startPoint}
        end={endPoint}
      >
        {props.children}
      </LinearGradient>
    </EventuallyPressable>
  )
}
