import { FC } from 'react'

import { Pressable, PressableProps } from '~/elements'

import { CtaBackground } from '../images/CtaBackground'

type PressableSvgProps = PressableProps & {
    /**
     * Dato CMS asset id
     */
    assetId:string
    assetTw:string
    pressed: boolean
}

export const PressableSvg:FC<PressableSvgProps> = ({
  assetId, children, assetTw, pressed = true, ...props
}) => (
  <Pressable
    {...props}
  >
    {pressed && <CtaBackground tw={assetTw} assetId={assetId}/>}
    {children}
  </Pressable>
)
