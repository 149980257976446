import { useLinkTo } from '@react-navigation/native'
import omit from 'lodash/omit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import slugify from 'slugify'

import { LocalContentRecord } from '~/definitions/aloglia'
import { useDurationFormat } from '~/hooks/dateFns'
import { useUser } from '~/hooks/user.context'

import { ContentTile } from '../panels/tiles/ContentTile'

type HitProps = {
  hit: LocalContentRecord,
  insights
}

/**
 * Snippet presenter for Content Search results
 */
export const Hit:FC<HitProps> = ({ hit, insights }) => {
  // console.log('Hit', hit)
  const { user } = useUser()
  const { t } = useTranslation(['common', 'player'])
  const linkTo = useLinkTo()
  const duration = useDurationFormat({ minutes: Math.round((hit.readTime || 60) / 60) }, { format: ['minutes'] })

  let metadata = ''
  // if (hit.author) {
  //   metadata += `${upperFirst(t('common:media.byAuthor'))} ${getFullName(hit.author)}`
  // }
  if (hit.readTime) {

    // console.log('duration', duration)
    metadata += t('player:inlineReadTime', { duration })
  }

  // Custom on Press in order to properly report analytics to Algolia
  const onPress = () => {
    // console.log('-> onNavigableItemPressed')
    insights('clickedObjectIDsAfterSearch', {
      eventName: 'Product Clicked',
      userToken: user?.uid,
    })

    linkTo(`/content/${hit.objectID}`)
  }

  return <ContentTile
    testID={slugify(`/content/${hit.objectID}`)}
    title={hit.title}
    metadata={metadata}
    description={hit.excerpt}
    image={{ ...omit(hit.cover, 'main'), illustration: hit.cover?.main }}
    onPress={onPress}
    orientation="landscape"
  />
}
