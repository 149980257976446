import Color from 'color'
import orderBy from 'lodash/orderBy'

import { getColor } from '~/theme/helpers'

// TW classes
const testColors = ['white', 'black']

/**
 * based on a given backgrounc color, will determine if white or black is best contrasting color
 * base on https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
 * @param backgroundColor - TW color class
 */
export const getBestTextColor = (backgroundColor:string) => {
  const bgColor = Color(getColor(backgroundColor))
  const contrasts = testColors.map((color) => ({ color, contrast: bgColor.contrast(Color(getColor(color))) }))
  const orderedContrasts = orderBy(contrasts, (o) => o.contrast, 'desc')

  return orderedContrasts[0].color
}
