import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import {
  FieldValues, FormProvider, useForm, useWatch,
} from 'react-hook-form'

import {
  View,
} from '~/elements'

import { Field } from './input-groups/Field'
// import { ProgressFooter } from './ProgressFooter'
import { SubInputElement } from './SubInputElement'

type WizardProps = {
  labelTw?:string
  steps:Array<any>
  initialValues:FieldValues
  onSubmit: (values:FieldValues) => void
  tw?:string
  validationSchema?:any
  style?:any
}

/**
 * Wizard is a single Formik instance whose children are each page of the
 * multi-step form. The form is submitted on each forward transition (can only
 * progress with valid input), whereas a backwards step is allowed with
 * incomplete data. A snapshot of form state is used as initialValues after each
 * transition. Each page has an optional submit handler, and the top-level
 * submit is called when the final page is submitted.
 */
export const Wizard:FC<WizardProps> = ({
  labelTw, steps, initialValues, onSubmit, tw, validationSchema, style,
}) => {
  // console.log('-> Wizard')

  const [stepNumber, setStepNumber] = useState(0)

  // used to measure time to complete
  const [landedAt, setLandedAt] = useState<number>()

  const step = steps[stepNumber]
  // console.log('current step', step)
  const totalSteps = steps.length
  const isLastStep = stepNumber === totalSteps - 1

  // const { t } = useTranslation(['forms'])

  useEffect(() => {
    if (!landedAt) {
      setLandedAt(new Date().getTime())
    }
  }, [landedAt])

  const next = () => {
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1))
  }

  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    shouldUseNativeValidation: true,
    resolver: yupResolver(validationSchema),
  })

  const currentStepValue = useWatch({ control: methods.control, name: step.name })
  // console.log('currentStepValue', currentStepValue)

  const onPressNextOrSubmit = async () => {

    // console.log('onPressNextOrSubmit for', step.name)

    // Submit on last step
    if (isLastStep) {
      // console.log('last step; will submit response')

      // add metadata to the response
      const metadata = {
        landedAt,
        submittedAt: new Date().getTime(),
      }
      methods.setValue('metadata', metadata)

      methods.handleSubmit(onSubmit)()
    } else {
      // Validate and go to next step
      const stepValidationResult = await methods.trigger(step.name)

      // Move next step unless errors
      if (stepValidationResult) {
        // console.log('step valid; will move to next one')
        next()
      }
      // console.log('invalid step')
    }

    return true
  }

  const currentStepError = methods.formState.errors[step.name]
  // console.log('currentStepError', currentStepError)

  return (
    <FormProvider {...methods}>
      <View tw={tw} style={style}>
        <Field labelTw={labelTw} {...step} helper={step.help} align="left" onEnterKeyPress={onPressNextOrSubmit} />
        <View tw="">
          { currentStepValue !== '' && !currentStepError
              && <SubInputElement isLastStep={isLastStep} onPress={onPressNextOrSubmit}/>}
        </View>

        {/* Footer */}
        {/* <ProgressFooter
            handleSubmit={handleSubmit}
            previous={previous}
            stepNumber={stepNumber}
            totalSteps={totalSteps}
          /> */}

      </View>
    </FormProvider>
  )
}
