import { FC } from 'react'
import {
  ChannelListMessengerProps as RnChannelListMessengerProps,
} from 'stream-chat-react'

import { View } from '~/elements'

import { ChatDown } from '../ChatDown/ChatDown'
import { LoadingChannels } from '../Loading/LoadingChannels'
// import { LoadingChannels } from '../Loading/LoadingChannels'

export type ChannelListMessengerProps = RnChannelListMessengerProps

/**
 * A preview list of channels, allowing you to select the channel you want to open
 */
export const ChannelListMessenger:FC<ChannelListMessengerProps> = (props) => {
  const {
    children,
    error = false,
    loading,
    LoadingErrorIndicator = ChatDown,
    LoadingIndicator = LoadingChannels,
  } = props

  if (error) {
    return <LoadingErrorIndicator type='Connection Error' />
  }

  if (loading) {
    return <LoadingIndicator />
  }

  return (
    <View tw="">
      <View tw="">{children}</View>
    </View>
  )
}
