/**
 * Screen wrapping a sub evaluation navigator: containing all evaluations, formerly associated with Profile tab
 */

import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'

import { EvaluationsStackParamList } from '~/navigators/EvaluationsStackParamList'

import { EvaluationsNavigator } from '../../navigators/EvaluationsNavigator'

export type EvaluationsScreenProps = StackScreenProps<EvaluationsStackParamList>

export const AllEvaluationsScreen: FC<EvaluationsScreenProps> = () => <EvaluationsNavigator />
