/* eslint-disable global-require */

import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { registerRootComponent } from 'expo'
import {
  APP_VERSION,
  ENV, SENTRY_ENDPOINT_ID, SENTRY_PROJECT_ID,
  SENTRY_PUBLIC_KEY,
} from 'react-native-dotenv'

import { AppBackgroundContextProvider } from '~/context/AppBackgroundContext'

import { AppWithBgContext } from './AppWithBgContext'

if (SENTRY_PUBLIC_KEY) {
  // console.log('will init Sentry')
  init({
    dsn: `https://${SENTRY_PUBLIC_KEY}@${SENTRY_ENDPOINT_ID}.ingest.sentry.io/${SENTRY_PROJECT_ID}`,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: ENV,
    release: `v${APP_VERSION}`,
  })
}

// TODO: dark mode
// const colorScheme = useColorScheme()

export default registerRootComponent(() => (
  <AppBackgroundContextProvider>
    <AppWithBgContext />
  </AppBackgroundContextProvider>
))
