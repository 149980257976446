import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { useTailwind } from 'tailwind-rn'

import { View } from '~/elements/containers/View'

import { getRoundingClasses } from './tw.helpers'

type HtmlImageProps = {
  id?: string
  tw?: string
  imageTw?:string
  alt: string
  url: string
  children?: ReactNode
}

/**
* The HTML original <image>.
*
* Used to display SVG and non responsive images
*
* We wrap image in a div that controls the aspect ratio while image takes full width/height
*/
export const ImageHtml: FC<HtmlImageProps> = ({
  alt, url, id, tw, children, imageTw,
}) => {
  const tailwind = useTailwind()
  // console.log('tw', tw)
  const { roundedTw } = getRoundingClasses(tw)

  const containerTw = tw
  // console.log(`containerTw`, containerTw)

  const newImageTw = classNames(roundedTw, imageTw)
  // console.log('newImageTw', newImageTw)

  return (
    <View
      testID={id}
      tw={containerTw}
    >
      <img
        alt={alt}
        width="100%"
        height="100%"
        style={tailwind(newImageTw)}
        src={url}
      />
      {children}
    </View>
  )
}

ImageHtml.defaultProps = {
  tw: '',
}
