import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire'

import { meetingStatus, meetingStatusDetails } from '~/constants/firestore.meetings'
import { DocumentReference, EventuallyDocumentReference } from '~/definitions/firebase.firestore.types'
import { MeetingData } from '~/definitions/firestore.meeting'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getMeetingRef } from '~/queries/firestore.helpers'
import {
  getFirstMeetingsByParticipantsQuery,
  getMeetingByCalendlyIdQuery,
  getMeetingsByStatusQuery,
  getMeetingsForTrack,
  getUserOrientationMeetingsByTrackQuery,
  getUserOrientationMeetingsQuery,
  nextPlannedMeetingsQuery,
  pastMeetingsQuery,
} from '~/queries/firestore.meetings'

import { ArrayDocsQueryResult, SingleDocQueryResult } from '../definitions/firestore.queries'
import { useDurationFormat } from './dateFns'
import {
  withArrayDocQueryResultAdapter, withGetDocResultAdapter, withSingleDocQueryResultAdapter, withTakeFirstResultAdapter,
} from './firestore.hooks.helpers'
import { useCurrentUserRef } from './firestore.users'
import { useDurationToTimestamp, useNow } from './time'
import { useUser } from './user.context'

const useNextPlannedMeetingsQuery = (limit:number) => {
  const userRef = useCurrentUserRef()
  // console.log(`userRef.path`, userRef.path)
  const now = useNow()
  const query = nextPlannedMeetingsQuery({ userRef, now }, { db: useFirestore(), limit })

  return useFirestoreCollection<MeetingData>(query)
}

export const useCurrentUserNextPlannedSessions = (limit = 1): ArrayDocsQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserNextSessions')
  const res = useNextPlannedMeetingsQuery(limit)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserNextPlannedSession = (): SingleDocQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserNextSessions')
  const res = useNextPlannedMeetingsQuery(1)

  return withTakeFirstResultAdapter(res)
}

export const useCurrentUserPastSessions = (): ArrayDocsQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef()
  const now = useNow()
  const query = pastMeetingsQuery({ userRef, now }, { db: useFirestore(), limit: 3 })
  const res = useFirestoreCollection<MeetingData>(query)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserDoneSessions = (): ArrayDocsQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef()

  const query = getMeetingsByStatusQuery({ userRef, status: meetingStatus.DONE }, { db: useFirestore(), limit: 3 })
  const res = useFirestoreCollection<MeetingData>(query)

  return withArrayDocQueryResultAdapter(res)
}

export const useMeetingByCalendlyId = (calendlyId: string): SingleDocQueryResult<MeetingData> => {
  // console.log('-> useMeetingByCalendlyId')
  const query = getMeetingByCalendlyIdQuery({ calendlyId }, { db: useFirestore() })
  const res = useFirestoreCollection<MeetingData>(query)

  return withSingleDocQueryResultAdapter(res)
}

export const useCurrentUserLatestTrackMeetings = (): ArrayDocsQueryResult<MeetingData> => {
  const { userTrackDoc } = useUser()

  const query = getMeetingsForTrack({ trackRef: userTrackDoc.ref || null }, { db: useFirestore() })
  const res = useFirestoreCollection<MeetingData>(query)

  return withArrayDocQueryResultAdapter(res)
}

export const useCurrentUserOrientationMeetings = (): ArrayDocsQueryResult<MeetingData> => {
  // console.log('-> useCurrentUserOrientationMeetings')
  const userRef = useCurrentUserRef()
  const query = getUserOrientationMeetingsQuery({ userRef }, { db: useFirestore() })
  const res = useFirestoreCollection<MeetingData>(query)

  return withArrayDocQueryResultAdapter(res)
}

export const useMeetingByRef = (ref:EventuallyDocumentReference<MeetingData>): SingleDocQueryResult<MeetingData> => {

  const res = useFirestoreDoc<MeetingData>(ref, {
    idField: 'id', // this field will be added to the object created from each document
  })

  return withGetDocResultAdapter(res)

}

export const useMeetingById = (meetingId?: string): SingleDocQueryResult<MeetingData> => {
  // console.log('-> useMeetingById')
  const ref = getMeetingRef(meetingId, useFirestore())

  return useMeetingByRef(ref)
}

export const useCurrentUserTrackOrientationMeeting = (): SingleDocQueryResult<MeetingData> => {

  const { userTrackDoc } = useUser()
  // console.log('current user ref ', data?.ref.path)

  const query = getUserOrientationMeetingsByTrackQuery({ trackRef: userTrackDoc.ref || null }, { db: useFirestore() })
  const result = useFirestoreCollection<MeetingData>(query)
  // console.log('results', result?.data?.docs.map((doc) => console.log(`yup-${doc.id}`)).join(', '))

  return withSingleDocQueryResultAdapter(result)
}

export const useGetMeetingsForTrack = (trackRef: DocumentReference<UserTrackData>): ArrayDocsQueryResult<MeetingData> => {
  const query = getMeetingsForTrack({ trackRef }, { db: useFirestore() })
  const res = useFirestoreCollection<MeetingData>(query)

  const queryRes = withArrayDocQueryResultAdapter(res)

  if (queryRes.data) {
    return {
      ...queryRes,
      data: queryRes.data.filter((doc) => meetingStatusDetails[doc.data().status].countAgainstPlan === true),
    }
  }

  return queryRes
}

export const useGetFirstMeetingWithMinder = (minder : string| null = null): SingleDocQueryResult<MeetingData> => {
  const userRef = useCurrentUserRef() || null
  // console.log('useGetFirstMeetingWithMinder', minder, userRef?.path)

  const query = getFirstMeetingsByParticipantsQuery({ userRef, minder }, { db: useFirestore(), limit: 1 })
  const res = useFirestoreCollection<MeetingData>(query)

  return withSingleDocQueryResultAdapter(res)
}

export const useGetDurationSinceFirstMeetingWithMinder = (minder:string) => {
  const { loading, error, data: firstMeetingDoc } = useGetFirstMeetingWithMinder(minder)

  const since = firstMeetingDoc?.data()?.start_time
  // console.log('since', since)

  const duration = useDurationToTimestamp(since)
  // console.log('duration', duration);
  const format = Object.entries(duration).map(([key, val]) => ({ key, val })).filter((o) => o.val > 0)[0]?.key
  const durationStr = useDurationFormat(duration, { format: [format] })

  return { loading, error, data: duration.isFuture ? durationStr : null }
}
