import {
  createContext, FC, ReactNode, useMemo,
} from 'react'

import { QueryDocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { useUserTrackDocByConversationId } from '~/hooks/firestore.userTracks'

type MissiveConversationContextType = {
  loading: boolean
  trackDoc: QueryDocumentSnapshot<UserTrackData>
} | undefined

export const MissiveConversationContext = createContext<MissiveConversationContextType>(undefined)

type MissiveConversationContextProps = {
  conversationId: string
  children: ReactNode
}

export const MissiveConversationProvider: FC<MissiveConversationContextProps> = ({ conversationId, children }) => {
  // console.log('-> MissiveConversationProvider', conversationId)

  // const conversationId = useConversationId()
  // console.log(`conversationId`, conversationId)

  const { loading, data } = useUserTrackDocByConversationId(conversationId)
  // if(data) console.log(`data`, data.data())

  const result = useMemo(() => ({
    loading,
    trackDoc: data,
  }), [loading, data])

  return <MissiveConversationContext.Provider value={result}>{children}</MissiveConversationContext.Provider>
}
