import { gql } from '@apollo/client'

export const GET_EVALUATION_MODEL = gql`
query GetEvaluationModelQuery($slug: String, $locale: SiteLocale) {
    evaluation(filter: {slug: {eq: $slug}}, locale: $locale) {
    slug
    title
    scoreIntro
    scoreOutro
    backgroundColor
    background {
      url
      format
      title
      alt
      height
      width
    }
    illustration {
      url
      format
      title
      alt
      height
      width
    }
    dimensionScoring {
      key
      title
      description
      excerpt
      illustration {
        url
        format
        title
        alt
        height
        width
      }
      linkedValues {
        key
        name
      }
      segments {
        key
        title
        explainer
        description
      }
      visualisationType,
      visualisationMaxValue,
      visualisationMinValue,
    }
    dimensionGroups {
      key
      title
      description
      excerpt
      illustration {
        url
        format
        title
        alt
        height
        width
      }
      dimensions {
        key
      }
    }
  }
  }`
