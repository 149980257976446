import { FC } from 'react'
import { Image as DatoImage } from 'react-datocms'
import { useTailwind } from 'tailwind-rn'

import { ImageHtml } from './ImageHtml.web'
import { ImageProps } from './ImageProps'

/**
 * Depending on data available, we either serve a
 * https://github.com/datocms/react-datocms#progressiveresponsive-image
 * TODO: interpret TW classes as specific props for the Dato component, similar to what we've done in Gatsby
 */
export const ResponsiveImageWeb: FC<ImageProps> = ({
  testID, tw, alt, url, responsiveImage,
}) => {
  const tailwind = useTailwind()

  // console.log('-> ResponsiveImageWeb', responsiveImage)
  if (!responsiveImage) {
    return <ImageHtml alt={alt} url={url} id={testID} tw={tw} />
  }

  // console.log('Will use Dato Image component')
  // console.log('tw', tw)

  return <DatoImage
    data={responsiveImage}
    style={tailwind(tw)}
  />
}
