import classNames from 'classnames'
import { TextInput as RnTextInput } from 'dripsy'
import { FC, useRef } from 'react'

import { useStateAwareTailwind } from '~/hooks/tailwind'

import { adaptOutlineClasses, adaptRingClasses } from '../containers/tw.helpers'
import { View } from '../containers/View'
import Icon from '../icons/Icon'
import { Text } from '../text/Text'
import { TextInputProps } from './TextInputProps'

const wrapperBaseStyle = 'mb-2 mt-2 flex-col'
const containerBaseStyle = 'flex-row mt-1 border-b border-gray-300 focus:border-brand-darker'
// eslint-disable-next-line max-len
// const containerBaseStyleError = 'block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md'
// const disabledInputBaseStyle = ''
const errorBaseStyle = 'mt-2 text-sm text-red-700'
// const inputContainerBaseStyle = ''
const inputBaseStyle = 'px-3 py-2 block w-full border-0 border-b border-transparent bg-gray-100 outline-none focus:border-brand-darker focus:ring-0 text-sm sm:text-base'
const labelBaseStyle = 'block text-sm font-medium text-gray-700'
const helperBaseStyle = 'mt-2 text-sm text-gray-500'
const leftIconBaseStyle = 'h-5 w-5 text-gray-500'
const leftIconContainerBaseStyle = 'absolute inset-y-0 left-0 flex justify-center pointer-events-none pl-3'
const rightIconBaseStyle = 'h-5 w-5 text-gray-500'
const rightIconContainerBaseStyle = 'absolute inset-y-0 right-0 pr-3 flex justify-center pointer-events-none pr-3'

/**
 *
 * Tailwindised RN Input
 * See https://tailwindui.com/components/application-ui/forms/input-groups
 * and https://reactnativeelements.com/docs/input
 *
 * TODO
 * - floating label https://www.youtube.com/watch?v=nJzKi6oIvBA
 * - Check if some of the non supported classes can be substituted
 *
 * check https://www.npmjs.com/package/react-native-input-outline
 *
 */
export const TextInput: FC<TextInputProps> = ({
  tw = '',
  containerTw = '',
  wrapperTw = '',
  // disabledInputTw,
  errorTw = '',
  // inputContainerTw,
  inputTw = '',
  labelTw = '',
  helperTw = '',
  helper,
  label,
  LeftPicker,
  errorMessage,
  leftIcon = undefined,
  rightIcon = undefined,
  RightComponent,
  leftIconTw = '',
  rightIconTw = '',
  leftIconContainerTw = '',
  rightIconContainerTw = '',
  testID,
  ...props
}) => {

  // console.log('test ID', testID)

  // Note: using useState for focus wont work as not rerendered after that. better to use that hook

  const ref = useRef(null)

  const tailwind = useStateAwareTailwind(ref)

  let newInputBaseStyle = inputBaseStyle

  // const onFocus = (e) => {
  //   if (props.onFocus) props.onFocus(e)
  //   // setFocus(true)
  // }
  // const onBlur = (e) => {
  //   if (props.onBlur) props.onBlur(e)
  //   // setFocus(false)
  // }

  // if (props.style) console.log(props.style)

  // We add right/left padding to the container if absolutely positionned icons
  if (leftIcon) {
    newInputBaseStyle += ' pl-10'
  }
  if (rightIcon) {
    newInputBaseStyle += ' pr-10'
  }

  // TODO - break tw into various component styles. For the time being we apply to wrapper

  // console.log('inputBaseStyle', inputBaseStyle)

  /**
 * We add input styles with base styles
 */
  const wrapperStyle = classNames(wrapperBaseStyle, wrapperTw, tw)
  const containerStyle = classNames(containerBaseStyle, containerTw)
  // const containerStyleError = classNames(containerBaseStyleError, containerTw)
  // const disabledInputStyle = classNames(disabledInputBaseStyle, disabledInputTw)
  const errorStyle = classNames(errorBaseStyle, errorTw)
  // const inputContainerStyle = classNames(inputContainerBaseStyle, inputContainerTw)
  let inputStyle = classNames(newInputBaseStyle, inputTw)
  const labelStyle = classNames(labelBaseStyle, labelTw)
  const helperStyle = classNames(helperBaseStyle, helperTw)
  const leftIconContainerStyle = classNames(leftIconContainerBaseStyle, leftIconContainerTw)
  const rightIconContainerStyle = classNames(rightIconContainerBaseStyle, rightIconContainerTw)
  const leftIconStyle = classNames(leftIconBaseStyle, leftIconTw)
  const rightIconStyle = classNames(rightIconBaseStyle, rightIconTw)

  // replace ring- by borders eventually
  inputStyle = adaptRingClasses(inputStyle)

  const adaptOutlineClassesRes = adaptOutlineClasses(inputStyle)
  inputStyle = adaptOutlineClassesRes.tw

  const inputFinalStyle = {
    ...tailwind(inputStyle),
    ...adaptOutlineClassesRes.style,
  }

  // console.log('containerStyle', containerStyle)
  // console.log('containerStyleError', containerStyleError)
  // console.log('disabledInputStyle', disabledInputStyle)
  // console.log('errorStyle', errorStyle)
  // console.log('inputContainerStyle', inputContainerStyle)
  // console.log('inputStyle', inputStyle)
  // console.log('labelStyle', labelStyle)
  // console.log('helperStyle', helperStyle)
  // console.log('leftIconContainerStyle', leftIconContainerStyle)
  // console.log('rightIconContainerStyle', rightIconContainerStyle)

  // console.log(`props`, props)

  return (
    <View tw={wrapperStyle} testID={testID}>
      {!!label && <Text tw={labelStyle}>{label}</Text>}
      <View
        testID="input-container"
        tw={containerStyle}
      >
        {!!LeftPicker && <LeftPicker />}
        {!!leftIcon && <View tw={leftIconContainerStyle}><Icon tw={leftIconStyle} {...leftIcon} /></View>}
        <RnTextInput
          ref={ref}
          style={inputFinalStyle}
          enterKeyHint={undefined}
          {...props}
          defaultValue={undefined} // force no default value so as to always have a controlled component
        />
        {!!rightIcon && <View tw={rightIconContainerStyle}><Icon tw={rightIconStyle} {...rightIcon} /></View>}
        {!!RightComponent && <View tw={rightIconContainerStyle}><RightComponent /></View>}
      </View>
      {!!errorMessage && <Text tw={errorStyle}>{errorMessage}</Text>}
      {!!helper && <Text tw={helperStyle}>{helper}</Text>}
    </View>
  )
}
