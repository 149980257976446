/* eslint-disable max-len */
import last from 'lodash/last'
import sortBy from 'lodash/sortBy'

import { WidthMediaQueryType } from '~/hooks/mediaQuery'
import { theme } from '~/tailwind.config'

import {
  getBreakpointIndex, getCurrentBpIndex, getTwNoPrefix,
} from './tw.breakpoints.helpers'
import { getVariant } from './tw.classes.helpers'

/** ****************************************************
 * utilities
 ***************************************************** */

const getFullValue = <S, T>(root:string|undefined, variant:T):S => {
  if (root) {
    return `${root}-${variant}`
  }

  return variant
}

const getClassArr = <S>(tw = ''):Array<S> => tw.split(' ')

const getClassNamesFromRootVariants = <S, T>(root:string|undefined, variants:Array<T>):Array<S> => variants.map((variant) => getFullValue(root, variant))

const filterClassesOfCategory = <S, T>(tw:string, root:string|undefined, variants:Array<T>):Array<S> => getClassArr<S>(tw).filter((cn) => {
  // console.log('-> filterClassesOfCategory', root, variants)

  if (root) {
    return cn.includes(`${root}-`) && variants.includes(getVariant(cn))
  }

  return variants.includes(getVariant(cn))
})

const getRelevantCategoryClass = <S, T>(tw:string, mq:WidthMediaQueryType, root:string|undefined, variants:Array<T>, defaultValue:T):S => {
  const categoryClassesArr = filterClassesOfCategory<S, T>(tw, root, variants)
  // console.log('categoryClassesArr', categoryClassesArr)
  // only filter classes up to current breakpoint and return last value
  const validClasses = categoryClassesArr.filter((item) => getBreakpointIndex(item) <= getCurrentBpIndex(mq))
  // console.log('valid classes', validClasses)
  const res = last<S|undefined>(sortBy<S>(validClasses, [getBreakpointIndex])) // sortBy() as [].sort() isnt stable

  if (!res) {
    return getFullValue(root, defaultValue)
  }

  // we remove prefix, if any

  return getTwNoPrefix<S>(res)
}

const excludeClassesOfCategory = <S, T>(tw:string, root:string|undefined, variants:Array<T>):string => {
  const fullClassNameOptions = getClassNamesFromRootVariants<S, T>(root, variants)

  return getClassArr<S>(tw).filter((key) => !fullClassNameOptions.some((o) => key.includes(o))).join(' ')
}

const textPrefixList = ['font', 'text', 'leading', 'tracking']

type TextRelatedClassesType = {textTw:string, nonTextTw:string}

export const getTextRelatedClasses = (tw = ''):TextRelatedClassesType => {
  // console.log('-> sortTextRelatedClasses for ', tw)
  const arr: Array<string> = getClassArr(tw)
  const textTwArr = arr.filter((item) => textPrefixList.some((prefix) => getTwNoPrefix(item).startsWith(`${prefix}-`)))
  // console.log(`textTw`, textTwArr.join(' '))

  return {
    textTw: textTwArr.join(' '),
    nonTextTw: arr.filter((item) => !textTwArr.includes(item)).join(' '),
  }
}

/** ****************************************************
 * Text size
 ***************************************************** */
type FontSizeType = 'text-xs'| 'text-sm'| 'text-base'| 'text-lg'| 'text-xl'| 'text-2xl'| 'text-3xl'| 'text-4xl'| 'text-5xl'
type FontSizeVariantType = 'xs'| 'sm'| 'base'| 'lg'| 'xl'| '2xl'| '3xl'| '4xl'| '5xl'
const fontSizeRoot = 'text'
const fontSizeVariants:Array<FontSizeVariantType> = ['xs', 'sm', 'base', 'lg', 'xl', '2xl', '3xl', '4xl', '5xl']

export const getRelevantTextSizeClass = (tw:string, mq: WidthMediaQueryType):FontSizeType => getRelevantCategoryClass<FontSizeType, FontSizeVariantType>(tw, mq, fontSizeRoot, fontSizeVariants, 'base')

export const removeTextSizeClasses = (tw:string):string => excludeClassesOfCategory<FontSizeType, FontSizeVariantType>(tw, fontSizeRoot, fontSizeVariants)

/**
 * Automatically increase input fontSize class by one step at each mq step
 * @returns single tailwind class, no prefix
 */
export const getMqAdjustedFontSizeClass = (base: FontSizeType, mq : WidthMediaQueryType):FontSizeType => {
  // console.log(`base`, base)
  const baseIndex = fontSizeVariants.findIndex((item) => item === base.replace(`${fontSizeRoot}-`, ''))
  if (mq.xl) {
    return `text-${fontSizeVariants[baseIndex + 4] || '5xl'}`
  }
  if (mq.lg) {
    return `text-${fontSizeVariants[baseIndex + 3] || '5xl'}`
  }
  if (mq.md) {
    return `text-${fontSizeVariants[baseIndex + 2] || '5xl'}`
  }
  if (mq.sm) {
    return `text-${fontSizeVariants[baseIndex + 1] || '5xl'}`
  }

  return base
}

/** ****************************************************
 * Line height
 ***************************************************** */

// rem = 1
const baseFontHeight = 16
type LeadingType = 'leading-none'| 'leading-tight'| 'leading-snug'| 'leading-normal'| 'leading-relaxed'| 'leading-loose'
type LeadingVariantType = 'none'| 'tight'| 'snug'| 'normal'| 'relaxed'| 'loose'
const relativeLineHeightRootClass = 'leading'
const relativeLineHeightVariantClasses:Array<LeadingVariantType> = ['none', 'tight', 'snug', 'normal', 'relaxed', 'loose']

export const getLineHeight = (fSize:string, multiplier: LeadingType):number => {
  // console.log('-> getLineHeight', fSize, multiplier)
  const fontSizeClass = fSize.replace('text-', '')
  // console.log('fontSizeClass', fontSizeClass)
  const themeSize = theme.extend.fontSize[fontSizeClass]
  // console.log('themeSize', themeSize)

  const themeSizeNumber = Number(themeSize.replace('rem', '')) * baseFontHeight
  // console.log(`themeSizeNumber`, themeSizeNumber)

  switch (multiplier) {
  case 'leading-none':
    return Math.round(themeSizeNumber * 10) / 10
  case 'leading-tight':
    return Math.round(1.25 * themeSizeNumber * 10) / 10

  case 'leading-snug':
    return Math.round(1.375 * themeSizeNumber * 10) / 10

  case 'leading-normal':
    return Math.round(1.5 * themeSizeNumber * 10) / 10

  case 'leading-relaxed':
    return Math.round(1.625 * themeSizeNumber * 10) / 10

  case 'leading-loose':
    return Math.round(2 * themeSizeNumber * 10) / 10

  default:
    throw new Error(`unknown text leading class ${multiplier}`)
  }
}

export const getRelevantLineHeightClass = (tw:string, mq:WidthMediaQueryType):LeadingType => getRelevantCategoryClass<LeadingType, LeadingVariantType>(tw, mq, relativeLineHeightRootClass, relativeLineHeightVariantClasses, 'normal')

/** ****************************************************
* Font families (serif vs sans => Recoleta vs poppins)
***************************************************** */
type FontFamilyType = 'font-sans'| 'font-serif'
type FontFamilyVariantType = 'sans'| 'serif'
const fontFamilyRootClass = 'font'
const fontFamilyClasses:Array<FontFamilyVariantType> = ['sans', 'serif']

/**
 * @param tw - Tailwind classes to consider
 * @param mq - applicable media query state to test against
 * @returns the tailwind class applicable given the current media query (without prefix)
 */
export const getRelevantFontFamilyClass = (tw:string, mq:WidthMediaQueryType):FontFamilyType => getRelevantCategoryClass<FontFamilyType, FontFamilyVariantType>(tw, mq, fontFamilyRootClass, fontFamilyClasses, 'sans')

/**
* @returns same as input, without classes related to font family
*/
export const removeFontFamilyClasses = (tw:string):string => excludeClassesOfCategory<FontFamilyType, FontFamilyVariantType>(tw, fontFamilyRootClass, fontFamilyClasses)

/** ****************************************************
 * Font weight
 ***************************************************** */
 type FontWeightType = 'font-thin'| 'font-extralight'| 'font-light'| 'font-normal'| 'font-medium'| 'font-semibold'| 'font-bold'| 'font-extrabold'| 'font-black'
 type FontWeightVariantType = 'thin'| 'extralight'| 'light'| 'normal'| 'medium'| 'semibold'| 'bold'| 'extrabold'| 'black'
const fontWeightRootClass = 'font'
const fontWeightClasses:Array<FontWeightVariantType> = ['thin', 'extralight', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']

/**
 * @param tw - Tailwind classes to consider
 * @param mq - applicable media query state to test against
 * @returns the tailwind class applicable given the current media query (without prefix)
 */
export const getRelevantFontWeightClass = (tw:string, mq:WidthMediaQueryType):FontWeightType => getRelevantCategoryClass<FontWeightType, FontWeightVariantType>(tw, mq, fontWeightRootClass, fontWeightClasses, 'normal')

/**
* @returns same as input, without classes related to font weight
*/
export const removeFontWeightClasses = (tw:string):string => excludeClassesOfCategory<FontWeightType, FontWeightVariantType>(tw, fontWeightRootClass, fontWeightClasses)

/** ****************************************************
 * Italic
 ***************************************************** */
 type FontItalicType = 'italic'| 'not-italic'
const fontItalicClasses:Array<FontItalicType> = ['italic', 'not-italic']

/**
 * @param tw - Tailwind classes to consider
 * @param mq - applicable media query state to test against
 * @returns the tailwind class applicable given the current media query (without prefix)
 */
export const getRelevantFontItalicClass = (tw:string, mq:WidthMediaQueryType):FontItalicType => getRelevantCategoryClass<FontItalicType, FontItalicType>(tw, mq, undefined, fontItalicClasses, 'not-italic')

/**
* @returns same as input, without classes related to italic
*/
export const removeFontItalicClasses = (tw:string):string => excludeClassesOfCategory<FontItalicType, FontItalicType>(tw, undefined, fontItalicClasses)
