import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Avatar, Button, Pressable, Text, View,
} from '~/elements'
import { useContractorBySlug } from '~/hooks/dato.contractors'
import { useGetDurationSinceFirstMeetingWithMinder } from '~/hooks/firestore.meetings'
import { getFullName } from '~/utils/people.helpers'

library.add(faArrowRightLong)

export type MinderCardProps = {
  slug: string
}

export const MinderDesktopSideCard: FC<MinderCardProps> = ({ slug }) => {
  // console.log('-> MinderDesktopSideCard', slug)

  const { loading: minderLoading, data } = useContractorBySlug(slug)
  const { t } = useTranslation(['track'])

  const { loading: durationLoading, data: duration } = useGetDurationSinceFirstMeetingWithMinder(slug)

  // console.log('minder', minder)

  if (minderLoading || durationLoading || !data?.contractor?.minder) {
    return null
  }

  const { minder } = data.contractor

  return (
    <Pressable to={`/minders/${slug}`} testID={`minder-card-${slug}`} tw="flex-col bg-surface-1 rounded-2xl py-8 px-6 items-center">
      {minder?.picture && <Avatar size="giant" image={minder?.picture} tw="bg-basic-lighter" />}
      <Text tw="mt-2 font-serif font-medium text-xl">{getFullName(minder)}</Text>
      {
        !durationLoading
      && duration != null
      && <View tw="mt-3 rounded-full bg-brand-lighter px-4 py-1">
        <Text tw="text-surface-1 text-sm">{`${t('track:minderCard.since')} ${duration}`}</Text>
      </View>
      }
      <Button tw="mt-4" size="sm" variant="ghost" icon={{ name: 'arrow-right-long', variant: 'regular' }} title={t('track:minderCard.cta')} />
    </Pressable>
  )
}

// const { loading, data } = useCurrentUserMinder(){...data.contractor}
