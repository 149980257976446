import { gql } from '@apollo/client'

import { DatoReponsiveImage } from '~/definitions/dato.react'

export const GET_MINDER_BY_SLUG = gql`
  query GetMinderBySlugQuery($slug: String!) {
    minder(filter: { slug: { eq: $slug } }) {
      id
      gender
      slug
      firstName
      minddayEmail
      lastName
      calendly
      home {
        city
      }
      picture {
        url
          format
        responsiveImage(
          imgixParams: { fit: crop, w: 200, h: 200, auto: format }
        ) {
          # HTML5 src/srcset/sizes attributes
          srcSet
          webpSrcSet
          sizes
          src

          # size information (post-transformations)
          width
          height
          aspectRatio

          # SEO attributes
          alt
          title

          # background color placeholder or...
          bgColor

          # blur-up placeholder, JPEG format, base64-encoded
          base64
        }
      }
    }
  }
`

export const GET_MINDER_BY_CALENDLY_ID = gql`
  query GetMinderByCalendlyIdQuery($calendlyId: String!) {
    minder(filter: { calendlyUuid: { eq: $calendlyId } }) {
      gender
      slug
      firstName
      minddayEmail
      lastName
      calendly
      picture {
        url
          format
        responsiveImage(
          imgixParams: { fit: crop, w: 200, h: 200, auto: format }
        ) {
          # HTML5 src/srcset/sizes attributes
          srcSet
          webpSrcSet
          sizes
          src

          # size information (post-transformations)
          width
          height
          aspectRatio

          # SEO attributes
          alt
          title

          # background color placeholder or...
          bgColor

          # blur-up placeholder, JPEG format, base64-encoded
          base64
        }
      }
    }
  }
`

export type MinderQueryResult = {
  gender: string
  firstName: string
  minddayEmail: string
  lastName: string
  calendly: string
  picture: DatoReponsiveImage
}
