import {
  limit,
  orderBy, query, where,
} from 'firebase/firestore'

import {
  ByUserRefQueryParams, ByUserRefSlugNowQueryParams,
  ByUserRefSlugQueryParams, WithDbLimitQueryOptions, WithDbQueryOptions,
} from '~/definitions/firestore.queries'

import { getEvaluationCol } from './firestore.helpers'

export const nextEvaluationsByTypeQuery = (
  { userRef, now, slug }: ByUserRefSlugNowQueryParams,
  { db, limit: limitOpt }: WithDbLimitQueryOptions,
) => query(
  getEvaluationCol(db),
  where('user', '==', userRef),
  where('slug', '==', slug),
  where('date', '>=', now),
  orderBy('date', 'asc'),
  limit(limitOpt || 1),
)

export const latestEvaluationsByTypeQuery = (
  { userRef, slug }: ByUserRefSlugQueryParams,
  { db, limit: limitOpt }: WithDbLimitQueryOptions,
) => query(
  getEvaluationCol(db),
  where('user', '==', userRef),
  where('slug', '==', slug),
  orderBy('date', 'desc'),
  limit(limitOpt || 1),
)

export const getAllEvaluationsQuery = (
  { userRef }: ByUserRefQueryParams,
  { db }: WithDbQueryOptions,
) => query(
  getEvaluationCol(db),
  where('user', '==', userRef),
  orderBy('date', 'desc'),
)

export const getUserEvaluationsByTypeQuery = (
  { userRef, slug }: ByUserRefSlugQueryParams,
  { db }: WithDbQueryOptions,
) => query(
  getEvaluationCol(db),
  where('slug', '==', slug),
  where('user', '==', userRef),
)
