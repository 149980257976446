import { useTranslation } from 'react-i18next'

import { Badge } from '~/elements'
import { useGetDurationSinceFirstMeetingWithMinder } from '~/hooks/firestore.meetings'

export const MinderSinceBadge = ({ minder }) => {
  // console.log('-> MyMinderSinceBadge', minder)
  const { loading, data: duration } = useGetDurationSinceFirstMeetingWithMinder(minder)
  const { t } = useTranslation(['track'])

  if (loading || !duration) {
    return null
  }

  return <Badge value={t('track:minder.yourMinderDuration', { duration })} />

}
