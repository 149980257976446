import { FC } from 'react'

import { Avatar } from '../avatar/Avatar'
import { AvatarProps } from '../avatar/Avatar.d'
import { View } from '../containers/View'
import Icon from '../icons/Icon'
import { IconProps } from '../icons/Icon/IconProps.d'

type ListItemLeftAccessoryProps = {
  icon?: IconProps
  avatar?: AvatarProps
  iconStyle?: string
  avatarStyle?: string
}
const leftAccessoryContainerBaseTw = 'mr-2 justify-center items-center'
/**
 * Left Accessory is one of Avatar, Icon,
 */
export const ListItemLeftAccessory: FC<ListItemLeftAccessoryProps> = ({
  icon, avatar, avatarStyle, iconStyle,
}) => {
  if (avatar) {
    return <View tw={leftAccessoryContainerBaseTw}><Avatar {...avatar} tw={avatarStyle} /></View>
  }
  if (icon) {
    return <View tw={leftAccessoryContainerBaseTw}><Icon {...icon} tw={iconStyle} /></View>
  }

  return null
}
