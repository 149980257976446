import classNames from 'classnames'
import camelCase from 'lodash/camelCase'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { cardBrandShadowStyle } from '~/components/panels/cards/CtaCard'
import {
  Button, Image,
  Pressable, Text, View,
} from '~/elements'
import { useDatoSvg } from '~/hooks/dato.assets'

type MindexCardProps = {
  mode: 'result' | 'cta',
  status: 'non-init' | 'valid' | 'obsolete',
  score?: number
}

export const MindexCard: FC<MindexCardProps> = ({
  status,
  mode,
  score,
}) => {
  const { t } = useTranslation(['home'])
  const [mindexLevel, setMindexLevel] = useState<null | '1' | '2' | '3' | '4'>(null)
  const { data } = useDatoSvg('39077892')

  useEffect(() => {
    if (score) {
      if (score <= 28) {
        setMindexLevel('1')
      }
      if (score > 28 && score <= 50) {
        setMindexLevel('2')
      }
      if (score > 50 && score <= 75) {
        setMindexLevel('3')
      }
      if (score > 75) {
        setMindexLevel('4')
      }
    }
  }, [score])

  const to = mode === 'cta' ? '/evaluations/WHO-5/new' : undefined

  const camelCaseStatus = camelCase(status) as 'nonInit' | 'valid' | 'obsolete'
  const ctaText = t(`home:mindex.cta.${camelCaseStatus}`)
  const hoverTw = to ? 'cursor-pointer' : 'cursor-default'
  const pressableTw = classNames('bg-surface-1 rounded-xl p-6 w-full items-center', hoverTw)

  return (
    <Pressable tw={pressableTw} to={to} style={cardBrandShadowStyle}>
      <Text tw="font-serif text-base font-semibold sm:text-lg leading-snug text-gray-900">{t('home:mindex.title')}</Text>
      { data && <Image {...data.upload} tw="w-48" /> }
      <View tw="text-center w-3/4">
        {status === 'non-init' && <Text tw="text-basic-darkest">{t('home:mindex.cta.explanation')}</Text>}
        {status !== 'non-init' && (<>
          { score && <Text tw="font-serif font-semibold text-lg sm:text-xl leading-snug text-brand-base mb-2">{score}</Text> }
          { mindexLevel && <Text tw="text-basic-darkest">{t(`home:mindex.result.${mindexLevel}`)}</Text> }
        </>
        )}
      </View>
      {mode !== 'result' && <Button tw="mt-4" title={ctaText} />}
    </Pressable>
  )
}
