import classNames from 'classnames'
import { FC, KeyboardEvent } from 'react'
import {
  FieldName, FieldValues, RegisterOptions, useController, useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { TextInput, TextInputProps } from '~/elements'
import { getColor } from '~/theme/helpers'

export type PasswordInputProps<
TFieldValues extends FieldValues = FieldValues
> = TextInputProps & {
  name:FieldName<TFieldValues>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >
  defaultValue?:unknown
  align?:'left' | 'right' | 'center'
  onEnterKeyPress:() =>void
}

export const PasswordInput:FC<PasswordInputProps> = ({
  name,
  rules = {

  },
  defaultValue,
  onEnterKeyPress,
  align = 'left',
  helperTw,
  wrapperTw,
  labelTw,
  containerTw,
  inputTw,
  placeholderTextColor,
  ...rest
}) => {

  const { control } = useFormContext()
  const { t } = useTranslation('forms')

  const defaultRules = {
    minLength: {
      value: 8,
      message: t('forms:password.too_short'),
    },
  }

  const {
    field: {
      onChange, onBlur, name: fieldName, value, ref,
    },
    fieldState: { invalid, isTouched, error },
    // formState: { touchedFields, dirtyFields },

  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
    defaultValue: defaultValue || '',
  })

  return (
    <TextInput
      errorMessage={ isTouched && invalid ? error?.message : undefined}
      testID={name}
      autoFocus
      onChangeText={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={fieldName} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      keyboardType="default"
      textContentType="password"
      autoCompleteType="password"
      secureTextEntry={true}
      passwordRules="minlength: 10; required: lower; required: upper; required: digit; required: [-];"
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && onEnterKeyPress) {
          onEnterKeyPress()
        }
      }}
      {...rest}
      // custom styles for email input
      wrapperTw={classNames('w-full', wrapperTw)}
      helperTw={classNames(`text-${align}`, helperTw)}
      labelTw={classNames(`font-normal text-sm leading-relaxed text-gray-500 text-${align}`, labelTw)}
      containerTw={classNames('border-gray-300', containerTw)}
      inputTw={classNames(`bg-transparent text-${align}`, inputTw)}
      placeholderTextColor={classNames(getColor('gray-300'), placeholderTextColor)}

    />
  )
}
