import { getDoc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import compact from 'lodash/compact'
import findKey from 'lodash/findKey'
import get from 'lodash/get'
import pick from 'lodash/pick'
import {
  createContext, ReactNode, useEffect, useMemo, useState,
} from 'react'

import { UserInfo } from '~/definitions/firebase.auth.types'
import { UserData } from '~/definitions/firestore.user'
import { useFunctions } from '~/hooks/firebase'
import { useDocConnectedToMissiveConversation } from '~/hooks/missive.conversations'
import { customerIoSegmentsIds } from '~/vendors/customer.io/customers.segments'

type BeneficiaryContextType = UserData & UserInfo

export const BeneficiaryContext = createContext<BeneficiaryContextType | undefined>(undefined)

type BeneficiaryContextProps = {
  children: ReactNode
}

/**
 * Base on a conversation loaded in Missive, we provide associated Beneficiary data
 * coming from various sources (Firestore, Auth, Segment...)
 */
export const BeneficiaryProvider = ({ children }: BeneficiaryContextProps) => {
  // console.log('-> BeneficiaryProvider')

  const functionsUS = useFunctions('us-central1')
  const functionsEU = useFunctions('europe-west3')

  const reqResult = useDocConnectedToMissiveConversation()
  // console.log(`reqResult`, reqResult)

  const uid = get(reqResult, 'trackDoc.ref.parent.parent.id')
  // console.log(`uid`, uid)

  /**
   * Data states
   */
  const [userInfo, setUserInfo] = useState<UserInfo>()
  const [userData, setUserData] = useState<UserData>()
  const [segments, setSegments] = useState([])

  /**
   * Loading indicators
   */
  const [isAuthLoading, setAuthLoading] = useState<boolean>(false)
  const [isFirestoreLoading, setFirestoreLoading] = useState<boolean>(false)
  const [isCustomerIoLoading, setCustomerIoLoading] = useState<boolean>(false)

  /**
   * Load user info from Firebase Auth
   */
  useEffect(() => {
    const getUserInfo = async () => {
      setAuthLoading(true)
      try {
        const proxUserInfo = await httpsCallable(functionsEU, 'httpGetAuthUser')({ uid })
        // console.log(`proxUserInfo`, proxUserInfo.data)
        if (proxUserInfo) {
          setUserInfo(proxUserInfo.data)
        }
      } catch (error) {
        console.error(error)
      }
      setAuthLoading(false)
    }
    if (uid) {
      getUserInfo()
    }
    // purposly ignored functionsEU
  }, [uid])

  /**
   * Load user data from Firestore
   */
  useEffect(() => {
    const getUserData = async () => {
      setFirestoreLoading(true)
      try {
        const userDoc = await getDoc(get(reqResult, 'userTrackDoc.ref.parent.parent'))
        if (userDoc.exists) {
          // console.log(`userData`, userDoc.data())
          setUserData(userDoc.data())
        }
      } catch (error) {
        console.error(error)
      }
      setFirestoreLoading(false)
    }
    if (uid) {
      getUserData()
    }
    // purposly ignored db
  }, [uid, reqResult])

  /**
   * Load segments from Customer.io
   */
  useEffect(() => {
    const getCustomerSegmentsContext = async () => {
      setCustomerIoLoading(true)
      try {
        const { data } = await httpsCallable(functionsUS, 'httpGetCustomerSegment')(uid)
        // console.log('user segments', data)

        if (data) {
          // map to known constants
          const custSegments = compact(data.map((item) => findKey(customerIoSegmentsIds, (o) => o === item.id)))
          // console.log(`custSegments`, custSegments)
          setSegments(custSegments)
        }
      } catch (error) {
        console.error(error)
      }
      setCustomerIoLoading(false)
    }
    if (uid) {
      getCustomerSegmentsContext()
    }
    // purposly ignored functionsUS
  }, [uid])

  const result = useMemo(() => ({
    user: {
      ...pick(userInfo, ['uid', 'displayName', 'email', 'phoneNumber', 'photoURL']),
      ...userData,
      segments,
    },
    loading: isFirestoreLoading || isAuthLoading || isCustomerIoLoading,
  }), [userInfo, userData, segments, isFirestoreLoading, isAuthLoading, isCustomerIoLoading])

  // if (isFirestoreLoading) console.log('Firestore still loading...')
  // if (isAuthLoading) console.log('Auth still loading...')
  // if(isCustomerIoLoading) console.log('Customer.io still loading...')

  // console.log('beneficiary context', result)

  return <BeneficiaryContext.Provider value={result}>{children}</BeneficiaryContext.Provider>
}
