import { FC } from 'react'
import { CardProps as RnCardProps } from 'react-native-elements'
import RnCardBase from 'react-native-elements/dist/card/Card'
import CardDivider from 'react-native-elements/dist/card/CardDivider'
import CardFeaturedSubtitle from 'react-native-elements/dist/card/CardFeaturedSubtitle'
import CardFeaturedTitle from 'react-native-elements/dist/card/CardFeaturedTitle'
import CardImage from 'react-native-elements/dist/card/CardImage'
import CardTitle from 'react-native-elements/dist/card/CardTitle'

export type CardProps = Record<string, unknown> & RnCardProps

/**
 * See
 * https://reactnativeelements.com/docs/card
 */
export const CardBase: FC<CardProps> = ({ containerStyle, ...props }) => {
  const newContainerStyle = {
    ...containerStyle,
    borderRadius: 12,
  }

  return <RnCardBase {...props} containerStyle={newContainerStyle} />
}

const Card = Object.assign(CardBase, {
  Divider: CardDivider,
  Title: CardTitle,
  Image: CardImage,
  FeaturedTitle: CardFeaturedTitle,
  FeaturedSubtitle: CardFeaturedSubtitle,
})

export default Card
