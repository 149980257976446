import * as Sentry from '@sentry/react'
import cleanDeep from 'clean-deep'
import {
  getAnalytics, logEvent, setUserId, setUserProperties,
} from 'firebase/analytics' // Firebase Analytics isnt supported by Segment apparently hence direct integration here
import { Platform } from 'react-native'

import { getFirebaseAnalyticsEventName, getFirebaseAnalyticsUserProperties } from './firebase-analytics-utils'
import { getEventByKey, TrackingEventKey } from './trackingEvents'
import {
  GroupPayload, IdentifyPayload, ScreenPayload,
} from './types'

declare global {
  interface Window {
    analytics: any
  }
}

const getGenericOptions = (options = {}, integrations) => cleanDeep({
  ...options,
  integrations,
  context: {
    ...options.context,
    app: {
      name: `${Platform.OS}-app`,
    },
  },
})

// Initialize Firebase Analytics
// const getAnalytics() = getAnalytics()

export const getIdentifyOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
})

export const getTrackOptions = (eventData, options) => getGenericOptions(options, eventData.integrations)

export const getGroupOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
})

export const getScreenOptions = (options) => getGenericOptions(options, {
  'Recombee AI': false,
  'Customer.io': false,
})

/**
 * We generally integrates with Segment but we also use Firebase Analytics directly
 * as JS source not supported by Segment
 *
 */
const analytics = {

  /**
   * Associates the current user with a user ID with optional traits.
   * Call this before other segment calls.
   * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
   * @param {string} payload.userId – User ID for the current user.
   * @param {object=} payload.traits – A map of custom properties.
   * @param {object=} payload.options – A map that can include any of these [common fields](https://segment.com/docs/connections/spec/common/). Defaults to null.
   */
  identify(payload: IdentifyPayload) {
    // console.log('Web analytics / identify')

    // Segment
    window.analytics.identify(payload.userId, payload.traits || {}, getIdentifyOptions(payload.options))

    // Firebase Analytics
    setUserId(getAnalytics(), payload.userId)
    if (payload.traits) {
      const firebaseCustomProperties = getFirebaseAnalyticsUserProperties(payload.traits)
      // console.log('Web analytics / identify / firebaseCustomProperties', firebaseCustomProperties)
      setUserProperties(getAnalytics(), firebaseCustomProperties)
    }

    // Sentry should work via Segment but not quite sure why it doesnt seem to work all of the times
    Sentry.setUser({ id: payload.userId })

    // TODO add remote config to Sentry logs (I want to be able to define staff/minder/beneficiary)
  },

  /**
   * Log an event to Segment with optional properties.
   * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
   * @param {string} payload.event – The event name.
   * @param {object=} payload.properties – A map of custom properties.
   * @param {object=} payload.options – A map that can include any of these [common fields](https://window.analytics.com/docs/connections/spec/common/). Defaults to null.
   *
   */
  track(eventKey: TrackingEventKey, properties?: Record<string, string | boolean | number | null | string[]>, options?: any) {
    // console.log('Web analytics / track')
    // console.log('payload', payload)

    const eventData = getEventByKey(eventKey)

    // Segment
    window.analytics.track(eventData.event, properties || {}, getTrackOptions(eventData, options))

    // Firebase Analytics
    logEvent(getAnalytics(), getFirebaseAnalyticsEventName(eventData.event), properties)

    // Recombee
    // if (payload.event === 'Content Bookmarked') {
    //   recombee.send(new Recombee.AddBookmark(userId, payload.properties?.id))
    // }
    // if (payload.event === 'Content Viewed') {
    //   recombee.send(new Recombee.AddDetailView(userId, payload.properties?.id))
    // }

    // if (payload.event === 'Content Rated') {
    //   recombee.send(new Recombee.AddRating(userId, payload.properties?.id, payload.properties?.rating))
    // }

    // if (payload.event === 'ContentSection Viewed') {
    //   recombee.send(new Recombee.SetViewPortion(userId, payload.properties?.id, payload.properties?.progress))
    // }
  },

  /**
   * Associate the user with a group with optional traits.
   * See https://window.analytics.com/docs/spec/group/.
   * @param {string} payload.groupId – ID of the group.
   * @param {object=} payload.traits – free-form dictionary of traits of the group.
   * @param {object=} payload.options – A map that can include any of these [common fields](https://window.analytics.com/docs/connections/spec/common/). Defaults to null.
   */
  group(payload: GroupPayload) {
    // console.log('Web analytics / group')
    // Segment
    try {
      window.analytics.group(payload.groupId, payload.traits || {}, getGroupOptions(payload.options))
    } catch (error) {
      console.error(error)
      throw error
    }
    // Firebase Analytics
    setUserProperties(getAnalytics(), { groupId: payload.groupId })
  },

  /**
   * Record that a user has seen a screen to Segment with optional custom properties.
   * See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
   * @param {string} payload.name – Name of the screen.
   * @param {object=} payload.properties – A map of custom properties.
   * @param {object=} payload.options – A map that can include any of these [common fields](https://window.analytics.com/docs/connections/spec/common/). Defaults to null.
   *
   */
  screen(payload: ScreenPayload) {
    // console.log('Web analytics / screen')
    // console.log('Viewed Page', payload.name)
    // console.log('payload', payload)

    // Segment
    window.analytics.page(payload.name, payload.properties || {}, getScreenOptions(payload.options))

    // Firebase Analytics
    logEvent(getAnalytics(), 'screen_view', {
      firebase_screen: payload.name,
      // firebase_screen_class: screenClass
    })
  },

  /**
   * Clears the current user.
   * See https://window.analytics.com/docs/sources/mobile/ios/#reset.
   */
  reset() {
    Sentry.configureScope((scope) => scope.setUser(null))

    // console.log('Web analytics / reset')
    // Segment
    return window.analytics.reset()
  },
}

export default analytics
