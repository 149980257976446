/* eslint-disable react/no-unstable-nested-components */
import classNames from 'classnames'
import {
  isHeading, isLink,
  isList, isListItem, isParagraph, renderRule,
} from 'datocms-structured-text-utils'
import { FC } from 'react'
import {
  StructuredText as RnStructuredText,
  StructuredTextPropTypes as RnStructuredTextPropTypes,
} from 'react-datocms'
import { Platform } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { Image, View } from '..'
import { Article } from '../containers/Article'
import { Text } from './Text'

export type StructuredTextProps = {
  paragraphTw?: string
  headingTw?: string
} & RnStructuredTextPropTypes

const baseParagraphTw = 'font-sans mb-4'

const baseHeadingTw = 'font-sans'

const baseListItemTw = 'font-sans mb-2'

/**
 * Wrapper to present Structured Text
 * See https://github.com/datocms/react-datocms#structured-text
 */
export const StructuredText: FC<StructuredTextProps> = ({ paragraphTw, headingTw, ...props }) => {
  const tailwind = useTailwind()
  return (
    <Article>
      <RnStructuredText

        customRules={[

          // Paragraph
          renderRule(isParagraph, ({ children, key }) => <Text key={key} tw={classNames(baseParagraphTw, paragraphTw)}>{children}</Text>),

          // Headings
          renderRule(isHeading, ({ children, node, key }) => <Text key={key} tw={classNames(baseHeadingTw, headingTw)} category={`h${node.level + 1}`}>{children}</Text>),

          // Blockquotes (TODO use native in web, styled in native)
          // renderRule(isBlockquote, ({ children }) => <Text>{children}</Text>),

          // renderRule(isBlockquote, ({ children }) => <Text>{children}</Text>),

          renderRule(isLink, ({ node, key }) => <a key={key} target="_blank" href={node.url} rel="noreferrer" style={tailwind('no-underline')}>
            <Text tw="no-underline text-brand-base">{node.children[0].value}</Text>
          </a>),

          renderRule(isList, ({ children, key }) => (Platform.OS === 'web' ? <ul key={key} className={''} style={tailwind('-mt-2')}>{children}</ul> : <View>{children}</View>)),

          renderRule(isListItem, ({ children, key }) => {
            if (Platform.OS === 'web') {
              return <li key={key}>{children}</li>
            }

            return (
              <View key={key} tw="flex-row">
                <Text tw={classNames(baseListItemTw, paragraphTw, 'mr-1')}>{'\u2022'}</Text>
                <Text tw={classNames(baseListItemTw, paragraphTw)}>{children}</Text>
              </View>)

          }),

        ]}

        /**
       * Custom renderers for inline blocks
       */
        renderBlock={({ record }) => {
          // eslint-disable-next-line no-underscore-dangle
          switch (record.__typename) {
          case 'ImageRecord':
            return <View tw="my-4">
              <Image {...record.media} />
            </View>
          default:
            return null
          }
        }}

        /**
       * TODO - custom link renderer
       * Perhaps we want to display specific card style rather than simple links
       */
        //  renderLinkToRecord={({ record, children, transformedMeta }) => {
        //   switch (record.__typename) {
        //     case 'TeamMemberRecord':
        //       return (
        //         <a {...transformedMeta} href={`/team/${record.slug}`}>
        //           {children}
        //         </a>
        //       );
        //     default:
        //       return null;
        //   }
        // }}

        {...props}

      />
    </Article>
  )
}
