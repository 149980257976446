import { getSwiperByName_appSwiper_slides } from '~/__generated__/getSwiperByName'
import { Text, View } from '~/elements'
import { IllustrationComponent } from '~/elements/images/IllustrationComponent'
import { useMediaQuery } from '~/hooks/mediaQuery'

export const CarouselSlide = (item: getSwiperByName_appSwiper_slides) => {
  const { mdv } = useMediaQuery()

  const titleClasses = ['text-center', 'font-semibold', 'font-serif', `text-${item.titleColor || 'gray-700'}`, 'px-8', 'text-base']
  const descriptionClasses = ['text-center', 'px-8', 'mt-4']

  if (mdv) {
    titleClasses.push('text-2xl')
    descriptionClasses.push('mt-8')
  }

  const titleTwClasses = titleClasses.join(' ')
  const descriptionTwClasses = descriptionClasses.join(' ')

  return (
    <View tw="items-center flex-col justify-center w-full h-full">

      {item.image && (
        <IllustrationComponent tw="mb-12 w-2/5 bg-yellow-100 rounded-2xl" illustrationId={item.image.id} background={false} />
      )}
      {!!item.title && <Text tw={titleTwClasses}>{item.title}</Text>}
      {!!item.description && <Text tw={descriptionTwClasses}>{item.description}</Text>}
    </View>
  )
}
