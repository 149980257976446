import { gql } from '@apollo/client'

export const GET_AFFILIATION_BY_SLUG = gql`
  query GetAffiliationBySlug($slug:String!, $locale: SiteLocale ) {
    affiliation(filter: {slug: {eq: $slug}}, locale: $locale) {
      logo {
        format
        url
        height
        width
      }
      applicationStoreAssets {
        slug
        downloadUrl
        icon {
          format
          url
          height
          width
        }
      }
      illustrations {
        format
        url
        height
        width
      }
    }
  }
`
