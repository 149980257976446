import head from 'lodash/head'
import last from 'lodash/last'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { Slider, Text, View } from '~/elements'
import { ContentPageHeading } from '~/elements/text/Player.FontTypes'
import { getColor } from '~/theme/helpers'

import { ReaderFooterProps } from '../BottomNavBar'

type ScaleLayoutProps = ReaderFooterProps & {
  name: string
  before: string
  after: string
  item: {
    key: string
    label: string
    value: number
  }

}

/**
 * A specific layout to display an interactive scale as a slider
 */
export const ScaleLayout: FC<ScaleLayoutProps> = ({
  name, before, after, item,
}) => {
  // console.log('before', before)
  // console.log('after', after)
  const { t } = useTranslation(['player'])
  // console.log(`item`, item)

  const data = [...item.values].sort((o) => o.value)
  // console.log(`data`, data)

  // eslint-disable-next-line no-unsafe-optional-chaining
  const startValue = Math.round(last(data)?.value - head(data)?.value)
  const initValue = data.find((o) => o.value === startValue)
  // console.log('startValue', initValue)
  const [currentValue, setCurrentValue] = useState(initValue)

  return (
    <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
      <TwoColGridSection>
        <ContentPlayerScrollView>
          <ContentPageHeading>{name}</ContentPageHeading>
          <View tw="flex-col flex-1 justify-center">
            {before && <Text category="p" tw="uppercase text-brand-darker font-semibold text-center">{before}</Text>}
            <View tw=" flex p-8 mt-4">
              <View tw="w-full h-24 text-center justify-center mb-8">
                {currentValue && (
                  <Text tw="text-3xl font-bold">
                    {currentValue.value}
                    {' '}
                    -
                    {' '}
                    {currentValue.label}
                  </Text>
                )}
              </View>
              <View tw="mt-16">
                <Slider
                  minimumValue={head(data)?.value}
                  maximumValue={last(data)?.value}
                  thumbTintColor={getColor('brand-base')}
                  minimumTrackTintColor={getColor('gray-300')}
                  maximumTrackTintColor={getColor('gray-300')}
                  step={1}
                  value={currentValue.value}
                  onValueChange={(val) => {
                    // console.log('val', val)
                    const res = data.find((o) => o.value === val)
                    setCurrentValue(res)
                  }}
                />
                <Text tw="text-sm mt-8 text-gray-500 text-center">{t('player:scale.slider.instructions')}</Text>
              </View>
            </View>
            {after && <Text category="p">{after}</Text>}
          </View>
        </ContentPlayerScrollView>
      </TwoColGridSection>
    </FullOverlayContainer>
  )
}
