import { useLinkTo } from '@react-navigation/native'
import classNames from 'classnames'
import { memo, useRef } from 'react'
import { ChannelPreviewUIComponentProps } from 'stream-chat-react'
import {
  DefaultAttachmentType, DefaultChannelType, DefaultCommandType, DefaultEventType, DefaultMessageType, DefaultReactionType, DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import {
  Badge, Pressable, Text, View,
} from '~/elements'
import { useDateFormat } from '~/hooks/dateFns'
import { getTimestampFromIsoString } from '~/utils/firebase.firestore'

import { ChannelAvatars } from './ChannelAvatars'
// import { Avatar } from '~/elements/avatar/Avatar'

export const channelPreviewContainerTw = 'flex-row justify-start bg-surface-1 p-4 mb-2 rounded-2xl'

const UnMemoizedChannelPreviewMessenger = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: ChannelPreviewUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const {
    active,
    channel,
    // displayImage,
    displayTitle,
    latestMessage,
    lastMessage,
    setActiveChannel,
    unread,
    watchers,
  } = props

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null)
  const linkTo = useLinkTo()

  const activeTw = active ? '' : ''
  const unreadTw = unread && unread >= 1 ? '' : ''

  // const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id

  const onSelectChannel = () => {
    if (setActiveChannel) {
      setActiveChannel(channel, watchers)
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur()
    }
    linkTo(`/conversations/${channel.id}`)
  }

  // console.log('lastMessage', lastMessage)

  const createdAt = lastMessage?.created_at
  // console.log('createdAt', createdAt)

  const time = useDateFormat(getTimestampFromIsoString(createdAt), 'time')

  const isChannelArchived = channel?.data.status === 'inactive'

  const mainTextColor = isChannelArchived ? 'text-basic-base' : 'text-basic-darkest'
  const secondaryTextColor = isChannelArchived ? 'text-basic-lighter' : 'text-basic-base'

  return (
    <Pressable
      tw={classNames(channelPreviewContainerTw, activeTw, unreadTw)}
      //   className={`str-chat__channel-preview-messenger ${unreadClass} ${activeClass}`}
      testID='channel-preview-button'
      onPress={onSelectChannel}
      // ref={channelPreviewButton}
    >
      <ChannelAvatars channel={channel} />
      <View tw="flex-grow flex-col">
        <View tw="flex-row items-center">
          <Text tw={classNames('flex-1 w-1 text-sm font-medium', mainTextColor)} numberOfLines={1}>{displayTitle}</Text>
          {!!unread && unread > 0 && <Badge color="danger" value={unread} tw="text-surface-1 py-1 ml-2" />}
        </View>
        <View tw="mt-1 flex-row items-center">
          {/* Adding w-1 to force text wrap here. @see https://jorgecolonconsulting.com/react-native-text-wrap/ */}
          <Text tw={classNames('flex-1 w-1 text-xs', secondaryTextColor)} numberOfLines={1}>{latestMessage}</Text>
          {!!time && time.length > 0 && <Text tw={classNames('text-xs ml-2', secondaryTextColor)}>{time}</Text>}
        </View>
      </View>
    </Pressable>
  )
}

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreviewMessenger = memo(
  UnMemoizedChannelPreviewMessenger,
) as typeof UnMemoizedChannelPreviewMessenger
