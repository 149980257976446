import { getColor } from './helpers'

export const CustomRNElementsLightTheme = {
  colors: {
    primary: getColor('brand-base'),
    secondary: getColor('blue-500'),
    white: getColor('white'),
    black: getColor('black'),
    grey0: getColor('white'),
    grey1: getColor('gray-100'),
    grey2: getColor('gray-300'),
    grey3: getColor('gray-500'),
    grey4: getColor('gray-700'),
    grey5: getColor('gray-900'),
    greyOutline: getColor('gray-500'),
    searchBg: getColor('surface-1'), // white
    success: getColor('success-base'),
    error: getColor('danger-base'),
    warning: getColor('warning-base'),
    divider: getColor('brand-darker'),
  },
}
