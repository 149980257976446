import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'

export const ContentDesktopCover = (props) => {
  const cardContainerTw = 'w-full rounded-2xl'

  return (
    <DoubleSvgImage
      shadow={false}
      aspectW={369}
      aspectH={512}
      {...props}
      containerTw={cardContainerTw} />
  )
}
