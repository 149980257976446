import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetSessionSlugQuery } from '~/__generated__/GetSessionSlugQuery'
import { GET_SESSION_BY_SLUG } from '~/queries/dato.sessions'

export const useSessionBySlug = (slug: string) => {
  const { i18n } = useTranslation()

  return useQuery<GetSessionSlugQuery>(GET_SESSION_BY_SLUG, {
    variables: {
      slug,
      locale: i18n.language,
    },
  })
}
