import AsyncStorage from '@react-native-async-storage/async-storage'
import { createStackNavigator } from '@react-navigation/stack'
import {
  Suspense, useCallback, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import { useOnboardingData } from '~/hooks/actionList.onboarding'
import { useAsyncStorage } from '~/hooks/storage'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { hasSeenWelcomeCarouselKey, OnboardingChecklistScreen } from '~/screens/OnboardingChecklistScreen/OnboardingChecklistScreen'
import { RegistrationConfirmedScreen } from '~/screens/RegistrationConfirmedScreen/RegistrationConfirmedScreen'
import RegistrationFormScreen from '~/screens/RegistrationFormScreen/RegistrationFormScreen'
import { WelcomeScreen } from '~/screens/WelcomeScreen/WelcomeScreen'

import { TaskListStackParamList } from './TaskListStackParamList'

const TaskListStack = createStackNavigator<TaskListStackParamList>()

export const taskListLocalStorageKey = 'hasUserSeenOnboardingTaskList'

/**
 * A navigator that is called upon new session being started as well as on request via CTA
 */
export const TaskListNavigator = () => {
  // console.log('-> TaskListNavigator')
  const { isComplete } = useOnboardingData()

  // We load translation and ensure loading before moving on
  const { t } = useTranslation(['forms', 'onboarding', 'common'])

  const { loading, value, setItem } = useAsyncStorage(hasSeenWelcomeCarouselKey)

  const [hasSeenCarouselInSession, setHasSeenCarouselInSession] = useState<boolean>(false)

  useEffect(() => {
    AsyncStorage.setItem(taskListLocalStorageKey, true)
  }, [])

  useEffect(() => {
    if (hasSeenCarouselInSession) {
      setItem(true)
    }
  }, [hasSeenCarouselInSession, setItem])

  const StackHeader = useCallback(
    () => (
      <CloseFullOverlayHeader
        label={isComplete() ? t('common:cta.continue') : t('common:cta.later')}
        headerRightTw="mr-2 "
        padded
      />
    ),
    [isComplete, t],
  )

  if (loading) {
    return <LoadingScreen />
  }

  if (!value && !hasSeenCarouselInSession) {
    return <WelcomeScreen onComplete={() => {
      setHasSeenCarouselInSession(true)
    }}/>
  }

  return (
    <Suspense fallback={<LoadingScreen />}>
      <TaskListStack.Navigator
        screenOptions={{
          headerShown: true,
          header: StackHeader,
        }}
      >
        <TaskListStack.Screen
          name="Main"
          component={OnboardingChecklistScreen}
        />
        <TaskListStack.Screen
          name="RegistrationForm"
          component={RegistrationFormScreen}
          options={{
            title: t('navigation:onboarding.registration'),
          }}
        />
        <TaskListStack.Screen
          name="RegistrationConfirmed"
          component={RegistrationConfirmedScreen}
          options={{
            title: t('navigation:onboarding.registration'),
          }}
        />
      </TaskListStack.Navigator>
    </Suspense>
  )
}
