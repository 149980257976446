import { useEffect, useState } from 'react'
import { useFirestore, useFirestoreDoc, useUser } from 'reactfire'

import { EventuallyDocumentReference } from '~/definitions/firebase.firestore.types'
import { SingleDocQueryResult } from '~/definitions/firestore.queries'
import { UserData } from '~/definitions/firestore.user'
import { getUserRef } from '~/queries/firestore.helpers'

import { withGetDocResultAdapter } from './firestore.hooks.helpers'

export const useCurrentUserRef = ():EventuallyDocumentReference<UserData> => {
  const db = useFirestore()
  const [userRef, setUserRef] = useState<EventuallyDocumentReference<UserData>>(null)
  const { data: user } = useUser()
  useEffect(() => {
    // console.log('Will recompute userRef for ' + user.uid)
    if (user?.uid && user.uid !== userRef?.id) {
      setUserRef(getUserRef(user?.uid, db))
    }

    // purposly ignored db, userRef?.id
  }, [user?.uid])

  return userRef
}

export const useUserByRef = (ref:EventuallyDocumentReference<UserData>): SingleDocQueryResult<UserData> => {
  const res = useFirestoreDoc<UserData>(ref, {
    idField: 'id', // this field will be added to the object created from each document
  })

  return withGetDocResultAdapter(res)

}

export const useUserById = (uid?: string): SingleDocQueryResult<UserData> => {
  // console.log('-> useUserById')
  const ref = getUserRef(uid, useFirestore())

  return useUserByRef(ref)
}

export const useCurrentUser = (): SingleDocQueryResult<UserData> => {

  const ref = useCurrentUserRef()
  return useUserByRef(ref)
}
