import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { View } from '~/elements'
import { AnimatedView } from '~/elements/containers/AnimatedView'
import { Skeleton } from '~/elements/skeleton/Skeleton'
import { getColor } from '~/theme/helpers'

const transition = { opacity: { duration: 300 } }

// TODO should build on top of https://getstream.io/activity-feeds/notification-feeds/

export const NotificationsScreen = () => (
  <MainContainer tw="max-w-xl lg:max-w-xl">
    <BasicSectionContainer tw="">
      <AnimatedView
        transition={{ type: 'timing' }}
        tw="flex-row justify-center p-4"
        animate={{ backgroundColor: getColor('surface-1') }}
      >
        <Skeleton transition={transition} tw="h-20 w-20 mb-4"/>
        <View tw="flex-col">
          <Skeleton transition={transition} tw="w-4/5 mb-2"/>
          <Skeleton transition={transition} tw="w-full mb-2"/>
          <Skeleton transition={transition} tw="w-1/5 mb-2"/>
        </View>
        <Skeleton transition={transition} tw="h-20 w-20 mb-4"/>
      </AnimatedView>

    </BasicSectionContainer>
  </MainContainer>
)
