import '../i18n/i18n'
import '../theme/iconsLoad'

import {
  ApolloProvider,
} from '@apollo/client'
import * as Sentry from '@sentry/react'
import { DripsyProvider, makeTheme } from 'dripsy'
import { Suspense, useCallback } from 'react'
import { ENV, SEGMENT_WEB_KEY } from 'react-native-dotenv'
import { FirebaseAppProvider } from 'reactfire'

import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import useCachedResources from '../hooks/cachedResources'
import { client } from './app.dato.config'
import { firebaseConfig } from './app.firebase.config'
import { PreLoadFirebase } from './PreLoadFirebase'

if (!ENV) {
  throw new Error('No env defined. You need to set ENV variable first...')
}
// console.log('Environment', ENV)
// console.log('firebaseConfig / projectId', firebaseConfig.projectId)
// console.log('cms', DATOCMS_API_KEY)
// console.log('firebaseConfig / api key', firebaseConfig.apiKey)

const theme = makeTheme({})

export const GenericApp = ({ children }) => {
  const isLoadingComplete = useCachedResources()

  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])

  if (!isLoadingComplete) {
    return null
  }

  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      <DripsyProvider theme={theme}>
        <ApolloProvider client={client}>
          <Suspense fallback={<LoadingScreen />}>
            <FirebaseAppProvider firebaseConfig={firebaseConfig}>
              <PreLoadFirebase>{ children}</PreLoadFirebase>
            </FirebaseAppProvider>
          </Suspense>
        </ApolloProvider>
      </DripsyProvider>
    </Sentry.ErrorBoundary>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()

// inject Segment script
const script = document.createElement('script')
script.setAttribute('type', 'text/javascript')
// eslint-disable-next-line max-len
script.text = `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${SEGMENT_WEB_KEY}";analytics.SNIPPET_VERSION="4.13.2";analytics.load("${SEGMENT_WEB_KEY}");analytics.page();}}();`
document.head.appendChild(script)
