/**
 * A screen that displays the very few info we have about a content while it's being written.
 */
import { useHeaderHeight } from '@react-navigation/elements'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from 'react-native'

import { RecommendedContentFromScenarioProps } from '~/components/sections/content/RecommendedContent'
import { cardMaxHeight, cardTopMargin, useCardFooterHeight } from '~/constants/ContentPlayerContainer.constants'
import {
  Text, View,
} from '~/elements'

const recommendationSettings: Partial<RecommendedContentFromScenarioProps> = {
  // key: 'recommended-content',
  // description: '',
  nbItems: 8,
  scenario: 'Home_ForYou',
}

export const NoContentScreen = ({ groups }) => {
  const cardFooterHeight = useCardFooterHeight()
  // console.log('NoContentScreen', props)

  // const [isModalVisible, setModalVisible] = useState(false)

  // const { data } = useDatoSvg('21800786')

  // const navigation = useNavigation()
  // const route = useRoute()

  const { t, i18n } = useTranslation(['library'])

  const instantHeaderHeight = useHeaderHeight()

  const { height } = useWindowDimensions()
  // console.log('screen height', height)

  const [headerHeight, setHeaderHeight] = useState(0)
  useEffect(() => {
    if (instantHeaderHeight > 0 && instantHeaderHeight !== headerHeight) {
      setHeaderHeight(instantHeaderHeight)
    }
  }, [instantHeaderHeight, headerHeight])
  // console.log('header height', headerHeight)

  const minAroundHeight = headerHeight + cardTopMargin + cardFooterHeight
  // console.log('minAroundHeight', minAroundHeight)
  const cardHeight = Math.min(height - minAroundHeight, cardMaxHeight - cardFooterHeight)

  // console.log('cardHeight', cardHeight)

  // console.log('groups', groups)

  // add title as a route param so that we display it in navigation header
  // if (!route.params.title) {
  //   navigation.setParams({ title })
  // }

  const reQLExp = `('wordCount_${i18n.language}' > 0) and ("${groups[0].id}" in 'categories')`
  // console.log('reQLExp', reQLExp.replace(/\\"/, '"'))
  recommendationSettings.filter = reQLExp.replace(/\\"/, '"')
  recommendationSettings.title = t('library:noContent.recommendation_title')
  recommendationSettings.tileSettings = { size: 'small' }

  // console.log('recommendationSettings', recommendationSettings)
  return (
    <View tw="flex-1 flex-col items-center">
      <View
        tw="w-full max-w-xl rounded-xl content-between px-8 pb-24 pt-6"
        style={{
          marginTop: headerHeight + cardTopMargin,
          height: cardHeight,
          backgroundColor: groups[0].backgroundColor,
        }}
      >
        {/* <ContentPlayerCoverScreen title={title} category={groups[0]} author={author} /> */}
      </View>
      <View tw="my-4">
        <Text tw="text-center font-serif font-semibold">{ t('library:noContent.sorry')}</Text>
        {/* <Text tw="text-center">{ t('noContent.description')}</Text> */}
      </View>
      {/* {data && (<Image {...data.upload} />)} */}

      {/* <View>
        <Button
          color="brand"
          title={t('noContent.cta')}
          onPress={() => {
            setModalVisible(true)
          }}
        />
      </View> */}

      {/* Add category based recommendation */}
      {/* <RecommendedContentFromScenario {...recommendationSettings} /> */}

      {/* <Overlay
        onBackdropPress={() => setModalVisible(false)}
        isVisible={isModalVisible}
      >
        <Overlay.Title>{ t('noContent.confirmOverlay.title')}</Overlay.Title>
        <Overlay.Content><Text>{ t('noContent.confirmOverlay.text')}</Text></Overlay.Content>
        <Overlay.Footer>
          <Button
            title={t('noContent.confirmOverlay.btn')}
            onPress={() => {
              // console.log('Well...we got you!')
              setModalVisible(false)
            }}
          />
        </Overlay.Footer>
      </Overlay> */}
    </View>
  )
}
