import { BlurView as RnBlurView, BlurViewProps as RnBlurViewProps } from 'expo-blur'
import { FC } from 'react'
import { useTailwind } from 'tailwind-rn'

export type BlurViewProps = Omit<RnBlurViewProps, 'style'> & { tw?:string}

export const BlurView:FC<BlurViewProps> = ({ tw, style, ...props }) => {
  const tailwind = useTailwind()
  return <RnBlurView style={{ ...tailwind(tw), ...style }} {...props}/>
}
