import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Avatar, Icon, Pressable, Text, View,
} from '~/elements'
import { useContractorBySlug } from '~/hooks/dato.contractors'
import { useGetDurationSinceFirstMeetingWithMinder } from '~/hooks/firestore.meetings'
import { getFullName } from '~/utils/people.helpers'

import { MinderCardProps } from './MinderDesktopSideCard'

library.add(faArrowRightLong)

export const MinderMobileCard: FC<MinderCardProps> = ({
  slug,
}) => {
  // console.log('minder', minder)
  const { loading: minderLoading, data } = useContractorBySlug(slug)
  const { t } = useTranslation(['track'])

  const { loading, data: duration } = useGetDurationSinceFirstMeetingWithMinder(slug)

  if (minderLoading || !data?.contractor?.minder) {
    return null
  }

  const { minder } = data.contractor

  return (

    <Pressable to={`/minders/${slug}`} testID={`minder-card-${slug}`} tw="flex-row mb-8 items-center justify-between" >
      <View tw="flex-row items-center ">
        {minder.picture && <Avatar size="large" image={minder.picture} tw="border-4 border-white bg-gray-300" />}
        <View tw="flex-col ml-2">
          <Text tw="text-base font-medium">{getFullName(minder)}</Text>
          {!loading && duration != null && <Text tw="text-gray-500 text-xs">{`${t('track:minderCard.since')} ${duration}`}</Text>}
        </View>
      </View>
      <Icon name="arrow-right-long" variant="regular"/>
    </Pressable>

  )
}
