/**
 * Navigator once authenticaed
 */

// import 'stream-chat-react/dist/css/index.css' // remove when GetStream fully migrated to RN components

import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useSigninCheck } from 'reactfire'
import { Streami18n } from 'stream-chat-react'

import { Chat } from '~/components/chat'
import { UserProvider } from '~/context/UserContext'
import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import { ChatConversationHeader } from '~/elements/headers/ConversationHeader'
import { useOnboardingData } from '~/hooks/actionList.onboarding'
import { useChatClient } from '~/hooks/chat'
import { useFeatureConfigBoolean } from '~/hooks/features'
import NotFoundScreen from '~/screens.shared/NotFoundScreen/NotFoundScreen'
import { ChatConversationScreen } from '~/screens/ChatConversationScreen/ChatConversationScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { AllEvaluationsScreen } from '~/screens/MyEvaluationsScreen/AllEvaluations'
import { NotificationsScreen } from '~/screens/NotificationsScreen/NotificationsScreen'
import { PreferencesOverlayScreen } from '~/screens/PreferencesScreen/PreferencesScreen'

import { ContentPlayerNavigator } from './ContentPlayerNavigator'
import { MainStackParamList } from './MainStackParamList'
import { MenuNavigator } from './MenuNavigator'
import { TaskListNavigator } from './TaskListNavigator'

const MainStack = createNativeStackNavigator<MainStackParamList>()

const PreferencesHeader = () => <CloseFullOverlayHeader headerRightTw="mr-6 mt-12" />
const NotificationsHeader = () => <CloseFullOverlayHeader headerRightTw="mr-6" />

/**
 *
 * Note the initialRouteName isnt honored
 */
const MainNavigator = () => {
  // console.log('-> MainNavigator')
  const { status, data: isMyTrackEnabled } = useFeatureConfigBoolean('enableMenuItemMyTrack')
  // const { data: allConfig } = useRemoteConfigAll()
  const { loading, data } = useOnboardingData()
  const { client, loading: chatClientLoading } = useChatClient()

  const { i18n } = useTranslation()

  if (loading || status === 'loading' || chatClientLoading) {
    return <LoadingScreen />
  }
  // console.log('allConfig', allConfig)
  // console.log('-> MainNavigator', data, messageValue)

  // we load onboarding stack as initial route unless already completed
  let initialRouteName: keyof MainStackParamList = 'Onboarding'
  const activeStepIndex = data.findIndex((item) => item.active === true)
  // console.log('activeStepIndex', activeStepIndex)
  if (activeStepIndex < 0 || !isMyTrackEnabled) {
    initialRouteName = 'Menu'
  }
  const i18nInstance = new Streami18n({ language: i18n.language })

  // console.log('initialRouteName', initialRouteName)

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Chat client={client} i18nInstance={i18nInstance}>
        <MainStack.Navigator initialRouteName={initialRouteName}>
          <MainStack.Screen
            name="Onboarding"
            component={TaskListNavigator}
            options={{
              headerShown: false,
              presentation: 'modal',
            }} />
          <MainStack.Screen
            name="Menu"
            component={MenuNavigator}
            options={{
              headerShown: false,
            }} />
          <MainStack.Screen
            name="NotFound"
            component={NotFoundScreen}
            options={{
              headerShown: false,
            }} />
          <MainStack.Screen
            name="AllEvaluations"
            component={AllEvaluationsScreen}
            options={{
              headerShown: false,
              presentation: 'modal',
            }} />
          <MainStack.Screen
            name="ContentPlayer"
            component={ContentPlayerNavigator}
            options={{
              headerShown: false,
              presentation: 'modal',
            }} />
          <MainStack.Screen
            name="Conversation"
            component={ChatConversationScreen}
            options={({ route }) => ({
              headerShown: true,
              header: () => <ChatConversationHeader route={route} />,
            })}
          />
          <MainStack.Screen
            name="Preferences"
            component={PreferencesOverlayScreen}
            options={{
              headerShown: true,
              presentation: 'modal',
              header: PreferencesHeader,
            }}
          />
          <MainStack.Screen
            name="Notifications"
            component={NotificationsScreen}
            options={{
              headerShown: true,
              presentation: 'modal',
              header: NotificationsHeader,
            }}
          />
        </MainStack.Navigator>
      </Chat>
    </Suspense>
  )
}

const WrappedMainNavigator = () => {
  // console.log('-> WrappedMainNavigator')
  const { status, data: signInCheckResult } = useSigninCheck()
  // console.log('WrappedMainNavigator / user', signInCheckResult.user)

  if (status === 'loading' || signInCheckResult.signedIn === false) {
    // console.log('Not signed in in yet')
    return <LoadingScreen />
  }

  return (
    <UserProvider>
      <MainNavigator />
    </UserProvider>
  )
}

export default WrappedMainNavigator
