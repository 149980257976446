import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { useTranslation } from 'react-i18next'
import { useTailwind } from 'tailwind-rn'

import { BottomTabNavigatorIcon } from '../navigators.shared/BottomTabNavigatorIcon'
import { nonVisibleScreens, useMenuItems } from './menuItems'
import { MenuParamList } from './MenuParamList'

const BottomTabStack = createBottomTabNavigator<MenuParamList>()

export const BottomTabNavigator = () => {
  const tailwind = useTailwind()
  const { t } = useTranslation(['navigation'])
  const { loading, data: menuItems } = useMenuItems()

  if (loading) {
    return null
  }

  return (
    <BottomTabStack.Navigator
      screenOptions={{
        headerShown: false,
        lazy: true,
        tabBarShowLabel: false,
        tabBarStyle: {
          ...tailwind('border-t-0 bg-surface-1'),
          height: 68,
        },
      }}
    >
      {menuItems.filter((item) => !nonVisibleScreens.includes(item.name)).map((item) => (
        <BottomTabStack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            tabBarLabel: t(`menu.${item.key}`),
            tabBarIcon: (props) => <BottomTabNavigatorIcon {...props} {...item.icon} />,

          }}
        />
      ))}
    </BottomTabStack.Navigator>
  )
}
