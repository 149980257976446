import { useHeaderHeight } from '@react-navigation/elements'
import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useWindowDimensions } from 'react-native'

import {
  cardMaxHeight,
  cardTopMargin,
  useCardFooterHeight,
} from '~/constants/ContentPlayerContainer.constants'
import { View } from '~/elements'
import { ViewProps } from '~/elements/containers/View'
import { ContentPlayerCoverNav } from '~/screens/ContentPlayerScreen/ContentPlayerCoverNav'

type ContentPlayerViewProps = {
  /**
   * Container tw classes
   */
  tw?:string

  /**
   * Main card tw classes
   */
   cardTw?:string

  /**
   * Main card additionnal style
   */
  style?: ViewProps

  /**
   * Path to next page
   */
  to:string
}

/**
 * A layout for more static cards of our content stack such as the cover
 * - header is fully transparent
 * - Card has a same height as ScrollView layout (except for small screens)
 * - Nav buttons are located outside of the card
 */
export const ContentPlayerView:FC<ContentPlayerViewProps> = ({
  tw, cardTw, to, style, children,
}) => {
  const cardFooterHeight = useCardFooterHeight()
  const instantHeaderHeight = useHeaderHeight()

  const { height } = useWindowDimensions()
  // console.log('screen height', height)

  const [headerHeight, setHeaderHeight] = useState(0)
  useEffect(() => {
    if (instantHeaderHeight > 0 && instantHeaderHeight !== headerHeight) {
      setHeaderHeight(instantHeaderHeight)
    }
  }, [instantHeaderHeight, headerHeight])
  // console.log('header height', headerHeight)

  const minAroundHeight = headerHeight + cardTopMargin + cardFooterHeight
  // console.log('minAroundHeight', minAroundHeight)
  const cardHeight = Math.min(height - minAroundHeight, cardMaxHeight - cardFooterHeight)

  // console.log('cardHeight', cardHeight)

  const containerTw = classNames('flex-1 flex-col items-center', tw)
  // console.log('containerTw', containerTw)

  return (
    <View tw={containerTw}>
      <View
        tw={classNames('w-full max-w-xl rounded-2xl', cardTw)}
        style={{
          marginTop: headerHeight + cardTopMargin,
          height: cardHeight,
          ...style,
        }}
      >
        {children}
      </View>
      <ContentPlayerCoverNav style={{ height: cardFooterHeight }} to={to} />
    </View>
  )
}
