import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetAllEnabledTypeformQuery } from '~/__generated__/GetAllEnabledTypeformQuery'
import { GetEvaluationModelQuery } from '~/__generated__/GetEvaluationModelQuery'
import { GET_EVALUATION_MODEL } from '~/queries/dato.evaluations'
import { GET_ALL_ENABLED_EVALUATIONS } from '~/queries/dato.typeforms'

export const useAllEnabledEvaluations = () => {
  const { i18n } = useTranslation()

  return useQuery<GetAllEnabledTypeformQuery, { locale: string }>(GET_ALL_ENABLED_EVALUATIONS, {
    variables: {
      locale: i18n.language,
    },
  })
}

export const useEvaluationModelBySlug = (slug:string) => {
  const { i18n } = useTranslation()

  return useQuery<GetEvaluationModelQuery, { slug:string, locale: string }>(GET_EVALUATION_MODEL, {
    variables: {
      locale: i18n.language,
      slug,
    },
  })
}
