export const faPrefixes: Record<string, string> = {
  solid: 'fas',
  regular: 'far',
  thin: 'fat',
  light: 'fal',
  duotone: 'fad',
  brands: 'fab',
}

export type VariantType = keyof typeof faPrefixes

const sizeMatch = {
  xs: 0.75,
  sm: 0.875,
  lg: 1.33,
  '2x': 2,
  '3x': 3,
  '4x': 4,
  '5x': 5,
  '6x': 6,
  '7x': 7,
  '8x': 8,
  '9x': 9,
  '10x': 10,
}

export type SizeType = keyof typeof sizeMatch

export const getNativeSize = (size) => {
  const base = 16

  return Math.round(sizeMatch[size] + base)
}
