// eslint-disable-next-line import/no-extraneous-dependencies
import { from } from 'nearest-color'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../tailwind.config.js'

const { theme: { colors } } = resolveConfig(tailwindConfig)

// console.log('tailwind colors', colors)

export const getColor = (color:string) => {
  // console.log('color', color)
  const [baseColor, shade] = color.split('-')
  if (shade) {
    return colors[baseColor][shade]

  }
  return colors[baseColor]

}

const twColors = Object.keys(colors).reduce((acc, base) => {

  if (typeof colors[base] === 'string') {
    return { ...acc, [base]: colors[base] }
  }

  const obj = Object.keys(colors[base]).reduce((shades, shade) => ({ ...shades, [`${base}-${shade}`]: colors[base][shade] }), {})
  return { ...acc, ...obj }

}, {})

const twColorsNoTransparent = Object.keys(twColors).reduce((acc, color) => {
  if (color.includes('transparent')) {
    return acc
  }
  return { ...acc, [color]: twColors[color] }
}, {})

// console.log('twColors', twColorsNoTransparent)

export const getNearestTailwindColor = (hex:string) => {
  // console.log('getNearestTailwindColor for ', hex)

  const nearestColorUtil = from(twColorsNoTransparent)
  // console.log('nearestColorUtil', nearestColorUtil)

  const res = nearestColorUtil(hex)
  // console.log('res', res)

  return res.name
}
