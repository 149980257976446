import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { getSwiperByName } from '~/__generated__/getSwiperByName'
import { GET_SWIPER_BY_NAME } from '~/queries/dato.swipers'

export const useSwiperData = (key: string) => {
  const { i18n } = useTranslation()

  return useQuery<getSwiperByName>(GET_SWIPER_BY_NAME, { variables: { key, locale: i18n.language } })
}
