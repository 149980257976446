import { useEffect } from 'react'
// import { FileUploadButton, ImageDropzone } from 'react-file-utils'
import {
  ChatAutoComplete,
  EmojiPicker,
  KEY_CODES,
  Tooltip,
  UploadsPreview,
  // useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
  useTranslationContext,
} from 'stream-chat-react'
import type {
  CustomTrigger,
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import { Button, View } from '~/elements'

import {
  EmojiIconSmall as DefaultEmojiIcon,
  // FileUploadIcon as DefaultFileUploadIcon,
} from './icons'

export const EditMessageForm = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType,
  V extends CustomTrigger = CustomTrigger
>() => {
  // const { acceptedFiles, multipleUploads } = useChannelStateContext<At, Ch, Co, Ev, Me, Re, Us>('EditMessageForm')
  const { t } = useTranslationContext('EditMessageForm')

  const {
    clearEditingState,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    // openEmojiPicker,
    // uploadNewFiles,
  } = useMessageInputContext<At, Ch, Co, Ev, Me, Re, Us, V>('EditMessageForm')

  const {
    EmojiIcon = DefaultEmojiIcon,
    // FileUploadIcon = DefaultFileUploadIcon,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('EditMessageForm')

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.keyCode === KEY_CODES.ESC && clearEditingState) {
        clearEditingState()
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [clearEditingState])

  return (
    <View
    // className='str-chat__edit-message-form'
    >
      {/* <ImageDropzone
        accept={acceptedFiles}
        disabled={!isUploadEnabled || maxFilesLeft === 0}
        handleFiles={uploadNewFiles}
        maxNumberOfFiles={maxFilesLeft}
        multiple={multipleUploads}
      > */}
      {isUploadEnabled && <UploadsPreview />}
      <EmojiPicker small />
      <ChatAutoComplete />
      <View
        // className='str-chat__message-team-form-footer'
      >
        <View
          // className='str-chat__edit-message-form-options'
        >
          {/* 'str-chat__input-emojiselect' onClick={openEmojiPicker} */}
          <EmojiIcon />
          {isUploadEnabled && (
            <View
              // className='str-chat__fileupload-wrapper'
              testID='fileinput'
            >
              <Tooltip>
                {maxFilesLeft
                  ? t('Attach files')
                  : t("You've reached the maximum number of files")}
              </Tooltip>
              {/* <FileUploadButton
                  accepts={acceptedFiles}
                  disabled={maxFilesLeft === 0}
                  handleFiles={uploadNewFiles}
                  multiple={multipleUploads}
                >
                  'str-chat__input-fileupload'
                  <FileUploadIcon />
                </FileUploadButton> */}
            </View>
          )}
        </View>
        <View>
          <Button
            onPress={() => {
              if (clearEditingState) {
                clearEditingState()
              }
            }}
            title={t('Cancel')}
          />
          <Button onPress={handleSubmit} title={t('Send')}/>
        </View>
      </View>
      {/* </ImageDropzone> */}
    </View>
  )
}
