import { FC } from 'react'

import { CheckboxInput } from './CheckboxInput'
import { EmailInput } from './EmailInput'
import { NumberInput } from './NumberInput'
import { PasswordInput } from './PasswordInput'
import { PhoneNumberInput } from './PhoneNumberInput'
import { ShortTextInput } from './ShortTextInput'

type InputTypetype = 'multiple_choice' | 'short_text' | 'long_text' | 'statement' | 'yes_no' | 'email' | 'phone_number' | 'rating' | 'date' | 'number' | 'dropdown' | 'password'
// multiple_choice option is covered elsewhere, by the component MultipleChoiceField, without Formik

type FieldProps = {
  inputType: InputTypetype
  align: 'center' | 'left'
}

export const Field:FC<FieldProps> = ({ inputType, ...props }) => {
  switch (inputType) {
  case 'short_text':
    return <ShortTextInput {...props} />
  case 'number':
    return <NumberInput {...props} />
  case 'email':
    return <EmailInput {...props} />
  case 'password':
    return <PasswordInput {...props} />
  case 'phone_number':
    return <PhoneNumberInput {...props} />
  case 'checkbox':
    return <CheckboxInput {...props} />
  default:
    throw new Error(`unhandled input type ${inputType}`)
  }
}

Field.defaultProps = {
  align: 'center',
}
