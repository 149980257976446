import { Picker } from '@react-native-picker/picker'
import { CountryCode } from 'libphonenumber-js/max'
import { FC } from 'react'
import { useTailwind } from 'tailwind-rn'

const countryOptions = ['FR', 'US', 'GB', 'DE', 'IT', 'ES', 'PT', 'NL', 'BE', 'CH'].sort()

type ICCCSelectorProps = {
  value: CountryCode,
  onChange: (countryCode: CountryCode) => void
}

export const InternationalCountryCallingCodeSelector:FC<ICCCSelectorProps> = ({ value, onChange }) => {
  const tailwind = useTailwind()
  return (
    <Picker
    // mode="dropdown"
      onValueChange={onChange}
      selectedValue={value}
      style={tailwind('h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 text-sm sm:text-base rounded-md')}
    >
      {countryOptions.map((code) => (
        <Picker.Item value={code} key={code} label={code}>
          {code}
          {/* <Flag code={code === 'GB' ? 'GB-UKM' : code} /> */}
        </Picker.Item>
      ))}

    </Picker>
  )
}
