import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import { ScrollView } from '~/elements'
import { EventTileInfo } from '~/screens/EventsScreen/EventTileInfo'

export type EventsProps = {
    events: GroupMeetingWithSessions[] | null
}

export const EventsIncoming: FC<EventsProps> = ({ events }) => {
  const { t } = useTranslation(['events'])

  return (
    <>
      <SectionHeading title={t('events:incoming.title')} tw="px-4 md:px-8" />
      <CarouselSectionContainer tw="pr-0 md:pr-8">
        <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-2 flex flex-row" >
          {events && events.map((event) => <EventTileInfo
            key={event?.id}
            eventWithSessions={event}
            navigateTo={`/events/${event.id}`}
            // onPress?
          />)}
        </ScrollView>
      </CarouselSectionContainer>
    </>
  )
}
