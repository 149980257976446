/**
 * A custom drawer item taken from react navigation 6; that hides label on smaller screens
 * https://github.com/react-navigation/react-navigation/blob/main/packages/drawer/src/views/DrawerItem.tsx
 */

import { useTheme } from '@react-navigation/native'
import Color from 'color'
import { LinearGradient } from 'expo-linear-gradient'
import { ReactNode } from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { LinkPressable } from '~/elements/buttons/LinkPressable'
import { useMediaQuery } from '~/hooks/mediaQuery'

const EventuallyGradient = ({ focused, ...props }) => {
  const tailwind = useTailwind()
  if (focused) {
    return <LinearGradient
      colors={['rgba(249, 173, 139, 1)', 'rgba(255,153,92,1)']} // gradient #3
      locations={[0.1, 0.9]} // restrict gradient to in-between these location values
      start={{ x: 0.3, y: 0.0 }}
      end={{ x: 0.7, y: 1.0 }}
      style={tailwind('z-20')}
      {...props} />
  }

  return <View {...props}/>
}

export const styles = StyleSheet.create({
  container: {
    // marginHorizontal: 10,
    // marginVertical: 4,
    overflow: 'hidden',
  },
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
  },
  label: {
    // marginRight: 32,
    flex: 1,
  },
  button: {
    display: 'flex',
  },
})

 type DrawerItemProps = {
   /**
      * The label text of the item.
      */
   label:
   | string
   | ((props: { focused: boolean, color: string }) => ReactNode)
   /**
      * Icon to display for the `DrawerItem`.
      */
   icon?: (props: {
     focused: boolean
     size: number
     color: string
   }) => ReactNode
   /**
      * URL to use for the link to the tab.
      */
   to?: string
   /**
      * Whether to highlight the drawer item as active.
      */
   focused?: boolean
   /**
      * Function to execute on press.
      */
   onPress: () => void
   /**
      * Color for the icon and label when the item is active.
      */
   activeTintColor?: string
   /**
      * Color for the icon and label when the item is inactive.
      */
   inactiveTintColor?: string
   /**
      * Background color for item when its active.
      */
   activeBackgroundColor?: string
   /**
      * Background color for item when its inactive.
      */
   inactiveBackgroundColor?: string
   /**
      * Color of the touchable effect on press.
      * Only supported on Android.
      *
      * @platform android
      */
   pressColor?: string
   /**
      * Opacity of the touchable effect on press.
      * Only supported on iOS.
      *
      * @platform ios
      */
   pressOpacity?: number
   /**
      * Style object for the label element.
      */
   labelStyle?: StyleProp<TextStyle>
   /**
      * Style object for the wrapper element.
      */
   style?: StyleProp<ViewStyle>
 }

/**
  * A component used to show an action item with an icon and a label in a navigation drawer.
  */
const DrawerItem = (props: DrawerItemProps) => {
  // console.log('DrawerItem', props)

  const { colors } = useTheme()

  const { lg } = useMediaQuery()
  const tailwind = useTailwind()

  const {
    icon,
    testID,
    label,
    labelStyle,
    to,
    focused = false,
    activeTintColor = colors.primary,
    inactiveTintColor = Color(colors.text).alpha(0.68).rgb().string(),
    // activeBackgroundColor = Color(colors.primary).alpha(0.10).rgb().string(),
    // inactiveBackgroundColor = 'transparent',
    style,
    onPress,
    pressColor,
    pressOpacity,
    ...rest
  } = props

  const { borderRadius = 4 } = StyleSheet.flatten(style || {})
  const color = focused ? activeTintColor : inactiveTintColor
  // const backgroundColor = focused
  //   ? activeBackgroundColor
  //   : inactiveBackgroundColor

  const iconNode = icon ? icon({ size: 20, focused, color }) : null

  // console.log('testID', testID)

  return (

    <>
      {/* Add separator over Settings */}
      {testID.startsWith('Preferences') && !focused && (
        <View style={{
          ...tailwind('bg-basic-lighter mt-4 w-full lg:w-auto lg:ml-1.5 lg:w-2/5'),
          height: StyleSheet.hairlineWidth,
        }}/>
      )}
      <EventuallyGradient
        collapsable={false}
        {...rest}
        focused={focused}
        style={[styles.container, { borderRadius }, style]}
      >
        <LinkPressable
          onPress={onPress}
          style={[styles.wrapper, { borderRadius }]}
          accessibilityRole="button"
          accessibilityState={{ selected: focused }}
          pressColor={pressColor}
          pressOpacity={pressOpacity}
          to={to}
        >
          <>
            {iconNode}
            {lg && (
              <View
                style={[
                  styles.label,
                  {
                    marginLeft: iconNode ? 16 : 0,
                  // marginVertical: 5
                  },
                ]}
              >
                {typeof label === 'string' ? (
                  <Text
                    numberOfLines={1}
                    style={[
                      {
                        color,
                        fontWeight: '500',
                      },
                      labelStyle,
                    ]}
                  >
                    {label}
                  </Text>
                ) : (
                  label({ color, focused })
                )}
              </View>
            )}
          </>
        </LinkPressable>
      </EventuallyGradient>
    </>
  )
}

export default DrawerItem
