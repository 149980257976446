import { ObservableStatus } from 'reactfire'

import { DocumentSnapshot, QuerySnapshot } from '~/definitions/firebase.firestore.types'
import { ArrayDocsQueryResult, HasDocQueryResult, SingleDocQueryResult } from '~/definitions/firestore.queries'

/**
 * Helpers to convert ReactFire response into a simili GraphQL like QueryResult, or AsyncReact to some extend
 * GraphQL useQuery => https://www.apollographql.com/docs/react/data/queries/#result
 * ReactAsync state => https://docs.react-async.com/api/state
 */

export const withGetDocResultAdapter = <T>({
  status, data, error,
}: ObservableStatus<DocumentSnapshot<T>>) => ({
    loading: status === 'loading',
    error,
    data,
  })

export const withSingleDocQueryResultAdapter = <T>({ status, data, error }: ObservableStatus<QuerySnapshot<T>>): SingleDocQueryResult<T> => ({
  loading: status === 'loading',
  error,
  data: (!data || data?.size === 0) ? undefined : data.docs[0],
})

export const withArrayDocQueryResultAdapter = <T>({ status, data, error }: ObservableStatus<QuerySnapshot<T>>):ArrayDocsQueryResult<T> => ({
  loading: status === 'loading',
  error,
  data: data?.docs,
})

export const withTakeFirstResultAdapter = <T>({ data, status, error }: ObservableStatus<QuerySnapshot<T>>): SingleDocQueryResult<T> => (
  {
    loading: status === 'loading',
    error,
    data: (data && data.size > 0) ? data.docs[0] : undefined,
  }
)

export const withHasResultAdapter = <T>({ data, status, error }:ObservableStatus<QuerySnapshot<T>>):HasDocQueryResult => (
  {
    loading: status === 'loading',
    error,
    data: (data && data.size > 0),
  }
)
