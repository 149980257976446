import { FC } from 'react'
import { PricingCard as RnPricingCard, PricingCardProps } from 'react-native-elements'

export type PricingProps = PricingCardProps

/**
 * See
 * https://reactnativeelements.com/docs/PricingCard
 */
export const Pricing: FC<PricingCardProps> = (props) => <RnPricingCard {...props} />
