import { library } from '@fortawesome/fontawesome-svg-core'
import { faCopy } from '@fortawesome/pro-light-svg-icons' // somehow works or not, just trying and chosing pro-solid/pro-regular/pro-light
import Clipboard from '@react-native-clipboard/clipboard'
import { openBrowserAsync } from 'expo-web-browser'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import analytics from '~/analytics'
import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import {
  Button, Icon, Image, Overlay, Pressable, Text, View,
} from '~/elements'
import { useAffiliationBySlug } from '~/hooks/dato.affiliation'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { useUser } from '~/hooks/user.context'

import { Expire } from './Expire'

library.add(faCopy)

export const MindSection = () => {
  const { t } = useTranslation(['home', 'common', 'player'])
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)

  const { sm } = useMediaQuery()

  const { user } = useUser()
  const code = user?.vendors?.mind?.activation_code
  const { data } = useAffiliationBySlug('mind')

  const onOpenOverlay = useCallback(() => {
    setIsOverlayOpen(true)
    analytics.track('affiliation-viewed', {
      name: 'mind',
      code: code || null,
    })
  }, [code])

  if (!data || !code) {
    return null
  }

  const {
    affiliation: {
      logo,
      illustrations,
      applicationStoreAssets,
    },
  } = data

  const appStoreAsset = applicationStoreAssets.find((applicationStoreAsset) => applicationStoreAsset.slug === 'apple')
  const playStoreAsset = applicationStoreAssets.find((applicationStoreAsset) => applicationStoreAsset.slug === 'google')

  const MindOverlay = () => {
    const [isCopyClicked, setIsCopyClicked] = useState<boolean>(false)

    const copyToClipboard = () => {
      if (code) {
        Clipboard.setString(code)
        setIsCopyClicked(true)
      }
    }

    const closeOverlay = () => {
      setIsOverlayOpen(false)
      setIsCopyClicked(false)
    }

    const steps = [1, 2, 3]

    return (
      <Overlay isVisible={isOverlayOpen} onBackdropPress={() => closeOverlay()} >
        <Overlay.Content scrollEnabled={true} tw="bg-brand-lightest sm:rounded-xl">
          { illustrations && <Image {...illustrations[0]} tw="w-32 absolute top-0 left-0" /> }

          <View tw="px-8 lg:px-12">

            <View tw="flew items-center justify-center mt-16">
              { logo && <Image {...logo} tw="w-12" /> }
            </View>

            <Text tw="font-serif font-semibold text-xl text-center basic-darkest mt-8">{t('home:mind.titleOverlay')}</Text>
            <Text tw="mt-4 font-sans text-sm black leading-relaxed">{t('home:mind.description')}</Text>

            <Text tw="font-serif font-semibold text-base text-center basic-darker mt-4">{t('home:mind.code')}</Text>

            <View tw="my-4">
              { code && <View tw='flex-row justify-center items-center'>
                <Pressable tw="bg-surface-1 inline rounded-sm py-1 px-2" onPress={copyToClipboard}>
                  <Text tw='mr-2 font-sans font-semibold text-sm'>
                    {code}
                  </Text>
                  <Icon name='copy' variant="light" sizeNum={14} />
                </Pressable>
              </View> }
              { isCopyClicked && <Expire delay={3000} onExpired={() => setIsCopyClicked(false)}>
                <Text tw="text-center font-sant text-xs mt-1">{t('home:mind.copied')}</Text>
              </Expire>}
            </View>

            <View tw="my-4">
              <Text tw="basic-darker font-sans font-semibold text-xs uppercase mb-2"> {t('home:mind.how.question')}</Text>
              {steps.map((step, i) => <Text key={i} tw="text-sm black font-sans leading-relaxed">
                {t(`home:mind.how.step${step}`)}
              </Text>)}

              <View tw="flex-row justify-between md:justify-center mt-8">
                <Pressable onPress={() => openBrowserAsync(appStoreAsset.downloadUrl)} >
                  { appStoreAsset && <Image {...appStoreAsset.icon} tw="w-36 md:mr-4 h-12" /> }
                </Pressable>
                <Pressable onPress={() => openBrowserAsync(playStoreAsset.downloadUrl)}>
                  { playStoreAsset && <Image {...playStoreAsset.icon} tw="w-40 h-12" /> }
                </Pressable>
              </View>
            </View>
          </View>
        </Overlay.Content>
      </Overlay>
    )
  }

  const CloudAndLogo = () => <>
    { illustrations && <Image {...illustrations[0]} tw="w-28 sm:w-32 absolute top-0 left-0" /> }
    { logo && <Image {...logo} tw="w-6 sm:w-10 absolute top-4 left-4" /> }
  </>

  const Heading = () => <SectionHeading title={t('home:mind.title')} description={t('home:mind.description')} tw="mt-10 sm:mt-4" />

  const CellImage = () => (illustrations ? <Image {...illustrations[1]} tw="mt-4 sm:mt-8 w-60" /> : null)

  const ObtainCta = () => <Button title={t('home:mind.cta.obtain')} onPress={onOpenOverlay} tw="mt-4 mb-12 sm:mb-8" />

  return (
    <>
      {!sm
        && <BasicSectionContainer tw="bg-brand-lightest mt-8">
          <CloudAndLogo />
          <Heading />
          <View tw="flex items-center">
            <CellImage />
          </View>
          <ObtainCta />
        </BasicSectionContainer>
      }

      {sm
        && <BasicSectionContainer tw="mt-4">
          <Heading />
          <View tw="bg-brand-lightest mt-4 rounded-2xl">
            <CloudAndLogo />
            <View tw="flex items-center">
              <CellImage />
              <ObtainCta />
            </View>
          </View>
        </BasicSectionContainer>
      }
      <MindOverlay />
    </>
  )
}
