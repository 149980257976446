import { DrawerNavigationOptions } from '@react-navigation/drawer'
import { ReactNode } from 'react'

import { FeatureFlagType } from '~/definitions/featureConfig'
import { IconProps } from '~/elements/icons/Icon/IconProps.d'
import { FeatureConfigAllValues, useFeatureConfigAll } from '~/hooks/features'

import { ChatNavigator } from './ChatNavigator'
import { EventsNavigator } from './EventsNavigator'
import { HomeNavigator } from './HomeNavigator'
import { LibraryNavigator } from './LibraryNavigator'
import { MenuParamList } from './MenuParamList'
import { MyTrackNavigator } from './MyTrackNavigator'

type MenuItem = {
  key: string
  name: keyof MenuParamList
  component: ReactNode
  icon?: IconProps
  hidden?:boolean
  options?: DrawerNavigationOptions
  featureFlag?: FeatureFlagType
}

const menuItems:Array<MenuItem> = [
  {
    key: 'home',
    name: 'HomeStack',
    component: HomeNavigator,
    icon: {
      name: 'house',
    },
  },
  {
    key: 'library',
    name: 'LibraryStack',
    component: LibraryNavigator,
    icon: {
      name: 'books',
    },
  },
  {
    key: 'myTrack',
    name: 'MyTrackStack',
    component: MyTrackNavigator,
    icon: {
      name: 'loveseat',
    },
  },
  {
    key: 'chat',
    name: 'ChatStack',
    component: ChatNavigator,
    icon: {
      name: 'message-lines',
    },
  },
  {
    key: 'events',
    name: 'EventsStack',
    component: EventsNavigator,
    icon: {
      name: 'calendar-week',
    },
  },
]

export const nonVisibleScreens = menuItems.filter((item) => item.hidden).map((item) => item.name)

const isMenuItemActive = (featureConfig: FeatureConfigAllValues, menuItem: MenuItem) => {
  if (menuItem.hidden) {
    return false
  }

  if (!menuItem.featureFlag) {
    return true
  }

  const featureConfigAsString = featureConfig[menuItem.featureFlag].asString()

  if (featureConfigAsString) {
    // Force default to true in case of featureConfig doesn't define this property yet
    const { isMenuItemDisplayed = true } = JSON.parse(featureConfigAsString)

    if (!isMenuItemDisplayed) {
      return false
    }
  }

  return true
}

export const useMenuItems = () => {

  const { status, data: featureConfig } = useFeatureConfigAll()

  return {
    loading: status === 'loading',
    data: menuItems,
    // ? menuItems.filter((menuItem) => isMenuItemActive(featureConfig, menuItem))
    // : undefined,
  }
}
