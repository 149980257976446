import {
  APP_VERSION,
} from 'react-native-dotenv'

import { Button, Text, View } from '~/elements'
import { useFeatureConfigBoolean } from '~/hooks/features'
import { useUser } from '~/hooks/user.context'

export const DeveloperInfo = () => {

  const { status, data: enableDeveloperMode } = useFeatureConfigBoolean('enableDeveloperMode')
  const { user } = useUser()

  if (status === 'loading' || enableDeveloperMode === false) {
    return null
  }

  return (
    <View tw="w-full max-w-md">
      <Text tw="text-center">Logged as {user.email} | version {APP_VERSION}</Text>
      <Button tw="mt-4" title="Force crash the app" onPress={() => {
        throw new Error('manual error...')
      }} />
    </View>
  )
}
