/**
 * Navigation icons
 */
import { library } from '@fortawesome/fontawesome-svg-core'
// import {
//   faBell as falBell,
//   faBook as falBook,
//   faBooks as falBooks,
//   faBrain as falBrain,
//   faCircleUser as falCircleUser,
//   faComments as falComments,
//   faGear as falGear,
//   faHouse as falHouse,
//   faLoveseat as falLoveseat,
//   faMessageLines as falMessageLines,
//   faStar as falStar,
//   faUser as falUser,
// } from '@fortawesome/pro-light-svg-icons'
import {
  faBell as farBell,
  faBook as farBook,
  faBooks as farBooks,
  faBrain as farBrain,
  faCalendarWeek as farCalendarWeek,
  faCircleUser as farCircleUser,
  faComments as farComments,
  faGear as farGear,
  faHouse as farHouse,
  faLoveseat as farLoveseat,
  faMessageLines as farMessageLines,
  faStar as farStar,
  faUser as farUser,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faBell,
  faBook,
  faBooks,
  faBrain,
  faCalendarWeek,
  faCircleUser,
  faComments,
  faGear,
  faHouse,
  faLoveseat,
  faMessageLines,
  faStar,
  faUser,
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  // Light style imports
  // falBell,
  // falBook,
  // falBooks,
  // falBrain,
  // falComments,
  // falGear,
  // falHouse,
  // falMessageLines,
  // falStar,
  // falUser,
  // falCircleUser,
  // falLoveseat,
  // Regular style imports
  farBell,
  farBook,
  farBooks,
  farBrain,
  farComments,
  farGear,
  farHouse,
  farMessageLines,
  farCalendarWeek,
  farStar,
  farUser,
  farCircleUser,
  farLoveseat,
  // Solid style imports
  faBell,
  faBook,
  faBooks,
  faBrain,
  faComments,
  faGear,
  faHouse,
  faMessageLines,
  faCalendarWeek,
  faUser,
  faCircleUser,
  faStar,
  faLoveseat,

)
