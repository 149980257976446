import {
  ApolloClient, createHttpLink,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import {
  DATOCMS_API_KEY,
  DATOCMS_ENABLE_PREVIEW,
  ENV,
} from 'react-native-dotenv'

/**
 * We may want to target specific environment
 * See https://www.datocms.com/docs/content-delivery-api/api-endpoints#specifying-an-environment
 */
const datoEnvironment = 'primary'

const datoEndpointUriBuilder = () => {
  let uri = 'https://graphql.datocms.com/'

  // In case of specific environment (only for non production)
  if (datoEnvironment !== 'primary' && ENV !== 'production') {
    uri += `${datoEnvironment}/`
  }

  // we always access drafts docs unless in production
  if (DATOCMS_ENABLE_PREVIEW) {
    uri += 'preview'
  }

  return uri
}

const httpLink = createHttpLink({
  uri: datoEndpointUriBuilder(),
})

const authLink = setContext((_, { headers }) => ({
  headers: Object.assign(headers || {}, {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${DATOCMS_API_KEY}`,
  }),
}))

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      AppSlide: { keyFields: ['key'] },
      AppSwiper: { keyFields: ['key'] },
      Content: { keyFields: ['catalogueId'] },
      ContentGroup: { keyFields: ['slug'] },
      ContentIllustrationRecord: { keyFields: ['id'] },
      Evaluation: { keyFields: ['slug'] },
      GroupSession: { keyFields: ['catalogueId'] },
      Contractor: { keyFields: ['slug'] },
      Minder: { keyFields: ['slug'] },
      Scale: { keyFields: ['key'] },
      ScaleValue: { keyFields: ['key'] },
      ScoreDimension: { keyFields: ['key'] },
      ScoreDimensionGroup: { keyFields: ['key'] },
      ScoreSegment: { keyFields: ['key'] },
      ScoreValue: { keyFields: ['key'] },
      Session: { keyFields: ['slug'] },
    },
  }),
  connectToDevTools: __DEV__,
})
