import { getFunctions } from 'firebase/functions'
import { useFirebaseApp } from 'reactfire'

import { Functions } from '~/definitions/firebase.functions.types'

export const FIREBASE_REGION = 'europe-west3'

/**
 * Wrapper to provide functions with specific region
 */
export const useFunctions = (region?: string): Functions => {
  const firebaseApp = useFirebaseApp()

  return getFunctions(firebaseApp, region || FIREBASE_REGION)
}
