import { useLinkTo } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import * as Sentry from '@sentry/react'
import { Suspense, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, View } from '~/elements'
import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
// import { CustomHeaderTitle } from '~/elements/headers/CustomHeaderTitle'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { EvaluationPlayerScreen } from '~/screens/EvaluationPlayerScreen/EvaluationPlayer'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { MyEvaluationsScreen } from '~/screens/MyEvaluationsScreen/MyEvaluationsScreen'
import { NewFormThankYouScreen } from '~/screens/NewFormThankYouScreen/NewFormThankYouScreen'
import { NewTypeformScreen } from '~/screens/NewTypeformScreen/NewTypeformScreen'

import { EvaluationsStackParamList } from './EvaluationsStackParamList'

const EvaluationsStack = createStackNavigator<EvaluationsStackParamList>()

export const EvaluationsNavigator = () => {
  // We load translation and ensure loading before moving on
  const { t } = useTranslation(['my-evaluations', 'navigation'])
  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])
  const NewTypeformHeader = useCallback(() => <CloseFullOverlayHeader label={t('common:cta.later')} headerRightTw="mr-6 md:mr-4" />, [t])
  return (
    <Sentry.ErrorBoundary
      fallback={<Fallback />}
      beforeCapture={(scope) => {
        scope.setTag('location', 'evaluations')
      }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <EvaluationsStack.Navigator>

          <EvaluationsStack.Screen
            name="MyEvaluations"
            component={MyEvaluationsScreen}
            options={{
              headerShown: false,
              title: t('navigation:evaluations.main'),
              headerTransparent: true,
              headerTitle: () => null,
            }}
          />

          <EvaluationsStack.Screen
            name="PersonalityPlayer"
            component={EvaluationPlayerScreen}
            // options={{
            //   headerTitle: CustomHeaderTitle,
            // }}
            options={{
              headerShown: false,
            }}
          />

          <EvaluationsStack.Screen
            name="NewTypeform"
            component={NewTypeformScreen}
            options={{
              headerShown: true,
              // title: t('evaluations.personalityTest'),
              // headerTitle: CustomHeaderTitle,
              header: NewTypeformHeader,
            }}
          />

          <EvaluationsStack.Screen
            name="NewTypeformComplete"
            component={NewFormThankYouScreen}
            options={{
              headerShown: false,
            }}
          />

        </EvaluationsStack.Navigator>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}

export const SideIcon = () => {
  const linkTo = useLinkTo()

  return (
    <View style={{ marginRight: 16 }}>
      <Icon name="plus" size="lg" onPress={() => linkTo('/evaluations/orientation/new')} />
    </View>
  )
}
