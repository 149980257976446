import { FC, memo } from 'react'

import { ActivityIndicator } from '~/elements'

export type LoadingIndicatorProps = {
  /** Set the color of the LoadingIndicator */
  color?: string;
  /** The size of the loading icon, @default 15px */
  size?: number;
};

const UnMemoizedLoadingIndicator: FC<LoadingIndicatorProps> = (props) => <ActivityIndicator {...props}/>

/**
 * Simple loading spinner
 */
export const LoadingIndicator = memo(
  UnMemoizedLoadingIndicator,
  (prevProps, nextProps) => prevProps.color === nextProps.color && prevProps.size === nextProps.size,
) as typeof UnMemoizedLoadingIndicator
