import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'

import { Button } from '~/elements'
import { MainStackParamList } from '~/navigators/MainStackParamList'

export type NotFoundScreenProps = StackScreenProps<MainStackParamList, 'NotFound'>

const NotFoundScreen: FC<NotFoundScreenProps> = () => {
  const { t } = useTranslation(['main'])

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      <Text>{t('main:notFound.description')}</Text>
      <Button tw="mt-4" title={t('main:notFound.cta')} to="/" />
    </View>
  )
}

export default NotFoundScreen
