import { Attachment } from 'stream-chat-react'

import { View } from '~/elements'
import { useContentById } from '~/hooks/dato.content'
import { getFullName } from '~/utils/people.helpers'

import { ContentTile } from '../panels/tiles/ContentTile'

const ContentAttachement = ({ contentId }) => {

  const { loading, data } = useContentById(contentId)

  if (loading) {
    return null
  }

  const {
    id, title, coverBackground, coverMain, coverBackgroundColor, coverMainFormat, excerpt, author,
  } = data.content

  return (
    <View tw="mt-2">
      <ContentTile
        testID={id}
        image={{
          backgroundColor: coverBackgroundColor,
          background: coverBackground,
          illustration: coverMain,
          format: coverMainFormat,
        }}
        title={title}
        metadata={getFullName(author)}
        description={excerpt}
        orientation="portrait"
        size="medium"
        navigateTo={`/content/${id}`}
      />
    </View>
  )
}

export const CustomAttachment = (props) => {
  const { attachments } = props
  const [attachment] = attachments || []

  if (attachment?.type === 'mindday-content') {
    return (
      <ContentAttachement contentId={attachment.contentId}/>
    )
  }

  return <Attachment {...props} />
}
