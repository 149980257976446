import cleanDeep from 'clean-deep'
import Color from 'color'
import { add, format } from 'date-fns'
import {
  CSSProperties, Dispatch, FC, memo, SetStateAction,
} from 'react'
import {
  CalendlyEventListener,
  InlineWidget,
} from 'react-calendly'
import { Platform } from 'react-native'

import { UserContextType } from '~/context/UserContext.d'
import { getColor } from '~/theme/helpers'

type CalendlyEmbedProps = {
  setStep: Dispatch<SetStateAction<'intro' | 'scheduled' | 'date_time_selection' | 'confirmation'>>,
  url: string,
  user: UserContextType['user'],
  setMeetingData: Dispatch<SetStateAction<{ calendlyMeetingId: string } | undefined>>,
  eventId: string,
  style: CSSProperties,
}

export const CalendlyEmbed: FC<CalendlyEmbedProps> = memo(({
  setStep,
  url,
  user,
  setMeetingData,
  eventId,
  style,
}) => {

  // open the calendar in the month in two weeks time
  const inTwoWeeksDate = add(new Date(), { weeks: 2 })

  const monthParam = format(inTwoWeeksDate, 'yyyy-MM')

  const backgroundColor = Color(getColor('surface-brand')).hex().replace('#', '')
  const primaryColor = Color(getColor('brand-base')).hex().replace('#', '')
  const textColor = Color(getColor('black')).hex().replace('#', '')

  return (
    <CalendlyEventListener
      onDateAndTimeSelected={() => {
        // console.log(' CalendlyEventListener/onDateAndTimeSelected', e.data)
        setStep('confirmation')
      }}
      onEventScheduled={(e) => {
        // console.log(' CalendlyEventListener/onEventScheduled', e.data)
        setStep('scheduled')
        setMeetingData({
          calendlyMeetingId: e.data.payload.event.uri.split('/').slice(-1)[0] as string,
        })
      }}
      onEventTypeViewed={() => {
        // console.log(' CalendlyEventListener/onEventTypeViewed', e.data)
        setStep('date_time_selection')
      }}
    >
      <InlineWidget
        url={`${url}?month=${monthParam}`}
        styles={style}
        pageSettings={{
          backgroundColor,
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          hideGdprBanner: true,
          primaryColor,
          textColor,
        }}
        prefill={cleanDeep({
          email: user.email ? user.email : undefined,
          name: user.displayName ? user.displayName : undefined,
          customAnswers: {
            a1: user.phoneNumber ? user.phoneNumber : undefined,
          },
        })}
        utm={{
          // utmCampaign: 'Spring Sale 2019',
          utmContent: eventId,
          utmMedium: Platform.OS,
          utmSource: 'App',
          utmTerm: user.uid,
        }} />
    </CalendlyEventListener>
  )
})
