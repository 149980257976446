import classNames from 'classnames'

import Image from '../images/Image'
import { getAvatarBackgroundColorString, getAvatarShapeString, getAvatarSizeString } from './tw.helpers'

export const AvatarImage = ({
  size, shape, color, image, tw,
}) => {
  // console.log('-> AvatarImage', size)

  const newTw = classNames(
    getAvatarBackgroundColorString(color),
    getAvatarSizeString(size),
    getAvatarShapeString(shape),
    tw,
  )

  // console.log('newTw', newTw)

  return <Image {...image} tw={newTw} />
}
