// import { Header, HeaderProps } from '@react-navigation/elements'
import classNames from 'classnames'
import { BlurTint } from 'expo-blur'
import { FC } from 'react'

import { View } from '~/elements'
import { useMediaQuery } from '~/hooks/mediaQuery'

import { BlurView } from '../containers/BlurView'
import { Header, HeaderProps } from './Header'

export type FullOverlayHeaderProps = HeaderProps & {
 tw?:string
 blur?:BlurTint
 padded: boolean // add extra padding horizontally
}

const EventuallyBlurView = ({ children, blur, ...props }) => {
  const { md } = useMediaQuery()
  if (blur && md) {
    return <BlurView {...props} intensity={30} tint={blur} >{children}</BlurView>
  }

  return <View {...props}>{children}</View>
}

/**
 * A custom heder that respects the same layout as the FullOverlay grid layout
 */
export const FullOverlayHeader:FC<FullOverlayHeaderProps> = ({
  padded, tw, blur = false, setDimensions, ...props
}) => {
  const { md } = useMediaQuery()
  const containerTw = classNames('absolute w-full self-center flex-row justify-center', tw)

  return (
    <EventuallyBlurView tw={containerTw} blur={blur}>
      <View
        onLayout={(event) => {
          const {
            width, height,
          } = event.nativeEvent.layout
          if (setDimensions) {
            setDimensions({ width, height })
          }
        }}
        tw={classNames('max-w-6xl ', md && padded ? 'w-10/12' : 'w-full')}
      >
        <Header
          {...props}
        />
      </View>
    </EventuallyBlurView>
  )
}
