import { gql } from '@apollo/client'

// Notes we should use fragments but not quite sure about how to pass variables

export const GET_TYPEFORM_BY_SLUG = gql`
  query GetTypeformBySlugQuery($slug: String, $locale: SiteLocale) {
    form(filter: {slug: {eq: $slug}}) {
      title
      description
      to
      outro(locale: $locale)
      typeformId(locale: $locale)
      typeformIdDev(locale: $locale)
    }
  }
`

// cf https://www.datocms.com/docs/content-delivery-api/images-and-videos#responsive-images

export type TypeformQueryResult = {
  title: string
  typeformId: string
  typeformIdDev: string
}

export const GET_ALL_ENABLED_EVALUATIONS = gql`
  query GetAllEnabledEvaluationsQuery($locale: SiteLocale) {
    allEvaluations(locale: $locale, filter: {displayInApp: {eq: "true"}}) {
      slug
      title
      typeform {
        typeformId
        typeformIdDev
      }
    }
  }
`
