import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire'

import { DocumentReference, DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { CustomerCampaignData } from '~/definitions/firestore.customerCampaign'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getLatestAuthorizedCampaignByDomain } from '~/queries/firestore.customers'

import { SingleDocQueryResult } from '../definitions/firestore.queries'
import { withGetDocResultAdapter, withSingleDocQueryResultAdapter } from './firestore.hooks.helpers'

export const useLatestAuthorizedCampaignByDomain = (domain: string): SingleDocQueryResult<CustomerCampaignData> => {
  const query = getLatestAuthorizedCampaignByDomain({ domain }, { db: useFirestore() })
  const res = useFirestoreCollection<CustomerCampaignData>(query, {
    idField: 'id', // this field will be added to the object created from each document
  })

  return withSingleDocQueryResultAdapter(res)
}

export const useCustomerCampaignByRef = (customerCampaignRef: DocumentReference<CustomerCampaignData>) => {
  const query = useFirestoreDoc(customerCampaignRef)

  return withGetDocResultAdapter(query)
}

export const useCustomerCampaignByUserTrackDoc = (userTrackDoc: DocumentSnapshot<UserTrackData>) => {
  const customerCampaignRef = userTrackDoc.data()?.customerCampaign as DocumentReference<CustomerCampaignData>

  const query = useFirestoreDoc(customerCampaignRef)

  return withGetDocResultAdapter(query)
}
