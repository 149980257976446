import { createStackNavigator } from '@react-navigation/stack'
import * as Sentry from '@sentry/react'
import { Suspense, useCallback } from 'react'

import { GoBackHeaderButton } from '~/elements/buttons/headerButtons/GoBackHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { CategoryScreen } from '~/screens/CategoryScreen/CategoryScreen'
import { ExploreContentScreen } from '~/screens/ExploreContentScreen/ExploreContentScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { LibraryStackParamList } from './LibraryStackParamList'

const LibraryStack = createStackNavigator<LibraryStackParamList>()

export const LibraryNavigator = () => {

  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])
  return (
    <Sentry.ErrorBoundary
      fallback={<Fallback />}
      beforeCapture={(scope) => {
        scope.setTag('location', 'library')
      }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <LibraryStack.Navigator initialRouteName="Explore" >
          <LibraryStack.Screen
            name="Explore"
            component={ExploreContentScreen}
            options={{ headerShown: false }}
          />
          <LibraryStack.Screen
            name="Category"
            component={CategoryScreen}
            options={{
              headerShown: true,
              headerTransparent: true,
              headerLeft: () => <GoBackHeaderButton label={false} color="control" to="/library"/>,
              header: MainLayoutHeader,
            }}
          />
        </LibraryStack.Navigator>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}
