import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import { meetingStatus } from '~/constants/firestore.meetings'
import { CustomerCampaignData } from '~/definitions/firestore.customerCampaign'
import { useHasCurrentUserFeelingsEvaluation, useHasCurrentUserPersonalityEvaluation } from '~/hooks/firestore.evaluations'
import { useCurrentUserOrientationMeetings } from '~/hooks/firestore.meetings'
import { useUser } from '~/hooks/user.context'
import { getMyOnboardingList, OnboardingListItem } from '~/screens/OnboardingChecklistScreen/onboardingList'

import { useCustomerCampaignByUserTrackDoc } from './firestore.customers.campaigns'

type OnboardingDataType = {
  loading: boolean
  error: Error
  data: Array<any>
  isComplete: () => boolean
}

const hasAccessLevel = ({
  customerCampaignData,
  access,
}: {
  customerCampaignData?: CustomerCampaignData,
  access: OnboardingListItem['access'],
}) => {
  if (!customerCampaignData) {
    return false
  }
  if (!access) {
    return true
  }

  return Object.keys(access).every((key) => access[key] === customerCampaignData.plan[key])
}
export const useOnboardingData = (): OnboardingDataType => {

  const { user, userTrackDoc } = useUser()
  const { t } = useTranslation(['home'])

  const { data: customerCampaign } = useCustomerCampaignByUserTrackDoc(userTrackDoc)
  const customerCampaignData = customerCampaign?.data()

  // CONTACT - Has user already completed the "contact details" step ?
  const hasUserCompletedDetails = !!get(user, 'phoneNumber')

  // ORIENTATION FORM - Do we have a user-orientation evaluation already (aka "how I feel")?
  const feelingEvaluationResponse = useHasCurrentUserFeelingsEvaluation()
  const hasUserTakenOrientationForm = feelingEvaluationResponse.data

  // ORIENTATION MEETING - Do we have an orientation meeting scheduled yet ?
  const meetingResponse = useCurrentUserOrientationMeetings()
  const hasUserScheduledOrientationMeeting = meetingResponse.data?.length > 0

  let orientationMeetingStatus = 'todo'
  if (hasUserScheduledOrientationMeeting) {
    // if all meetings are planned, we're still awaiting
    orientationMeetingStatus = meetingResponse.data.find((meeting) => meeting.get('status') === meetingStatus.DONE)
      ? 'done'
      : 'await'
  }

  // PERSONALITY - Do we have a personality test taken already ?
  const personalityEvaluationResponse = useHasCurrentUserPersonalityEvaluation()
  const hasUserTakenPersonalityTest = personalityEvaluationResponse.data

  /**
   * We enrich our steps with current status and fetched data
   */
  let hasActiveItem = false
  const data = getMyOnboardingList(t).reduce((items, item, index) => {

    if (!hasAccessLevel({ customerCampaignData, access: item.access })) {
      return items
    }

    // console.log('item', item)
    const newItem = { ...item }
    switch (index) {
    /**
       * contact details is a simple binary item
       */
    case 0: {
      newItem.status = hasUserCompletedDetails ? 'completed' : 'todo'
      newItem.data = user
      break
    }
    /**
       * user-orientation evaluation
       */
    case 1: {
      newItem.status = hasUserTakenOrientationForm ? 'completed' : 'todo'
      newItem.data = feelingEvaluationResponse.data
      break
    }
    /**
       * orientation meeting
       */
    case 2: {
      newItem.status = orientationMeetingStatus
      newItem.data = meetingResponse.data
      break
    }
    /**
       * Personality test
       */
    case 3: {
      newItem.status = hasUserTakenPersonalityTest ? 'completed' : 'todo'
      newItem.data = personalityEvaluationResponse.data
      break
    }
    default:
      break
    }

    // We flag first item to be done
    if (newItem.status === 'todo' && !hasActiveItem) {
      newItem.active = true
      hasActiveItem = true
    }

    return [
      ...items,
      newItem,
    ]
  }, [] as Array<OnboardingListItem>)

  // if there is no element active then the task list was completed by user
  const isComplete = () => !data.find((o) => o.active)

  return {
    loading: feelingEvaluationResponse.loading || meetingResponse.loading || personalityEvaluationResponse.loading,
    error: feelingEvaluationResponse.error || meetingResponse.error || personalityEvaluationResponse.error,
    data,
    isComplete,
  }
}
