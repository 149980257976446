const positions = ['static', 'fixed', 'absolute', 'relative', 'sticky']

const placement = ['top', 'right', 'bottom', 'left', 'inset']

export const getPositionRelatedClasses = (tw:string) => {

  const arr: Array<string> = tw.split(' ')
  const positionTwArr = arr.filter((item) => {
    if (positions.includes(item)) {
      return true
    }
    return placement.some((key) => (item.startsWith(`${key}-`) || item.startsWith(`-${key}-`)))
  })
  //   console.log('positionTwArr', positionTwArr.join(' '))

  return {
    positionTw: positionTwArr.join(' '),
    nonPositionTw: arr.filter((item) => !positionTwArr.includes(item)).join(' '),
  }
}

const sizePrefixes = ['h', 'w']
// Extract w- and h- from tw
export const getSizeRelatedClasses = (tw = '') => {
  const arr: Array<string> = tw.split(' ')
  const sizeTwArr = arr.filter((item) => sizePrefixes.some((key) => (item.startsWith(`${key}-`))))
  const res = sizeTwArr.join(' ')
  return {
    sizeTw: res.length > 0 ? res : undefined,
    nonSizeTw: arr.filter((item) => !sizeTwArr.includes(item)).join(' '),
  }
}

const roundedPrefixes = ['rounded']
// Extract w- and h- from tw
export const getRoundedRelatedClasses = (tw = '') => {
  const arr: Array<string> = tw.split(' ')
  const roundedTwArr = arr.filter((item) => roundedPrefixes.some((key) => (item.startsWith(`${key}-`))))
  const res = roundedTwArr.join(' ')
  return {
    roundedTw: res.length > 0 ? res : undefined,
    nonRoundedTw: arr.filter((item) => !roundedTwArr.includes(item)).join(' '),
  }
}

const marginPrefixes = ['mr', 'ml', 'mt', 'mb', 'mx', 'my']
export const getMarginRelatedClasses = (tw = '') => {
  const arr: Array<string> = tw.split(' ')
  const twArr = arr.filter((item) => marginPrefixes.some((key) => (item.startsWith(`${key}-`))))
  const res = twArr.join(' ')
  return {
    marginTw: res.length > 0 ? res : undefined,
    nonMarginTw: arr.filter((item) => !twArr.includes(item)).join(' '),
  }
}
