import { FC } from 'react'

import { useCurrentUserNextPlannedSession } from '~/hooks/firestore.meetings'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { SessionCard } from '../../components/panels/cards/SessionCard'

type NextSessionCardProps = Record<string, never>

export const SessionPlannedSection: FC<NextSessionCardProps> = () => {
  const { loading, error, data } = useCurrentUserNextPlannedSession()

  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  if (!data) {
    return null
  }

  return (<SessionCard {...data.data()} meetingId={data.id} />)
}
