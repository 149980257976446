import removeMarkdown from 'markdown-to-text'
import { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '~/elements'
import { ContentPageParagraph } from '~/elements/text/Player.FontTypes'

type CollapsibleContentProps = {
  /**
   * Additionnal style for paragraph text
   */
  tw?: string
  numberOfLines?: number
  children: ReactNode
}

export const CollapsibleContent:FC<CollapsibleContentProps> = ({ tw = 'text-center', numberOfLines = 3, children }) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const { t } = useTranslation()

  if (isCollapsed) {
    return (
      <>
        <ContentPageParagraph tw={tw} numberOfLines={numberOfLines} markdown={false}>{removeMarkdown(children)}</ContentPageParagraph>
        <Button title={t('common:cta.details')} variant="ghost" onPress={() => setCollapsed(false)} tw="uppercase text-orange-500" />
      </>
    )
  }

  return <ContentPageParagraph markdown tw={tw}>{children}</ContentPageParagraph>
}
