import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Categories } from '~/__generated__/Categories'
import { GetAllStoriesByGroupQuery } from '~/__generated__/GetAllStoriesByGroupQuery'
import { GET_GROUP_WITH_CONTENT } from '~/queries/dato.content'
import { GET_CATEGORIES } from '~/queries/dato.content.groups'

export const useAllContentsForCategoryById = (categoryId: string) => {
  const { i18n } = useTranslation()

  return useQuery<GetAllStoriesByGroupQuery, { groupId: string }>(GET_GROUP_WITH_CONTENT, {
    variables: { groupId: categoryId, locale: i18n.language },
  })
}

export const useCategories = () => {
  const { i18n } = useTranslation()

  const reqRes = useQuery<Categories, Record<string, unknown>>(GET_CATEGORIES, { variables: { locale: i18n.language } })

  // count nbContent per group
  // FIXME when we have more than 100 content, needs pagination and a separate hook likely
  if (reqRes.data) {
    // console.log(`reqRes.data`, reqRes.data)
    const nbContentPerGroup = reqRes.data.allContents.reduce((acc, content) => {
      content.groups.forEach((group) => {
        const groupId = group.id
        if (acc[groupId]) {
          acc[groupId] += 1
        } else {
          acc[groupId] = 1
        }
      })

      return acc
    }, {})

    const res = reqRes.data?.allContentGroups.map((item) => ({ ...item, nbContent: nbContentPerGroup[item.id] || 0 }))
    // console.log(`res`, res)

    return {
      loading: reqRes.loading,
      error: reqRes.error,
      data: { allContentGroups: res },
    }
  }

  return reqRes
}
