import snakeCase from 'lodash/snakeCase'

import { roleList } from '~/constants/firebase.auth'

/**
 * adjusts events names to be compatible with Firebase Analytics
 */
export const getFirebaseAnalyticsEventName = (name: string) => {

  // Semantic events - See https://segment.com/docs/connections/destinations/catalog/firebase/#event-mappings
  if (name === 'Products Searched') {
    return 'search'
  }
  if (name === 'Product List Viewed') {
    return 'view_item_list'
  }
  if (name === 'Product Viewed') {
    return 'view_item'
  }
  if (name === 'Product Clicked') {
    return 'select_content'
  }
  if (name === 'Product Shared') {
    return 'share'
  }
  if (name === 'Product Added') {
    return 'add_to_cart'
  }
  if (name === 'Product Added To Wishlist') {
    return 'add_to_wishlist'
  }
  if (name === 'Checkout Started') {
    return 'begin_checkout'
  }
  if (name === 'Promotion Viewed') {
    return 'present_offer'
  }
  if (name === 'Payment Info Entered') {
    return 'add_payment_info'
  }
  if (name === 'Order Completed') {
    return 'ecommerce_purchase'
  }
  if (name === 'Order Refunded') {
    return 'purchase_refund'
  }

  //  Other cases: snake casing should be sufficient
  return snakeCase(name)
}

export const getFirebaseAnalyticsPropertyName = (name: string) => {

  // Semantic properties - https://segment.com/docs/connections/destinations/catalog/firebase/#property-mappings
  if (name === 'category') {
    return 'item_category'
  }
  if (name === 'product_id') {
    return 'item_id'
  }
  if (name === 'name') {
    return 'item_name'
  }
  if (name === 'price') {
    return 'price'
  }
  if (name === 'quantity') {
    return 'quantity'
  }
  if (name === 'query') {
    return 'search_term'
  }
  if (name === 'shipping') {
    return 'shipping'
  }
  if (name === 'tax') {
    return 'tax'
  }
  if (name === 'total') {
    return 'value'
  }
  if (name === 'revenue') {
    return 'value'
  }
  if (name === 'order_id') {
    return 'transaction_id'
  }
  if (name === 'currency') {
    return 'currency'
  }

  //  Other cases: snake casing should be sufficient
  return snakeCase(name)
}

export const getFirebaseAnalyticsProperties = (obj: Record<string, unknown>): { [key: string]: string } => (
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: getFirebaseAnalyticsPropertyName(obj[key]) }), {})
)

//  Other cases: snake casing should be sufficient
export const getFirebaseAnalyticsUserPropertyName = (name: string) => snakeCase(name)
// console.log('-> getFirebaseAnalyticsUserPropertyName', name)

/**
 * Only include traits that are explicitely declared as custom properties
 */
export const getFirebaseAnalyticsUserProperties = (obj: Record<string, unknown>): AcceptableUserPropertiesType => Object.keys(obj)
  .reduce(
    (acc, key) => (roleList.includes(key)
      ? { ...acc, [getFirebaseAnalyticsUserPropertyName(key)]: obj[key] }
      : acc),
    {},
  )

export type AcceptableUserPropertiesType = Partial<{
    hasRoleStaff: boolean,
    hasRoleMinder: boolean,
  }>
