/**
 * Screen wrapping a sub evaluations navigator: containing personality test player
 */

import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'

import { EvaluationProvider, useEvaluationContext } from '~/context/EvaluationContext'
import { EvaluationsStackParamList } from '~/navigators/EvaluationsStackParamList'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { EvaluationPlayerNavigator } from '../../navigators/EvaluationPlayerNavigator'

export type EvaluationPlayerScreenProps = StackScreenProps<EvaluationsStackParamList, 'Testplayer'>

const PlayerCore = () => {
  const { loading, error, data } = useEvaluationContext()
  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }
  if (!data) {
    return null
  }

  // wait for content to be loaded in context before going further
  return <EvaluationPlayerNavigator />
}

export const EvaluationPlayerScreen: FC<EvaluationPlayerScreenProps> = ({ route }) => {
  const { evaluationId } = route.params
  // console.log('-> EvaluationPlayerScreen', evaluationId)

  // current page is either directly provided by route state when existing, or by global state if initial url
  // let currentPage = 0
  // if (route.params?.params?.pageNb) {
  //   currentPage = Number(route.params?.params?.pageNb)
  // }

  // console.log(`currentPage`, currentPage)

  // console.log(`footerText`, footerText)

  return (

    <EvaluationProvider evaluationId={evaluationId}>
      <PlayerCore />
    </EvaluationProvider>

  )
}
