/* eslint-disable import/no-duplicates */
/* eslint-disable global-require */
import {
  format as formatDate, formatDistance, formatRelative, isDate,
} from 'date-fns'
import { enGB, fr } from 'date-fns/locale'
import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import { initReactI18next } from 'react-i18next'
import { i18nextPlugin } from 'translation-check'

import DatoBackend from './dato.backend'
import CustomLanguageDetector from './language.detector'
import { defaultLanguage, supportedLanguages } from './languages'
// import AsyncStorageBackend from './local-storage.backend' // Temporarily commented

// i18n namespaces as declared in DatoCMS
export const i18nNamespaces = [
  'auth',
  'chat',
  'common',
  'events',
  'forms',
  'home',
  'library',
  'main',
  'me',
  'my-evaluations',
  'navigation',
  'onboarding',
  'player',
  'track',
]

// console.log('resources', resources)

// const locales = getLocales()
// console.log('locales', locales)

const dateFnsLocales = { en: enGB, fr }

// only need to init once (to avoid HMR warning)
if (!i18n.isInitialized) {
  i18n
    .use(CustomLanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(i18nextPlugin)
    .init({
    // debug: true,
      preload: supportedLanguages,
      ns: i18nNamespaces, // This forces some namespace to load
      fallbackLng: defaultLanguage,
      defaultNS: 'common',
      appendNamespaceToCIMode: true,
      appendNamespaceToMissingKey: true,
      supportedLngs: supportedLanguages,
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: (value, format, language) => {
          if (isDate(value)) {
            if (format === 'short') {
              return formatDate(value, 'P', { locale: dateFnsLocales[language] })
            }
            if (format === 'normal') {
              return formatDate(value, 'PP', { locale: dateFnsLocales[language] })
            }
            if (format === 'long') {
              return formatDate(value, 'PPPP', { locale: dateFnsLocales[language] })
            }
            if (format === 'time') {
              return formatDate(value, 'H:mm', { locale: dateFnsLocales[language] })
            }
            if (format === 'relative') {
              return formatRelative(value, new Date(), { locale: dateFnsLocales[language] })
            }
            if (format === 'datetime') {
              return formatDate(value, 'PPp', { locale: dateFnsLocales[language] })
            }
            if (format === 'ago') {
              return formatDistance(value, new Date(), {
                locale: dateFnsLocales[language],
                addSuffix: true,
              })
            }

            return formatDate(value, format, { locale: dateFnsLocales[language] })
          }

          return value
        },
      },
      react: {
        useSuspense: true, // currently fails to trigger ready state hence never going away from Suspense
      // bindI18n: 'added languageChanged',
      // bindI18nStore: 'added',
      },
      backend: {
        backends: [
        // AsyncStorageBackend, // Fetch from cache in priority
          DatoBackend, // Usually fetch from Dato CMS
        ],
        backendOptions: [{
          expirationTime: 0.5 * 24 * 60 * 60 * 1000, // 0.5 days
        }, {
          namespaceFieldName: 'namespace',
          dataFieldName: 'keys',
        }],
      },
    })
}

export default i18n
