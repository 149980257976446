import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ThreeColGridSection } from '~/components/layouts/column-sections/ThreeColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { MainStackParamList } from '~/navigators/MainStackParamList'
import { MenuParamList } from '~/navigators/MenuParamList'

import { PreferencesCore } from './PreferencesCore'

export type PreferencesOverlayScreenProps = StackScreenProps<MainStackParamList, 'Preferences'>

// TODO should be a proper navigation modal - https://reactnavigation.org/docs/modal/
export const PreferencesOverlayScreen: FC<PreferencesOverlayScreenProps> = () => {
  const { t } = useTranslation(['navigation'])
  return (
    <FullOverlayContainer tw="bg-surface-2 md:bg-transparent">
      <ScreenHeadingSection title={t('navigation:home.settings')} tw="mb-12" />
      <ThreeColGridSection tw="mt-0 md:px-0 pb-12 items-center md:items-start">
        <PreferencesCore />
      </ThreeColGridSection>
    </FullOverlayContainer>
  )
}

export type PreferencesDrawerScreenProps = StackScreenProps<MenuParamList, 'Preferences'>

// TODO should be a proper navigation modal - https://reactnavigation.org/docs/modal/
export const PreferencesDrawerScreen: FC<PreferencesDrawerScreenProps> = () => {
  const { t } = useTranslation(['navigation'])
  return (
    <MainContainer scrollEnabled tw="">
      <ScreenHeadingSection title={t('navigation:home.settings')} tw="mb-8" />
      <BasicSectionContainer>
        <PreferencesCore />
      </BasicSectionContainer>
    </MainContainer>
  )
}
