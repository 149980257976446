import { yupResolver } from '@hookform/resolvers/yup'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import {
  browserLocalPersistence, isSignInWithEmailLink, setPersistence, signInWithEmailLink,
} from 'firebase/auth'
import { FC, useEffect, useState } from 'react'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAuth } from 'reactfire'
import * as Yup from 'yup'

import { EmailInput } from '~/components/forms/input-groups/EmailInput'
import { AuthLayout } from '~/components/layouts/containers/AuthLayout'
import { LocalStorageSignInStrategies } from '~/definitions/localStorage.signInStrategy'
// import { UserCredential } from '~/definitions/firebase.auth.types'
import { Button } from '~/elements'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { localStorageKeys, useAsyncStorage } from '~/hooks/storage'
import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

export type MagicLinkReturnScreenProps = StackScreenProps<NonAuthStackParamList, 'MagicLinkReturn'>

type FormErrorsType = Record<'email', string | null>

type TitleType = 'auth:magicLinkReturn.title' | 'auth:magicLinkReturn.titleAlternateSignIn'
type DescriptionType = 'auth:magicLinkReturn.description' | 'auth:magicLinkReturn.titleAlternateDescription'

// Ex return url
// http://localhost:19006/auth/callback?continue=%2Fonboarding&mode=signIn&lang=en&oobCode=DMBwucG8y_0TXbXVaLV68CJD5BqY_zrdTfoOCXoPCxvqudsRuprDOx&apiKey=fake-api-key

/**
 * Displayed upon hitting magic link.
 * If link expired, we route him to the signin screen
 * If user is pre-logged with matching email, we directly route him to the redirect next screen
 * If not, we ask for email to log in if:
 * - current device is different than the one used to ask for sign in
 * - an alternate email has been used to sign in
 */
export const MagicLinkReturnScreen: FC<MagicLinkReturnScreenProps> = () => {

  const linkTo = useLinkTo()
  const auth = useAuth()

  // user email from local storage
  const {
    loading: isEmailFromLocalStorageLoading,
    value: emailFromLocalStorage,
  } = useAsyncStorage<string>(localStorageKeys.EMAIL)
  const {
    loading: isSignInEmailStrategyFromLocalStorageLoading,
    value: signInEmailStrategyFromLocalStorage,
  } = useAsyncStorage<string>(localStorageKeys.EMAIL_STRATEGY)

  const { t } = useTranslation(['auth', 'forms'])
  const { lg } = useMediaQuery()

  const [email, setEmail] = useState<null | string>(null)
  const [formErrors, setFormErrors] = useState<FormErrorsType>({
    email: null,
  })

  const [shouldDisplayForm, setShouldDisplayForm] = useState<boolean>(false)

  const [title, setTitle] = useState<TitleType>('auth:magicLinkReturn.title')
  const [description, setDescription] = useState<DescriptionType>('auth:magicLinkReturn.description')

  const schema = Yup.object().shape({
    email: Yup.string().email(t('forms:email.invalid')).required(t('forms:string.required')),
  })

  const methods = useForm({
    defaultValues: { email, password: '' },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (!isSignInEmailStrategyFromLocalStorageLoading && signInEmailStrategyFromLocalStorage === LocalStorageSignInStrategies.ALTERNATE) {
      setTitle('auth:magicLinkReturn.titleAlternateSignIn')
      setDescription('auth:magicLinkReturn.titleAlternateDescription')
    }
  }, [isSignInEmailStrategyFromLocalStorageLoading, signInEmailStrategyFromLocalStorage])

  useEffect(() => {
    if (!isEmailFromLocalStorageLoading) {
      setEmail(emailFromLocalStorage)

      if (!emailFromLocalStorage) {
        setShouldDisplayForm(true)
      }
    }
  }, [isEmailFromLocalStorageLoading, emailFromLocalStorage])

  // we've got a code
  useEffect(() => {
    const signUserIn = async () => {
      // eslint-disable-next-line no-useless-catch
      try {
        await setPersistence(auth, browserLocalPersistence)
      } catch (persistenceError) {
        // console.error('Persistence error')
        // console.error(error)
        throw persistenceError
      }

      try {
        // https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemaillink
        await signInWithEmailLink(auth, email, window.location.href)
        //  console.log('successful signInWithEmailLink')
      } catch (signInWithEmailLinkError) {

        switch (signInWithEmailLinkError.code) {
        case 'auth/invalid-action-code': {
          // most likely code expired. Need to resend code
          const encodedEmail = encodeURIComponent(email)

          linkTo(`/auth/signin?email=${encodedEmail}&origin=code-expired`)

          return
        }
        case 'auth/invalid-email': {
          setFormErrors({
            ...formErrors,
            email: t('auth:magicLinkReturn.invalidEmail'),
          })

          return
        }
        default:
          linkTo('/auth/signin?origin=unexpected')
          break
        }
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        // console.log('auth error code', error.code)
        throw signInWithEmailLinkError
      }

      // Clear email from storage.
      await AsyncStorage.removeItem(localStorageKeys.EMAIL)
      await AsyncStorage.removeItem(localStorageKeys.EMAIL_STRATEGY)
    }

    if (email && email.length > 0 && isSignInWithEmailLink(auth, window.location.href)) {
      signUserIn()
    }

    // purposely ignored auth, linktTo
  }, [email])

  if (isEmailFromLocalStorageLoading || emailFromLocalStorage) {
    return <LoadingScreen />
  }

  const onSubmit = (val:FieldValues) => setEmail(val.email)

  return (

    <AuthLayout title={t(title)} description={t(description)} imageId={10700738}>
      {shouldDisplayForm && (
        <FormProvider {...methods}>
          <EmailInput
            name='email'
            label={t('auth:magicLinkReturn.emailLabel')}
            placeholder={t('auth:magicLinkReturn.emailPlaceholder')}
            align={lg ? 'left' : 'center'}
            onEnterKeyPress={methods.handleSubmit(onSubmit)}
          />
          <Button
            onPress={methods.handleSubmit(onSubmit)}
            title={t('auth:magicLinkReturn.cta')}
            color="basic"
            size="xl"
            tw="mt-12"
          />
        </FormProvider>
      )}
    </AuthLayout>

  )
}
