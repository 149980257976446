import classNames from 'classnames'
import omit from 'lodash/omit'
import { FC } from 'react'

import { View } from '~/elements'

import { ActionListItem } from './ActionListItem'
import { baseContainerStyleTw, ListProps } from './List'

/**
 * A list for our action items
 */
export const ActionList: FC<ListProps> = ({
  data, tw,
}) => (
  <View tw={classNames(baseContainerStyleTw, tw)}>
    {data.map((item) => (
      <ActionListItem
        key={`action-${item.key}`}
        {...omit(item, 'key')}
      />
    ))}
  </View>
)
