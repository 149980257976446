import { differenceInMinutes } from 'date-fns'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MINDDAY_THRESHOLD_JOIN_MEETING_MIN } from 'react-native-dotenv'
import { WebView } from 'react-native-webview'

import { Timestamp } from '~/definitions/firebase.firestore.types'
import {
  Button, Overlay, Text, View,
} from '~/elements'
import { useNow } from '~/hooks/time'

const canStartMeeting = (now, target) => {

  const diff = differenceInMinutes(target.toDate(), now.toDate())
  //  console.log(`differenceInMinutes`, diff)

  return diff < MINDDAY_THRESHOLD_JOIN_MEETING_MIN
}

type EventuallyJoinButtonProps = {
  startTime: Timestamp
  meetingUrl:string
  tw?:string
}

export const EventuallyJoinButton:FC<EventuallyJoinButtonProps> = ({ startTime, meetingUrl, tw }) => {

  const [isModalVisible, setModalVisible] = useState(false)
  const { t } = useTranslation(['track'])
  const now = useNow(30000) // refresh every 30 sec

  const isAllowToJoin = canStartMeeting(now, startTime)
  // console.log('isAllowToJoin', isAllowToJoin)

  return (
    <View tw={tw}>
      <Button
        color={isAllowToJoin ? 'brand' : 'basic'}
        title={t('track:main.join')}
        disabled={!isAllowToJoin}
        onPress={() => setModalVisible(true)}
      />
      {!isAllowToJoin && (
        <Text category="c2" tw="mt-1 text-xs text-gray-500 text-center">{t('track:main.ctaInfo', { allowJoinMinBefore: MINDDAY_THRESHOLD_JOIN_MEETING_MIN })}</Text>
      )}
      <Overlay
        onBackdropPress={() => setModalVisible(false)}
        visible={isModalVisible}
      >
        <WebView source={{ uri: meetingUrl }} style={{ width: 400, height: 672, borderRadius: 12 }} />
      </Overlay>
    </View>
  )
}
