import classNames from 'classnames'
import { FC, KeyboardEvent } from 'react'
import {
  FieldName, FieldValues, RegisterOptions, useController, useFormContext,
} from 'react-hook-form'

import { TextInput, TextInputProps } from '~/elements'

export type ShortTextInputInputProps<
TFieldValues extends FieldValues = FieldValues
> = TextInputProps & {
  name:FieldName<TFieldValues>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >
  defaultValue?:unknown
  align?:'left' | 'right' | 'center'
  onEnterKeyPress:() =>void
}

/**
 * A wrapper around TextInput in the context of React Hook Form
 */
export const ShortTextInput:FC<ShortTextInputInputProps> = ({
  name, rules = {}, defaultValue, onEnterKeyPress, align = 'left', helperTw, wrapperTw, ...rest
}) => {
  const { control } = useFormContext()
  const {
    field: {
      onChange, onBlur, name: fieldName, value, ref,
    },
    fieldState: { invalid, isTouched, error },
    // formState: { touchedFields, dirtyFields },

  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  })

  return (
    <TextInput
      errorMessage={ isTouched && invalid ? error?.message : undefined}
      testID={name}
      autoFocus
      onChangeText={onChange} // send value to hook form
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={fieldName} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear
      keyboardType="default"
      textContentType="none"
      autoCompleteType="off"
      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && onEnterKeyPress) {
          // console.log('onEnterKeyPressed')
          onEnterKeyPress()
        }
      }}
      // custom styles for short text input
      wrapperTw={classNames('w-full', wrapperTw)}
      helperTw={classNames(`text-${align}`, helperTw)}
      {...rest}
    />

  )
}
