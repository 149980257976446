import { IconProps } from '../icons/Icon/IconProps.d'
import { ButtonColor, ButtonSize, ButtonVariant } from './Button.d'

type PropType = {
  variant?: ButtonVariant
  color?: ButtonColor
  size?:ButtonSize
  rounded?:boolean
  block?:boolean
  iconRight?:boolean
  justify?:'start' | 'end' | 'center'
  icon?: IconProps
  hasTitle?:boolean
}

const defaultProps: PropType = {
  variant: 'filled',
  color: 'basic',
  size: 'base',
  rounded: true,
  block: false,
  iconRight: true,
  justify: 'center',
  icon: {},
  hasTitle: true,
  disabled: false,
}

/**
 * Utility that provides tailwind classes for variant, color, and size classes
 * WIP
 */
export const getBaseButtonClasses = (props: PropType = {}) => {
  const {
    variant = defaultProps.variant,
    color = defaultProps.color,
    size = defaultProps.size,
    rounded = defaultProps.rounded,
    block = defaultProps.block,
    icon = defaultProps.icon,
    justify = defaultProps.justify,
    disabled = defaultProps.disabled,
  } = props

  // vertically center content
  const arr = [`justify-${justify}`]

  /**
     * padding
     */
  switch (size) {
  case 'xs':
    arr.push('px-2.5 py-1')
    break
  case 'sm':
    arr.push('px-3 py-1.5')
    break
  case 'base':
    arr.push('px-4 py-2.5')
    break
  case 'l':
    arr.push('px-6 py-4')
    break
  case 'xl':
    arr.push('px-8 py-6')
    break

  default:
    throw new Error(`no size ${size}`)
  }

  /**
   * Content alignment
   */
  if (icon.name) {
    arr.push('flex-row items-center')
  }

  /**
   * Shape
   */
  if (rounded) {
    arr.push('rounded-full')
  }

  /**
   * Base text
   */
  arr.push('font-semibold text-center')

  // Text based on button size
  switch (size) {
  case 'xs':
    arr.push(variant === 'ghost' ? 'text-sm' : 'text-xs')
    break
  case 'sm':
    arr.push(variant === 'ghost' ? 'text-sm' : 'text-xs')
    break
  case 'base':
    arr.push(variant === 'ghost' ? 'text-sm' : 'text-xs')
    break
  case 'l':
    arr.push(variant === 'ghost' ? 'text-sm' : 'text-xs')
    break
  case 'xl':
    arr.push(variant === 'ghost' ? 'text-base' : 'text-sm')
    break

  default:
    throw new Error(`no size ${size}`)
  }

  // text based on variant
  if (variant !== 'ghost') {
    arr.push('uppercase tracking-widest')
  }

  /**
   * Variant/color based classes
   * - background related classes
   * - text related classes
   */
  if (!disabled) {
    if (variant === 'ghost') {
      arr.push('bg-transparent')

      /**
       * Text color
       */
      switch (color) {
      case 'basic':
        arr.push('text-gray-500 hover:text-gray-700')
        break
      case 'brand':
        arr.push('text-brand-base hover:text-brand-lighter')
        break
      case 'control':
        arr.push('text-gray-700 hover:text-gray-500')
        break
      default:
        arr.push(`text-${color}-darker hover:text-${color}-base`)
      }

    } else if (variant === 'filled') {
      switch (color) {
      case 'brand':
        arr.push('bg-gradient-to-br from-orange-100 hover:from-orange-300 opacity-70 to-orange-500 hover:to-orange-700 opacity-90')
        arr.push('text-basic-darkest')
        break
      case 'basic':
        arr.push('bg-black hover:bg-gray-700')
        arr.push('text-white')
        break
      case 'control':
        arr.push('bg-white hover:bg-gray-100')
        arr.push('text-basic-darker')
        break
      default:
        console.warn(`Need to specify Button helper classes for filled/${color}`)
        break
      }
    } else if (variant === 'outline') {
      /**
       * Background
       */
      arr.push('bg-surface-1')
      if (color === 'control') {
        arr.push('hover:bg-gray-300')
      } else if (color === 'brand') {
        arr.push('hover:bg-brand-lightest')
      } else {
        arr.push(`hover:bg-${color}-lighter`)
      }

      /**
       * Border
       */
      switch (color) {
      case 'info':
        arr.push('border-2 border-info-darker')
        break
      case 'success':
        arr.push('border-2 border-success-darker')
        break
      case 'warning':
        arr.push('border-2 border-warning-darker')
        break
      case 'danger':
        arr.push('border-2 border-danger-darker')
        break
      case 'control':
        arr.push('border-2 border-basic-lightest')
        break
      case 'brand':
        arr.push('border-2 border-brand-base')
        break
      case 'basic':
        arr.push('border-2 border-basic-darker')
        break
      default:
        console.warn(`Need to specify Button helper border classes for outline/${color}`)
      }

      /**
       * Text color
       */
      if (color === 'control') {
        arr.push('text-gray-500')
      } else if (color === 'basic') {
        arr.push('text-basic-darkest')
      } else {
        arr.push(`text-${color}-base `)
      }
    }

  /**
   * Disabled
   */
  } else if (variant === 'ghost') {
    arr.push('bg-gray-100 opacity-30')
    arr.push('text-gray-500 opacity-30')
  } else if (variant === 'filled') {
    switch (color) {
    case 'brand':
      arr.push('bg-gradient-to-br from-gray-500 to-gray-300')
      arr.push('text-basic-base')
      break
    case 'control':
      arr.push('bg-basic-lightest')
      arr.push('text-basic-lighter')
      break
    default:
      arr.push(`bg-${color}-lighter`)
      arr.push(`text-${color}-lightest`)
      break
    }
  } else if (variant === 'outline') {
    arr.push('bg-basic-lightest opacity-10')
    arr.push(`border-2 border-${color}-lighter`)

    arr.push(`text-${color}-base  opacity-30`)

  }

  /**
   * Full width on block
   */
  if (block) {
    arr.push('w-full')
  }

  return arr.join(' ')

}

export const getBaseButtonIconClasses = (props: PropType = {}) => {
  const {
    variant = defaultProps.variant,
    color = defaultProps.color,
    size = defaultProps.size,
    // rounded = defaultProps.rounded,
    // block = defaultProps.block,
    iconRight = defaultProps.iconRight,
    hasTitle = defaultProps.hasTitle,
  } = props

  const arr = [' justify-center items-center']

  switch (size) {
  case 'xs':
    arr.push('hidden') // No icon on smallest button size
    break
  case 'sm':
    arr.push('h-4 w-4')
    if (hasTitle) {
      arr.push(iconRight ? 'ml-2 -mr-0.5' : '-ml-0.5 mr-2')
    }
    break
  case 'base':
    arr.push('h-5 w-5')
    if (hasTitle) {
      arr.push(iconRight ? 'ml-2 -mr-1' : '-ml-1 mr-2')
    }
    break
  case 'l':
    arr.push('h-5 w-5')
    if (hasTitle) {
      arr.push(iconRight ? '"ml-3 -mr-1' : '-ml-1 mr-3')
    }
    break
  case 'xl':
    arr.push('h-5 w-5')
    if (hasTitle) {
      arr.push(iconRight ? 'ml-3 -mr-1' : '-ml-1 mr-3')
    }
    break

  default:
    throw new Error(`no size ${size}`)
  }

  /**
   * Variant/color based classes
   * - background related classes
   * - text related classes
   */
  if (variant === 'ghost') {
    arr.push('text-basic-darker')
  } else if (variant === 'filled') {
    switch (color) {
    case 'brand':
      arr.push('text-black')
      break
    case 'basic':
      arr.push('text-white')
      break
    default:
      break
    }
  } else if (variant === 'outline') {
    if (color === 'control') {
      arr.push('text-gray-500')
    } else {
      arr.push(`text-${color}-lighter`)
    }
  }

  return arr.join(' ')
}

export const getButtonEnabledColorClasses = (variant, color) => {
  const arr = []
  if (variant === 'ghost') {
    arr.push('bg-transparent')
    arr.push(`text-${color}-lighter`)
  } else if (variant === 'filled') {
    switch (color) {
    case 'brand':
      arr.push('bg-gradient-to-br from-orange-100 opacity-70 to-orange-500 opacity-90 hover:from-orange-300 opacity-70 hover:to-orange-700 opacity-90')
      arr.push('text-black')
      break
    case 'basic':
      arr.push('bg-black hover:bg-gray-700')
      arr.push('text-white')
      break
    case 'control':
      arr.push('bg-white hover:bg-gray-100')
      arr.push('text-basic-darker')
      break
    default:
      console.warn(`Need to specify Button helper classes for filled/${color}`)
      break
    }
  } else if (variant === 'outline') {
    arr.push('bg-surface-1')
    if (color === 'control') {
      arr.push('hover:bg-gray-300')
    } else {
      arr.push(`hover:bg-${color}-lighter`)
    }
    arr.push(`border-2 border-${color}-darker`)
    if (color === 'control') {
      arr.push('text-gray-500')
    } else {
      arr.push(`text-${color}-base `)
    }
  }

  return arr
}
