import { FC } from 'react'

import { MeetingData } from '~/definitions/firestore.meeting'
import {
  Icon,
  Pressable, Text, View,
} from '~/elements'
import { useDurationFormat } from '~/hooks/dateFns'
import { useSessionBySlug } from '~/hooks/dato.sessions'

import { DatePresenter } from '../../data-display/DatePresenter'
import { MinderMini } from '../../data-display/MinderMini'

type PastSessionCardProps = Pick<MeetingData, 'start_time' | 'minder' | 'name' | 'type' > & {
  meetingId: string,
}
export const PastSessionCard: FC<PastSessionCardProps> = ({
  start_time, minder, name, meetingId, type,
}) => {

  const { data } = useSessionBySlug(type)
  const durationString = useDurationFormat({ minutes: data?.session?.duration || 30 })

  return (
    <Pressable tw="mb-2 bg-transparent rounded-xl p-6 border border-brand-lighter" to={`/sessions/${meetingId}`}>
      <View tw="md:w-2/3 flex-row items-start">
        <DatePresenter date={start_time} tw="mr-2" textColor="gray-300"/>
        <View tw=" ml-1">
          <Text tw="font-serif font-medium text-lg text-gray-500 mb-1">{name}</Text>
          <MinderMini minder={minder} textColor="gray-300"/>
          <View tw="mt-1 flex-row text-gray-500 items-center">
            <Icon tw="text-gray-500" name="clock" variant="regular"/>
            <Text tw="ml-3 text-sm text-gray-500" >{durationString}</Text>
          </View>
        </View>
      </View>
    </Pressable>
  )
}
