import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire'

import { evaluationTypes } from '~/constants/firestore.evaluations'
import { EvaluationType } from '~/definitions/firestore.evaluation.properties'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import {
  getAllEvaluationsQuery,
  getUserEvaluationsByTypeQuery, latestEvaluationsByTypeQuery, nextEvaluationsByTypeQuery,
} from '~/queries/firestore.evaluations'
import { getEvaluationRef } from '~/queries/firestore.helpers'

import {
  ArrayDocsQueryResult, GetDocQueryResult, HasDocQueryResult, SingleDocQueryResult,
} from '../definitions/firestore.queries'
import {
  withArrayDocQueryResultAdapter, withGetDocResultAdapter, withHasResultAdapter, withSingleDocQueryResultAdapter,
} from './firestore.hooks.helpers'
import { useCurrentUserRef } from './firestore.users'
import { useNow } from './time'

/**
 * This hook listen for a new evaluation being created
 * @returns undefined if none yet or that evaluation doc if newly created
 */
export const useCurrentUserNewEvaluation = (slug: EvaluationType): SingleDocQueryResult<EvaluationData> => {
  const userRef = useCurrentUserRef()
  // console.log('-> useCurrentUserNewEvaluation', slug, userRef.path)
  const now = useNow()
  const query = nextEvaluationsByTypeQuery({ userRef, now, slug }, { db: useFirestore(), limit: 1 })
  const res = useFirestoreCollection<EvaluationData>(query)

  return withSingleDocQueryResultAdapter(res)
}

export const useEvaluationById = (testId: string): GetDocQueryResult<EvaluationData> => {
  const ref = getEvaluationRef(testId, useFirestore())
  // console.log('ref.path', ref.path)
  const res = useFirestoreDoc<EvaluationData>(ref)

  return withGetDocResultAdapter(res)
}

/**
 * Direct wrap of the query with no adapter
 */
const useQueryCurrentUserLatestEvaluationByType = (slug: EvaluationType) => {
  const userRef = useCurrentUserRef()
  const query = latestEvaluationsByTypeQuery({ userRef, slug }, { db: useFirestore(), limit: 1 })

  return useFirestoreCollection<EvaluationData>(query)
}

export const useGetCurrentUserLatestEvaluationByType = (slug: EvaluationType): SingleDocQueryResult<EvaluationData> => {
  const res = useQueryCurrentUserLatestEvaluationByType(slug)

  // We add the adapter to return single item only
  return withSingleDocQueryResultAdapter(res)
}

export const useCurrentUserAllEvaluations = (): ArrayDocsQueryResult<EvaluationData> => {
  const userRef = useCurrentUserRef()
  const query = getAllEvaluationsQuery({ userRef }, { db: useFirestore() })
  const res = useFirestoreCollection<EvaluationData>(query)

  return withArrayDocQueryResultAdapter(res)
}

/**
 * Fetch all evaluations for a given type
 * Direct wrap of the query with no adapter
 * TODO: optionally filter by active track
 */
const useQueryCurrentUserGenericTypeEvaluations = (slug: EvaluationType) => {
  const userRef = useCurrentUserRef()
  const query = getUserEvaluationsByTypeQuery({ userRef, slug }, { db: useFirestore() })

  return useFirestoreCollection<EvaluationData>(query)
}

/**
 * We add the adapter to return single item only on top of useQueryCurrentUserGenericTypeEvaluations
 */
const useGetCurrentUserGenericTypeEvaluations = (slug: EvaluationType): ArrayDocsQueryResult<EvaluationData> => {
  const res = useQueryCurrentUserGenericTypeEvaluations(slug)

  return withArrayDocQueryResultAdapter(res)
}

export const useGetCurrentUserFeelingsEvaluations = (): ArrayDocsQueryResult<EvaluationData> => useGetCurrentUserGenericTypeEvaluations(evaluationTypes.HOW_I_FEEL)

export const useGetCurrentUserPersonalityEvaluations = (): ArrayDocsQueryResult<EvaluationData> => useGetCurrentUserGenericTypeEvaluations(evaluationTypes.USER_PERSONALITIES)

/**
 * We add the adapter to return existence boolean only on top of useQueryCurrentUserGenericTypeEvaluations
 */
const useHasCurrentUserGenericTypeEvaluation = (slug: EvaluationType): HasDocQueryResult => {
  const res = useQueryCurrentUserGenericTypeEvaluations(slug)

  return withHasResultAdapter(res)
}

/**
 * Evaluate if current user already has a "HOW I FEEL" evaluation recorded
 */
export const useHasCurrentUserFeelingsEvaluation = (): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.HOW_I_FEEL)

/**
 * Evaluate if current user already has a "PERSONALITY TEST" evaluation recorded
 */
export const useHasCurrentUserPersonalityEvaluation = (): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.USER_PERSONALITIES)

/**
 * Evaluate if current user already has a "GAD-7" evaluation recorded
 */
export const useHasCurrentUserGadEvaluation = (): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.GAD_7)

/**
  * Evaluate if current user already has a "PHQ-9" evaluation recorded
  */
export const useHasCurrentUserPhqEvaluation = (): HasDocQueryResult => useHasCurrentUserGenericTypeEvaluation(evaluationTypes.PHQ_9)
