import { CSSProperties, FC, memo } from 'react'

export type DomImgType = {
    src:string
    alt?:string
    width?:number
    height?:number
    style?: CSSProperties
    tw?:string
    testID?:string
}

export const MemoImgSvg:FC<DomImgType> = memo(({ tw, testID, ...props }) => <img id={testID} {...props} className={tw}/>)
