import upperFirst from 'lodash/upperFirst'
import { FC, ReactElement } from 'react'

import { hasGradientClasses } from '~/utils/tailwind/tw.gradients.helpers'
import { getTwForInteractionState } from '~/utils/tailwind/tw.interaction.helpers'
import { getTextRelatedClasses } from '~/utils/tailwind/tw.texts.helpers'

import { Pressable } from '../containers/Pressable'
import { View } from '../containers/View'
import Icon from '../icons/Icon'
import { IconProps } from '../icons/Icon/IconProps.d'
import { Text } from '../text/Text'
import { TwButtonProps } from './Button.d'
import { GradientButton } from './GradientButton'

type ButtonContentProps = {
  title?:string
  titleTw?:string
  icon?: IconProps
  iconRight?:boolean
  iconTw?:string
  iconContainerTw?:string
  restIcon:Record<string, unknown>
}

const ButtonContent:FC<ButtonContentProps> = ({
  title, titleTw, icon = {}, iconRight, iconTw, iconContainerTw, restIcon,
}) => (
  <>
    {!!icon.name && !iconRight && <View tw={iconContainerTw}><Icon tw={iconTw} {...restIcon} /></View>}
    {!!title && <Text tw={titleTw}>{upperFirst(title)}</Text>}
    {!!icon.name && iconRight && <View tw={iconContainerTw}><Icon tw={iconTw} {...restIcon} /></View>}
  </>
)

type EventuallyPressableButtonProps = {
  to?: string
  onPress?: () => void
  iconTw?: string
  titleTw?: string
  tw?: string
} & ButtonContentProps

const EventuallyPressableButton: FC<EventuallyPressableButtonProps> = ({
  to, onPress, iconTw = '', titleTw = '', tw, ...props
}) => {

  if (to || onPress) {
    return <Pressable to={to} onPress={onPress} tw={tw} >
      {({ focused, hovered }:PressableState): ReactElement => {

        // console.log('Button focused ?', focused)
        // console.log('Button hovered ?', hovered)

        const is = { focus: focused, hover: hovered }

        const iconTwWithState = getTwForInteractionState(iconTw, is)
        const titleTwWithState = getTwForInteractionState(titleTw, is)
        // console.log('titleTwWithState', titleTwWithState)

        return <ButtonContent {...props} iconTw={iconTwWithState} titleTw={titleTwWithState} />
      }}
    </Pressable>
  }

  // console.log('-> EventuallyPressableButton (View)', props)

  const is = { focus: false, hover: false }
  const iconTwWithState = getTwForInteractionState(iconTw, is)
  // console.log('iconTwWithState', iconTwWithState)
  const titleTwWithState = getTwForInteractionState(titleTw, is)
  // console.log('titleTwWithState', titleTwWithState)
  return <View tw={tw}>
    <ButtonContent {...props} iconTw={iconTwWithState} titleTw={titleTwWithState}/>
  </View>
}

export type PressableState = Readonly<{
  pressed: boolean;
  hovered?: boolean;
  focused?: boolean;
}>;

/**
 * A simple button build from TailwindClasses
 * Useful to integrate Tailwind components directly
 */
export const TwButton: FC<TwButtonProps> = (props) => {
  // console.log('-> TwButton', props.title)
  const {
    title = '', tw, hookKey, icon = {}, iconRight, ...rest
  } = props

  if (!title && !icon.name) {
    return null
  }

  if (hasGradientClasses(tw)) {
    // const { gradientTw } = getGradientRelatedClasses(tw)
    // console.log(`hasGradientClasses`, gradientTw)
    // console.log(`tw`, tw)
    return <GradientButton {...props} />
  }

  const { tw: iconFullTw, ...restIcon } = icon

  // Split tw classes into text vs container classes
  const { textTw: titleTw, nonTextTw: containerTw } = getTextRelatedClasses(tw)
  // console.log('textTw', titleTw)

  // Idem for icon classes
  const { textTw: iconTw, nonTextTw: iconContainerTw } = getTextRelatedClasses(iconFullTw)

  // console.log('Button containerTw', containerTw)

  return (
    <EventuallyPressableButton
      title={title}
      tw={containerTw}
      hookKey={hookKey}
      icon={icon}
      iconTw={iconTw}
      iconRight={iconRight}
      restIcon={restIcon}
      iconContainerTw={iconContainerTw}
      titleTw={titleTw}
      {...rest}
    />
  )
}
