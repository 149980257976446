import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { Suspense, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseFullOverlayHeader } from '~/elements/headers/CloseStackCustomHeader'
import EvaluationPlayerOutroScreen from '~/screens/EvaluationPlayerScreen/Page.Outro'
import GenericPage from '~/screens/EvaluationPlayerScreen/Page.Wrapper'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { EvaluationPlayerDrawerParamList } from './EvaluationPlayerDrawerParamList.d'

const EvaluationPlayerStack = createNativeStackNavigator<EvaluationPlayerDrawerParamList>()

export const EvaluationPlayerNavigator = () => {
  useTranslation(['player'])
  const Header = useCallback(() => <CloseFullOverlayHeader headerRightTw="mr-6 md:mr-4" />, [])
  return (
    <Suspense fallback={<LoadingScreen />}>
      <EvaluationPlayerStack.Navigator
        screenOptions={{
          headerShown: true,
          headerTransparent: true,
          header: Header,
        }}
      >
        <EvaluationPlayerStack.Screen name="EvaluationPlayerPage" component={GenericPage} />
        <EvaluationPlayerStack.Screen name="EvaluationPlayerOutro" component={EvaluationPlayerOutroScreen} />
      </EvaluationPlayerStack.Navigator>
    </Suspense>
  )
}
