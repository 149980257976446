import { FC } from 'react'
import { useTailwind } from 'tailwind-rn'

import { useDatoSvg } from '~/hooks/dato.assets'

type SvgByIdProps = {
    /**
     * Asset id in Dato CMS
     */
    assetId:string

    tw?:string
}

/**
 * A wrapper to display an SVG asset from Dato CMS
 *
 * TODO add skeleton while loading
 * TODO handle other platforms but web
 */
export const SvgById:FC<SvgByIdProps> = ({ assetId, tw }) => {
  // console.log('-> SvgById', assetId)
  const tailwind = useTailwind()
  const { loading, data } = useDatoSvg(assetId)

  if (loading) {
    return null
  }

  const {
    alt, url, height, width,
  } = data.upload

  return <img alt={alt} src={url} height={height} width={width} style={tailwind(tw)}/>
}
