import { FC } from 'react'
import { Platform } from 'react-native'

import { Image as NativeImage } from './Image.native'
import { ResponsiveImageWeb as WebImage } from './Image.web'
import { ImageProps } from './ImageProps'
import { ImageSvg } from './ImageSvg'
// TODO only use DatoCMS Image of props.responsiveImage; fallback to a component that implements html image otherwise (ie url only)

/**
 * Implements Gatsby Static Image API
 * https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image#staticimage
 */
const Image: FC<ImageProps> = ({ format, ...props }) => {
  if (format === 'svg') {
    return <ImageSvg {...props} />
  }

  if (Platform.OS === 'web') {
    return <WebImage {...props} />
  }

  return <NativeImage {...props} />
}

export default Image
