import { useEffect } from 'react'

import analytics from '~/analytics'
import { useFormattedContentById } from '~/hooks/dato.content'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { ContentPlayerDataWrapper } from '../ContentPlayerDataWrapper'
import { ContentCoverDesktopView } from './ContentCoverDesktopView'
import { ContentCoverMobileView } from './ContentCoverMobileView'

const ContentPlayerCoverScreen = ({ route }) => {
  const { md } = useMediaQuery()
  const { contentId } = route.params
  // console.log(`-> CoverPage for ${contentId}`)
  const { loading, error, data } = useFormattedContentById(contentId)

  useEffect(() => {
    analytics.track('playing-started', { itemId: contentId })
  }, [contentId])

  if (loading) {
    return <LoadingScreen />
  }

  if (error) {
    return <ErrorScreen error={error}/>
  }

  return (
    <ContentPlayerDataWrapper contentId={contentId}>
      {md ? <ContentCoverDesktopView {...data}/> : <ContentCoverMobileView {...data}/>}
    </ContentPlayerDataWrapper>)

}

export default ContentPlayerCoverScreen
