import compact from 'lodash/compact'
import last from 'lodash/last'

import { getColor } from '~/theme/helpers'
import { getApplicableClassForBreakpoint, getSmallerKeys, splitClassName } from '~/utils/tailwind/tw.breakpoints.helpers'

const hasWidthAutoClass = (tw: string): boolean => tw.split(' ').some((cn) => last(cn.split(':')) === 'w-auto')
const getWidthAutoClass = (tw: string): string => tw.split(' ').find((cn) => last(cn.split(':')) === 'w-auto')

const getWidthRelatedClasses = (tw) => tw.split(' ').filter((cn) => last(cn.split(':')).startsWith('w-'))

const getAspectRatioRelatedClasses = (tw) => tw.split(' ').filter((cn) => cn.startsWith('aspect-'))

/**
 * Eventually remove width definition from smaller resolutions
 * @param tw - tailwind classes
 * @param mq - media query selectors
 * Ex w-full sm:w-auto => w-full on smaller than sm and "" on sm and over
 */
export const processWauto = (tw: string, mq): string => {
  if (!hasWidthAutoClass(tw)) {
    return tw
  }
  // console.log(`processWauto`, tw)

  // console.log(`hasWidthAutoClass(tw)`, hasWidthAutoClass(tw))

  const arr = getWidthRelatedClasses(tw)
  // console.log(`Width related classes`, arr)

  const refClass = getWidthAutoClass(tw)
  // console.log(`ref`, refClass)
  const [refBp] = refClass.split(':')
  // console.log(`refBp`, refBp)

  // We directly return if w-auto not applicable
  if (!mq[refBp]) {
    return tw
  }

  const smallerClasses = getSmallerKeys(arr, refBp)
  // console.log(`classes smaller than w-auto`, smallerClasses)

  let newTw: string = tw

  // we effectively replace smaller width related classes
  smallerClasses.forEach((cn) => {
    newTw = newTw.replace(cn, '')
  })

  // we do remove w-auto as well
  newTw = newTw.replace(refClass, '')

  // clean result from consecutive whitespaces
  newTw = newTw.replace('  ', ' ').trim()

  // console.log(`newTw`, newTw)
  return newTw
}

/**
 * aspect-w-16 aspect-h-9 => aspectRatio:16/9
 */
export const getAspectRatio = (tw = '', mq): {
  tw: string
  aspectRatio: number
} => {
  // console.log(`tw`, tw)
  if (!tw.split(' ').some((cn) => cn.startsWith('aspect-'))) {
    return { tw, aspectRatio: undefined }
  }

  // only deal with applicable classes
  const arr = getAspectRatioRelatedClasses(tw)

  const aspectWclass = getApplicableClassForBreakpoint(arr.filter((key) => key.startsWith('aspect-w-')), mq)
  const aspectHclass = getApplicableClassForBreakpoint(arr.filter((key) => key.startsWith('aspect-h-')), mq)

  // next tw is cleaned form any aspect related classes

  return {
    tw: tw.split(' ').filter((key) => !key.startsWith('aspect-')).join(' '),
    aspectRatio: Number(last(aspectWclass.split('-'))) / Number(last(aspectHclass.split('-'))),
  }
}

/**
 * replace ring- classes with a border equivalent counterpart
 */
export const adaptRingClasses = (tw = '') => {
  if (!tw.includes('ring-')) {
    return tw
  }
  // console.log('-> adaptRingClasses before:', tw)
  const res = tw.split(' ').map((item) => {
    if (!item.includes('ring-')) {
      return item
    }
    // const { selector, value } = splitClassName(item)

    // a simple replacement works most of the time
    return item.replace('ring-', 'border-')
  }).join(' ')
  // console.log('-> adaptRingClasses after:', res)

  return res
}

/**
 *
 * TODO different breakpoints
 */
export const adaptOutlineClasses = (tw = '') => {
  if (!tw.includes('outline-')) {
    return { tw, style: undefined }
  }
  const style = {}
  const newTw = compact(tw.split(' ').map((item) => {
    if (!item.includes('outline-')) {
      return item
    }
    const { value } = splitClassName(item)
    if (value === 'outline-none') {
      style.outline = 'none'
      style.outlineColor = 'transparent'
      style.outlineWidth = 0
    } else if (value === 'outline-white') {
      style.outlineColor = getColor('white')
      style.outlineStyle = 'dotted'
      style.outlineWidth = 2
      style.outlineOffset = 2
    } else if (value === 'outline-black') {
      style.outlineColor = getColor('black')
      style.outlineStyle = 'dotted'
      style.outlineWidth = 2
      style.outlineOffset = 2
    }

    return undefined
  })).join(' ')

  return { tw: newTw, style }
}
