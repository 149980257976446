import { SearchIcon } from '@heroicons/react/solid'
import { TextInputProps } from 'dripsy'
import { useState } from 'react'
import { useTailwind } from 'tailwind-rn'

import { getColor } from '~/theme/helpers'

import { View } from '../containers/View'
import { TextInput } from '../input/TextInput'

export type SearchBarProps = Record<string, unknown> & TextInputProps

export const SearchBar = () => {
  /* focus-within:text-gray-700 */
  const [isFocused, setFocus] = useState(false)
  const tailwind = useTailwind()
  const onFocus = () => {
    setFocus(true)
  }
  const onBlur = () => {
    setFocus(false)
  }
  const textInputTextStyle = isFocused ? { color: getColor('gray-700') } : undefined
  const iconStyle = isFocused ? { color: getColor('gray-700') } : undefined

  return (
    <View accessible accessibilityLabel="Search" style={tailwind('relative text-white')}>
      <View style={tailwind('pointer-events-none absolute inset-y-0 left-0 pl-3 flex flex-row items-center')}>
        <SearchIcon style={{ ...tailwind('h-5 w-5'), ...iconStyle }} aria-hidden="true" />
      </View>
      <TextInput id="search" placeholder="Search" type="search" name="search" onFocus={onFocus} onBlur={onBlur} style={textInputTextStyle} />
    </View>
  )
}
