import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseHeaderButton } from '~/elements/buttons/headerButtons/CloseHeaderButton'

import { FullOverlayHeader, FullOverlayHeaderProps } from './FullOverlayHeader'

type CloseFullOverlayHeaderProps = FullOverlayHeaderProps & {
 to: string

 label:string // label for larger screens; also used for accessibility

 headerRightTw?:string
}
/**
 * We wrap React Navigation Header in a View so as to apply specific styles
 */
export const CloseFullOverlayHeader:FC<CloseFullOverlayHeaderProps> = ({
  to, onPress, label, headerRightTw, options, ...props
}) => {
  const { t } = useTranslation(['common'])

  const HeaderRight = useCallback(
    () => <CloseHeaderButton
      to={to}
      onPress={onPress}
      label={label || t('common:cta.close')}
      tw={headerRightTw}
    />,
    [to, label, headerRightTw, onPress],
  )
  return (
    <FullOverlayHeader
      {...props}
      options={{
        ...options,
        headerRight: HeaderRight,
      }}
    />
  )
}
