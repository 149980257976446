import {
  limit,
  query,
  where,
} from 'firebase/firestore'

import { ByUserRefAndGroupMeetingSessionRefsQueryParams, WithDbLimitQueryOptions, WithDbQueryOptions } from '~/definitions/firestore.queries'

import { getGroupMeetingCol, getGroupMeetingSessionColGroup, getUserGroupMeetingSessionCol } from './firestore.helpers'

export const getGroupMeetingById = (
  { id }: { id: string },
  { db, limit: limitOpt = 1 }: WithDbLimitQueryOptions,
) => query(
  getGroupMeetingCol(db),
  where('vendors.livestorm.eventId', '==', id),
  limit(limitOpt),
)

export const getGroupMeetingSessionsByIds = (
  { ids }: { ids: string[] },
  { db, limit: limitOpt = 1 }: WithDbLimitQueryOptions,
) => query(
  getGroupMeetingSessionColGroup(db),
  where('vendors.livestorm.eventId', 'in', ids),
  limit(limitOpt),
)

export const getUserGroupMeetingSessionsByRefs = (
  { userRef, groupMeetingSessionRefs }: ByUserRefAndGroupMeetingSessionRefsQueryParams,
  { db }: WithDbQueryOptions,
) => query(
  getUserGroupMeetingSessionCol(db),
  where('userRef', '==', userRef),
  where('groupMeetingSessionRef', 'in', groupMeetingSessionRefs),
)
