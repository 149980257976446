import { useContractorBySlug } from './dato.contractors'
import { useUser } from './user.context'

export type StringQueryHookResult = {
  loading: boolean
  error: Error | null
  data: string | null
}

export const useCurrentUserMinderSlug = ():StringQueryHookResult => {
  // console.log('useCurrentUserMinderSlug')
  const { loading, error, userTrackDoc } = useUser()

  return {
    loading,
    error,
    data: userTrackDoc?.data() ? userTrackDoc.get('minder') : undefined,
  }
}

export const useCurrentUserMinder = () => {
  // console.log('useCurrentUserMinder')
  const { loading: currentUserMinderSlugLoading, error: currentUserMinderSlugError, data: currentUserMinderSlug } = useCurrentUserMinderSlug()
  const contractorBySlugRes = useContractorBySlug(currentUserMinderSlug)

  return {
    loading: currentUserMinderSlugLoading || contractorBySlugRes.loading,
    error: currentUserMinderSlugError || contractorBySlugRes.error,
    data: contractorBySlugRes.data,
  }
}
