import { FC, ReactNode } from 'react'
import { useWindowDimensions } from 'react-native'

import { Text, View } from '~/elements'
import { BrandLogo } from '~/elements/images/BrandLogo'
import { SvgById } from '~/elements/images/SvgById'
import { useMediaQuery } from '~/hooks/mediaQuery'

import { FlagLanguageSelector } from './FlagLanguageSelector'
// import { LargeBackground } from './LargeBackground'

type AuthLayoutProps = {
  title?:string
  imageId?:string
  description?:string
  children?:ReactNode
  showLanguageSelector:boolean
}

const getTopMargin = (h) => {
  if (h > 950) {
    return '16'
  }
  if (h > 900) {
    return '12'
  }
  if (h > 850) {
    return '8'
  }
  if (h > 800) {
    return '4'
  }

  return '1'
}

export const AuthLayout:FC<AuthLayoutProps> = ({
  title, description, children,
}) => {
  // console.log('-> AuthLayout')
  const { lg } = useMediaQuery()
  const { height } = useWindowDimensions()
  // console.log('height', height, getTopMargin(height))

  // console.log('iconData', iconData?.data?.upload)

  // if(!bgQueryRes || !frontQueryRes) return null

  const align = lg ? 'left' : 'center'

  return (
    <View tw="relative h-full justify-center">
      {/* <LargeBackground /> */}

      {/* Logo container */}
      <View tw="absolute inset-0 flex-row items-start">
        <View tw={`${lg ? 'w-2/5' : 'w-full'} flex-row p-3 justify-center mt-${getTopMargin(height)}`}>
          <View tw={`m-3 flex-grow max-w-lg items-${align === 'left' ? 'start' : align}`}>
            <BrandLogo color='brand' />
          </View>
        </View>
      </View>

      {/* Card (children) */}
      <View tw="flex-row items-start">
        <View tw={`${lg ? 'w-2/5' : 'w-full'} max-w-xl mt-24`}>
          <View tw="m-3 flex-grow justify-center">
            <View tw="w-full self-center max-w-lg bg-surface-1 rounded-2xl p-8">
              <Text tw={`font-serif mb-1 text-black text-xl font-medium text-${align}`}>{title}</Text>
              <Text tw={`mb-4 font-normal text-sm leading-relaxed text-gray-500 text-${align}`} >{description}</Text>
              {children}
            </View>
            <FlagLanguageSelector tw="mt-2"/>
          </View>
          {/* <Text tw="m-3 w-full">Footer</Text> */}
        </View>
        {lg && (
          <View tw="w-3/5 justify-center">
            <SvgById assetId="27414843"/>
          </View>
        )}
      </View>
    </View>
  )
}
