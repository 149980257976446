import { useLinkTo } from '@react-navigation/native'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChatContext } from 'stream-chat-react'

import analytics from '~/analytics'
import { trackingEventKeys } from '~/analytics/trackingEvents'
import { useFeatureConfigString } from '~/hooks/features'

import { Header, Text, View } from '..'
import { ArchiveHeaderButton } from '../buttons/headerButtons/ArchiveHeaderButton'
import { GoBackHeaderButton } from '../buttons/headerButtons/GoBackHeaderButton'
import { BlurView } from '../containers/BlurView'

/**
 * A header for our conversations
 */
export const ChatConversationHeader = ({ route, options, props }) => {

  const { t } = useTranslation(['common', 'chat'])

  const linkTo = useLinkTo()

  const id = route?.params?.id
  const { status, data: chatRemoteConfig } = useFeatureConfigString('chat')
  const { client } = useChatContext()
  const channel = client?.getChannelById('messaging', id, {})
  // console.log('route', route, id)
  // console.log('channel', channel)
  const isChannelArchived = channel?.data?.status === 'inactive'
  const [isChannelArchivedState, setIsChannelArchivedState] = useState<boolean>(isChannelArchived)
  // console.log('channelCategory', channelCategory)

  if (status === 'loading') {
    return null
  }

  const title = t('chat:main.agents.careManagers.name')
  // console.log('title', title)

  const onPress = async () => {
    setIsChannelArchivedState(!isChannelArchivedState)
    await channel?.updatePartial({ set: { status: isChannelArchivedState ? 'active' : 'inactive' } })
    analytics.track(isChannelArchivedState ? trackingEventKeys.CONVERSATION_UNARCHIVED : trackingEventKeys.CONVERSATION_ARCHIVED, { conversationId: id })
    if (!isChannelArchivedState) {
      linkTo('/conversations')
    }
  }

  const {
    isArchiveEnabled,
  } = (chatRemoteConfig && JSON.parse(chatRemoteConfig)) || {}

  const HeaderLeft = () => <GoBackHeaderButton label={t('common:cta.back')} to="/conversations"/>
  const HeaderRight = () => (isArchiveEnabled ? <ArchiveHeaderButton status={isChannelArchivedState ? 'archived' : 'unarchived'} onPress={onPress}/> : null)
  const HeaderTitle = () => {

    const description = route?.params?.title || channel?.data?.name

    return (
      <>
        <View tw="flex-row content-center">
          <Text tw="text-sm w-full text-center font-medium text-basic-darkest" numberOfLines={1}>{title}</Text>
        </View>
        <View tw="flex-row content-center">
          <Text tw="text-xs w-full text-center text-basic-base" numberOfLines={1}>{description}</Text>
        </View>
      </>
    )
  }
  return (
    <BlurView intensity={100} tint="light" tw="bg-surface-1 w-full items-center px-4">
      <Header
        {...props}
        headerTransparent
        options={{
          ...options,
          headerLeft: HeaderLeft,
          headerTitle: HeaderTitle,
          headerRight: HeaderRight,
        }}
        tw="w-full max-w-7xl sm:h-24"
      />
    </BlurView>
  )
}
