import { gql } from '@apollo/client'

// , $imgWidth: FloatType, $imgHeight: FloatType
export const GET_IMAGE_BY_ID = gql`
query GetImageByIdQuery($id: UploadId, $width: FloatType, $height: FloatType) {
    upload(filter: {id: {eq: $id}}) {
          url
          format
         responsiveImage(
           imgixParams: {  auto: format, w: $width, h: $height }
            ) {
              
              # HTML5 src/srcset/sizes attributes
              srcSet
              webpSrcSet
              sizes
              src

              # size information (post-transformations)
              width
              height
              aspectRatio

              # SEO attributes
              alt
              title

              # background color placeholder or...
              bgColor

              # blur-up placeholder, JPEG format, base64-encoded
              base64
            }
    }
  }`

export const GET_LOTTIE_BY_ID = gql`
query GetLottieByIdQuery($id: UploadId, ) {
    upload(filter: {id: {eq: $id}}) {
      url
      format
    }
  }`

export const GET_SVG_BY_ID = gql`
query GetSvgByIdQuery($id: UploadId, ) {
    upload(filter: {id: {eq: $id}}) {
      url
      format
      height
      width
    }
  }`
