/**
 * Web implementation relies on @headlessui library
 */

import { Switch as RnSwitch } from '@headlessui/react'
import { FC } from 'react'

import { SwitchProps } from './SwitchProps'

export const Switch: FC<SwitchProps> = (props) => <RnSwitch {...props} />
