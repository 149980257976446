import classNames from 'classnames'

import { useAppBackgroundContext } from '~/context/AppBackgroundContext'
import { View } from '~/elements'
import { MemoImgSvg } from '~/elements/images/Svg/MemoImgSvg'
import { useDatoSvg } from '~/hooks/dato.assets'
import { GenericApp } from '~/navigators.shared/App'

import NavigationContainerWrapper from './RootNavigator'
// TODO: be smart about SVG positionning
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AppBackgroundSvg = ({ assetId }) => {
  // console.log('-> AppBackgroundSvg', assetId)

  const bgData = useDatoSvg(assetId)

  const bgSvgData = bgData?.data?.upload
  // const frontSvgData = illustrationData?.data?.upload
  if (!bgSvgData) {
    return null
  }

  // console.log('bgSvgData', bgSvgData)

  return (
    <View tw="absolute h-full w-full">
      <MemoImgSvg
        testID="bg-svg"
        alt={bgSvgData.alt}
        src={bgSvgData.url}
        tw=''
      />
    </View>

  )
}

export const AppWithBgContext = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { bgColor, bgSvgId } = useAppBackgroundContext()

  // console.log(`bgColor`, bgColor)
  return (
    <GenericApp>
      <View tw={classNames('flex-1', bgColor ? `bg-${bgColor}` : '')}>
        {/* {bgSvgId && <AppBackgroundSvg assetId={bgSvgId}/>} */}
        <NavigationContainerWrapper colorScheme="light" />
      </View>
    </GenericApp>
  )
}
