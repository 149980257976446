import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { BaseSyntheticEvent } from 'react'
import { Message } from 'stream-chat'
import { useChannelStateContext } from 'stream-chat-react'
// import { useTranslationContext } from 'stream-chat-react'
import type {
  DefaultAttachmentType,
  DefaultMessageType,
  DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import { Button } from '~/elements'

library.add(faArrowRight)

export type SendButtonProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Me extends DefaultMessageType = DefaultMessageType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = {
  disabled: boolean,
  sendMessage: (
    event: BaseSyntheticEvent,
    customMessageData?: Partial<Message<At, Me, Us>>,
  ) => void;
};

export const SendButton = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Me extends DefaultMessageType = DefaultMessageType,
  Us extends DefaultUserType<Us> = DefaultUserType
>({
    disabled,
    sendMessage,
  }: SendButtonProps<At, Me, Us>) => {
  const { channel } = useChannelStateContext()
  // console.log('channel', channel)
  const isChannelArchived = channel.data.status === 'inactive'
  return (
    <Button
      color="brand"
      icon={{ name: 'arrow-right', variant: 'regular', tw: 'text-surface-1' }}
      onPress={sendMessage}
      disabled={disabled || isChannelArchived}
    />
  )
}

// const { t } = useTranslationContext('SendButton')
