import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { useEvaluationContext } from '~/context/EvaluationContext'
import { Button, View } from '~/elements'

import { ContentPageHeading, ContentPageParagraph } from '../../elements/text/Player.FontTypes'

const EvaluationPlayerOutroScreen = () => {
  const { data: { model: { title, scoreOutro } } } = useEvaluationContext()
  // console.log('title', title)

  const { t } = useTranslation(['player', 'common'])

  return (
    <MainContainer scrollEnabled contentContainerTw="items-center">
      <BasicSectionContainer tw="mt-4 md:mt-12 lg:mt-20">
        <ContentPageHeading>{title}</ContentPageHeading>
        <View tw="flex-grow content-between mb-8">
          <ContentPageParagraph>{scoreOutro}</ContentPageParagraph>
        </View>
        <Button title={t('common:cta.complete')} to="/" color="brand" tw="mt-8"/>
      </BasicSectionContainer>
    </MainContainer>
  )
}

export default EvaluationPlayerOutroScreen
