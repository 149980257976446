import { useFirestore, useFirestoreCollection, useFirestoreDoc } from 'reactfire'

import { GetDocQueryResult, SingleDocQueryResult } from '~/definitions/firestore.queries'
import { UserTrackData } from '~/definitions/firestore.userTrack'
import { getUserTrackRef } from '~/queries/firestore.helpers'
import { findLatestActiveUserTracksQuery, findUserTrackByConversationIdQuery, findUserTracksQuery } from '~/queries/firestore.userTracks'

import { withGetDocResultAdapter, withTakeFirstResultAdapter } from './firestore.hooks.helpers'
// import { useUser } from './user.context'

export const useUserTrackDoc = (uid: string, campaignId: string): GetDocQueryResult<UserTrackData> => {
  const ref = getUserTrackRef(uid, campaignId, useFirestore())
  const res = useFirestoreDoc<UserTrackData>(ref)

  return withGetDocResultAdapter(res)
}

export const useUserTrackDocByConversationId = (conversationId: string): GetDocQueryResult<UserTrackData> => {
  // console.log('-> useUserTrackDocByConversationId', conversationId)
  const query = findUserTrackByConversationIdQuery({ conversationId }, { db: useFirestore() })
  const queryRes = useFirestoreCollection<UserTrackData>(query)

  return withTakeFirstResultAdapter(queryRes)
}

export const useCurrentUserLatestActiveTrackDoc = (uid: string): SingleDocQueryResult<UserTrackData> => {
  // console.log('-> useCurrentUserLatestActiveTrackDoc')
  // console.log('uid', uid)
  const query = findLatestActiveUserTracksQuery({ uid }, { db: useFirestore(), limit: 1 })
  const queryRes = useFirestoreCollection<UserTrackData>(query)

  // console.log('queryRes', queryRes.data?.docs.map((doc) => console.log(`track id : ${doc.id}`)))

  return withTakeFirstResultAdapter(queryRes)
}

export const useCurrentUserLatestTrackDoc = (uid: string): SingleDocQueryResult<UserTrackData> => {
  const query = findUserTracksQuery({ uid }, { db: useFirestore(), limit: 1 })
  const queryRes = useFirestoreCollection<UserTrackData>(query)

  return withTakeFirstResultAdapter(queryRes)
}
