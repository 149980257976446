import { useFirestore, useFirestoreDoc } from 'reactfire'

import { ConversationData } from '~/definitions/firestore.conversation'
import { getConversationRef } from '~/queries/firestore.helpers'

import {
  GetDocQueryResult,
} from '../definitions/firestore.queries'
import {
  withGetDocResultAdapter,
} from './firestore.hooks.helpers'

export const useConversationById = (conversationId: string): GetDocQueryResult<ConversationData> => {
  const ref = getConversationRef(conversationId, useFirestore())

  const doc = useFirestoreDoc(ref)

  return withGetDocResultAdapter(doc)
}
