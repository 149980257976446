import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CtaCard } from '~/components/panels/cards/CtaCard'
import { useOnboardingData } from '~/hooks/actionList.onboarding'
import { useFeatureConfigBoolean } from '~/hooks/features'
import { useCurrentUserMinder } from '~/hooks/minder'

type OnboardingCtaProps = Record<string, never>

export const OnboardingCta: FC<OnboardingCtaProps> = () => {

  const { loading: loadingMinder, data: minder } = useCurrentUserMinder()
  const { t } = useTranslation(['home'])
  const { status, data: isMyTrackEnabled } = useFeatureConfigBoolean('enableMenuItemMyTrack')
  const { loading, data } = useOnboardingData()

  if (loadingMinder || minder) {
    return null // do not show onboarding Cta anymore after orientation call and minder was assigned
  }

  if (loading || status === 'loading' || !isMyTrackEnabled) {
    return null
  }

  // console.log('data', data)
  const activeStepIndex = data.findIndex((item) => item.active === true)
  // console.log(`activeStepIndex`, activeStepIndex)

  if (activeStepIndex < 0) {
    return null
  }

  const nbSteps = data.length
  const remainingSteps = nbSteps - 1 - activeStepIndex

  const url = '/onboarding'
  const title = activeStepIndex === 0 ? t('home:onboarding_cta.titleFirstStep') : t('home:onboarding_cta.titleDefault')
  const description = remainingSteps === 0 ? t('home:onboarding_cta.descriptionLastStep') : `${t('home:onboarding_cta.descriptionDefault')} ${data[activeStepIndex].title}`

  return (
    <CtaCard url={url} title={title} description={description} />
  )
}
