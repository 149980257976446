import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ENV } from 'react-native-dotenv'

import { GetAudiencesBySegments } from '~/__generated__/GetAudiencesBySegments'
import { GetEventByIdByAudiences } from '~/__generated__/GetEventByIdByAudiences'
import { GetEventsByAudiences } from '~/__generated__/GetEventsByAudiences'
// import { GetAudiencesBySegmentsDev } from '~/__generated__/GetAudiencesBySegmentsDev' // same typing as above anyway
import {
  GET_AUDIENCES_BY_SEGMENTS, GET_AUDIENCES_BY_SEGMENTS_DEV, GET_EVENT_BY_ID_BY_AUDIENCES,
  GET_EVENTS_BY_AUDIENCES,
} from '~/queries/dato.events'

import { useCustomerIoUserSegments } from './vendors.customerio'

export const useAudiencesByUid = (uid: string) => {
  const { i18n } = useTranslation()

  // 1. obtain segment ids from user id
  const { data: segmentsId } = useCustomerIoUserSegments(uid) // example ['7', '9']

  // 2. obtain datocms audiences ids from customer io segment ids
  const AUDIENCES_QUERY_BY_ENV = (ENV === 'production') ? GET_AUDIENCES_BY_SEGMENTS : GET_AUDIENCES_BY_SEGMENTS_DEV
  const { data: audiences } = useQuery<GetAudiencesBySegments>(AUDIENCES_QUERY_BY_ENV, { skip: !segmentsId, variables: { segmentsId, locale: i18n.language } })

  const audiencesId = audiences?.allAudienceSegments.map((item) => item.id) // example ["97439043", "95810924"]
  return audiencesId
}

export const useEventsByUid = (uid: string) => {
  const { i18n } = useTranslation()
  const audiencesId = useAudiencesByUid(uid)

  return useQuery<GetEventsByAudiences>(GET_EVENTS_BY_AUDIENCES, {
    skip: !audiencesId,
    variables: {
      audiencesId,
      locale: i18n.language,
    },
  })
}

export const useEventById = (eventId: string, uid: string) => {
  const { i18n } = useTranslation()
  const audiencesId = useAudiencesByUid(uid)

  return useQuery<GetEventByIdByAudiences>(GET_EVENT_BY_ID_BY_AUDIENCES, {
    skip: !eventId || !audiencesId,
    variables: {
      eventId,
      audiencesId,
      locale: i18n.language,
    },
  })
}
