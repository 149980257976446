import { StackScreenProps } from '@react-navigation/stack'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useWindowDimensions } from 'react-native'
import { MINDDAY_CALENDLY_TEAM } from 'react-native-dotenv'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { meetingTypes } from '~/constants/firestore.meetings'
import { Button, Text, View } from '~/elements'
import { useContractorBySlug } from '~/hooks/dato.contractors'
import { useSessionBySlug } from '~/hooks/dato.sessions'
import { useUser } from '~/hooks/user.context'
import { MyTrackStackParamList } from '~/navigators/MyTrackStackParamList'

import { CalendlyEmbed } from './CalendlyEmbed'
import { SchedulingConfirmationScreen } from './MeetingSchedulingConfirmationScreen'

export type MeetingSchedulingScreenProps = StackScreenProps<MyTrackStackParamList, 'Scheduling'>

export const MeetingSchedulingScreen: FC<MeetingSchedulingScreenProps> = ({ route }) => {
//  console.log('-> SchedulingScreen')

  const { trackId, type } = route?.params || {}

  const { t, i18n } = useTranslation(['track'])
  const vwDimensions = useWindowDimensions()
  const [step, setStep] = useState<'intro' | 'scheduled' | 'date_time_selection' | 'confirmation'>('intro')
  const [scheduledMeetingData, setScheduledMeetingData] = useState<{
    calendlyMeetingId: string,
  }>()
  const { user } = useUser()
  const { loading: userLoading, userTrackDoc } = useUser()
  const userMinderSlug = userTrackDoc?.data()?.minder

  const { loading: sessionLoading, data: sessionQueryResult } = useSessionBySlug(type)
  const { loading: minderLoading, data: contractorQueryResult } = useContractorBySlug(userMinderSlug)

  if (sessionLoading || minderLoading || userLoading || (type !== meetingTypes.ORIENTATION && !userMinderSlug) || !sessionQueryResult?.session) {
    return null
  }

  const { title, description, calendlySlug } = sessionQueryResult.session

  if (!calendlySlug) {
    throw new Error(`missing calendly slug for minder ${userMinderSlug}`)
  }

  const contractor = contractorQueryResult?.contractor
  // console.log('minder', minder)

  // minder is either given by the track, or default to team for orientation
  if (!trackId && type !== meetingTypes.ORIENTATION) {
    throw new Error(`missing trackId for ${type} meeting type.`)
  }

  let calendlyTeamOrMinder: string | undefined
  // Note language should come from union of minders language, user language
  let eventId = calendlySlug

  if (type === meetingTypes.ORIENTATION) {
    calendlyTeamOrMinder = `${MINDDAY_CALENDLY_TEAM}-${i18n.language}`
  } else {

    if (!contractor || !contractor.minder) {
      throw new Error(`missing minder with slug ${userMinderSlug}`)
    }

    calendlyTeamOrMinder = contractor.minder.calendly || userMinderSlug

    if (!calendlyTeamOrMinder) {
      throw new Error('no minder for this user....')
    }

    // meeting language is defined by the intersection of our minder's languages and the specified user language
    const languages = contractor.languages.map((item) => item.code)
    if (languages.length === 1) {
      eventId += `-${languages[0]}`
    } else if (languages.length === 0) {
      throw new Error(`Should configure languages for minder ${userMinderSlug}`)
    } else {
      // we take the first that matches track user language (or user language, or UI language)
      const userLanguage = userTrackDoc?.get('language') || user.settings.language || i18n.language
      if (languages.includes(userLanguage)) {
        eventId += `-${userLanguage}`
      } else {
        // we pick minder's first language
        eventId += `-${languages[0]}`
      }
    }
  }

  const url = `https://calendly.com/${calendlyTeamOrMinder}/${eventId}`

  // console.log('Calendly scheduling url', url)

  if (step === 'intro') {
    return (
      <MainContainer>
        <BasicSectionContainer>
          <View tw="mb-8 mt-4 md:mt-20">
            { title && <SectionHeading title={t('track:scheduling.intro', { session_name: title.toLowerCase() })} /> }
            { description && <View tw="rounded-xl mt-4 justify-between">
              <Text tw="leading-relaxed">{description}</Text>
            </View> }
          </View>
          <Button title={t('track:scheduling.cta_continue')} onPress={() => setStep('date_time_selection')} />
        </BasicSectionContainer>
      </MainContainer>
    )
  }

  if (step === 'scheduled' && scheduledMeetingData) {
    return (
      <SchedulingConfirmationScreen calendlyMeetingId={scheduledMeetingData.calendlyMeetingId} />
    )
  }

  const remainingHeight = vwDimensions.height - 68 // tabBarHeight

  return (
    <MainContainer>
      <BasicSectionContainer>
        <View tw="mt-8 md:mt-20">
          <Text tw="text-center mb-2">{t(`track:scheduling.${step}`)}</Text>
        </View>
        <CalendlyEmbed
          setStep={setStep}
          url={url}
          style={{ height: remainingHeight - 64 }}
          user={user}
          setMeetingData={setScheduledMeetingData}
          eventId={eventId}
        />
      </BasicSectionContainer>
    </MainContainer>
  )
}
