import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { EvaluationProvider } from '~/context/EvaluationContext'
// import { Text } from '~/elements'
import { useGetCurrentUserLatestEvaluationByType } from '~/hooks/firestore.evaluations'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { UserPersonalityInviteSection } from './UserPersonalityInviteSection'
import { UserPersonalityResultsSection } from './UserPersonalityResultsSection'

/**
 * Section wrapper that either presents invite to take the test or latest results
 */
export const UserPersonalitySection = () => {
  const { t } = useTranslation(['my-evaluations'])

  const queryRes = useGetCurrentUserLatestEvaluationByType('user-personalities')

  // console.log('queryRes', queryRes)

  if (queryRes.loading) {
    return <LoadingScreen />
  }
  if (queryRes.error) {
    return <ErrorScreen error={queryRes.error} />
  }

  if (!queryRes.data) {
    // No result yet; we present invite to take the test
    return <UserPersonalityInviteSection />
  }

  return (
    <>
      <ScreenHeadingSection title={t('my-evaluations:main.personality.title')} />
      <BasicSectionContainer tw="md:mt-4">
        {queryRes.data && queryRes.data.id && (
          <EvaluationProvider evaluationId={queryRes.data.id}>
            <UserPersonalityResultsSection data={queryRes.data} />
          </EvaluationProvider>
        )}
      </BasicSectionContainer>
    </>
  )
}
