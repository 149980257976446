import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCircleCheck, faCircleNotch,
} from '@fortawesome/pro-solid-svg-icons'
import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
// eslint-disable-next-line import/no-duplicates
import addSeconds from 'date-fns/addSeconds'
// eslint-disable-next-line import/no-duplicates
import differenceInSeconds from 'date-fns/differenceInSeconds'
// eslint-disable-next-line import/no-duplicates
import fromUnixTime from 'date-fns/fromUnixTime'
// eslint-disable-next-line import/no-duplicates
import getUnixTime from 'date-fns/getUnixTime'
import { httpsCallable } from 'firebase/functions'
import {
  FC, useCallback, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { AuthLayout } from '~/components/layouts/containers/AuthLayout'
import { LocalStorageSignInStrategies } from '~/definitions/localStorage.signInStrategy'
import {
  Button, Icon, Text, View,
} from '~/elements'
import { useFunctions } from '~/hooks/firebase'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { localStorageKeys, useAsyncStorage } from '~/hooks/storage'
import { useSecondsTo } from '~/hooks/time'
import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'
import { hashEmail } from '~/utils/crypto'
// import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

library.add(faCircleCheck, faCircleNotch)

const DO_NOT_SEND_BEFORE = 45 // delay to allow an new send

const magicLinkSendingStatus = {
  STARTING: 'starting',
  SENDING_EMAIL: 'sendingEmail',
  EMAIL_SENT: 'emailSent',
  RECENTLY_SENT_EMAIL: 'rencentlySentEmail',
  ERROR: 'error',
  USER_NOT_FOUND: 'userNotFound',
} as const

type MagicLinkSendingStatus = typeof magicLinkSendingStatus[keyof typeof magicLinkSendingStatus];

export type MagicLinkAwaitScreenProps = StackScreenProps<NonAuthStackParamList, 'MagicLinkAwait'>

const httpSendSignInEmails = {
  SEND_SIGN_IN_EMAIL: 'httpSendSigninEmail',
  SEND_SIGN_IN_EMAIL_FROM_ALTERNATE_EMAIL: 'httpSendSignInEmailFromAlternateEmail',
}

/**
 * Effectively send magic link and record login email in local storage
 * TODO avoid resending email on browser refresh (we could set a timestamp in local storage and prevent befre X seconds)
 */
export const MagicLinkAwaitScreen: FC<MagicLinkAwaitScreenProps> = ({ route }) => {
  const functions = useFunctions()
  const {
    i18n: {
      language,
    },
    t,
  } = useTranslation(['auth'])

  const linkTo = useLinkTo()
  const { lg } = useMediaQuery()

  const {
    email: emailFromParams,
    origin,
    alternate: alternateEmailSignIn,
  } = route.params

  const email = decodeURIComponent(emailFromParams)
  const encodedEmail = encodeURIComponent(email)
  const isAlternateSignIn = alternateEmailSignIn && alternateEmailSignIn === 'true'

  const [status, setStatus] = useState<MagicLinkSendingStatus>('starting')

  const [shouldSendEmail, setShouldSendEmail] = useState<undefined | true | false>()

  const [userNotFound, setUserNotFound] = useState(false)

  const { loading, value: localLastSent, setItem: setLocalLastSent } = useAsyncStorage<string>(localStorageKeys.MAGIC_EMAIL)
  const { setItem: setLocalEmail } = useAsyncStorage<string>(localStorageKeys.EMAIL)
  const { setItem: setLocalEmailStrategy } = useAsyncStorage<string>(localStorageKeys.EMAIL_STRATEGY)

  const [nextAllowedUnix, setNextAllowedUnix] = useState<number|undefined>()

  const timeToAllowed = useSecondsTo(nextAllowedUnix)

  useEffect(() => {

    const evaluateIfShouldSendEmail = async () => {

      if (!loading) {
        if (localLastSent) {

          // console.log('magicEmailLastSent', localLastSent)
          const [oldHash, oldTimestamp] = localLastSent.split('-')
          if (!nextAllowedUnix && oldTimestamp) {
            const nextAllowedDateFns = addSeconds(fromUnixTime(Number(oldTimestamp)), DO_NOT_SEND_BEFORE)
            setNextAllowedUnix(getUnixTime(nextAllowedDateFns))
          }
          // console.log('oldHash', oldHash)
          // console.log('oldTimestamp', oldTimestamp)
          const hash = await hashEmail(email)
          if (oldHash === hash) {
            // console.log('same email...')
            const diff = differenceInSeconds(new Date(), fromUnixTime(Number(oldTimestamp)))
            // console.log('diff in sec', diff)
            if (diff < 30) {
              // console.log('too soon')
              setStatus(magicLinkSendingStatus.RECENTLY_SENT_EMAIL)
              setShouldSendEmail(false)
            } else {
              // console.log('Seems OK to send email - beyond waiting time')
              setShouldSendEmail(true)
            }
          } else {
            // console.log('Seems OK to send email - different email ')
            setShouldSendEmail(true)
          }

        } else {
        // No known hash; it's OK to sent email
          setShouldSendEmail(true)
        }
      } else {
        setShouldSendEmail(undefined)
      }
    }

    evaluateIfShouldSendEmail()

    // purposly ignored nextAllowedUnix, localLastSent
  }, [email, loading])

  const sendMagicLink = useCallback(
    () => {
      const sendMagicLinkAsyncFn = async () => {
        setStatus(magicLinkSendingStatus.SENDING_EMAIL)

        const actionCodeSettings = {
        // https://firebase.google.com/docs/auth/web/passing-state-in-email-actions
          url: `${window.location.origin}/auth/callback`,
          // dynamicLinkDomain: 'links.mindday.dev',

          // forces to handle the action code link within mobile application first
          // handleCodeInApp: true,

          // settings to handle code link within iOS
          // iOS: {
          //      bundleId: 'com.mindday.app',
          // },

        // settings to handle code link within Android
        // android: {
        //     packageName: 'com.mindday.app',
        //     installApp: true,
        // },
        }
        //  console.log('actionCodeSettings', actionCodeSettings)
        //  console.log('Will send magic link to redirect to', actionCodeSettings.url)

        const settings = {
          language,
        }

        try {

          const callableName = isAlternateSignIn
            ? httpSendSignInEmails.SEND_SIGN_IN_EMAIL_FROM_ALTERNATE_EMAIL
            : httpSendSignInEmails.SEND_SIGN_IN_EMAIL

          await httpsCallable(functions, callableName)({
            email,
            actionCodeSettings,
            origin,
            settings,
          })
          // console.log('success')

          setStatus(magicLinkSendingStatus.EMAIL_SENT)

          if (!isAlternateSignIn) {
            // Store user email in local storage
            await setLocalEmail(email)
            await setLocalEmailStrategy(LocalStorageSignInStrategies.PRIMARY)
          } else {
            await setLocalEmailStrategy(LocalStorageSignInStrategies.ALTERNATE)
          }

          /**
         * We also store locally a ppty made of the hash of the email and the timestamp of the last sent email
         */
          const hashResult = await hashEmail(email)
          // update local storage object
          const currentTimestamp = Math.floor(Date.now() / 1000) // Timestamp in sec
          const nextAllowedDateFns = addSeconds(Date.now(), DO_NOT_SEND_BEFORE)
          setNextAllowedUnix(getUnixTime(nextAllowedDateFns))
          const localStorageValue = `${hashResult}-${currentTimestamp}`
          // console.log('Wil locally store', localStorageValue)
          await setLocalLastSent(localStorageValue)

        } catch (error) {

          const {
            message,
            details: {
              code: codeFromErrorDetails,
            } = {},
          } = error

          // console.log('codeFromErrorDetails', codeFromErrorDetails)

          switch (codeFromErrorDetails) {

          case 'auth/user-not-found': {
            setStatus('userNotFound')
            setUserNotFound(true)
            break
          }

          case 'EMAIL_ALREADY_USED': {
            // Email already used => Not allowed => redirect to NotAllowed screen to sign in with other account
            linkTo(`/auth/forbidden?email=${encodedEmail}&origin=email-already-used`)
            break
          }

          default:
            setStatus(magicLinkSendingStatus.ERROR)
            throw new Error(`unhandled error :(${codeFromErrorDetails}) ${message}`)
          }
        }
      }
      sendMagicLinkAsyncFn()
    },
    [email, functions, language, origin, isAlternateSignIn, setLocalEmail, setLocalLastSent],
  )

  useEffect(() => {

    // eventualy analyse last send timestamp before sending email
    if (shouldSendEmail === true) {
      // console.log('Will trigger initial email')
      sendMagicLink()
    }
    // purposly ignored sendMagicLink
  }, [email, shouldSendEmail, origin])

  const align = lg ? 'left' : 'center'

  const shouldDisplayActivityIndicator = status !== magicLinkSendingStatus.RECENTLY_SENT_EMAIL && timeToAllowed !== 0 && status !== magicLinkSendingStatus.USER_NOT_FOUND

  return (
    <AuthLayout imageId={'10700738'}>
      {/* <Animation itemId="19632899" autoPlay /> */}
      <View tw="py-16 px-4 justify-center items-center">
        <Text nativeID="await-msg" tw={`font-serif mb-6 text-black text-xl font-medium text-${align} items-center`}>{t(`auth:magicLinkAwait.${status}`)}</Text>

        { shouldDisplayActivityIndicator && (<Icon name={status !== 'emailSent' ? 'circle-notch' : 'circle-check'} spin={status !== 'emailSent'} size="4x"/>)}

        {(status === magicLinkSendingStatus.RECENTLY_SENT_EMAIL || status === magicLinkSendingStatus.EMAIL_SENT) && timeToAllowed > 0 && (
          <Text tw="mt-4">{t('auth:magicLinkAwait.sendAgainAwait', { time: timeToAllowed })}</Text>
        )}

        {(status === magicLinkSendingStatus.RECENTLY_SENT_EMAIL || status === magicLinkSendingStatus.EMAIL_SENT) && timeToAllowed === 0 && (
          <Button title={t('auth:magicLinkAwait.sendAgainCta')} onPress={() => {
            // rest count down
            setNextAllowedUnix(undefined)

            // console.log('Will trigger a new email on user request')
            sendMagicLink()
          }

          }/>
        )}
        {userNotFound && (
          <>
            <Text tw={`mt-2 text-${align}`}>{email + t('auth:magicLinkAwait.unknownEmail') }</Text>
            <Text tw={`text-${align}`}>{ t('auth:magicLinkAwait.doNext')}</Text>
            <Button
              tw="mt-4"
              title={t('auth:magicLinkAwait.primaryCta')}
              to={'/auth/signin'}
            />
            <Button
              variant="outline"
              title={t('auth:magicLinkAwait.secondaryCta')}
              to={`/auth/register?email=${encodedEmail}&origin=challenge`}
              tw="mt-4"
            />
          </>
        )}
      </View>
    </AuthLayout>
  )
}
