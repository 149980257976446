import classNames from 'classnames'
import {
  FC, ReactNode, useRef, useState,
} from 'react'
import RnSwiper from 'react-native-web-swiper'

import { Button, View } from '~/elements'
import { Swiper } from '~/elements/swiper/Swiper'
import { useSwiperData } from '~/hooks/dato.swipers'

import { CarouselSlide } from './CarouselSlide'

type CarouselProps = {
  tw?: string
  /**
   * One of the swipers keys. See https://cms.mindday.com/editor/item_types/1151671/items
   * TODO: dynamically build that type
   */
  swiperKey: 'evaluation-player'|'app-welcome'

  children?: ReactNode

  buttonTitle?: string
  buttonLastTitle?: string
  onLast?: () => void

}

/**
 * Wraps Swiper component with data coming from Dato CMS
 * TODO: custom slide background color based on slide definition
 */
export const Carousel: FC<CarouselProps> = ({
  tw,
  swiperKey,
  children,
  buttonTitle,
  buttonLastTitle,
  onLast,
}) => {
  // console.log('-> Carousel', swiperKey)

  const currentRef = useRef<RnSwiper>(null)
  const { data } = useSwiperData(swiperKey)
  const [isLast, setIsLast] = useState(false)

  if (!data || !data.appSwiper) {
    return null
  }

  // console.log(`data`, data)

  const { slides } = data.appSwiper

  // console.log('slides', slides)

  const onPressNavigationButton = () => {
    if (onLast && isLast) {
      onLast()
    } else {
      currentRef.current?.goToNext()
    }
  }
  const onIndexChanged = (activeIndex: number) => {
    const isLastSlide = (slides.length - 1) === activeIndex

    if (isLastSlide) {
      setIsLast(true)
    } else {
      setIsLast(false)
    }
  }

  const bottomView = (onLast && buttonTitle && buttonLastTitle)
    ? (
      <View tw="w-full flex-row py-4">
        <Button
          onPress={onPressNavigationButton}
          title={isLast ? buttonLastTitle : buttonTitle}
        />
      </View>
    ) : undefined

  return (
    <View tw={classNames('flex flex-1 w-full h-full', tw)}>
      <Swiper
        ref={currentRef}
        containerStyle="flex-grow" // No padding here
        innerContainerStyle="pb-4" // padding for dots
        // swipeAreaStyle=""
        slideWrapperStyle="pt-12 pb-16 flex items-center justify-center" // slide padding
        dotActiveStyle="bg-brand-base"
        onIndexChanged={onIndexChanged}
        controlsProps={{
          dotsTouchable: true,
          dotsPos: 'bottom',
          prevPos: false,
          nextPos: false,
          cellsContent: {
            bottom: bottomView,
          },
        }}
      >
        {slides.map((item) => <CarouselSlide key={`slide-${item.id}`} testID={`slide-${item.id}`} {...item}/>)}
      </Swiper>
      {children}
    </View>

  )
}
