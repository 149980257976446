import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetContractorBySlug } from '~/__generated__/GetContractorBySlug'
import { GET_CONTRACTOR_BY_SLUG } from '~/queries/dato.contractors'

export const useContractorBySlug = (slug?: string) => {
  const { i18n } = useTranslation()

  return useQuery<GetContractorBySlug>(GET_CONTRACTOR_BY_SLUG, {
    variables: { slug, locale: i18n.language },
    skip: !slug,
  })
}
