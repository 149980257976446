import classNames from 'classnames'
import { memo } from 'react'
import {
  areMessageUIPropsEqual,
  MessageContextValue,
  messageHasAttachments,
  messageHasReactions,
  MessageUIComponentProps,
  // MML,
  Modal,
  useComponentContext, useMessageContext,
} from 'stream-chat-react'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import {
  // Pressable,
  // Text,
  View,
} from '~/elements'

// import { Avatar as DefaultAvatar } from '~/elements/avatar/Avatar'
import { EditMessageForm as DefaultEditMessageForm } from '../MessageInput/EditMessageForm'
import { MessageInput } from '../MessageInput/MessageInput'
// import { ReactionSelector as DefaultReactionSelector } from '../Reactions/ReactionSelector'
// import { ReactionsList as DefaultReactionList } from '../Reactions/ReactionsList'
import { MessageDeleted as DefaultMessageDeleted } from './MessageDeleted'
// import { MessageOptions as DefaultMessageOptions } from './MessageOptions'
// import { MessageRepliesCountButton as DefaultMessageRepliesCountButton } from './MessageRepliesCountButton'
import { MessageStatus as DefaultMessageStatus } from './MessageStatus'
import { MessageText } from './MessageText'
// import { MessageTimestamp as DefaultMessageTimestamp } from './MessageTimestamp'
import {
  messageDeletedTw,
  messageEphemeralTw,
  messageErrorTw,
  messageHasAttachementTw,
  messageHasTextTw,
  messageMeTw,
  messageReceivedTw,
  messageRegularTw,
  messageReplyTw,
  messageSendingTw,
  // messageSimpleNameTw,
  messageSystemTw,
  messageTw,
  messageWithReactionsTw,
} from './styles.tw'

// import { areMessageUIPropsEqual, messageHasAttachments, messageHasReactions } from './utils.tsx.old'

// str-chat__message-simple-timestamp
// const messageTimestamp = ''

// str-chat__message-simple-data
// const messageDataTw = 'font-normal text-xs text-gray-500'

type MessageSimpleWithContextProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = MessageContextValue<At, Ch, Co, Ev, Me, Re, Us>;

const MessageSimpleWithContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: MessageSimpleWithContextProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {

  const {
    additionalMessageInputProps,
    clearEditingState,
    testID,
    editing,
    // endOfGroup,
    // firstOfGroup,
    // groupedByUser,
    handleAction,
    // handleOpenThread,
    // handleRetry,
    isMyMessage,
    isReactionEnabled,
    message,
    // onUserClick,
    // onUserHover,
    // reactionSelectorRef,
    // showDetailedReactions,
    // threadList,
  } = props

  // console.log('-> MessageSimpleWithContext', testID)

  const {
    Attachment,
    // Avatar = DefaultAvatar,
    EditMessageInput = DefaultEditMessageForm,
    MessageDeleted = DefaultMessageDeleted,
    // MessageOptions = DefaultMessageOptions,
    // MessageRepliesCountButton = DefaultMessageRepliesCountButton,
    MessageStatus = DefaultMessageStatus,
    // MessageTimestamp = DefaultMessageTimestamp,
    // ReactionSelector = DefaultReactionSelector,
    // ReactionsList = DefaultReactionList,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageSimple')

  // const messageWrapperRef = useRef<HTMLDivElement | null>(null)

  const hasAttachment = messageHasAttachments(message)
  const hasReactions = messageHasReactions(message)

  let containerTw = isMyMessage() ? classNames(messageTw, messageMeTw) : classNames(messageTw)

  if (message.customType === 'message.date') {
    return null
  }

  if (message.deleted_at || message.type === 'deleted') {
    return <MessageDeleted message={message} />
  }

  // console.log('-> Message simple', message.type, message.status)

  // str-chat__message--${message.type}
  switch (message.type) {
  case 'regular':
    containerTw = classNames(containerTw, messageRegularTw)
    break
  case 'ephemeral':
    containerTw = classNames(containerTw, messageEphemeralTw)
    break
  case 'error':
    containerTw = classNames(containerTw, messageErrorTw)
    break
  case 'reply':
    containerTw = classNames(containerTw, messageReplyTw)
    break
  case 'system':
    containerTw = classNames(containerTw, messageSystemTw)
    break
  case undefined:
    break
  default:
    throw new Error(`Unhamdled message type ${message.type}`)
  }

  // str-chat__message--${message.status}
  switch (message.status) {
  case 'deleted':
    containerTw = classNames(containerTw, messageDeletedTw)
    break
  case 'sending':
    containerTw = classNames(containerTw, messageSendingTw)
    break
  case 'received':
    containerTw = classNames(containerTw, messageReceivedTw)
    break
  default:
    throw new Error(`Unhamdled message status ${message.status}`)
  }

  // message.text ? 'str-chat__message--has-text' : 'has-no-text'
  if (message.text) {
    containerTw = classNames(containerTw, messageHasTextTw)
  }

  // hasAttachment ? 'str-chat__message--has-attachment'
  if (hasAttachment) {
    containerTw = classNames(containerTw, messageHasAttachementTw)
  }

  // hasReactions && isReactionEnabled ? 'str-chat__message--with-reactions'
  if (hasReactions && isReactionEnabled) {
    containerTw = classNames(containerTw, messageWithReactionsTw)
  }

  // message.pinned ? 'pinned-message'
  // if(message.pinned) containerTw = classNames(containerTw, messagePinnedTw)

  // groupedByUser ? 'str-chat__virtual-message__wrapper--group'
  // if(groupedByUser) containerTw = classNames(containerTw, virtualMessageWrapperGroupTw)

  // firstOfGroup ? 'str-chat__virtual-message__wrapper--first'
  // if(firstOfGroup) containerTw = classNames(containerTw, virtualMessageWrapperFirstTw)

  // endOfGroup ? 'str-chat__virtual-message__wrapper--end'
  // if(endOfGroup) containerTw = classNames(containerTw, virtualMessageWrapperEndTw)

  return (
    <>
      {editing && (
        <Modal onClose={clearEditingState} open={editing}>
          <MessageInput
            clearEditingState={clearEditingState}
            Input={EditMessageInput}
            message={message}
            {...additionalMessageInputProps}
          />
        </Modal>
      )}
      {
        <View
          tw={classNames(containerTw)}
          key={message.id}
          testID={testID}
          // ref={messageWrapperRef}
        >
          {/* {({ hovered }) => ( */}
          <>
            <MessageStatus />
            {/* {message.user && (
            <Avatar
              image={message.user.image}
              name={message.user.name || message.user.id}
              onClick={onUserClick}
              onMouseOver={onUserHover}
              user={message.user}
            />
          )} */}
            <View
              tw="flex-col items-center py-1"
              testID='message-inner'
              // onClick={
              //   message.status === 'failed' && message.errorStatusCode !== 403
              //     ? () => handleRetry(message)
              //     : undefined
              // }
            >
              <>
                {/* <MessageOptions messageWrapperRef={messageWrapperRef} hovered={hovered}/> */}
                {/* {hasReactions && !showDetailedReactions && isReactionEnabled && (
                    <ReactionsList reverse />
                  )} */}
                {/* {showDetailedReactions && isReactionEnabled && (
                <ReactionSelector ref={reactionSelectorRef} />
              )} */}
              </>

              <MessageText message={message} isMyMessage={isMyMessage()}/>
              {message.attachments?.length && !message.quoted_message ? (
                <Attachment actionHandler={handleAction} attachments={message.attachments} />
              ) : null}
              {/* {message.mml && (
                <MML
                  actionHandler={handleAction}
                  align={isMyMessage() ? 'right' : 'left'}
                  source={message.mml}
                />
              )} */}
              {/* {!threadList && !!message.reply_count && (
                  <View
                    // className='str-chat__message-simple-reply-button'
                  >
                    <MessageRepliesCountButton
                      onClick={handleOpenThread}
                      reply_count={message.reply_count}
                    />
                  </View>
                )} */}
              {/* {(!groupedByUser || endOfGroup) && (
                  <View tw={messageDataTw}>
                    {!isMyMessage() && message.user ? <Text tw={messageSimpleNameTw}>{message.user.name || message.user.id}</Text> : null}
                    <MessageTimestamp calendar tw={messageTimestamp} />
                  </View>
                )} */}
            </View>
          </>
          {/* )} */}
        </View>
      }
    </>
  )
}

const MemoizedMessageSimple = memo(MessageSimpleWithContext, areMessageUIPropsEqual) as typeof MessageSimpleWithContext

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
export const MessageSimple = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: MessageUIComponentProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const messageContext = useMessageContext<At, Ch, Co, Ev, Me, Re, Us>('MessageSimple')

  return <MemoizedMessageSimple {...messageContext} {...props} />
}
