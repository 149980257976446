import { StackHeaderTitleProps } from '@react-navigation/stack'
import classNames from 'classnames'

import { Text, View } from '~/elements'
import { useMediaQuery } from '~/hooks/mediaQuery'

export const CustomHeaderTitle = ({ tw, children }: StackHeaderTitleProps) => {
  const { md } = useMediaQuery()

  return (
    <View tw={classNames('mt-2', tw)}>
      <Text
        numberOfLines={md ? undefined : 1}
        tw="font-serif leading-normal text-lg sm:text-xl md:text-2xl lg:text-3xl"
      >
        {children}
      </Text>
    </View>
  )
  // }
}
