import { ContentPlayerView } from '~/components/layouts/containers/ContentPlayerView'
import { View } from '~/elements'
import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'

import { getBestTextColor } from '../../../utils/text.accessibility.helpers'
// import { ContentAuthorBox } from './ContentAuthorBox'
import { ContentCoverDesktopTitleBox } from './ContentCoverDesktopTitleBox'

export const ContentCoverMobileView = (props) => {

  const {
    id, title, coverBackgroundColor, coverBackground, coverMain, coverMainFormat,
  } = props

  const textColor = getBestTextColor(coverBackgroundColor || 'surface-1')

  return (
    <ContentPlayerView
      tw={'px-4 pb-24'}
      cardTw={`flex-col justify-between bg-${coverBackgroundColor || 'surface-1'}`}
      to={`/content/${id}/page/1`}>
      <View style={{ minHeight: 200 }} tw="flex-col justify-between px-4 pt-8">
        <ContentCoverDesktopTitleBox title={title} containerTw="md:px-24" textTw={`text-center text-${textColor}`} />
        {/* {author && author.picture && author.picture.responsiveImage && (
          <ContentAuthorBox author={author} metadata={metadata} tw="justify-center mt-16" backgroundColor={coverBackgroundColor} />
        )} */}
      </View>
      <DoubleSvgImage
        aspectW={6}
        aspectH={7}
        shadow={false}
        background={coverBackground}
        illustration={coverMain}
        format={coverMainFormat}
        containerTw="rounded-b-2xl" />
    </ContentPlayerView>
  )
}
