import classNames from 'classnames'
import { FC } from 'react'

import { Badge } from '../badge/Badge'
import { View } from '../containers/View'
import { Text } from '../text/Text'
import {
  AvatarProps,
} from './Avatar.d'
import { AvatarImage } from './AvatarImage'
import { Initials } from './AvatarInitials'
import { Placeholder } from './AvatarPlaceholder'
import {
  getAvatarBadgePositionString,
  getAvatarBadgeSizeString,
} from './tw.helpers'

export const AvatarWithText = ({
  textPosition, title, description, ...props
}) => {
  const containerTw = textPosition === 'right' ? 'flex-row' : 'flex-col items-center'
  const textTw = textPosition === 'right' ? 'text-left' : 'text-center'

  const textContainerTw = textPosition === 'right' ? 'justify-center ml-2' : 'mt-2'

  return (
    <View tw={containerTw}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <Avatar {...props} />
      <View tw={classNames('', textContainerTw)}>
        {!!title && <Text tw={classNames(textTw, 'font-medium text-gray-700')}>{title}</Text>}
        {!!description && <Text tw={classNames(textTw, 'font-normal text-gray-500')}>{description}</Text>}
      </View>
    </View>
  )
}

/**
 *
 * See
 * https://tailwindui.com/components/application-ui/elements/avatars
 * https://reactnativeelements.com/docs/avatar
 *
 * A great presentation of Avatar components:
 * https://blog.prototypr.io/designing-the-avatar-all-you-need-to-know-a22af3daa1f2
 *
 * TODO: border should be automatic on giant avatars, and disappear on smallers
 */
export const Avatar: FC<AvatarProps> = ({
  size,
  shape,
  color,
  image,
  title,
  description,
  withText,
  textPosition,
  placeholder,
  badge,
  badgePosition,
  tw,
}) => {
  /**
   * If we have title and/or description, we add that to the base Avatar
   */
  if ((title || description) && withText) {
    return (
      <AvatarWithText
        size={size}
        shape={shape}
        image={image}
        placeholder={placeholder}
        badge={badge}
        badgePosition={badgePosition}
        tw={tw}
        title={title}
        description={description}
        textPosition={textPosition}
      />
    )
  }

  /**
   * If we have a badge, we wrap Avatar with a view and absolutely position badge
   */
  if (badge) {
    const badgeStyle = getAvatarBadgeSizeString(size)
    const badgePositionStyle = getAvatarBadgePositionString(badgePosition)

    const badgeTw = classNames(badgeStyle, badgePositionStyle)
    // console.log('badge tw', badgeTw)

    return (
      <View tw="relative">
        <Avatar
          size={size}
          shape={shape}
          image={image}
          placeholder={placeholder}
          tw={tw}
        />
        <Badge {...badge} tw={badgeTw} size="mini" />
      </View>
    )
  }

  /**
   * This is the "pure" avatar,
   * 1) the regular image
   * 2) initials
   * 3) generic icon placeholder
   */

  if (image) {
    return (
      <AvatarImage
        image={image}
        size={size}
        shape={shape}
        color={color}
        tw={tw}
      />
    )
  }

  if (title) {
    return (
      <Initials
        name={title}
        size={size}
        shape={shape}
        color={color}
        tw={tw}
      />
    )
  }

  return (
    <Placeholder
      size={size}
      shape={shape}
      color={color}
      tw={tw}
    />
  )
}

Avatar.defaultProps = {
  size: 'medium',
  shape: 'circular',
  badgePosition: 'bottom',
  color: 'brand',
  withText: false,
  textPosition: 'right',
}
