import omit from 'lodash/omit'
import {
  createContext, FC, ReactNode, useContext, useMemo,
} from 'react'

import { GetEvaluationModelQuery_evaluation_dimensionGroups, GetEvaluationModelQuery_evaluation_dimensionScoring } from '~/__generated__/GetEvaluationModelQuery'
import { EvaluationRecord } from '~/definitions/dato.entities'
import { DocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import { useEvaluationModelBySlug } from '~/hooks/dato.evaluations'
import { useEvaluationById } from '~/hooks/firestore.evaluations'

type EvaluationContextType = {
  loading:boolean
  error: Error
  data:{
    doc: DocumentSnapshot<EvaluationData>
    model: EvaluationRecord
  }
}

export const EvaluationContext = createContext<EvaluationContextType | undefined>(undefined)

type EvaluationProviderProps = {
  evaluationId:string
  children: ReactNode
}

const EvaluationProviderLoc = ({ value, children }) => {
  // console.log(`EvaluationProviderLoc with doc`, value.data.doc.id)
  const getEvaluationModelRes = useEvaluationModelBySlug(value.data.doc.get('slug'))
  // console.log('getEvaluationModelRes', getEvaluationModelRes)

  const toc: (GetEvaluationModelQuery_evaluation_dimensionScoring | GetEvaluationModelQuery_evaluation_dimensionGroups)[] = []
  if (getEvaluationModelRes.data?.evaluation) {
    getEvaluationModelRes.data?.evaluation.dimensionGroups.forEach((item) => {
      // console.log('item', item)
      // Add a section page
      toc.push(omit(item, 'dimensions'))

      // Add a page per score dimension
      item.dimensions.forEach(({ key }) => {
        // console.log('Looking for dmension', key)
        toc.push(getEvaluationModelRes.data?.evaluation.dimensionScoring.find((o) => o.key === key))
      })
    })
  }

  const result = useMemo(() => ({
    loading: value.loading || getEvaluationModelRes.loading,
    error: value.error || getEvaluationModelRes.error,
    data: {
      doc: value.data.doc,
      model: {
        toc,
        ...getEvaluationModelRes.data?.evaluation,
      },
    },
  }), [getEvaluationModelRes.data?.evaluation, getEvaluationModelRes.error, getEvaluationModelRes.loading, toc, value.data.doc, value.error, value.loading])

  return <EvaluationContext.Provider value={result}>{children}</EvaluationContext.Provider>
}

/**
 * Wraps hooks to fetch both doc and model and provide result as context
 */
export const EvaluationProvider: FC<EvaluationProviderProps> = ({ evaluationId, children }) => {
  const { loading, error, data } = useEvaluationById(evaluationId)

  const result = useMemo(() => ({
    loading,
    error,
    data: {
      doc: data,
      model: undefined,
    },
  }), [data, error, loading])

  if (data) {
    // console.log('Evaluation doc', data.id)
    return <EvaluationProviderLoc value={result}>{children}</EvaluationProviderLoc>
  }

  // console.log('Evaluation doc not fetched yet...')

  return <EvaluationContext.Provider value={result}>{children}</EvaluationContext.Provider>
}

export const useEvaluationContext = () => {
  const context = useContext(EvaluationContext)
  if (context === undefined) {
    throw new Error('useEvaluation must be used within a EvaluationProvider')
  }

  return context
}
