import { DarkTheme, DefaultTheme as LightTheme } from '@react-navigation/native'

import { getColor } from './helpers'

export const CustomReactNavigationLightTheme = {
  ...LightTheme,
  colors: {
    ...LightTheme.colors,
    primary: getColor('brand-base'),
    background: getColor('transparent'), // background should be provided at app level
    card: getColor('surface-3'),
    text: getColor('black'),
    border: getColor('transparent'),
    notification: getColor('brand-base'),
  },
}

export const CustomReactNavigationDarkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
  },
}
