/* eslint-disable no-nested-ternary */
/**
 * Root navigator handles early data loading and protect authenticated routes
 */

import {
  getPathFromState, getStateFromPath,
} from '@react-navigation/core'
import {
  CommonActions, NavigationContainer,
  NavigatorScreenParams,
  useNavigationContainerRef,
} from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import cleanDeep from 'clean-deep'
import { useEffect, useState } from 'react'
import { ColorSchemeName } from 'react-native'
import { useSigninCheck } from 'reactfire'
import { useDebouncedCallback } from 'use-lodash-debounce'

import { useMediaQuery } from '~/hooks/mediaQuery'

import analytics from '../analytics'
import LoadingScreen from '../screens/LoadingScreen/LoadingScreen'
import { CustomReactNavigationDarkTheme, CustomReactNavigationLightTheme } from '../theme/rNavigation'
import { getDeepestRoute } from '../utils/navigation.helpers'
import LinkingConfiguration from './LinkingConfiguration'
import MainNavigator from './MainNavigator'
import { MainStackParamList } from './MainStackParamList'
import NonAuthNavigator from './NonAuthNavigator'
import { NonAuthStackParamList } from './NonAuthStackParamList.d'
import { TaskListStackParamList } from './TaskListStackParamList'

const RootStack = createStackNavigator<RootStackParamList>()

export type RootStackParamList = {
  Auth: NavigatorScreenParams<MainStackParamList | TaskListStackParamList>
  NonAuth: NavigatorScreenParams<NonAuthStackParamList>
}

const RootNavigatorSwitch = () => {

  const { status, data: signInCheckResult } = useSigninCheck()
  // console.log('-> RootNavigator signInCheckResult', signInCheckResult)

  if (status === 'loading') {
    return <LoadingScreen />
  }

  const shouldRenderAuthNavigator = signInCheckResult.signedIn === true
  // console.log('Will render root navigator with auth: ', shouldRenderAuthNavigator)

  return (
    <RootStack.Navigator screenOptions={{ headerShown: false }}>
      { shouldRenderAuthNavigator ? (
        <RootStack.Screen name="Auth" component={MainNavigator} />
      ) : (
        <RootStack.Screen name="NonAuth" component={NonAuthNavigator} />
      )}
    </RootStack.Navigator>
  )
}

// Delay before considering path has settled
const DEBOUNCE_DELAY_MS = 250

const NavigationContainerWrapper = ({ colorScheme }: { colorScheme: ColorSchemeName }) => {
  // console.log('-> RootNavigator')
  const navigationRef = useNavigationContainerRef()
  const { md } = useMediaQuery()

  /**
   * We store route name and params as debounced state
   */
  const [currentPath, setCurrentPath] = useState<string|undefined>(undefined)
  const setDebouncedCurrentPath = useDebouncedCallback(setCurrentPath, DEBOUNCE_DELAY_MS) // We wait a bit before setting the definitive path

  // trigger analytics when route changes
  useEffect(() => {
    if (currentPath !== undefined) {
      const state = getStateFromPath(currentPath)
      const { name, params } = getDeepestRoute(state)
      analytics.screen({
        name,
        properties: cleanDeep(params),
      })
    }
  }, [currentPath])

  /**
   * We reset nav state when screen resize implies change in layout
   */
  useEffect(() => {
    // we re inject state
    if (navigationRef.isReady()) {
      navigationRef.dispatch(CommonActions.reset(getStateFromPath(currentPath)))
    }
    // purposly ignored 'currentPath' and 'navigationRef'
  }, [md])

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? CustomReactNavigationDarkTheme : CustomReactNavigationLightTheme}
      fallback={<LoadingScreen />}

      // https://reactnavigation.org/docs/navigation-container/#documenttitle
      documentTitle={{
        formatter: () => 'mindDay', // (_options, route) => route?.name || 'mindDay'
      }}

      onStateChange={(state) => {
        // console.log('state', state)
        // console.log(`root navigator state changed from ${currNavState ? getPathFromState(currNavState) : undefined} to ${state ? getPathFromState(state) : undefined}`)

        const url = getPathFromState(state)

        if (url !== currentPath) {
          setDebouncedCurrentPath(url)
        }

      }}
    >
      <RootNavigatorSwitch />
    </NavigationContainer>
  )
}

export default NavigationContainerWrapper

// Only use SplashScreen on Native as it interferes with web history API
