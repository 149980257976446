import { FC, ReactNode } from 'react'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { Text, View } from '~/elements'

import { ReaderFooterProps } from '../BottomNavBar'

type IllustrationLayoutProps = ReaderFooterProps & {
  illustration: ReactNode
  description: string
}

export const IllustrationLayout: FC<IllustrationLayoutProps> = ({ illustration, description }) => (
  <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
    <TwoColGridSection tw="h-full">
      <ContentPlayerScrollView>
        <View tw="justify-center">
          <img alt={illustration.alt} src={illustration.url} height={illustration.height} width={illustration.width} />
          <Text markdown
            tw={'max-w-none justify-center'}
          >{description}</Text>
        </View>
      </ContentPlayerScrollView>
    </TwoColGridSection>
  </FullOverlayContainer>
)
