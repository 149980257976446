import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connectStateResults } from 'react-instantsearch-native'

import { Text, View } from '~/elements'
// import { useDatoImage } from '~/hooks/dato.assets'
import { useTimeout } from '~/hooks/time'
// import { EMPTY_EVALUATIONS_ILLUSTRATION_ID } from '~/screens/MyTrackEmptyScreen/MyTrackEmptyScreen'

const NoHitWithResults = ({ searchResults, isSearchStalled }) => {
  // const { data } = useDatoImage(EMPTY_EVALUATIONS_ILLUSTRATION_ID, { width: 240, height: 240 })

  const { t } = useTranslation(['library'])

  const [visible, setVisible] = useState(false)

  const show = () => setVisible(true)

  // only show after 1/2 sec
  useTimeout(show, 500)

  const hasResults = searchResults && searchResults.nbHits !== 0
  // const nbHits = searchResults && searchResults.nbHits

  if (hasResults || isSearchStalled || !visible) {
    return null
  }

  // console.log(`hasResults`, hasResults)
  // console.log(`nbHits`, nbHits)

  return (
    <View tw="flex-grow justify-center" style={{ minHeight: 300 }}>
      <Text tw="text-center uppercase font-semibold text-gray-500">{ t('explore.hits.noHit')}</Text>
    </View>
  )
}

export const NoHit = connectStateResults(NoHitWithResults)
