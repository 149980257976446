// import { Header, HeaderOptions, Layout } from '@react-navigation/elements'
import { HeaderOptions, Layout } from '@react-navigation/elements'
import classNames from 'classnames'
import { FC } from 'react'

import { View } from '~/elements'

import { Header } from './Header'

type RnHeaderProps = HeaderOptions & {
    /**
     * Whether the header is in a modal
     */
    modal?: boolean
    /**
     * Layout of the screen.
     */
    layout?: Layout
    /**
     * Title text for the header.
     */
    title: string

}

type LayoutHeaderProps = RnHeaderProps & {

    tw?:string

    containerTw?:string
}
/**
 * We wrap React Navigation Header in a view so as to apply specific styles
 */
const LayoutHeader:FC<LayoutHeaderProps> = ({ tw, containerTw, ...props }) => (
  <View tw={containerTw}>
    <View tw={tw}>
      <Header
        {...props}
      />
    </View>
  </View>
)

/**
 * We wrap React Navigation Header in a view so as to apply specific styles
 */
export const MainLayoutHeader:FC<LayoutHeaderProps> = ({ tw, ...props }) => (<LayoutHeader
  tw={classNames('max-w-xl w-full px-4 md:px-8 self-start', tw)}
  containerTw="max-w-xl"
  {...props}
/>)
