import {
  getDocs, onSnapshot,
} from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { useFirestore } from 'reactfire'

import { GetEventsByAudiences, GetEventsByAudiences_allConferenceInstances, GetEventsByAudiences_allWorkshopInstances } from '~/__generated__/GetEventsByAudiences'
import { QueryDocumentSnapshot } from '~/definitions/firebase.firestore.types'
import { GroupMeetingSessionData, GroupMeetingSessionDataExtended, GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import { UserGroupMeetingSessionData } from '~/definitions/firestore.userGroupMeetingSession'
import {
  getGroupMeetingById, getGroupMeetingSessionsByIds, getUserGroupMeetingSessionsByRefs,
} from '~/queries/firestore.groupmeetings'
import { getGroupMeetingSessionsDoc } from '~/queries/firestore.helpers'
import { compareAsc } from '~/utils/dateFns/common.helpers'

import { useEventsByUid } from './dato.events' // acceptable to still call it Event because it is the name used on DatoCMS
import { useCurrentUserRef } from './firestore.users'

const buildExtendedSessions = (
  docEventSession: QueryDocumentSnapshot<GroupMeetingSessionData>,
  userGroupMeetingSessionsDocs: UserGroupMeetingSessionData[] = [],
): GroupMeetingSessionDataExtended => {

  const eventSessionData = docEventSession.data()
  const userGroupMeetingSession = userGroupMeetingSessionsDocs
    .find((userGroupMeetingSessionsDoc) => userGroupMeetingSessionsDoc.groupMeetingSessionRef.id === docEventSession.id)
  const isRegistered = !!userGroupMeetingSession

  const roomLink = isRegistered ? userGroupMeetingSession.roomLink : null

  return {
    parentId: docEventSession.ref.parent.parent?.id as string,
    id: docEventSession.id,
    ...eventSessionData,
    isRegistered,
    roomLink,
  }
}

type eventType = GetEventsByAudiences_allConferenceInstances | GetEventsByAudiences_allWorkshopInstances

export const useEventWithSessions = (event?: eventType) => {
  const [data, setData] = useState<GroupMeetingWithSessions | null>(null)
  const db = useFirestore()
  const userRef = useCurrentUserRef()

  useEffect(() => {
    const getEvent = async (eventModel: eventType) => {

      const eventId = eventModel.livestormEventId

      const query = getGroupMeetingById(
        {
          id: eventId,
        },
        {
          db,
          limit: 1,
        },
      )

      const eventDocs = await getDocs(query)

      if (!eventDocs.size) {
        return
      }

      const eventDoc = eventDocs.docs[0]

      const groupMeetingSessions = await getGroupMeetingSessionsDoc(eventDoc.id, db)

      if (groupMeetingSessions) {

        const { docs: groupMeetingSessionDocs } = groupMeetingSessions
        const groupMeetingSessionRefs = groupMeetingSessionDocs.map((doc) => doc.ref)
        const getUserGroupMeetingSessionsByRefsQuery = getUserGroupMeetingSessionsByRefs({
          userRef,
          groupMeetingSessionRefs,
        }, { db })

        onSnapshot(getUserGroupMeetingSessionsByRefsQuery, (querySnapshot) => {

          const userGroupMeetingSessionsDocs = querySnapshot.docs.map((userGroupMeetingSession) => userGroupMeetingSession.data())
          const sessions = groupMeetingSessionDocs
            .map((doc) => buildExtendedSessions(doc, userGroupMeetingSessionsDocs))
            .sort((sessionA, sessionB) => compareAsc(sessionA.startAt, sessionB.startAt))

          setData({
            ...eventModel,
            sessions,
          })
        })

      }

    }

    if (event) {
      getEvent(event)
    }
  }, [event])

  return {
    data,
  }

}

export const useEventsWithSessions = (userId: string) => {

  const [data, setData] = useState<GroupMeetingWithSessions[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const db = useFirestore()
  const userRef = useCurrentUserRef()
  const { data: datoEvents } = useEventsByUid(userId)

  useEffect(() => {
    const getEvents = async (datoEventsByAudiences: GetEventsByAudiences) => {

      const conferences = datoEventsByAudiences.allConferenceInstances || []
      const workshops = datoEventsByAudiences.allWorkshopInstances || []
      const mergedEvents = [...conferences, ...workshops]
      const eventsIds = mergedEvents.map((event) => event.livestormEventId as string)

      try {
        const query = getGroupMeetingSessionsByIds(
          {
            ids: eventsIds,
          },
          {
            db,
            limit: 50,
          },
        )

        const { docs: groupMeetingSessionDocs } = await getDocs(query)
        const groupMeetingSessionRefs = groupMeetingSessionDocs.map((doc) => doc.ref)
        const getUserGroupMeetingSessionsByRefsQuery = getUserGroupMeetingSessionsByRefs({
          userRef,
          groupMeetingSessionRefs,
        }, { db })

        onSnapshot(getUserGroupMeetingSessionsByRefsQuery, (querySnapshot) => {

          const userGroupMeetingSessionsDocs = querySnapshot.docs.map((userGroupMeetingSession) => userGroupMeetingSession.data())
          const eventSessions = groupMeetingSessionDocs
            .map((doc) => buildExtendedSessions(doc, userGroupMeetingSessionsDocs))

          const events = mergedEvents.reduce((reducedEvents, mergedEvent) => {

            const sessions = eventSessions.filter((eventSession) => eventSession.vendors.livestorm.eventId === mergedEvent.livestormEventId)

            return [
              ...reducedEvents,
              {
                ...mergedEvent,
                sessions,
              },
            ]

          }, [] as GroupMeetingWithSessions[])

          setData(events)
        })

      } catch (errorFromGetEvents) {
        setError(true)
      }
      setLoading(false)
    }

    if (datoEvents) {
      getEvents(datoEvents)
    }
  }, [datoEvents])

  return {
    loading,
    error,
    data,
  }
}
