import { useQuery } from '@apollo/client'

import { GetImageByIdQuery } from '~/__generated__/GetImageByIdQuery'
import { GetSvgByIdQuery } from '~/__generated__/GetSvgByIdQuery'
import { GET_IMAGE_BY_ID, GET_LOTTIE_BY_ID, GET_SVG_BY_ID } from '~/queries/dato.uploads'

type UseImageOptions = {
  width: number
  height: number
}

export const useDatoImage = (id: string, { width, height }: UseImageOptions) => useQuery<GetImageByIdQuery, {
  id: string
  imgWidth: number
  imgHeight: number
}>(GET_IMAGE_BY_ID, {
  variables: {
    id,
    imgWidth: width || 640,
    imgHeight: height || 480,
  },
})

export const useDatoLottie = (id: string) => useQuery<GetImageByIdQuery, { id: string }>(GET_LOTTIE_BY_ID, { variables: { id } })

export const useDatoSvg = (id: string) => useQuery<GetSvgByIdQuery, { id: string }>(GET_SVG_BY_ID, { variables: { id } })
