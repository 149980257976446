import { createDrawerNavigator, DrawerContentScrollView } from '@react-navigation/drawer'
import classNames from 'classnames'
import Color from 'color'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions } from 'react-native'
import { useTailwind } from 'tailwind-rn'

import { BrandLogo } from '~/components/application-shells/stacked/TwoRowNavWithOverlap/Header/Logo'
import DrawerItemList from '~/components/navigation/sidebar-navigation/DrawerItemList'
import { CustomHeaderTitle } from '~/elements/headers/CustomHeaderTitle'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { PreferencesDrawerScreen } from '~/screens/PreferencesScreen/PreferencesScreen'
import { getColor } from '~/theme/helpers'

// import { AvatarDrawerItem } from '../components/navigation/sidebar-navigation/AvatarDrawerItem'
import { DrawerNavigatorIcon } from '../navigators.shared/DrawerNavigatorIcon'
import { nonVisibleScreens, useMenuItems } from './menuItems'
import { MenuParamList } from './MenuParamList'

export const DrawerStack = createDrawerNavigator<MenuParamList>()

const drawerLargeWidth = 248
const drawerSmallWidth = 88

const CustomDrawerContent = (props) => {
  const { state, ...rest } = props
  const tailwind = useTailwind()
  const newState = { ...state }
  newState.routes = newState.routes.filter((item) => !nonVisibleScreens.includes(item.name))

  // const { onPress, ...props } = useLinkProps({ to, action })
  return (
    <DrawerContentScrollView {...props}
      contentContainerStyle={{ paddingTop: 0 }} // takes away its default paddingTop 4
    >
      {/* <AvatarDrawerItem navigation={navigation} /> */}
      <BrandLogo tw="mb-8" />
      <DrawerItemList
        state={newState}
        {...rest}
        itemStyle={tailwind('rounded-lg px-2 py-2 items-center lg:items-start')}
        labelStyle={tailwind('text-sm font-medium')}
        activeBackgroundColor={Color(getColor('brand-base')).alpha(0.20).rgb().string()}
        inactiveTintColor={getColor('gray-500')}
        activeTintColor={getColor('surface-1')}
      />
    </DrawerContentScrollView>
  )
}

export const DrawerNavigator = () => {
  // console.log('-> DrawerNavigator')
  const { lg } = useMediaQuery()
  const tailwind = useTailwind()
  const { t } = useTranslation(['navigation'])

  const { loading, data: menuItems } = useMenuItems()
  // console.log(`menuItems`, menuItems)

  const windowWidth = Dimensions.get('window').width

  const drawerWidth = lg ? drawerLargeWidth : drawerSmallWidth
  const maxWidthXLP = 40 * 16 // maxWidth with padding - see Tailwind config
  const marginOffset = (windowWidth - maxWidthXLP) / 2 - drawerWidth
  const drawerMarginLeft = lg ? marginOffset : 0
  const drawerMarginRight = lg ? 0 : marginOffset

  const DrawerContent = useCallback((props) => <CustomDrawerContent {...props} lg={lg} />, [lg])

  if (loading) {
    return null
  }

  // we add Setting item to the end of the list
  const items = [...menuItems, {
    key: 'settings',
    name: 'Preferences',
    component: PreferencesDrawerScreen,
    icon: {
      name: 'gear',
    },
  }]

  return (
    <DrawerStack.Navigator
      defaultStatus="open"
      useLegacyImplementation={false}
      screenOptions={{
        headerShown: false,
        lazy: true,
        drawerType: 'permanent',
        drawerStyle: {
          ...tailwind(classNames('border-r-0 bg-transparent py-20 px-6')),
          width: drawerWidth,
          marginLeft: drawerMarginLeft,
          marginRight: drawerMarginRight,
        },
      }}
      drawerContent={DrawerContent}
    >
      {items.map((item) => (
        <DrawerStack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={{
            title: t(`${item.key}.stack`).split('.').length > 1 ? undefined : t(`${item.key}.stack`),
            headerShown: false,
            drawerLabel: lg ? t(`menu.${item.key}`) : '',
            drawerIcon: (props) => <DrawerNavigatorIcon {...props} {...item.icon} />,
            // headerRight: NotificationsIcon,
            headerTitle: CustomHeaderTitle,
            headerLeft: () => null,
            headerStyle: {
              borderBottomWidth: 0,
              // backgroundColor: 'green',
              // maxWidth: 300,
            },
            ...item.options,
          }}
        />
      ))}
    </DrawerStack.Navigator>
  )
}
