import classNames from 'classnames'
import { FC } from 'react'

import { Text, View } from '~/elements'
import { ButtonProps } from '~/elements/buttons/Button.d'
import { IconProps } from '~/elements/icons/Icon/IconProps.d'

type SectionHeadingType = {
  eyebrow?: string
  title: string
  description?: string | null
  accessoryRight?: IconProps | ButtonProps
  tw?:string
  descriptionNumberOfLines?: number
}

const eyebrowTw = ''
const titleTw = 'font-sans font-medium text-xs leading-normal uppercase text-black mb-2'
const descriptionTw = 'text-sm leading-relaxed text-gray-700'

const baseContainerTw = 'mt-6'

export const SectionHeading: FC<SectionHeadingType> = ({
  tw, title, description, eyebrow, descriptionNumberOfLines = 0,
}) => (
  <View tw={classNames(baseContainerTw, tw)}>
    {eyebrow && <Text tw={eyebrowTw}>{eyebrow}</Text>}
    {title && <Text tw={titleTw}>{title}</Text>}
    {description && <Text tw={descriptionTw} numberOfLines={descriptionNumberOfLines}>{description}</Text>}
  </View>
)
