import { LinearGradient } from 'expo-linear-gradient'
import { FC, ReactNode } from 'react'

import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { bottomFaderHeight } from '~/constants/EvaluationPlayerContainer.constants'
import { ScrollView, View } from '~/elements'
import { getLinearGradientStartEndPoint } from '~/elements/containers/gradient.helpers'
import { getColor } from '~/theme/helpers'

import { EvaluationNavBar, NavProps } from './NavBar'

type ContentPageProps = NavProps & {
  resultCard: ReactNode,
  dimensionCard: ReactNode
}

export const EvaluationPlayerDimensionDesktopScreen: FC<ContentPageProps> = ({
  resultCard,
  dimensionCard,
  ...props
}) => {
  const { startPoint, endPoint } = getLinearGradientStartEndPoint('bg-gradient-to-t')
  const bgColor = 'orange-50'

  return (
    <MainContainer scrollEnabled={false} tw={'items-center justify-center'} >

      <View tw="max-w-6xl">
        {/* two columns, two side arrows, total 5 vertical grids, with given desktop screen height */}
        <View tw="flex-row h-128 xl:h-144 max-h-168">
          <View tw="w-1/12 justify-center"><EvaluationNavBar {...props} src="Idea" position="left" /></View>
          {/* left column */}
          <View tw="w-4/12 rounded-xl p-6 items-center bg-yellow-100 relative">
            {dimensionCard}
          </View>
          <View tw="w-1/12" />
          {/* right column */}
          <View tw="w-5/12">
            <ScrollView style={{ paddingBottom: bottomFaderHeight }}>
              {resultCard}
            </ScrollView>
            <LinearGradient
              colors={[getColor(bgColor), getColor(bgColor), 'rgba(255,255,255,0)']} // 'transparent' isnt rendered as white on Ssfari but black. See https://stackoverflow.com/questions/38391457/linear-gradient-to-transparent-bug-in-latest-safari
              style={{ height: bottomFaderHeight, width: '100%', marginTop: -bottomFaderHeight }}
              locations={[0, 0.05, 1]}
              start={startPoint}
              end={endPoint}
            />
          </View>
          <View tw="w-1/12 justify-center"><EvaluationNavBar {...props} src="Idea" position="right" /></View>
        </View>
      </View>

    </MainContainer>
  )
}
