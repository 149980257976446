import { FC } from 'react'

import { View } from '~/elements'

import { DoubleSvgImageType } from './DoubleSvgImageType'

/**
 * Background adjusts its width to the container component and defines its height via initial aspectRatio
 */
const PortraitBackground = ({ aspectW, aspectH, image }) => {
  if (!image) {
    return null
  }

  const horizontalMargin = (aspectH - aspectW) / 2 / aspectW
  const horizontalPosition = `-${Math.round(horizontalMargin * 100)}%`

  return (
    <View
      style={{
        aspectRatio: image.width / image.height,
        height: '100%',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: horizontalPosition,
        right: horizontalPosition,
      }}
    >
      <img
        alt={image.alt}
        width="100%"
        height="100%"
        draggable={false}
        src={image.url}
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
        }} />
    </View>
  )
}

/**
 * Front illustration adjusts its height to the container component and defines its width via initial aspectRatio
 */
const PortraitForeground = ({
  aspectW, aspectH, image, format, padding,
}) => {
  if (!image) {
    return null
  }

  const imgStyle = {}

  // usually center vertically unless specified by a portrait position and container aspect ratio accomodate that
  let justify = 'center'
  // ..for portrait images...
  if (image.width < image.height) {

    if (aspectH / aspectW < image.height / image.width) {
      // if we cannot accomodate the whole image, we keep center positionning but crop image at top/bottom

      const verticalMargin = (image.height - image.width) / image.height
      const factor = 1 - ((aspectH / aspectW - 1) / (image.height / image.width - 1))
      // console.log('factor', factor)
      const verticalPositionOfffset = `-${Math.round(factor * verticalMargin * 100)}%`
      // console.log('verticalPositionOfffset', verticalPositionOfffset)
      if (format.includes('-top')) {
        imgStyle.marginTop = verticalPositionOfffset
      } else {
        imgStyle.marginBottom = verticalPositionOfffset
      }

    } else if (format.endsWith('-top')) {
      // case we can accomodate, we directly position image where expected

      justify = 'flex-start'
    } else {
      justify = 'flex-end'
    }

  }

  return (
    <View
      style={{
        position: 'absolute',
        top: padding,
        bottom: padding,
        left: padding,
        right: padding,
        justifyContent: justify,
        alignItems: 'center',
      }}
    >
      <img
        alt={image.alt}
        width="100%"
        src={image.url}
        draggable={false}
        style={imgStyle}
      />
    </View>
  )
}

export const Portrait: FC<DoubleSvgImageType> = ({
  aspectW, aspectH, illustration, background, children, illustrationPadding, format,
}) => (
  <>
    {background && <PortraitBackground aspectW={aspectW} aspectH={aspectH} image={background}/>}
    <PortraitForeground image={illustration} aspectW={aspectW} aspectH={aspectH} padding={illustrationPadding} format={format}/>
    {children}
  </>
)

PortraitForeground.defaultProps = {
  padding: 0,
}
