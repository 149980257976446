import { ScrollView as RnScrollView, ScrollViewProps as RnScrollViewProps } from 'dripsy'
import { FC, forwardRef } from 'react'
import { useTailwind } from 'tailwind-rn'

export type ScrollViewProps = {
  tw?: string
  contentContainerTw?: string
} & RnScrollViewProps

/**
 *
 * See https://necolas.github.io/react-native-web/docs/activity-indicator/
 */

export const ScrollView: FC<ScrollViewProps> = forwardRef(({
  tw, contentContainerTw, style, contentContainerStyle, ...props
}, ref) => {
  const tailwind = useTailwind()

  return (
    <RnScrollView
      {...props}
      style={{ ...tailwind(tw), ...style }}
      contentContainerStyle={{ ...tailwind(contentContainerTw), ...contentContainerStyle }}
      showsVerticalScrollIndicator={false}
      ref={ref}
    />
  )
})
