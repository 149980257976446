import { useFormattedContentById } from '~/hooks/dato.content'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'

import { ContentPlayerDataWrapper } from '../ContentPlayerDataWrapper'
import { CheckpointLayout } from './CheckpointLayout'
import { IllustrationLayout } from './IllustrationLayout'
import { ScaleLayout } from './ScaleLayout'
import { SectionLayout } from './SectionLayout'
import { TextLayout } from './TextLayout'
import { ToolLayout } from './ToolLayout'

/**
 * TODO adapt StructuredText renderer for non web platfroms
 */
const ContentPlayerCoreScreen = ({ route }) => {
  const { contentId } = route.params
  // console.log(`-> CoverPage for ${contentId}`)
  const { loading, error, data } = useFormattedContentById(contentId)
  // console.log('Chapter', route)

  if (error) {
    return <ErrorScreen error={error} />
  }
  if (loading || !data) {
    return null
  }

  const { toc } = data

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...props } = toc[Number(route.params.pageNb) - 1]

  if (__typename === 'ContentSectionRecord') {
    return <ContentPlayerDataWrapper contentId={contentId}><SectionLayout {...props} /></ContentPlayerDataWrapper>
  }
  if (__typename === 'ContentCheckpointRecord') {
    return <ContentPlayerDataWrapper contentId={contentId}><CheckpointLayout {...props} /></ContentPlayerDataWrapper>
  }
  if (__typename === 'ContentScaleRecord') {
    return <ContentPlayerDataWrapper contentId={contentId}><ScaleLayout {...props} /></ContentPlayerDataWrapper>
  }
  if (__typename === 'ContentToolRecord') {
    return <ContentPlayerDataWrapper contentId={contentId}><ToolLayout {...props} /></ContentPlayerDataWrapper>
  }
  if (__typename === 'ContentIllustrationRecord') {
    return <ContentPlayerDataWrapper contentId={contentId}><IllustrationLayout {...props} /></ContentPlayerDataWrapper>
  }

  return <ContentPlayerDataWrapper contentId={contentId}><TextLayout {...props} /></ContentPlayerDataWrapper>
}

export default ContentPlayerCoreScreen
