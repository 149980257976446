import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Timestamp } from '~/definitions/firebase.firestore.types'
import { GroupMeetingSessionDataExtended, GroupMeetingWithSessions } from '~/definitions/firestore.groupMeetingSession'
import {
  Button,
  Icon,
  Text,
  View,
} from '~/elements'
import { Pressable } from '~/elements/containers/Pressable'
import { useDateFormat } from '~/hooks/dateFns'
import { useIsWithinInterval } from '~/hooks/time'
import { getColor } from '~/theme/helpers'
import {
  addMinutes, subMinutes,
} from '~/utils/dateFns/common.helpers'

import { EventCompleteInfo } from '../EventScreen/EventCompleteInfo'

library.add(faCircleCheck)

// Used by home's events section and events' incoming section
type EventTileInfoProps = {
  eventWithSessions: GroupMeetingWithSessions | null,
  navigateTo: string,
  withBadge: boolean,
}

export const EventTileInfo: FC<EventTileInfoProps> = ({
  eventWithSessions, navigateTo, withBadge = true,
}) => {

  const { i18n } = useTranslation()
  const { t } = useTranslation(['events', 'common'])

  const [event, setEvent] = useState<GroupMeetingWithSessions | null>(null)
  const [eventSession, setEventSession] = useState<GroupMeetingSessionDataExtended | null>(null)
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [eventDate, setEventDate] = useState<Timestamp | undefined>()
  const [tenMinutesBeforeDate, setTenMinutesBeforeDate] = useState<Date>(new Date())
  const [endOfSessionDate, setEndOfSessionDate] = useState<Date>(new Date())

  const duration = event?.model?.duration || eventWithSessions?.model?.duration
  const timeDistance = useDateFormat(eventDate, 'ago', i18n.language)

  useEffect(() => {
    if (eventWithSessions) {
      setEvent(eventWithSessions)

      const registeredSession = eventWithSessions.sessions.find((session) => session.isRegistered)
      const currentEventSession = registeredSession || eventWithSessions.sessions[0]

      if (registeredSession || eventWithSessions.sessions.length === 1) {
        setEventSession(currentEventSession) // considered session is the one user is registered to OR the only one
      } else {
        setEventSession(null)
      }

      if (eventWithSessions.sessions.length === 1 || !!registeredSession) {
        setEventDate(eventSession?.startAt)
      } else {
        setEventDate(undefined) // which will translate to date placeholder being set to multiple slots
      }

      if (registeredSession) {
        setIsRegistered(true)
      }
    }
  }, [eventWithSessions, event])

  useEffect(() => {
    if (eventDate) {
      setTenMinutesBeforeDate(subMinutes(eventDate, 10))
      setEndOfSessionDate(addMinutes(eventDate, duration))
    }
  }, [eventDate])

  const interval = { start: tenMinutesBeforeDate, end: endOfSessionDate }
  const canUserConnectToSession = useIsWithinInterval(new Date(), interval)
  // we did not implement the isPast logic here because we consider the tile would not even appear
  // unless we want it to disappear from the carousel when past even if user had tab open without reloading it (EDGE CASE)

  const cardModeStyle = {
    backgroundColor: event?.model?.illustration?.backgroundColor && getColor(event.model?.illustration?.backgroundColor),
  }

  const btnStyle = canUserConnectToSession ? '' : 'text-black text-xs uppercase tracking-widest'
  const btnVariant = canUserConnectToSession ? 'filled' : 'ghost'
  const textByTime = canUserConnectToSession ? t('events:cta.join') : `${t('events:cta.available')} ${timeDistance}`
  const btnText = isRegistered ? textByTime : t('common:cta.more')

  return (
    <Pressable
      to={navigateTo}
      tw='flex mb-4 max-w-xs bg-surface-1 border border-gray-100 px-8 py-4 mr-4 rounded-2xl h-full w-80 items-center'
      style={cardModeStyle}
    >
      {withBadge && isRegistered
        && <View tw='flex-row justify-center items-center z-10 absolute top-2 right-2 rounded-2xl px-2 py-1 bg-surface-1 bg-opacity-40'>
          <Icon name='circle-check' sizeNum={14} />
          <Text tw='ml-2 font-sans font-normal text-xs leading-relaxed text-gray-700'>
            {t('events:event.suscribed')}
          </Text>
        </View>}
      <EventCompleteInfo event={event} eventDate={eventDate} type='tile' />
      <View tw="w-full px-4 flex-col absolute bottom-4">
        <Button tw={btnStyle} color="basic" variant={btnVariant} title={btnText} />
        {/* naturally has the navigateTo effect of the whole Pressable, which is so far to always redirect to event page */}
      </View>
    </Pressable>
  )
}
