import { library } from '@fortawesome/fontawesome-svg-core'
import { faBullhorn, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { FC } from 'react'

import { useMediaQuery } from '~/hooks/mediaQuery'

import { View } from '../containers/View'
import { BannerCallToAction } from './BannerCallToAction'
import { BannerCloseButton } from './BannerCloseButton'
import { BannerContent } from './BannerContent'

library.add(faXmark, faBullhorn)

export type BannerProps = {
  content: string

  /**
   * Longer content isplayed only on larger screens
   */
  longContent?: string

  ctaLabel:string
  onPress?: () => void

  icon?: string

  visible: boolean

  onClose: () => void
}

/**
 * To be done
 *
 * Banner should have ability to be floating or stick
 * Hide automatically or onClose
 * Fade in on show
 * Add different status (cf buttons)
 * ...
 *
 * See
 * https://tailwindui.com/components/marketing/elements/banners
 */
export const Banner: FC<BannerProps> = ({
  content, longContent, icon, ctaLabel, onPress, visible, onClose,
}) => {

  const { sm } = useMediaQuery()

  if (!visible) {
    return null
  }

  return (
    <View tw="bg-brand-base items-center">
      <View tw="max-w-7xl w-full py-3 px-3 sm:px-6 lg:px-8">
        <View tw="flex items-center justify-between flex-wrap flex-row">

          <BannerContent content={content} longContent={longContent} icon={icon} />

          {sm ? (
            <>
              <BannerCallToAction ctaLabel={ctaLabel} onPress={onPress} />
              <BannerCloseButton onPress={onClose} />
            </>
          ) : (
            <>
              <BannerCloseButton onPress={onClose} />
              <BannerCallToAction ctaLabel={ctaLabel} onPress={onPress} />
            </>
          )}

        </View>
      </View>
    </View>
  )
}

Banner.defaultProps = {
  onPress: () => {
    // console.log('Banner CTA pressed')
  },
  ctaLabel: 'Learn more',
  longContent: 'Big news! We\'re excited to announce a brand new product.',
  content: 'We announced a new product!',
  icon: 'bullhorn',
  visible: true,
  onClose: () => {
    // console.log('Close requested')
  },
}
