/* eslint-disable import/no-duplicates */
import {
  Duration,
  format as formatDate, formatDistance, formatDuration,
  formatRelative,
} from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import fr from 'date-fns/locale/fr'
import { useTranslation } from 'react-i18next'

import { Timestamp } from '~/definitions/firebase.firestore.types'

import { useDurationToTimestamp } from './time'

const locales = { en: enGB, fr }

type DateFormatOptions = 'short' | 'medium' | 'long' | 'relative' | 'ago' | 'longWithTime' | 'viz' | 'time'

// TODO check if/why this is redoundant with format definition in i18n. Perhaps due to file systme handling in native platforms?

/**
 * Hook that wraps the dateFns formatter
 * @See https://date-fns.org/v2.26.0/docs/format
 */
export const useDateFormat = (value: Timestamp|undefined, format: DateFormatOptions, language: string): string => {

  if (!value) {
    return ''
  }

  const locale = locales[language]

  if (format === 'short') {
    // 04/29/1453
    return formatDate(value.toDate(), 'P', { locale })
  }
  if (format === 'medium') {
    // August 28th, 2022
    return formatDate(value.toDate(), 'PPP', { locale })
  }
  if (format === 'long') {
    // Friday, April 29th, 1453
    return formatDate(value.toDate(), 'PPPP', { locale })
  }
  if (format === 'longWithTime') {
    return formatDate(value.toDate(), 'PPPPp', { locale })
  }
  if (format === 'relative') {
    return formatRelative(value.toDate(), new Date(), { locale })
  }
  if (format === 'ago') {
    return formatDistance(value.toDate(), new Date(), {
      locale,
      addSuffix: true,
    })
  }

  if (format === 'viz') {
    return formatDate(value.toDate(), 'd MMM', { locale })
  }

  if (format === 'time') {
    return formatDate(value.toDate(), 'p', { locale })
  }

  return formatDate(value.toDate(), format, { locale })
}

const getIfHasTrueDuration = (duration:Duration):boolean => Object.entries(duration).map(([key, val]) => ({ key, val })).filter((o) => o.val > 0).length > 0

/**
 * Transforms an input given as Duration into a human readable string
 */
export const useDurationFormat = (durationObj:Duration, { format } = { format: ['minutes'] }):string => {
  // console.log('useDurationFormat', durationObj, format)
  const { i18n } = useTranslation()

  const hasTrueDuration = getIfHasTrueDuration(durationObj)
  // console.log('hasTrueDuration', hasTrueDuration)
  if (!hasTrueDuration) {
    return ''
  }

  const locale = locales[i18n.language]
  // console.log('locale', locale)

  return formatDuration(durationObj, { format, locale })

}

/**
 * Formatter to return duration between now and another timestamp
 */
export const useDurationLabel = (origin: Timestamp):string => {
  // automatically defines format based on first non null duration key
  const duration = useDurationToTimestamp(origin) || { weeks: 0 }

  const format = Object.entries(duration).map(([key, val]) => ({ key, val })).filter((o) => o.val > 0)[0]?.key

  return useDurationFormat(duration, { format: [format] })

}

/**
 * Formatter to return hours and minutes, date-fns' minutesToHours returning only the hour unit
 */
export const useMinutesToHours = (num: number) => {
  const hours = (num / 60)
  const restHours = Math.floor(hours)
  const minutes = (hours - restHours) * 60
  const restMinutes = Math.round(minutes)
  return { hours: restHours, minutes: restMinutes }
}
