import classNames from 'classnames'
// import times from 'lodash/times'
import { FC, memo } from 'react'

import { View } from '~/elements'

// import { AnimatedView } from '~/elements/containers/AnimatedView'
// import { Skeleton } from '~/elements/skeleton/Skeleton'
// import { getColor } from '~/theme/helpers'
import { channelPreviewContainerTw } from '../ChannelPreview/ChannelPreview'

// const transition = { opacity: { duration: 300 } }

/**
 * Skeleton has been commented as it wasn't gracefully displayed in channel list
 */
const UnMemoizedLoadingChannels: FC = () => (
  <View tw={classNames('flex-col', channelPreviewContainerTw)}
  // className='str-chat__loading-channels'
  >
    {/* {times(3, (index) => <AnimatedView
      key={`skeleton-${index}`}
      transition={{ type: 'timing' }}
      tw="flex-row mb-4"
      animate={{ backgroundColor: getColor('surface-1') }}
    >
      <Skeleton transition={transition} tw="h-12 w-12 mr-4 rounded-full" />
      <Skeleton transition={transition} tw="w-3/4"/>
    </AnimatedView>)} */}
  </View>
)

/**
 * Loading indicator for the ChannelList
 */
export const LoadingChannels = memo(
  UnMemoizedLoadingChannels,
) as typeof UnMemoizedLoadingChannels
