/* eslint-disable max-len */
import {
  collection, collectionGroup, doc, getDoc, getDocs,
} from 'firebase/firestore'

import { Collections } from '~/constants/firestore.collections'
import {
  CollectionReference, DocumentReference, Firestore, Query,
  QueryDocumentSnapshot,
} from '~/definitions/firebase.firestore.types'
import { ConversationData } from '~/definitions/firestore.conversation'
import { EvaluationData } from '~/definitions/firestore.evaluations'
import { GroupMeetingData } from '~/definitions/firestore.groupMeeting'
import { GroupMeetingSessionData } from '~/definitions/firestore.groupMeetingSession'
import { MeetingData } from '~/definitions/firestore.meeting'
import { UserData } from '~/definitions/firestore.user'
import { UserGroupMeetingSessionData } from '~/definitions/firestore.userGroupMeetingSession'
import { UserListData, UserListItemData } from '~/definitions/firestore.userListItem'
import { UserTrackData } from '~/definitions/firestore.userTrack'

/**
 * Type helpers
 * NOTE THAT TYPE CONVERSION CREATED RENDERING INFINIT LOOP
 */

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TypeConverter_DO_NOT_USE = <T>() => ({
  toFirestore: (data: Partial<T>) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot<T>) => snapshot.data() as T,
})

// const collectionWrapper = <T>(collectionPath: string, db: Firestore) => collection(db, collectionPath).withConverter(typeConverter_DO_NOT_USE<T>())
// const collectionGroupWrapper = <T>(collectionId: Collection, db: Firestore) => collectionGdb, roup(collectionId).withConverter(typeConverter_DO_NOT_USE<T>())

/**
 * Users
 */

export const getUserCol = (db: Firestore):CollectionReference<UserData> => collection(db, Collections.USERS) as CollectionReference<UserData>
export const getUserRef = (uid: string|undefined, db: Firestore) => (!uid ? null : doc(getUserCol(db), uid))
export const getUserDoc = (uid: string, db: Firestore) => getDoc(getUserRef(uid, db) as DocumentReference<UserData>)

/**
 * User tracks
 */

export const getUserTrackCol = (uid: string, db: Firestore):CollectionReference<UserTrackData> => collection(db, `${Collections.USERS}/${uid}/${Collections.TRACKS}`) as CollectionReference<UserTrackData>
export const getUserTrackRef = (uid: string|undefined, trackId: string|undefined, db: Firestore) => ((!uid || !trackId) ? null : doc(getUserTrackCol(uid, db), trackId))
export const getUserTrackDoc = (uid: string, trackId: string, db: Firestore) => getDoc(getUserTrackRef(uid, trackId, db) as DocumentReference<UserTrackData>)
export const getUserTrackColGroup = (db: Firestore) => collectionGroup(db, Collections.TRACKS) as Query<UserTrackData>

/**
 * User lists
 */
export const getUserListCol = (uid: string, db: Firestore):CollectionReference<UserListData> => collection(db, `${Collections.USERS}/${uid}/${Collections.LISTS}`) as CollectionReference<UserListData>
export const getUserListRef = (uid: string|undefined, listId: string|undefined, db: Firestore) => ((!uid || !listId) ? null : doc(getUserListCol(uid, db), listId))
export const getUserListDoc = (uid: string, listId: string, db: Firestore) => getDoc(getUserListRef(uid, listId, db) as DocumentReference<UserListData>)

/**
 * User list items
 */
export const getUserListItemCol = (uid: string, listId: string, db: Firestore):CollectionReference<UserListItemData> => collection(db, `${Collections.USERS}/${uid}/${Collections.LISTS}/${listId}/${Collections.ITEMS}`) as CollectionReference<UserListItemData>
export const getUserListItemRef = (uid: string|undefined, listId: string|undefined, itemId: string|undefined, db: Firestore) => ((!uid || !listId || !itemId) ? null : doc(getUserListItemCol(uid, listId, db), itemId))
export const getUserListItemDoc = (uid: string, listId: string, itemId: string, db: Firestore) => getDoc(getUserListItemRef(uid, listId, itemId, db) as DocumentReference<UserListItemData>)

/**
 * Customers
 */

/**
  * Customer campaigns
  */
export const getCustomerCampaignColGroup = (db: Firestore) => collectionGroup(db, Collections.CAMPAIGNS)

/**
 * Evaluations
 */

export const getEvaluationCol = (db: Firestore):CollectionReference<EvaluationData> => collection(db, Collections.EVALUATIONS) as CollectionReference<EvaluationData>
export const getEvaluationRef = (evaluationId: string|undefined, db: Firestore) => (!evaluationId ? null : doc(getEvaluationCol(db), evaluationId))
export const getEvaluationDoc = (evaluationId: string, db: Firestore) => getDoc(getEvaluationRef(evaluationId, db) as DocumentReference<EvaluationData>)

/**
 * Conversations
 */
export const getConversationCol = (db: Firestore):CollectionReference<ConversationData> => collection(db, Collections.CONVERSATIONS) as CollectionReference<ConversationData>
export const getConversationRef = (conversationId: string|undefined, db: Firestore) => (!conversationId ? null : doc(getConversationCol(db), conversationId))
export const getConversationDoc = (conversationId: string, db: Firestore) => getDoc(getConversationRef(conversationId, db) as DocumentReference<ConversationData>)

/**
 * Meetings
 */

export const getMeetingCol = (db: Firestore):CollectionReference<MeetingData> => collection(db, Collections.MEETINGS) as CollectionReference<MeetingData>
export const getMeetingRef = (meetingId: string|undefined, db: Firestore) => (!meetingId ? null : doc(getMeetingCol(db), meetingId))
export const getMeetingDoc = (meetingId: string, db: Firestore) => (!meetingId ? undefined : getDoc(getMeetingRef(meetingId, db) as DocumentReference<MeetingData>))

/**
 * Events
 */
export const getGroupMeetingCol = (db: Firestore):CollectionReference<GroupMeetingData> => collection(db, Collections.GROUP_MEETINGS) as CollectionReference<GroupMeetingData>
export const getGroupMeetingRef = (eventId: string|undefined, db: Firestore) => (!eventId ? null : doc(getGroupMeetingCol(db), eventId))
export const getGroupMeetingDoc = (eventId: string, db: Firestore) => (!eventId ? undefined : getDoc(getGroupMeetingRef(eventId, db) as DocumentReference<GroupMeetingData>))
export const getGroupMeetingSessionCol = (eventDocId: string, db: Firestore) => collection(db, Collections.GROUP_MEETINGS, eventDocId, Collections.GROUP_MEETINGS_SESSIONS) as CollectionReference<GroupMeetingSessionData>
export const getGroupMeetingSessionsDoc = (eventDocId: string|null, db: Firestore) => (!eventDocId ? undefined : getDocs<GroupMeetingSessionData>(getGroupMeetingSessionCol(eventDocId, db)))
export const getGroupMeetingSessionColGroup = (db: Firestore) => collectionGroup(db, Collections.GROUP_MEETINGS_SESSIONS) as Query<GroupMeetingSessionData>

/**
 * User group meeting session
 */
export const getUserGroupMeetingSessionCol = (db: Firestore) => collection(db, Collections.USER_GROUP_MEETINGS_SESSIONS) as CollectionReference<UserGroupMeetingSessionData>
