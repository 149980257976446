import cleanDeep from 'clean-deep'
import has from 'lodash/has'
import pick from 'lodash/pick'
import { createContext, useMemo } from 'react'
import { useUser as useFirebaseUser } from 'reactfire'

import { useAuthStateChanged, useUserRoles } from '~/hooks/auth'
import { useUserById } from '~/hooks/firestore.users'
import { useCurrentUserLatestTrackDoc } from '~/hooks/firestore.userTracks'

import {
  UserContextProps, UserContextType,
} from './UserContext.d'

export const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider = ({ children }: UserContextProps) => {
  // console.log('-> UserProvider')

  // console.log(`-> signInCheckResult`, signInCheckResult )

  /**
   * Currently Auth-enticated user
   */
  const { data: user } = useFirebaseUser()
  // console.log('Auth user', user)

  // register listener for auth state changes
  useAuthStateChanged()

  // allows to report roles as traits in Segment and custom property in Firebase Analytics
  useUserRoles()

  /**
   * Get Firestore data
   */
  const { loading: isFirestoreLoading, data: firestoreUser, error: firestoreUserError } = useUserById(user?.uid)
  const { loading: isFirestoreUserTrackLoading, data: userTrackDoc, error: firestoreUserTrackError } = useCurrentUserLatestTrackDoc(user?.uid)

  const result = useMemo(() => {
    // console.log('will re-aggregate user data')
    const isSignedIn = (): boolean => has(user, 'uid')
    // const update = (path, value) => set(user, path, value)

    return ({
      user: cleanDeep({
        ...pick(user, ['uid', 'displayName', 'email', 'phoneNumber', 'photoURL']),
        ...firestoreUser?.data(),
      }),
      userTrackDoc,
      loading: isFirestoreLoading || isFirestoreUserTrackLoading,
      isSignedIn,
      error: firestoreUserError || firestoreUserTrackError,
      // update,
    })
  }, [
    firestoreUser,
    userTrackDoc,
    isFirestoreLoading,
    isFirestoreUserTrackLoading,
    user,
    firestoreUserError,
    firestoreUserTrackError,
  ])

  if (!firestoreUser || !userTrackDoc) {
    return null
  }

  return <UserContext.Provider value={result}>{children}</UserContext.Provider>
}
