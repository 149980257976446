import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button, Image,
  Pressable, Text, View,
} from '~/elements'

type PersonalityTileProps = {
  to: string
  title: string | null
  excerpt: string | null
  illustration: any
  orientation: 'landscape' | 'portrait'
}

export const PersonalityTile: FC<PersonalityTileProps> = ({
  to,
  title,
  excerpt,
  illustration,
  orientation,
}) => {

  const pressableTw = ['bg-surface-1 rounded-xl p-6']
  let viewTw = ''
  const imageTw = ['w-24 sm:w-28']
  const titleTw = ['text-brand-base uppercase font-semibold']
  const excerptTw = ['font-medium font-serif text-sm sm:text-base leading-tight sm:leading-normal']
  let ctaTw = ''
  let numLines

  if (orientation === 'landscape') {
    pressableTw.push('min-w-xlp mb-4 relative overflow-hidden')
    imageTw.push('absolute -top-4 -right-4')
    viewTw = 'w-4/5'
    titleTw.push('mb-2')
    excerptTw.push('mb-4')
    ctaTw = 'w-min'
    numLines = 4
  } else if (orientation === 'portrait') {
    pressableTw.push('w-60 flex-col items-center mr-4')
    imageTw.push('my-4')
    viewTw = 'text-center'
    titleTw.push('mb-4 h-12')
    excerptTw.push('mb-4')
    numLines = 2
  }

  const { t } = useTranslation(['common', 'player'])

  return (
    <Pressable tw={pressableTw.join(' ')} to={to}>
      <Image {...illustration} tw={imageTw.join(' ')} />
      <View tw={viewTw}>
        <Text tw={titleTw.join(' ')} numberOfLines={2}>{title}</Text>
        <Text tw={excerptTw.join(' ')} numberOfLines={numLines}>{excerpt}</Text>
        <Button tw={ctaTw} size="l" title={t('common:cta.discover')} />
      </View>
    </Pressable>
  )
}
