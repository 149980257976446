import { gql } from '@apollo/client'

export const GET_SESSION_BY_SLUG = gql`
query GetSessionSlugQuery($slug: String, $locale: SiteLocale) {
    session(filter: {slug: {eq: $slug}}, locale: $locale) {
        slug
        title
        excerpt
        calendlySlug
        description
        duration
        
    }
  }`
