import { FC } from 'react'

import { Animation } from '../images/Animation'

// import { ActivityIndicator as RnActivityIndicator, ActivityIndicatorProps as RnActivityIndicatorProps } from 'dripsy'

export type ActivityIndicatorProps = {
  tw?:string
  size?: 'small'|'medium'|'large'
}
/**
 *
 * See https://necolas.github.io/react-native-web/docs/activity-indicator/
 */
export const ActivityIndicator: FC<ActivityIndicatorProps> = ({ size = 'medium', ...props }) => {

  let sizeTw = ''
  if (size === 'small') {
    sizeTw = 'w-8 h-8'
  }
  if (size === 'medium') {
    sizeTw = 'w-16 h-16'
  }
  if (size === 'large') {
    sizeTw = 'w-32 h-32'
  }

  return <Animation itemId="27665421" autoPlay loop {...props} tw={sizeTw}/>
}
