import { gql } from '@apollo/client'

// filter: {groupType: {eq: "category"}}
export const GET_CATEGORIES = gql`
query Categories($locale: SiteLocale) {
  allContentGroups(locale: $locale) {
    id
    slug
    title(locale: $locale)
    backgroundColor
    background {
      url
      format
      title
      alt
      height
      width
    }
    illustration {
      url
      format
      title
      alt
      height
      width
    }
  }
  allContents(first: "100", locale: $locale, filter: {title: {isBlank: false}}) {
    groups {
      id
    }
  }
}
`
