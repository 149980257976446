import { FC } from 'react'
import { Platform } from 'react-native'

import { Pressable, PressableProps } from '~/elements/containers/Pressable'

import { Icon as NativeIcon } from './Icon.native'
import { Icon as WebIcon } from './Icon.web'
import { IconProps } from './IconProps.d'

const Icon:FC<IconProps & Omit<PressableProps, 'children'>> = (props) => {
  if (!props.name) {
    console.warn('No icon name provided. Props:', props)

    return null
  }

  // console.log(`Platform.OS`, Platform.OS)

  // eslint-disable-next-line react/destructuring-assignment
  if (props.onPress) {
    // Wraps with a Pressable
    const { onPress, ...rest } = props

    return (
      <Pressable onPress={onPress}>
        <Icon {...rest} />
      </Pressable>
    )
  }

  // TODO "raised => add a circle"

  // TODO reverse => use inverse on font-awesome

  if (Platform.OS === 'web') {
    return <WebIcon {...props} />
  }

  return <NativeIcon {...props} />
}

export default Icon
