import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { SectionHeading } from '~/components/headings/SectionHeading'
import { CarouselSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { ScrollView } from '~/elements'
import { useEventsWithSessions } from '~/hooks/firestore.events'
import { EventTileInfo } from '~/screens/EventsScreen/EventTileInfo'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { selectIncomingRegisteredEvents } from '~/selectors/events'

type EventsSectionProps = {
  uid: string
}

export const EventsSection: FC<EventsSectionProps> = ({
  uid,
}) => {
  const { t } = useTranslation(['home'])

  const { data: events } = useEventsWithSessions(uid)

  const incomingRegisteredEvents = selectIncomingRegisteredEvents(events)

  if (!incomingRegisteredEvents) {
    return <LoadingScreen />
  }

  if (!incomingRegisteredEvents.length) {
    return null
  }

  return (
    <>
      <SectionHeading title={t('home:events.title')} tw="px-4 md:px-8" />
      <CarouselSectionContainer tw="pr-0 md:pr-8">
        <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-4 flex flex-row" >
          {incomingRegisteredEvents.map((event) => <EventTileInfo
            key={event?.id}
            eventWithSessions={event}
            navigateTo={`/events/${event.id}`}
            withBadge={false}
          />)}
        </ScrollView>
      </CarouselSectionContainer>
    </>
  )
}
