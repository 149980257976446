import AsyncStorage from '@react-native-async-storage/async-storage'
import { useRoute } from '@react-navigation/core'
import classNames from 'classnames'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { View } from '~/elements'
import { PressableFlag } from '~/elements/buttons/PressableFlagProps'
import { languageLocalStorageKey } from '~/i18n/language.detector'
import { supportedLanguages } from '~/i18n/languages'

const languageToFlagCodeMapping = {
  en: 'GBR',
  fr: 'FRA',
}

export const FlagLanguageSelector = ({ tw }) => {
  const { i18n } = useTranslation()

  const route = useRoute()

  const isLanguageActive = useCallback((language: string) => language === i18n.language, [i18n.language])

  const onChangeLanguage = useCallback((language: string) => {

    const onChangeLanguageFn = async () => {
      // console.log('onChangeLanguage to', language)
      if (!isLanguageActive(language)) {
        await AsyncStorage.setItem(languageLocalStorageKey, language)
        i18n.changeLanguage(language)
      }
    }

    onChangeLanguageFn()

    // purposly ignored i18n
  }, [isLanguageActive])

  // Eventually update language if provided by url
  useEffect(() => {
    const lngParam = route?.params?.lng
    if (lngParam) {
      if (lngParam !== isLanguageActive) {
        onChangeLanguage(lngParam)
      }
    }

  }, [route?.params?.lng, isLanguageActive, onChangeLanguage])

  //   console.log('i18n', i18n)

  return (
    <View tw={classNames('flex-row justify-center', tw)}>
      {supportedLanguages.map((language, index) => {
        // console.log('language', language)
        const flagCode = languageToFlagCodeMapping[language]
        const nextTw = classNames(tw, index > 0 ? 'ml-2' : '')

        const activeLanguage = isLanguageActive(language)
        // console.log('activeLanguage', activeLanguage)
        //   if (activeLanguage) {
        //     nextTw = classNames(nextTw, 'bg-gray-900 opacity-40')
        //   }

        return <PressableFlag
          key={language}
          testID={`language-option-${language}`}
          onPress={activeLanguage ? undefined : () => onChangeLanguage(language)}
          code={flagCode}
          size="medium"
          disabled={activeLanguage}
          tw={nextTw}
        />
      })}
    </View>
  )
}
