import { FC } from 'react'

import { ActivityIndicator, View } from '~/elements'

import { DelayedRender } from './DelayedRender'

export type LoadingScreenProps = {
  /**
   * time in ms before rendering component
   */
  delay?:number
  size?: 'small'|'medium'|'large'
}

/**
 * A fallback component while we "wait" for other things to happen. Basically a wrapper around a Spinner (aka ActivityIndicator).
 *
 * In case "things" happen fast enough, we dont need to display anything as human will preceive as almost instantaneous loading
 * On the contrary, a brief rendering of this component could cause confusion
 * Hence deferring rendering by 500ms (customizable)
 */
const LoadingScreen:FC<LoadingScreenProps> = ({ delay = 500, size = 'large' }) => ( // props so far never used by any parent component
  <DelayedRender delay={delay}>
    <View tw="flex h-full w-full content-center justify-center">
      <View tw="flex flex-col items-center">
        <ActivityIndicator size={size} />
        {/* <Text>Hold tight.</Text> */}
        {/* <Text>Just getting this page ready.</Text> */}
      </View>
    </View>
  </DelayedRender>
)

export default LoadingScreen
