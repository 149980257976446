import { useTranslation } from 'react-i18next'

import { useEvaluationContext } from '~/context/EvaluationContext'
import {
  Button, Image,
  Pressable, Text, View,
} from '~/elements'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { CollapsibleContent } from '../EvaluationPlayerScreen/CollapsibleContent'

export const UserPersonalityResultsSection = () => {
  const evaluationContext = useEvaluationContext()

  const { t } = useTranslation(['common', 'player'])

  if (evaluationContext.loading) {
    return <LoadingScreen />
  }

  if (!evaluationContext.data) {
    return null
  }

  const {
    data: {
      doc: { id },
      model: {
        scoreIntro, dimensionGroups, toc,
      },
    },
  } = evaluationContext

  return (
    <View tw="content-between ">
      <View tw="mb-6"><CollapsibleContent tw="text-xs sm:text-base leading-tight sm:leading-snug mt-0" numberOfLines={2}>{scoreIntro}</CollapsibleContent></View>
      {dimensionGroups.map((dimensionGroup, index) => {
        // console.log('dimensionGroup', dimensionGroup)
        const targetPageIndex = toc.findIndex((o) => o.key === dimensionGroup.key) + 1
        const to = `/evaluations/${id}/page/${targetPageIndex}`

        return (
          // PersonalityTile orientation "landscape" and with description
          <Pressable key={dimensionGroup.key} tw="bg-surface-1 relative rounded-xl p-6 mb-6" to={to}>
            <View tw="mr-12 sm:mr-32">
              <View tw="">
                <Text tw="text-brand-base uppercase font-semibold">{`${t('player:section')} ${index + 1}`} - {dimensionGroup.title}</Text>
                <Text tw="mt-2 font-medium font-serif text-sm sm:text-base leading-tight sm:leading-normal" numberOfLines={4}>{dimensionGroup.description}</Text>
              </View>
              <View tw="mt-4">
                <Button tw="w-min" title={t('common:cta.discover')} />
              </View>
            </View>
            <Image {...dimensionGroup.illustration} tw="w-16 sm:w-32 absolute top-0 right-0" />
          </Pressable>
        )
      })}
    </View>
  )
}
