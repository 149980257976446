import { StackScreenProps } from '@react-navigation/stack'
import { FC, useEffect } from 'react'
import {
  // Attachment,
  EmojiPicker,
  EventComponent,
  // MessageNotification,
  // TypingIndicator,
  useChatContext,
} from 'stream-chat-react'

// import { QuotedMessagePreview } from 'stream-chat-react/dist/components/MessageInput/QuotedMessagePreview'
import {
  Channel, LoadingIndicator, MessageInput, MessageList,
} from '~/components/chat'
import { CustomAttachment } from '~/components/chat/CustomAttachement'
import { DateSeparator } from '~/components/chat/DateSeparator/DateSeparator'
import { EmptyStateIndicator, EmptyStateIndicatorNotReady } from '~/components/chat/EmptyStateIndicator'
import { LoadingErrorIndicator } from '~/components/chat/Loading/LoadingErrorIndicator'
// import { PinIndicator } from '~/components/chat/Message/icons'
import { MessageDeleted } from '~/components/chat/Message/MessageDeleted'
// import { MessageOptions } from '~/components/chat/Message/MessageOptions'
// import { MessageRepliesCountButton } from '~/components/chat/Message/MessageRepliesCountButton'
import { MessageSimple } from '~/components/chat/Message/MessageSimple'
// import { MessageStatus } from '~/components/chat/Message/MessageStatus'
// import { MessageTimestamp } from '~/components/chat/Message/MessageTimestamp'
// import { QuotedMessage } from '~/components/chat/Message/QuotedMessage'
// import { EditMessageForm } from '~/components/chat/MessageInput/EditMessageForm'
import { MessageInputFlat, MessageInputFlatNotReady } from '~/components/chat/MessageInput/MessageInputFlat'
import { SendButton } from '~/components/chat/MessageInput/SendButton'
// import { ReactionSelector } from '~/components/chat/Reactions/ReactionSelector'
// import { ReactionsList } from '~/components/chat/Reactions/ReactionsList'
import { View } from '~/elements'
import { useConversationById } from '~/hooks/firestore.conversations'
import { MainStackParamList } from '~/navigators/MainStackParamList'

export type ChatConversationScreenProps = StackScreenProps<MainStackParamList, 'Conversation'>

/**
 * Settings for our messages.
 * @see https://getstream.io/chat/docs/sdk/react/message-components/message/#ui-customization
 * As of 2021Q4 we start by disabling most of the fancy features
 * to allow better portability to Native and simpler integration with Missive.
 */
const CustomMessage = (props) => <MessageSimple
  {...props}
  closeReactionSelectorOnClick={true}
  disableQuotedMessages={true}
  messageActions={[]} // One of ['edit', 'delete', 'flag', 'mute', 'pin', 'quote', 'react', 'reply']
/>

export const ChatConversationScreen:FC<ChatConversationScreenProps> = ({ route }) => {
  // Get conversation id from route params

  const { id } = route.params

  const { client, channel, setActiveChannel } = useChatContext()

  useEffect(() => {
    if (client) {
      const res = client.getChannelById('messaging', id, {})
      res.watch()
      setActiveChannel(res)
    }
  }, [channel, client, id, setActiveChannel])

  const { data: conversation } = useConversationById(id)

  if (!channel || !conversation) {
    return null
  }

  const isConversationReady = !!conversation.data()?.vendors?.missive?.conversationId

  return (
    <View
      testID="chat-container"
      tw="items-center h-full"
    >
      <Channel
        channel={channel}

        // Channel config
        acceptedFiles={[]}
        dragAndDropWindow={false}
        maxNumberOfFiles={0}
        multipleUploads={false}

        // Custom Message components
        Attachment={CustomAttachment}
        DateSeparator={DateSeparator}
        // EmojiIndex={NimbleEmojiIndex}
        EmojiPicker={EmojiPicker} // TODO custom  emoji-mart picker that works cross platforms
        EmptyStateIndicator={isConversationReady ? EmptyStateIndicator : EmptyStateIndicatorNotReady} // TODO
        LoadingErrorIndicator={LoadingErrorIndicator}
        LoadingIndicator={LoadingIndicator}
        Message={CustomMessage}
        MessageDeleted={MessageDeleted}
        MessageNotification={() => null} // ToDo
        MessageOptions={() => null}
        MessageRepliesCountButton={() => null}
        MessageStatus={() => null}
        MessageSystem={EventComponent} // TODO
        MessageTimestamp={() => null}
        PinIndicator={() => null}
        QuotedMessage={() => null}
        ReactionSelector={() => null}
        ReactionsList={() => null}
        TypingIndicator={() => null} // TODO

        // Custom MessageInput components
        EditMessageInput={() => null} // TODO
        SendButton={SendButton}
        QuotedMessagePreview={() => null} // TODO

      >
        <MessageList testID="message-list" />
        <MessageInput
          testID="message-input"
          Input={isConversationReady ? MessageInputFlat : MessageInputFlatNotReady}
        />
      </Channel>

    </View>
  )
}
