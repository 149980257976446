import { FC } from 'react'
import { Divider as RnDivider, DividerProps as RnDividerProps } from 'react-native-elements'

export type DividerProps = Record<string, unknown> & RnDividerProps

/**
 * See
 * https://reactnativeelements.com/docs/divider
 */
export const Divider: FC<DividerProps> = (props) => <RnDivider {...props} />
