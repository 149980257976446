import { FC, memo } from 'react'
import { useTranslationContext } from 'stream-chat-react'

import { Text } from '~/elements'

export type LoadingErrorIndicatorProps = {
  /** Error object */
  error?: Error;
};

/**
 * UI component for error indicator in a Channel
 */
const UnMemoizedLoadingErrorIndicator: FC<LoadingErrorIndicatorProps> = ({ error }) => {
  const { t } = useTranslationContext('LoadingErrorIndicator')

  if (!error) {
    return null
  }

  return <Text>{t('Error: {{ errorMessage }}', { errorMessage: error.message })}</Text>
}

export const LoadingErrorIndicator = memo(
  UnMemoizedLoadingErrorIndicator,
  (prevProps, nextProps) => prevProps.error?.message === nextProps.error?.message,
) as typeof UnMemoizedLoadingErrorIndicator
