import { FC } from 'react'
import { Controller, UseControllerProps } from 'react-hook-form'

import { MultipleChoiceSelector, MultipleChoiceSelectorProps } from './MultipleChoiceSelector'

type LibProps = Pick<UseControllerProps, 'name' | 'rules'>

type MultipleChoiceFieldProps = LibProps & Omit<MultipleChoiceSelectorProps, 'onChange' | 'values'>

/**
 * Wrapper around MultipleChoiceSelector in the context of a React Hook Form
 */
export const MultipleChoiceField:FC<MultipleChoiceFieldProps> = ({
  name, rules, options, multipleSelect, randomize, color,
}) => <Controller
  rules={rules}
  render={({ field: { onChange, value } }) => (
    <MultipleChoiceSelector
      onChange={onChange}
      values={value}
      options={options}
      multipleSelect={multipleSelect}
      randomize={randomize}
      color={color}
    />
  )}
  name={name}
/>
