/**
 * Quite a strong refacto here as we've taken bits from a hook to directly provide elements to a FlatList
 * Note that it seems the RN version ship with a sllighlty optimised version
 */

import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ChannelActionContextValue,
  ChannelStateContextValue,
  defaultPinPermissions, EmptyStateIndicator as DefaultEmptyStateIndicator,
  EventComponent,
  // InfiniteScroll,
  InfiniteScrollProps,
  isDate,
  Message,
  // LoadingIndicator as DefaultLoadingIndicator,
  MESSAGE_ACTIONS,
  MessageNotification as DefaultMessageNotification,
  MessageProps, StreamMessage,
  // TypingIndicator as DefaultTypingIndicator,
  // useCallLoadMore,
  useChannelActionContext,
  useChannelStateContext,
  useChatContext,
  useComponentContext,
  useEnrichedMessages, useLastReadData, useScrollLocationLogic,
} from 'stream-chat-react'
// import { Center } from 'stream-chat-react/dist/components/MessageList/Center'
import { MessageListNotifications as DefaultMessageListNotifications } from 'stream-chat-react/dist/components/MessageList/MessageListNotifications'
import { getLastReceived } from 'stream-chat-react/dist/components/MessageList/utils'
import type {
  DefaultAttachmentType,
  DefaultChannelType,
  DefaultCommandType,
  DefaultEventType,
  DefaultMessageType,
  DefaultReactionType,
  DefaultUserType,
} from 'stream-chat-react/dist/types/types'

import { FlatList, Text } from '~/elements'

import { DateSeparator as DefaultDateSeparator } from '../DateSeparator/DateSeparator'

type MessageListWithContextProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = Omit<ChannelStateContextValue<At, Ch, Co, Ev, Me, Re, Us>, 'members' | 'mutes' | 'watchers'> &
  MessageListProps<At, Ch, Co, Ev, Me, Re, Us>;

const MessageListWithContext = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: MessageListWithContextProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {

  const { t } = useTranslation(['chat'])

  const {
    channel,
    disableDateSeparator = false,
    hideDeletedMessages = false,
    hideNewMessageSeparator = false,
    messageActions = Object.keys(MESSAGE_ACTIONS),
    messages = [],
    notifications,
    noGroupByUser = false,
    pinPermissions = defaultPinPermissions, // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
    returnAllReadData = false,
    threadList = false,
    unsafeHTML = false,
    headerPosition,
    read,
  } = props

  const scrollRef = useRef()

  // const { customClasses } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('MessageList')

  const {
    EmptyStateIndicator = DefaultEmptyStateIndicator,
    MessageListNotifications = DefaultMessageListNotifications,
    MessageNotification = DefaultMessageNotification,
    // TypingIndicator = DefaultTypingIndicator,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('MessageList')

  const { hasNewMessages, scrollToBottom, wrapperRect } = useScrollLocationLogic({
    messages,
    scrolledUpThreshold: props.scrolledUpThreshold,
  })

  const { client } = useChatContext<At, Ch, Co, Ev, Me, Re, Us>('useMessageListElements')

  const { messages: enrichedMessages } = useEnrichedMessages({
    channel,
    disableDateSeparator,
    headerPosition,
    hideDeletedMessages,
    hideNewMessageSeparator,
    messages,
    noGroupByUser,
    threadList,
  })

  const internalMessageProps = {
    additionalMessageInputProps: props.additionalMessageInputProps,
    closeReactionSelectorOnClick: props.closeReactionSelectorOnClick,
    customMessageActions: props.customMessageActions,
    disableQuotedMessages: props.disableQuotedMessages,
    formatDate: props.formatDate,
    getDeleteMessageErrorNotification: props.getDeleteMessageErrorNotification,
    getFlagMessageErrorNotification: props.getFlagMessageErrorNotification,
    getFlagMessageSuccessNotification: props.getFlagMessageSuccessNotification,
    getMuteUserErrorNotification: props.getMuteUserErrorNotification,
    getMuteUserSuccessNotification: props.getMuteUserSuccessNotification,
    getPinMessageErrorNotification: props.getPinMessageErrorNotification,
    Message: props.Message,
    messageActions,
    messageListRect: wrapperRect,
    onlySenderCanEdit: props.onlySenderCanEdit,
    onMentionsClick: props.onMentionsClick,
    onMentionsHover: props.onMentionsHover,
    onUserClick: props.onUserClick,
    onUserHover: props.onUserHover,
    openThread: props.openThread,
    pinPermissions,
    renderText: props.renderText,
    retrySendMessage: props.retrySendMessage,
    unsafeHTML,
  }

  const {
    DateSeparator = DefaultDateSeparator,
    HeaderComponent,
    MessageSystem = EventComponent,
  } = useComponentContext<At, Ch, Co, Ev, Me, Re, Us>('useMessageListElements')

  const lastReceivedId = useMemo(() => getLastReceived(enrichedMessages), [enrichedMessages])

  const readData = useLastReadData({
    messages: enrichedMessages,
    read,
    returnAllReadData,
    userID: client.userID,
  })

  // Scroll to end on load
  useEffect(() => {
    scrollRef?.current?.scrollToEnd()
  }, [])

  const getItemKey = (message) => {
    // console.log('renderItem', message)
    if (message.customType === 'message.date' && message.date && isDate(message.date)) {
      return `${message.date.toISOString()}-i`
    }

    if (message.customType === 'channel.intro' && HeaderComponent) {
      return 'intro'
    }

    if (message.type === 'system') {
      return (message.event as { created_at: string })?.created_at || (message.created_at as string) || ''
    }

    return message.id || (message.created_at as string)

  }

  const renderItem = ({ item: message }) => {
    // console.log('renderItem', message)
    if (message.customType === 'message.date' && message.date && isDate(message.date)) {
      return (
        <DateSeparator
          testID={`${message.date.toISOString()}-i`}
          date={message.date}
          formatDate={internalMessageProps.formatDate}
          unread={message.unread}
        />
      )
    }

    if (message.customType === 'channel.intro' && HeaderComponent) {
      return (
        <HeaderComponent />
      )
    }

    if (message.type === 'system') {
      return (
        <MessageSystem
          testID={(message.event as { created_at: string })?.created_at || (message.created_at as string) || ''}
          message={message} />
      )
    }

    return (
      <Message
        {...internalMessageProps}
        testID={message.id || (message.created_at as string)}
        lastReceivedId={lastReceivedId}
        message={message}
        readBy={readData[message.id] || []}
        threadList={threadList}
      />
    )

  }

  return (
    <FlatList
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
      ListEmptyComponent={() => <EmptyStateIndicator listType='message' />}
      ListHeaderComponent={() => <Text tw="text-center text-gray-500 text-xs my-4">{t('chat:conversation.welcomeMessage')}</Text>}
      data={enrichedMessages}
      keyExtractor={ getItemKey }
      renderItem={renderItem}
      tw="h-full max-w-xl w-full px-4 pb-12"
      onContentSizeChange={() => {
        scrollRef.current?.scrollToEnd({ animated: true })
      }}
      ListFooterComponent={() => <MessageListNotifications
        hasNewMessages={hasNewMessages}
        MessageNotification={MessageNotification}
        notifications={notifications}
        scrollToBottom={scrollToBottom}
      />}
    >
      {/* <ul className='str-chat__ul'>{elements}</ul> */}
      {/* <TypingIndicator threadList={threadList} /> */}

    </FlatList>

  )
}

type PropsDrilledToMessage =
  | 'additionalMessageInputProps'
  | 'closeReactionSelectorOnClick'
  | 'customMessageActions'
  | 'disableQuotedMessages'
  | 'formatDate'
  | 'getDeleteMessageErrorNotification'
  | 'getFlagMessageErrorNotification'
  | 'getFlagMessageSuccessNotification'
  | 'getMuteUserErrorNotification'
  | 'getMuteUserSuccessNotification'
  | 'getPinMessageErrorNotification'
  | 'Message'
  | 'messageActions'
  | 'onlySenderCanEdit'
  | 'onMentionsClick'
  | 'onMentionsHover'
  | 'onUserClick'
  | 'onUserHover'
  | 'openThread'
  | 'pinPermissions' // @deprecated in favor of `channelCapabilities` - TODO: remove in next major release
  | 'renderText'
  | 'retrySendMessage'
  | 'unsafeHTML';

export type MessageListProps<
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
> = Partial<Pick<MessageProps<At, Ch, Co, Ev, Me, Re, Us>, PropsDrilledToMessage>> & {
  /** Disables the injection of date separator components, defaults to `false` */
  disableDateSeparator?: boolean;
  /** Whether or not the list has more items to load */
  hasMore?: boolean;
  /** Position to render HeaderComponent */
  headerPosition?: number;
  /** Hides the MessageDeleted components from the list, defaults to `false` */
  hideDeletedMessages?: boolean;
  /** Hides the DateSeparator component when new messages are received in a channel that's watched but not active, defaults to false */
  hideNewMessageSeparator?: boolean;
  /** Overrides the default props passed to [InfiniteScroll](https://github.com/GetStream/stream-chat-react/blob/master/src/components/InfiniteScrollPaginator/InfiniteScroll.tsx) */
  internalInfiniteScrollProps?: InfiniteScrollProps;
  /** Whether or not the list is currently loading more items */
  loadingMore?: boolean;
  /** Function called when more messages are to be loaded, defaults to function stored in [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/) */
  loadMore?: ChannelActionContextValue['loadMore'] | (() => Promise<void>);
  /** The limit to use when paginating messages */
  messageLimit?: number;
  /** The messages to render in the list, defaults to messages stored in [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/) */
  messages?: StreamMessage<At, Ch, Co, Ev, Me, Re, Us>[];
  /** If true, turns off message UI grouping by user */
  noGroupByUser?: boolean;
  /** If true, `readBy` data supplied to the `Message` components will include all user read states per sent message */
  returnAllReadData?: boolean;
  /** The pixel threshold to determine whether or not the user is scrolled up in the list, defaults to 200px */
  scrolledUpThreshold?: number;
  /** If true, indicates the message list is a thread  */
  threadList?: boolean;
};

/**
 * The MessageList component renders a list of Messages.
 * It is a consumer of the following contexts:
 * - [ChannelStateContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_state_context/)
 * - [ChannelActionContext](https://getstream.io/chat/docs/sdk/react/contexts/channel_action_context/)
 * - [ComponentContext](https://getstream.io/chat/docs/sdk/react/contexts/component_context/)
 * - [TypingContext](https://getstream.io/chat/docs/sdk/react/contexts/typing_context/)
 */
export const MessageList = <
  At extends DefaultAttachmentType = DefaultAttachmentType,
  Ch extends DefaultChannelType = DefaultChannelType,
  Co extends DefaultCommandType = DefaultCommandType,
  Ev extends DefaultEventType = DefaultEventType,
  Me extends DefaultMessageType = DefaultMessageType,
  Re extends DefaultReactionType = DefaultReactionType,
  Us extends DefaultUserType<Us> = DefaultUserType
>(
    props: MessageListProps<At, Ch, Co, Ev, Me, Re, Us>,
  ) => {
  const { loadMore } = useChannelActionContext<At, Ch, Co, Ev, Me, Re, Us>('MessageList')

  const {
    members: membersPropToNotPass, // eslint-disable-line @typescript-eslint/no-unused-vars
    mutes: mutesPropToNotPass, // eslint-disable-line @typescript-eslint/no-unused-vars
    watchers: watchersPropToNotPass, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...restChannelStateContext
  } = useChannelStateContext<At, Ch, Co, Ev, Me, Re, Us>('MessageList')

  return (
    <MessageListWithContext<At, Ch, Co, Ev, Me, Re, Us>
      loadMore={loadMore}
      {...restChannelStateContext}
      {...props}
    />
  )
}
