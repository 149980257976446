export const sections = [
  {
    key: 'recommended-content',
    recommendations: {
      scenario: 'Home_ForYou',
      count: 3,
      options: {
        filter: undefined,
        booster: undefined,
      },
    },

    tileSettings: {
      size: 'medium',
    },
  },
  // {
  //   key: 'favorite-tools',
  //   title: 'Your toolbox',
  //   description: 'Tools you\'ve favorited',
  //   recommendations: {
  //     scenario: 'Favorites',
  //     count: 3,
  //     options: {
  //       filter: undefined,
  //       booster: undefined,
  //     },
  //   },
  //   tileSettings: {
  //     orientation: 'landscape',
  //   },
  // },
]
