import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CtaCard } from '~/components/panels/cards/CtaCard'

type PersonalityTestCtaProps = Record<string, never>

export const PersonalityTestCta:FC<PersonalityTestCtaProps> = () => {
  const { t } = useTranslation(['home'])

  const url = '/evaluations'
  const title = t('home:onboarding_steps.personality-evaluation.todo.cta')
  const description = t('home:onboarding_steps.personality-evaluation.description')

  return (
    <CtaCard url={url} title={title} description={description}/>
  )
}
