import upperFirst from 'lodash/upperFirst'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ChannelList } from '~/components/chat/ChannelList/ChannelList'
import { ChannelListMessenger } from '~/components/chat/ChannelList/ChannelListMessenger'
import { ChannelPreviewMessenger } from '~/components/chat/ChannelPreview/ChannelPreview'
import { Text, View } from '~/elements'
import { useGenericChannels } from '~/hooks/stream.channels'
import { useUser } from '~/hooks/user.context'

import LoadingScreen from '../LoadingScreen/LoadingScreen'

type ConversationListSectionProps = {
  status: 'active' | 'inactive'
  tw?: string
}

export const ConversationListSection:FC<ConversationListSectionProps> = ({ tw, status }) => {
  const { t } = useTranslation(['chat'])
  const {
    isLoading: isLoadingGenericChannels,
    channels,
  } = useGenericChannels(status)

  const { user } = useUser()

  if (isLoadingGenericChannels) {
    return <LoadingScreen />
  }

  if (channels.length === 0) {
    return null
  }

  const filters = { members: { $in: [user.uid] }, status }
  const options = { limit: 10 }

  const nbOngoingConversations = channels.length
  const i18nKey = nbOngoingConversations > 1 ? `chat:main.status${upperFirst(status)}_other` : `chat:main.status${upperFirst(status)}_one`

  return (
    <View tw={tw}>
      <Text tw="uppercase font-semibold font-sans text-xs text-basic-base py-2">{t(i18nKey, { nbOngoingConversations })}</Text>
      <ChannelList
        filters={filters}
        options={options}
        List={ChannelListMessenger}
        Preview={ChannelPreviewMessenger} />
    </View>
  )
}
