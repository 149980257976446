import { useQuery } from '@apollo/client'

import { GetMinderByCalendlyIdQuery } from '~/__generated__/GetMinderByCalendlyIdQuery'
import { GetMinderBySlugQuery } from '~/__generated__/GetMinderBySlugQuery'
import { GET_MINDER_BY_CALENDLY_ID, GET_MINDER_BY_SLUG } from '~/queries/dato.minders'

export const useMinderBySlug = (slug: string) => useQuery<GetMinderBySlugQuery, { slug: string }>(GET_MINDER_BY_SLUG, {
  variables: { slug },
})

export const useMinderByCalendlyId = (calendlyId: string) => useQuery<GetMinderByCalendlyIdQuery, { calendlyId: string }>(GET_MINDER_BY_CALENDLY_ID, {
  variables: { calendlyId },
})
