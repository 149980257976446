import {
  TextStyle, ViewStyle,
} from 'dripsy'
import pick from 'lodash/pick'
import { FC } from 'react'

import { ContentTile } from '~/components/panels/tiles/ContentTile'
import { TileOrientation, TileSize } from '~/components/panels/tiles/ContentTileProps.d'
import { LargeTileItem } from '~/components/panels/tiles/LargeTileItem'
import { LargeTileItemPlaceholder } from '~/components/panels/tiles/LargeTileItemPlaceholder'
import { View } from '~/elements'
import { ListItemProps } from '~/elements/list/ListItem.d'

import { ScrollView } from '../containers/ScrollView'

export type TileSettingType = {
  size ?: TileSize
  orientation ?: TileOrientation
}

type ContentListProps = {
  data: Array<ListItemProps>
  listItemStyle?: ViewStyle
  listItemTitleStyle?: TextStyle
  tileSettings:TileSettingType
}

const Item = ({ id, ...item }) => {
  if (id) {
    return <LargeTileItem key={id} {...item} />
  }

  return <LargeTileItemPlaceholder key={id} {...item} />
}

/**
 * A list that isnt as smart as a flat list
 */
export const TileList: FC<ContentListProps> = ({ data, tileSettings }) => {
  // console.log('TileList', data)

  // Add navigation property to list items
  const items = data.map((item, index) => {
    let navigateTo = item ? `/content/${item.id}` : undefined
    if (item?.recommId) {
      navigateTo += `?recommId=${item.recommId}`
    }

    return ({
      ...pick(item, ['id', 'title', 'metadata', 'description', 'image']),
      navigateTo,
      isFirst: index === 0,
      isLast: index === (data.length - 1),
    })
  })

  // console.log(`items`, items)

  if (tileSettings.size === 'large') {
    return (
      <View tw="w-full mt-4 flex flex-col">
        <ContentTile {...items[0]} size="large" />
      </View>
    )
  } if (tileSettings.size === 'medium') {
    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="mt-4 flex flex-row -ml-8 mr-0 px-8 pb-8">
        {items.map((item, index) => <ContentTile key={item?.id || `placeholder-${index}`} {...item} size="medium" />)}
      </ScrollView>
    )
  } if (tileSettings.size === 'small') {
    return (
      <ScrollView horizontal showsHorizontalScrollIndicator={false} tw="w-full mt-4 pb-4 pt-8 flex-row bg-surface-1 rounded-2xl">
        {items.map((item, index) => <ContentTile key={item?.id || `placeholder-${index}`} {...item} size="small" />)}
      </ScrollView>
    )
  } if (tileSettings.orientation === 'landscape') {
    return (
      <View tw="w-full mt-4 flex flex-col px-0">
        {items.map((item, index) => <ContentTile key={item?.id || `placeholder-${index}`} {...item} orientation="landscape" />)}
      </View>
    )
  }

  return (
    <View tw="w-full mt-1 flex flex-row -mx-2">
      {items.map((item, index) => <Item key={item?.id || `placeholder-${index}`} {...item} />)}
    </View>
  )
}
