export const routesConfig = {
  screens: {
    Auth: {
      path: '',
      screens: {
        Onboarding: {
          path: 'onboarding',
          screens: {
            Main: {
              path: '',
            },
            Welcome: {
              path: 'welcome',
            },
            RegistrationForm: {
              path: 'details',
            },
            RegistrationConfirmed: {
              path: 'complete',
            },
          },
        },
        Menu: {
          path: '',
          screens: {
            HomeStack: {
              path: '',
              screens: {
                Dashboard: {
                  path: '',
                },
              },
            },
            LibraryStack: {
              path: 'library',
              screens: {
                Explore: {
                  path: '',
                },
                Category: {
                  path: 'category/:id-slug',
                  exact: true,
                },
              },
            },
            ChatStack: {
              path: 'conversations',
              screens: {
                Main: {
                  path: '',
                },
                NewConversation: {
                  path: 'new',
                },
              },
            },
            EventsStack: {
              path: 'events',
              screens: {
                Events: {
                  path: '',
                },
                Event: {
                  path: ':id',
                },
              },
            },
            MyTrackStack: {
              path: 'track',
              screens: {
                MyTrackNotAllowed: {
                  path: 'forbidden',
                },
                MyTrack: {
                  path: '',
                },
                Scheduling: {
                  path: 'sessions/:type/new',
                  exact: true,
                },
                Session: {
                  path: 'sessions/:meetingId',
                  exact: true,
                },
                Rescheduling: {
                  path: 'sessions/:meetingId/:action',
                  exact: true,
                },
                Minder: {
                  path: 'minders/:slug',
                  exact: true,
                },
              },
            },
            MeStack: {
              path: 'me',
              screens: {
                Evaluations: {
                  path: 'tests',
                },
                LaterContentList: {
                  path: 'content/lists/later',
                },
                FavoritesContentList: {
                  path: 'content/lists/favorites',
                },
                HistoryContentList: {
                  path: 'content/lists/history',
                },
                ToolboxContentList: {
                  path: 'content/lists/toolbox',
                },
              },
            },
          },
        },
        AllEvaluations: {
          path: 'evaluations',
          screens: {
            MyEvaluations: {
              path: '',
            },
            PersonalityPlayer: {
              path: '/:evaluationId',
              screens: {
                EvaluationPlayerCarousel: 'carousel',
                EvaluationPlayerPage: {
                  path: 'page/:pageNb',
                },
                EvaluationPlayerOutro: 'outro',
              },
            },
            NewTypeform: {
              path: ':slug/new',
            },
            NewTypeformComplete: {
              path: ':slug/complete',
            },
          },
        },
        ContentPlayer: {
          screens: {
            Cover: {
              path: 'content/:contentId',
              exact: true,
            },
            Chapter: {
              path: 'content/:contentId/page/:pageNb',
              exact: true,
            },
            Outro: {
              path: 'content/:contentId/outro',
              exact: true,
            },
          },
        },
        Conversation: {
          path: 'conversations/:id',
        },
        Dev: {
          path: 'dev',
        },
        Preferences: {
          path: 'preferences',
          exact: true,
        },
        Notifications: {
          path: 'notifications',
          exact: true,
        },
        NotFound: '*',
      },
    },
    NonAuth: {
      path: 'auth',
      screens: {
        SignUp: {
          path: 'register',
        },
        SignIn: {
          path: 'signin',
        },
        MagicLinkAwait: {
          path: 'link',
        },
        MagicLinkReturn: {
          path: 'callback',
        },
        NotAllowed: {
          path: 'forbidden',
        },
        ResetPassword: {
          path: 'reset',
        },
      },
    },
  },
}
