import { FC, ReactNode } from 'react'
import { SvgUri } from 'react-native-svg'
import { useTailwind } from 'tailwind-rn'

import { View } from '~/elements/containers/View'

type SvgImageProps = {
  url: string
  children: ReactNode
}

export const ImageSvgNative:FC<SvgImageProps> = ({ url, children, ...props }) => {
  const tailwind = useTailwind()
  return (
    <View {...props} style={tailwind('bg-red-500 w-full h-full')}>
      <SvgUri
        width="100%"
        height="100%"
        uri={url}
      >
        {children}
      </SvgUri>
    </View>
  )
}
