import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowRightLong } from '@fortawesome/pro-regular-svg-icons'
import { FC } from 'react'

import {
  Icon, Text, View,
} from '~/elements'
import { PressableSvg } from '~/elements/containers/PressableSvg'

library.add(faArrowRightLong)

type CtaProps = {
  url: string
  title: string
  description: string
}

export const cardBrandShadowStyle = {
  shadowColor: '#ffc09c',
  shadowOffset: { width: 0, height: 16 },
  shadowOpacity: 0.3,
  shadowRadius: 32,
  elevation: 32,
}

export const CtaCard: FC<CtaProps> = ({ url, title, description }) => (
  <PressableSvg
    assetId="27590811"
    tw="relative mt-4 p-4 font-medium flex-row bg-yellow-100 rounded-2xl"
    assetTw="rounded-2xl"
    to={url}
    style={cardBrandShadowStyle}
  >
    <View tw="flex-grow flex-shrink">
      <Text tw="font-serif text-lg font-medium text-gray-900">{title}</Text>
      <Text tw="text-gray-700">{description}</Text>
    </View>
    <View tw="w-6 h-6 justify-center items-center">
      <Icon name="arrow-right-long" variant="regular" />
    </View>
  </PressableSvg>
)
