import { forwardRef, ReactNode } from 'react'
import RnSwiper, { SwiperProps as RnSwiperProps } from 'react-native-web-swiper'
import { useTailwind } from 'tailwind-rn'

export type SwiperProps = {
  /**
     * Container style classes (as tailwind string)
     */
  containerStyle?:string

  innerContainerStyle?:string

  swipeAreaStyle?:string

  slideWrapperStyle?:string

  dotActiveStyle ?:string

  children: ReactNode

} & Omit<RnSwiperProps, 'containerStyle' | 'innerContainerStyle' | 'swipeAreaStyle' | 'slideWrapperStyle' >

/**
 * See
 * https://www.npmjs.com/package/react-native-web-swiper
 */
export const Swiper = forwardRef<RnSwiper, SwiperProps>(({
  containerStyle, innerContainerStyle, swipeAreaStyle, slideWrapperStyle, dotActiveStyle, controlsProps, ...props
}, ref) => {
  const tailwind = useTailwind()
  const newContainerStyle = tailwind(containerStyle)
  const newInnerContainerStyle = tailwind(innerContainerStyle)
  const newSwipeAreaStyle = tailwind(swipeAreaStyle)
  const newSlideWrapperStyle = tailwind(slideWrapperStyle)

  const newControlsProps = {
    ...controlsProps,
    dotActiveStyle: {
      ...controlsProps.dotActiveStyle,
      ...tailwind(dotActiveStyle),
    },
  }

  return (
    <RnSwiper
      ref={ref}
      {...props}
      containerStyle={newContainerStyle}
      innerContainerStyle={newInnerContainerStyle}
      swipeAreaStyle={newSwipeAreaStyle}
      slideWrapperStyle={newSlideWrapperStyle}
      controlsProps={newControlsProps}
    />
  )
})
