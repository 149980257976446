import type { Value as RemoteConfigValue } from 'firebase/remote-config'
import { FIREBASE_LOCAL_REMOTE_CONFIG } from 'react-native-dotenv'
import {
  ObservableStatus, useRemoteConfigAll, useRemoteConfigBoolean, useRemoteConfigNumber, useRemoteConfigString,
} from 'reactfire'

import { defaultFeatureConfig } from '~/constants/featureConfig'

export type FeatureConfigAllValues = {
  [key: string]: RemoteConfigValue | FeatureConfigValue;
};

type ObservableFeatureStatus<ObservableType> = Pick<ObservableStatus<ObservableType>, 'status' | 'data' | 'error'>

// Custom class that mimic RemoteConfigValue behavior for local use
class FeatureConfigValue {

  value: string | number | boolean

  constructor(value: string | number | boolean) {
    this.value = value
  }

  /**
   * Gets the value as a boolean.
   *
   * The following values (case insensitive) are interpreted as true:
   * "1", "true", "t", "yes", "y", "on". Other values are interpreted as false.
   */
  asBoolean() {
    return ['1', 1, 'true', true, 'yes', 'y', 'on'].includes(this.value)
  }

  /**
   * Gets the value as a number. Comparable to calling <code>Number(value) || 0</code>.
   */
  asNumber() {
    return Number(this.value) || 0
  }

  /**
   * Gets the value as a string.
   */
  asString() {
    return this.value ? this.value.toString() : undefined
  }

  toString() {
    return this.value
  }
}

type ValueToType<T> = (value: FeatureConfigValue) => T | undefined

const asBoolean: ValueToType<boolean> = (value: FeatureConfigValue) => value.asBoolean()
const asNumber: ValueToType<number> = (value: FeatureConfigValue) => value.asNumber()
const asString: ValueToType<string> = (value: FeatureConfigValue) => value.asString()

function localConfigFunction<ObservableType>(key: string, valueToType: ValueToType<ObservableType>): ObservableFeatureStatus<ObservableType> {
  const data = new FeatureConfigValue(defaultFeatureConfig[key])

  return {
    status: 'success',
    data: valueToType(data) as ObservableType,
    error: undefined,
  }
}

function localAllConfigFunction<ObservableType>(key: string | null): ObservableFeatureStatus<ObservableType> {
  const data = Object.keys(defaultFeatureConfig).reduce((featureConfig, defaultFeatureConfigKey) => {
    if (key && (key === defaultFeatureConfigKey)) {
      return {
        [defaultFeatureConfigKey]: new FeatureConfigValue(defaultFeatureConfig[defaultFeatureConfigKey]),
      }
    }
    return {
      ...featureConfig,
      [defaultFeatureConfigKey]: new FeatureConfigValue(defaultFeatureConfig[defaultFeatureConfigKey]),
    }
  }, {}) as ObservableType

  return {
    status: 'success',
    data,
    error: undefined,
  }
}

function useConfig<ObservableType>(
  remoteConfigFunction: (key: string) => ObservableFeatureStatus<ObservableType>,
  key: string,
  valueToType: ValueToType<ObservableType>,
): ObservableFeatureStatus<ObservableType> {
  return FIREBASE_LOCAL_REMOTE_CONFIG === 'true' ? localConfigFunction<ObservableType>(key, valueToType) : remoteConfigFunction(key)
}

function useConfigAll<ObservableType>(
  remoteConfigFunction: (key: string) => ObservableFeatureStatus<ObservableType>,
  key: string,
): ObservableFeatureStatus<ObservableType> {
  return FIREBASE_LOCAL_REMOTE_CONFIG === 'true' ? localAllConfigFunction<ObservableType>(key) : remoteConfigFunction(key)
}

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `string` from a FeatureConfig parameter.
 * As useRemoteConfigString will return undefined when a ConfigValue is not remotely defined,
 * useFeatureConfigString data should be safe typed with value && JSON.parse(value) || {} when used for a serialized JSON value to avoid parsing an undefined value
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigString = (key: string): ObservableFeatureStatus<string> => useConfig<string>(useRemoteConfigString, key, asString)

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `number` from a FeatureConfig parameter.
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigNumber = (key: string): ObservableFeatureStatus<number> => useConfig<number>(useRemoteConfigNumber, key, asNumber)

/**
 * Convenience method similar to useRemoteConfigValue. Returns a `boolean` from a FeatureConfig parameter.
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigBoolean = (key: string): ObservableFeatureStatus<boolean> => useConfig<boolean>(useRemoteConfigBoolean, key, asBoolean)

/**
 * Convenience method similar to useRemoteConfigValue. Returns all FeatureConfig parameters.
 * @param key The parameter key in FeatureConfig
 */
export const useFeatureConfigAll = (key: string): ObservableFeatureStatus<FeatureConfigAllValues> => useConfigAll<FeatureConfigAllValues>(useRemoteConfigAll, key)
