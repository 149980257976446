import { TFunction } from 'react-i18next'

import { evaluationTypes } from '~/constants/firestore.evaluations'
import { meetingTypes } from '~/constants/firestore.meetings'

export type OnboardingListItem = {
  key:string
  title: string
  description?: string

  /**
   * data for when action is yet to be done
   */
  todo?: {
    cta?: string
    to?: string
  }

  /**
   * data for when action has been done but item yet to be completed
   */
  await?:{
    cta?: string
    to?: string
  }

  /**
   * data for when item has been completed
   */
  done?: {
    to?: string
  }

  access: null | {
    trackAccessLevel: boolean,
  }
}

export const getMyOnboardingList = (t:TFunction<'home'>): Array<OnboardingListItem> => [
  {
    key: 'details',
    title: t('onboarding_steps.details.title'),
    description: t('onboarding_steps.details.description'),
    todo: {
      cta: t('onboarding_steps.details.todo.cta'),
      to: '/onboarding/details',
    },
    access: null,
  },
  {
    key: 'how_i_feel',
    title: t('onboarding_steps.how_i_feel.title'),
    description: t('onboarding_steps.how_i_feel.description'),
    todo: {
      cta: t('onboarding_steps.how_i_feel.todo.cta'),
      to: `/evaluations/${evaluationTypes.USER_ORIENTATION}/new?redirect=${encodeURIComponent('/onboarding')}`,
    },
    access: null,
  },
  {
    key: 'schedule_orientation',
    title: t('onboarding_steps.schedule_orientation.title'),
    description: t('onboarding_steps.schedule_orientation.description'),
    todo: {
      cta: t('onboarding_steps.schedule_orientation.todo.cta'),
      to: `/sessions/${meetingTypes.ORIENTATION}/new`,
    },
    await: {
      cta: t('onboarding_steps.schedule_orientation.await.info'),
    },
    access: {
      trackAccessLevel: true,
    },
  },
  {
    key: 'personality-evaluation',
    title: t('onboarding_steps.personality-evaluation.title'),
    description: t('onboarding_steps.personality-evaluation.description'),
    todo: {
      cta: t('onboarding_steps.personality-evaluation.todo.cta'),
      to: '/evaluations',
    },
    access: null,
  },
]
