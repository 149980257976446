import {
  limit, orderBy, query, where,
} from 'firebase/firestore'

import { userTrackStatus } from '~/constants/firestore.userTracks'
import { ByUidQueryParams, WithDbLimitQueryOptions, WithDbQueryOptions } from '~/definitions/firestore.queries'

import { getUserTrackCol, getUserTrackColGroup } from './firestore.helpers'

export const findLatestActiveUserTracksQuery = (
  { uid }: ByUidQueryParams,
  { db, limit: limitOpt }: WithDbLimitQueryOptions,
) => query(
  getUserTrackCol(uid, db),
  where('status', '!=', userTrackStatus.COMPLETED),
  orderBy('status', 'asc'), // necessary to perform the query
  orderBy('createdAt', 'desc'),
  limit(limitOpt || 1),
)

export const findUserTracksQuery = (
  { uid }: ByUidQueryParams,
  { db, limit: limitOpt }: WithDbLimitQueryOptions,
) => query(
  getUserTrackCol(uid, db),
  orderBy('createdAt', 'desc'),
  limit(limitOpt || 1),
)

type FindUserTrackByConversationIdQueryInputType = {
  conversationId: string
}

export const findUserTrackByConversationIdQuery = (
  { conversationId }: FindUserTrackByConversationIdQueryInputType,
  { db }: WithDbQueryOptions,
) => query(
  getUserTrackColGroup(db),
  where('vendors.missive.conversationId', '==', conversationId),
)
