import { View as RnView } from 'dripsy'
import {
  ComponentProps, FC, forwardRef, useRef,
} from 'react'

import { useMediaQuery } from '~/hooks/mediaQuery'
import { useStateAwareTailwind } from '~/hooks/tailwind'

import { adaptOutlineClasses, processWauto } from './tw.helpers'
// import { Icon } from '../Icon/Icon'

export type ViewProps = {
  /**
   * Background surface level
   */
  level?: 1 | 2 | 3 | 4 | 'brand'

  /**
   * Tailwind classes
   */
  tw?: string

} & ComponentProps<typeof RnView>

/**
 * TODO
 * - Dark Theme
 * - includes only required selectors
 */
export const View: FC<ViewProps> = forwardRef((props, ref) => {
  const {
    children, level, tw = '', style = {}, ...restProps
  } = props

  const newRef = useRef(null)
  const pressableRef = ref || newRef

  const mq = useMediaQuery()

  const tailwind = useStateAwareTailwind(pressableRef)

  // w-auto => we remove width definition from smaller resolutions

  let newTw = processWauto(tw, mq)
  // console.log(`newTw`, newTw)

  const adaptOutlineClassesRes = adaptOutlineClasses(newTw)

  newTw = adaptOutlineClassesRes.tw

  const viewStyle = {
    ...tailwind(level ? `bg-surface-${level}` : ''),
    ...style,
    ...tailwind(newTw),
    ...adaptOutlineClassesRes.style,
  }

  // console.log('viewStyle', viewStyle)

  return (
    <RnView ref={pressableRef} style={viewStyle} {...restProps}>{children}</RnView>
  )
})
