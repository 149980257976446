// import last from 'lodash/last'
import { useLinkProps } from '@react-navigation/native'
import { FC, forwardRef, useEffect } from 'react'
import { PressableProps as RnPressableProps } from 'react-native'

import { BasePressable } from './BasePressable'

export type PressableProps = {
  tw?: string // Tailwind classes
  to?: string
  action?: any
  hookKey?: string
} & Omit<RnPressableProps, 'style'> // this Omit creates a few type errors bc we do pass style props to some Pressable

// transforms "to" into "onPress"
const NavigablePressable: FC<PressableProps> = forwardRef(({ to, action, ...rest }, ref) => {
  // console.log('NavigablePressable')
  const { onPress, ...props } = useLinkProps({ to, action })

  const onNavigableItemPressed = (e) => {
    // console.log('e', e)
    // console.log('->', e?.key ? e.key : 'Pressed ', 'to page', last(to?.split('/')), `(via ${rest.testID})`)
    if (!rest.disabled) {
      onPress(e)
    }
  }

  // console.log('NavigablePressable/onPress', onNavigableItemPressed)

  return (
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    <Pressable
      ref={ref}
      onPress={onNavigableItemPressed}
      {...props}
      {...rest} />
  )
})

type PressableWithKeyHookProps = {
  tw?: string; // Tailwind classes
  hookKey: string
} & Omit<RnPressableProps, 'style'>
// Adds a listener to the base pressable

const PressableWithKeyHook: FC<PressableWithKeyHookProps> = forwardRef(({ hookKey, onPress, ...rest }, ref) => {
  // Add a listener hooked to the specific key
  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      e.preventDefault()
      if (e.key === hookKey && onPress) {
        onPress(e)
      }
    }
    window.addEventListener('keyup', onKeyup)

    return () => window.removeEventListener('keyup', onKeyup)
  }, [hookKey, onPress])

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return <Pressable ref={ref} onPress={onPress} {...rest} />
})

/**
 * Pressable is a Core Component wrapper that can detect various stages of press interactions on any of its defined children.
 * TODO check React Navigation implementation to handle subtle differences
 * https://reactnavigation.org/docs/elements/#platformpressable
 * https://reactnative.dev/docs/pressable
 */
export const Pressable: FC<PressableProps> = forwardRef((props, ref) => {
  // console.log('-> Pressable')
  // console.log(`Pressable to ${props.to} via ${props.hookKey}`)
  // console.log('Pressable/onPress', props.onPress)

  const {
    to, action, hookKey, onPress, ...rest
  } = props

  if (to || action) {
    // we discard onPress and replace it with an equivalent from to/action
    return <NavigablePressable ref={ref} hookKey={hookKey} to={to} action={action} {...rest} />
  }
  // At this stage we likely have a pressable with onPress

  if (hookKey) {
    // we add a listener for the specified key
    return <PressableWithKeyHook ref={ref} onPress={onPress} {...rest} />
  }

  return <BasePressable ref={ref} onPress={onPress} {...rest} />
})
