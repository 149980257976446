import {
  FC, useCallback, useEffect, useState,
} from 'react'

import { View } from '~/elements'

// Component to wrap children so they appear only temporarily

export const Expire: FC<{
  delay: number,
  onExpired: () => void
}> = ({
  children,
  delay,
  onExpired,
}) => {
  const [isVisible, setIsVisible] = useState(true)

  const setTimer = useCallback((timerDelay: number) => {
    setTimeout(() => {
      onExpired()
      setIsVisible(false)
    }, timerDelay)
  }, [onExpired])

  useEffect(() => {
    setTimer(delay)
  }, [delay, setTimer])

  return (
    isVisible
      ? <View>{children}</View>
      : <View />
  )
}

export default Expire
