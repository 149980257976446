import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import { EvaluationProvider } from '~/context/EvaluationContext'
import { Text } from '~/elements'
import { useGetCurrentUserLatestEvaluationByType } from '~/hooks/firestore.evaluations'
// import { useTemporaryBackground } from '~/hooks/app.background'
import { useAsyncStorage } from '~/hooks/storage'
import { useUser } from '~/hooks/user.context'
import { HomeStackParamList } from '~/navigators/HomeStackParamList'
import { taskListLocalStorageKey } from '~/navigators/TaskListNavigator'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { getFirstName } from '~/utils/people.helpers'

import { ContentSection } from '../ExploreContentScreen/ContentSection'
import { sections } from './content-sections'
import { EventsSection } from './EventsSection'
import { MindexSection } from './MindexSection'
import { MindSection } from './MindSection'
import { OnboardingCta } from './OnboardingCta'
import { PersonalityTestCta } from './PersonalityTestCta'
import { PersonalityTestSection } from './PersonalityTestSection'
import { TestPsyCta } from './TestPsyCta'

export type DashboardScreenProps = StackScreenProps<HomeStackParamList, 'Dashboard'>

// const homeBgGradientTopAssetId = '28838982'
// const homeBgColor = 'brand-white'

/**
 * This screen is transverse to multiple functionalities
 * and present a highly personalised content
 * It's home to the action list and recommended content
 */
export const HomeScreen: FC<DashboardScreenProps> = () => {
  const { loading, value } = useAsyncStorage(taskListLocalStorageKey)
  const linkTo = useLinkTo()

  const { user } = useUser()
  // useTemporaryBackground({ bgColor: homeBgColor, bgSvgId: homeBgGradientTopAssetId })

  const { data: evaluation } = useGetCurrentUserLatestEvaluationByType('user-personalities')

  const { t } = useTranslation(['home'])

  // temporarily return null while we load local storage value
  if (loading) {
    return <LoadingScreen />
  }

  // console.log('taskListLocalStorageKey:', value)
  if (!value) {
    linkTo('/onboarding')
  }

  const firstName = getFirstName(user.displayName)

  let greeting = t('home:greeting')
  if (firstName) {
    greeting += ` ${firstName}`
  }
  greeting += ','

  return (
    <MainContainer scrollEnabled tw="">
      <Text tw="font-serif text-xl md:text-2xl max-w-xlp px-4 md:pl-8 font-medium mt-12 md:mt-20">{greeting}</Text>
      <BasicSectionContainer tw="">
        <OnboardingCta />
        {!evaluation && <PersonalityTestCta />}
        <TestPsyCta />
      </BasicSectionContainer>
      <EventsSection uid={user.uid}/>
      <ContentSection sections={sections} />
      <MindexSection />
      <MindSection />
      {evaluation && <EvaluationProvider evaluationId={evaluation.id}>
        <PersonalityTestSection />
      </EvaluationProvider>
      }
    </MainContainer>
  )
}
