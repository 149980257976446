import { FC } from 'react'

import { DoubleSvgImage } from '~/elements/images/DoubleSvgImage'
import { useDatoSvg } from '~/hooks/dato.assets'

const BG_IMAGE = '19063564'

type IllustrationComponentProps = {
  tw?: string
  illustrationId: number | string
  backgroundId ?: number | string
  background?:boolean
}

/**
 * A component that fetches an illustration and displays it on a background
 */
export const IllustrationComponent:FC<IllustrationComponentProps> = ({
  tw, illustrationId, backgroundId, background,
}) => {
  const bgQueryRes = useDatoSvg(backgroundId)
  const frontQueryRes = useDatoSvg(illustrationId)

  // console.log('bgQueryRes', bgQueryRes)
  // console.log('frontQueryRes', frontQueryRes)

  const haveSvgLoaded = (bgQueryRes?.data?.upload !== undefined) && (frontQueryRes?.data?.upload !== undefined)
  // console.log('haveSvgLoaded', haveSvgLoaded)

  if (!haveSvgLoaded) {
    return null
  }
  // console.log('Will render DoubleSvgImage')

  return (
    <DoubleSvgImage
      containerTw={tw}
      aspectW={1}
      aspectH={1}
      background={background ? bgQueryRes.data.upload : undefined}
      illustration={frontQueryRes.data.upload}
      shadow={false}
    />
  )
}

IllustrationComponent.defaultProps = {
  backgroundId: BG_IMAGE,
  background: true,
}
