import { useTranslation } from 'react-i18next'

import { Carousel } from '~/components/carousel/Carousel'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'

/**
 * Only shown when loading onboarding stack for the very first time
 */
export const WelcomeScreen = ({ onComplete }) => {
  const { t } = useTranslation(['onboarding', 'common'])

  return (
    <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="flex-grow md:h-full">
      <Carousel
        swiperKey="app-welcome"
        tw="rounded-none flex-col"
        onLast={onComplete}
        buttonTitle={t('common:cta.continue')}
        buttonLastTitle={t('onboarding:welcome.cta')}
      >
      </Carousel>
    </FullOverlayContainer>
  )
}
