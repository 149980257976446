import AsyncStorage from '@react-native-async-storage/async-storage'
import { ModuleType } from 'i18next'
import * as RNLocalize from 'react-native-localize'

import {
  getSupportedLanguage,
  isSupportedLanguage,
} from './languages'

export const languageLocalStorageKey = 'i18nextLng'

export default {
  type: 'languageDetector' as ModuleType,
  async: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init: () => {},
  async detect(callback: (language: string) => void) {

    let language

    try {
      language = await AsyncStorage.getItem(languageLocalStorageKey)

      // Clean stale unsupported language in localStorage
      if (language && !isSupportedLanguage(language)) {
        await AsyncStorage.removeItem(languageLocalStorageKey)
      }

    } catch (error) {
      console.error(error)
    }

    if (!language) {
      language = RNLocalize.getLocales()[0]?.languageCode
    }

    return callback(getSupportedLanguage(language))
  },
  async cacheUserLanguage(language: string) {
    const exisitingValue = await AsyncStorage.getItem(languageLocalStorageKey)
    if (exisitingValue !== language) {
      try {
        // console.log('Will cache language in local storage:', language)
        await AsyncStorage.setItem(languageLocalStorageKey, language)
      } catch (error) {
        console.error(error)
      }
    }
  },
}
