// import { useHover, useFocus } from "react-native-web-hooks"

import { Image as RnImage } from 'dripsy'
import { FC } from 'react'

import { ImageProps } from './ImageProps'

/**
 * We should reimplement reactdatoCMS Image for react native
 * See https://www.datocms.com/blog/offer-responsive-progressive-lqip-images-in-2020#putting-it-all-together-introducing-the-responsiveimage-query
 */
export const Image: FC<ImageProps> = (props) => {
  // console.log('Image', props)
  const {
    src, alt, ...restProps
  } = props

  return <RnImage accessible={!!alt} accessibilityLabel={alt} source={{ uri: src }} {...restProps} />
}
