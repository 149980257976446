import { useRoute } from '@react-navigation/core'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenHeadingSection } from '~/components/headings/ScreenHeadingSection'
import { ActionList } from '~/elements/list/ActionList'
import { useOnboardingData } from '~/hooks/actionList.onboarding'

type OnboardingChecklistProps = {
  title:string
  description:string
}

/**
 * An action list oriented for onboarding.
 * Currently covers
 * - taking the "contact details" form
 * - taking the "how are you" evaluation
 * - scheduling orientation meeting with a care manager
 * - taking personality test
 *
 * TODO
 * - https://github.com/mindDay/rn-web/issues/133
 */
export const OnboardingChecklist:FC<OnboardingChecklistProps> = ({ title, description }) => {
  // console.log('-> OnboardingChecklist')

  const { t } = useTranslation(['home'])
  const route = useRoute()

  const { loading, data } = useOnboardingData()

  if (loading) {
    return null
  }

  // override status is coming from details form
  if (get(route, 'params.details') === 'complete') {
    data[0].status = 'completed'
    data[0].active = false
    data[1].active = true
  }

  // console.log('-> OnboardingChecklist', data)

  return (
    <>
      <ScreenHeadingSection title={title || t('home:onboarding_steps.title')} description={description } tw="px-0 md:px-0 mt-12 md:mt-0" />
      {/* here exceptionnally ScreenHeadingSection is child of BasicSectionContainer, so we take away more horizontal padding */}
      <ActionList tw="mt-8 w-full" data={data.map((item) => omit(item, 'data'))} />
    </>
  )
}
