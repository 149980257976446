import { FC } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Button, View,
} from '~/elements'

// const keyHintStyle = 'inline-flex items-center border border-gray-300 rounded px-4 py-2 ml-2 text-sm font-sans font-medium text-gray-500'

type SubInputElementProps = {
  isLastStep:boolean
  onPress: (val:FieldValues) => void,
}

export const SubInputElement:FC<SubInputElementProps> = ({ isLastStep, onPress }) => {
  const { t } = useTranslation(['forms', 'common'])
  const btnText = isLastStep ? t('forms:submit') : t('common:cta.next')

  const { formState: { isSubmitting } } = useFormContext()

  return (
    <View tw="mt-4 w-full flex-row justify-end">
      <Button
        title={btnText}
        iconRight
        color="brand"
        disabled={isSubmitting}
        onPress={onPress}
      />
      {/* <Text tw="ml-4">{t('hit_enter')}</Text>
      <View tw={keyHintStyle}><Text>↵</Text></View> */}
    </View>
  )
}
