import { StatusBar } from 'expo-status-bar'
import { getAnalytics } from 'firebase/analytics'
import { FirebaseApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  connectFirestoreEmulator, initializeFirestore,
} from 'firebase/firestore'
import { connectFunctionsEmulator } from 'firebase/functions'
import { fetchAndActivate, getRemoteConfig } from 'firebase/remote-config'
import {
  FC, useEffect,
} from 'react'
import {
  CONNECT_LOCAL_EMULATOR, FIREBASE_APP_CHECK_TOKEN,
  FIREBASE_LOCAL_REMOTE_CONFIG,
  FIREBASE_REMOTE_CONFIG_CACHE_HOURS,
} from 'react-native-dotenv'
import { ThemeProvider } from 'react-native-elements'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import {
  AnalyticsProvider,
  AppCheckProvider,
  AuthProvider, FirestoreProvider,
  RemoteConfigProvider,
  useFirebaseApp, useInitFirestore, useInitRemoteConfig,
} from 'reactfire'
import { TailwindProvider } from 'tailwind-rn'

import { defaultFeatureConfig } from '~/constants/featureConfig'
import { useFunctions } from '~/hooks/firebase'
// import useColorScheme from '~/hooks/theme'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { CustomRNElementsLightTheme } from '~/theme/rnElements'
import { isLocalHost, isPreviewHost } from '~/utils/environment'
import utilities from '~/web/tailwind.json'

type PreLoadFirebaseProps = Record<string, unknown>

const runLocalEmulator = (CONNECT_LOCAL_EMULATOR === 'true') && isLocalHost()

const EventuallyAppCheckProvider:FC<{ firebaseApp: FirebaseApp}> = ({ firebaseApp, children }) => {
  if (runLocalEmulator || isLocalHost() || isPreviewHost()) {
    return <>{children}</>
  }

  const sdk = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(FIREBASE_APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  })

  return (
    <AppCheckProvider sdk={sdk}>
      {children}
    </AppCheckProvider>
  )

}

export const PreLoadFirebase:FC<PreLoadFirebaseProps> = ({ children }) => {
  // const colorScheme = useColorScheme()

  /**
   * TODO we load providers here post refacto
   * but they should be deferred to wherever level they are required first
   */
  const firebaseApp = useFirebaseApp()
  const auth = getAuth(firebaseApp)
  const analytics = getAnalytics(firebaseApp)

  const euFunctions = useFunctions('europe-west3')
  const usFunctions = useFunctions('us-central1')

  // cannot use getFirestore if we want to enable persistence as per ReactFire's doc
  const { status, data: firestoreInstance } = useInitFirestore(async (app) => {
    const db = initializeFirestore(app, {})
    // await enableIndexedDbPersistence(db, { forceOwnership: false })

    return db
  })

  const { status: remoteConfigLoadingStatus, data: remoteConfigInstance } = useInitRemoteConfig(async (app) => {

    const remoteConfig = getRemoteConfig(app)

    // console.log('FIREBASE_REMOTE_CONFIG_CACHE_HOURS', FIREBASE_REMOTE_CONFIG_CACHE_HOURS)

    // See https://firebase.google.com/docs/reference/js/remote-config.remoteconfigsettings
    remoteConfig.settings = {
      minimumFetchIntervalMillis: FIREBASE_REMOTE_CONFIG_CACHE_HOURS * 60 * 60 * 1000,
      fetchTimeoutMillis: 1000,
    }

    // local state for init values
    remoteConfig.defaultConfig = defaultFeatureConfig

    if (FIREBASE_LOCAL_REMOTE_CONFIG !== 'true') {
      try {
        await fetchAndActivate(remoteConfig)
      } catch (error) {
        if (error && error.code === 'remoteconfig/fetch-timeout') {
          // TODO catch timeout error to return gracefully
        } else {
          throw error
        }
      }
    }

    return remoteConfig
  })

  useEffect(() => {
    if (runLocalEmulator && firestoreInstance) {
      connectFirestoreEmulator(firestoreInstance, 'localhost', 8080)
      connectAuthEmulator(auth, 'http://localhost:9099')
      connectFunctionsEmulator(euFunctions, 'localhost', 5001)
      connectFunctionsEmulator(usFunctions, 'localhost', 5001)
    }
  }, [firestoreInstance, auth, euFunctions, usFunctions])

  // firestore init isn't complete yet
  if (status === 'loading' || remoteConfigLoadingStatus === 'loading') {
    return <LoadingScreen />
  }

  return (
    <ThemeProvider
      theme={CustomRNElementsLightTheme}
      useDark={false} // dont use dark mode yet (colorScheme === 'dark')
    >
      <TailwindProvider utilities={utilities}>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <SafeAreaProvider>
            <EventuallyAppCheckProvider firebaseApp={firebaseApp}>
              <AnalyticsProvider sdk={analytics}>
                <AuthProvider sdk={auth}>
                  <RemoteConfigProvider sdk={remoteConfigInstance}>
                    <FirestoreProvider sdk={firestoreInstance}>
                      { children}
                    </FirestoreProvider>
                  </RemoteConfigProvider>
                </AuthProvider>
              </AnalyticsProvider>
              <StatusBar />
            </EventuallyAppCheckProvider>
          </SafeAreaProvider>
        </GestureHandlerRootView>
      </TailwindProvider>
    </ThemeProvider>
  )
}
