import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUser,
} from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { FC } from 'react'

import { ButtonColor } from '../buttons/Button.d'
import { View } from '../containers/View'
import Icon from '../icons/Icon'
import { IconProps } from '../icons/Icon/IconProps.d'
import { AvatarShapeType, AvatarSizeType } from './Avatar.d'
import { getAvatarIconSizeNum, getAvatarPlaceholderClassesFromProps } from './tw.helpers'

library.add(faUser)

type PlaceholderProps = {
  size?: AvatarSizeType
  shape?: AvatarShapeType
  color?: ButtonColor
  icon?: IconProps
  tw?: string
}

const baseContainerTw = 'items-center justify-end'
const baseTextTw = ''

/**
 *
 * TODO: find a way to mask the SVG
 */
export const Placeholder: FC<PlaceholderProps> = ({
  size, shape, color, icon, tw,
}) => {
  // console.log('icon', icon)
  const { textTw, containerTw } = getAvatarPlaceholderClassesFromProps({
    size, color, shape, tw,
  })

  const iconTw = classNames(textTw, baseTextTw)
  // console.log(`iconTw`, iconTw)

  const { name, variant } = icon

  const newIconProps = {
    name: name || 'user',
    variant: variant || 'solid',
  }

  return (
    <View tw={classNames(containerTw, baseContainerTw)}>
      <Icon tw={iconTw} {...newIconProps} sizeNum={getAvatarIconSizeNum(size)} />
    </View>
  )
}

Placeholder.defaultProps = {
  size: 'small',
  shape: 'circular',
  color: 'brand',
  icon: {
  },
}
