import times from 'lodash/times'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { RecommendedContentWithData } from '~/components/sections/content/RecommendedContent'
import { useBatchRecommendedItems } from '~/hooks/recombee'

type ContentSectionProps = {
  sections
}

export const ContentSection: FC<ContentSectionProps> = ({ sections }) => {
  // console.log('-> ContentSection')
  const { t } = useTranslation(['home'])

  const recommendations = sections.map((item) => item.recommendations)
  // console.log(`recommendations targets`, recommendations)
  const { data: dataRecombee } = useBatchRecommendedItems(recommendations)
  // console.log('recommendations from recombee', dataRecombee)

  // combinedData matches sections (aka content categories) with individual recombee content items
  // also adding a number of undefined items while we load content so that skeleton can render appropriately
  const combinedData = sections.map((item, index) => ({
    ...item,
    data: dataRecombee.length > 0 ? dataRecombee[index] : times(item.recommendations.count, () => undefined),
  }))

  return (
    <>
      {combinedData.map((item) => (
        <RecommendedContentWithData
          key={item.key}
          data={item.data}
          tileSettings={item.tileSettings}
          title={t(`recommendations.${item.key}.title`)}
          description={t(`recommendations.${item.key}.description`)} />
      ))}
    </>
  )
}
