import { gql } from '@apollo/client'

export const GET_FORM = gql`
query GetFormByKeyQuery($key: String, $locale: SiteLocale) {
    webForm(filter: {key: {eq: $key}}, locale: $locale) {
        title
        description
        formFields {
            key
            label
            inputType
            help
            defaultValue
            required
        }
    }
  }`
