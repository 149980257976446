import { gql } from '@apollo/client'

export const GET_SWIPER_BY_NAME = gql`
query getSwiperByName($locale: SiteLocale, $key: String) {
    appSwiper(locale: $locale, filter: {key: {eq: $key}}) {
    slides {
      id
      title
      titleColor
      description
      image {
        id
      }
    }
  }
}
`
