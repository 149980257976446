import classNames from 'classnames'
import { FC } from 'react'
import { useTailwind } from 'tailwind-rn'

import { getColor } from '~/theme/helpers'

import { PressableProps } from '../containers/Pressable'
import { IconProps } from '../icons/Icon/IconProps.d'
import { Popable } from '../overlays/popable'
import { Button } from './Button'
import { ButtonColor, ButtonSize } from './Button.d'

export type IconButtonProps = PressableProps & {
  icon : IconProps

  /**
   * Bottom tooltip on hover
   */
  hint?:string

  color?: ButtonColor
  size?: ButtonSize
  tw?:string
}

const getIconBtnSizeToTw = (size:ButtonSize) => {
  switch (size) {
  case 'xs':
    return 6
  case 'sm':
    return 8
  case 'base':
    return 10
  case 'l':
    return 12
  case 'xl':
    return 14
  default:
    throw new Error(`unknown IconButton size ${size}`)
  }
}

export const getIconBtnSizeTwClasses = (size:ButtonSize, offset = 0) => {
  const val = getIconBtnSizeToTw(size) + offset
  return `w-${val} h-${val}`
}

const mapSizeToTw = (size: ButtonSize = 'base'):string => {

  const sizeTw = getIconBtnSizeTwClasses(size)
  switch (size) {
  case 'xs':
    return `${sizeTw} text-xs`
  case 'sm':
    return `${sizeTw} text-xs`
  case 'base':
    return `${sizeTw} text-sm`
  case 'l':
    return `${sizeTw} text-sm`
  case 'xl':
    return `${sizeTw} text-base`
  default:
    throw new Error(`unknown IconButton size ${size}`)
  }
}

const basePressableTw = 'rounded-full justify-center items-center p-0'
const baseIconTw = ''

export const IconButton:FC<IconButtonProps> = ({
  size, tw, icon = {}, hint, onPress, color, variant, ...props
}) => {
  const tailwind = useTailwind()
  const nextTw = classNames(basePressableTw, tw, mapSizeToTw(size))
  // console.log('containerTw', nextTw)

  const { tw: iconTw, ...restIcon } = icon

  const nextIconTw = classNames(baseIconTw, iconTw)
  // console.log('iconTw', nextIconTw)

  if (hint) {
    // console.log('Icon button with hint:', hint)
    return (
      <Popable
        content={hint}
        numberOfLines={1}
        action="hover"
        position="bottom"
        caret={false}
        backgroundColor={getColor('gray-900 opacity-30')}
        style={tailwind('mt-2 z-30 -right-6')}
        onPress={onPress}
      >
        <Button {...props} color={color} variant={variant} tw={nextTw} icon={{ ...restIcon, tw: nextIconTw }}/>
      </Popable>
    )
  }

  return <Button {...props} color={color} variant={variant} onPress={onPress} tw={nextTw} icon={{ ...restIcon, tw: nextIconTw }}/>
}
