import classNames from 'classnames'
import examples from 'libphonenumber-js/examples.mobile.json'
import {
  AsYouType, CountryCode, getExampleNumber, isValidPhoneNumber,
} from 'libphonenumber-js/max'
import { FC, KeyboardEvent, useState } from 'react'
import {
  FieldName, FieldValues, RegisterOptions, useController, useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getCountry } from 'react-native-localize'

import { TextInput, TextInputProps } from '~/elements'

import { InternationalCountryCallingCodeSelector } from './InternationalCountryCallingCodeSelector'

export type PhoneNumberProps<
TFieldValues extends FieldValues = FieldValues
> = TextInputProps & {
  name:FieldName<TFieldValues>
  rules?: Exclude<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' >
  defaultValue?:unknown
  align?:'left' | 'right' | 'center'
  onEnterKeyPress:() =>void
}

/**
 * Format and validate phone number
 * TODO: add a follow up screen to validate number with twilio
 */
export const PhoneNumberInput:FC<PhoneNumberProps> = ({
  name,
  rules = {},
  defaultValue,
  onEnterKeyPress,
  align = 'left',
  helperTw,
  wrapperTw,
  ...rest
}) => {

  const {
    control, setError, setValue, clearErrors,
  } = useFormContext()

  const {
    field: {
      onBlur, name: fieldName, value, ref,
    },
    fieldState: { invalid, error },
    // formState: { touchedFields, dirtyFields },

  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue || '',
  })

  const [country, setCountry] = useState<CountryCode>(getCountry() as CountryCode)

  const { t } = useTranslation(['forms'])

  const validatePhoneNumber = (val:string) => {
    // console.log('validatePhoneNumber', val, country)

    if (val && !isValidPhoneNumber(val, country)) {
      // console.log('Not a valid phone number')
      setError(fieldName, { type: 'phoneNumberError', message: t('forms:phoneNumber.invalid') })
    } else if (error) { // so error message does not stay when number gets valid or back to non-judgeable yet
      clearErrors(fieldName)
    }
  }

  // console.log('fieldState', invalid, isTouched, error)

  return (
    <TextInput
      errorMessage={ invalid ? error?.message : undefined}
      testID={name}
      autoFocus
      onBlur={onBlur} // notify when input is touched/blur
      value={value} // input value
      name={fieldName} // send down the input name
      inputRef={ref} // send input ref, so we can focus on input when error appear

      onChangeText={(val:string) => {
        if (val) {
          const asYouTypeFormatter = new AsYouType(country)
          const phoneNumber = asYouTypeFormatter.input(val)
          // console.log(fieldName, phoneNumber)

          setValue(fieldName, phoneNumber)

          const aytf = asYouTypeFormatter
          // console.log('country from typed phone number', aytf)
          const aytfCountry = aytf.getCountry()
          if (aytfCountry !== undefined && aytfCountry !== country) {
            // console.log('Will update country value to', aytfCountry)
            setCountry(aytfCountry)
          }

          validatePhoneNumber(phoneNumber)
        }
      }}
      keyboardType="phone-pad"
      textContentType="telephoneNumber"
      autoCompleteType="tel"

      placeholder={getExampleNumber(country, examples)?.formatInternational()}

      onKeyPress={(e: KeyboardEvent) => {
        if (e.key === 'Enter' && onEnterKeyPress) {
          onEnterKeyPress()
        }
      }}
      {...rest}
      LeftPicker={() => <InternationalCountryCallingCodeSelector value={country} onChange={(val) => {
        setCountry(val)
        setValue(fieldName, '')
      }} />}

      // custom styles for picker input
      wrapperTw={classNames('w-full', wrapperTw)}
      helperTw={classNames(`text-${align}`, helperTw)}
    />

  )
}
