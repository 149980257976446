import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'

import { faPrefixes, getNativeSize } from './faConstants'
import { IconProps } from './IconProps.d'

export const Icon = (props: IconProps) => {
  // console.log('Native Icon')
  // console.log('props', props)
  const {
    pack = 'fa', name, variant = 'solid', size, sizeNum, ...restProps
  } = props

  // if (pack === 'heroicons') {
  //   // const heroiconStyle = style === 'solid' ? 'solid' : 'outline'
  //   // const heroiconName = startCase(name.replace('-', ' ').toLowerCase()).replace(' ', '')
  //   // console.log('heroiconName', heroiconName)

  //   // const Heroicon = require(`~/fonts/heroicons/${heroiconStyle}/${heroiconName}`)

  //   return <ArrowNarrowRight {...restProps} />
  // }
  if (pack === 'fa') {
    // console.log('icon name', name)
    return <FontAwesomeIcon icon={[faPrefixes[variant], name]} size={sizeNum || getNativeSize(size)} {...restProps} />
  }

  return null
}
