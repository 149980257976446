import classNames from 'classnames'
import upperFirst from 'lodash/upperFirst'
import { FC, ReactNode } from 'react'

import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { ContentPlayerScrollView } from '~/components/layouts/containers/ContentPlayerScrollView'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { View } from '~/elements'
import { ContentPageHeading, contentPageHeadingBaseTw, contentPageParagraphBaseTw } from '~/elements/text/Player.FontTypes'
import { StructuredText } from '~/elements/text/StructuredText'

import { ReaderFooterProps } from '../BottomNavBar'

type TextLayoutProps = ReaderFooterProps & {
  title: string
  content: ReactNode
}

export const TextLayout: FC<TextLayoutProps> = ({ title, content }) => (
  <FullOverlayContainer tw="items-center bg-surface-1" innerContainerTw="h-full">
    <TwoColGridSection tw="h-full">
      <ContentPlayerScrollView>
        {/* <LeftNavBar /> */}
        {/* With LeftNav: View wrapping below with pl-14 sm:pl-16 lg:pl-20 pr-4 sm:pr-6 lg:pr-8 */}
        <ContentPageHeading>{upperFirst(title)}</ContentPageHeading>
        <View>
          <StructuredText
            data={content}
            paragraphTw={classNames(contentPageParagraphBaseTw, 'max-w-none')}
            headingTw={contentPageHeadingBaseTw}
          />
        </View>
      </ContentPlayerScrollView>
    </TwoColGridSection>
  </FullOverlayContainer>
)
