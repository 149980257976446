import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '~/hooks/mediaQuery'

import { Button } from '../..'
import {
  baseTwClasses, iconFamilyVariant, iconSize,
} from './headerButtons.helpers'
import { HeaderIconButton } from './HeaderIconButton'

/**
 * A responsive archive button that is a label on large screens and just an icon on smal screens
 */

export const ArchiveHeaderButton: FC<ArchiveStackHeaderButtonProps> = ({
  onPress, status = 'unarchived', tw, ...props
}) => {
  const { t } = useTranslation(['common'])
  const { lg } = useMediaQuery()

  const label = status === 'unarchived' ? t('common:cta.archive') : t('common:cta.unarchive')
  // console.log('label', label)

  if (lg && label) {
    return (
      <Button onPress={onPress} color="control" title={label} tw={tw} />
    )
  }
  const newTw = classNames(tw, baseTwClasses)

  return (
    <HeaderIconButton
      {...props}
      tw={newTw}
      icon={{ variant: iconFamilyVariant, sizeNum: iconSize, name: `folder-arrow-${status === 'archived' ? 'up' : 'down'}` }}
      hint={label}
      onPress={onPress}
    />
  )
}

export type ArchiveStackHeaderButtonProps = {
  /**
   *
   */
   status: 'archived' | 'unarchived'

  onPress?: () => void

  /**
   * Label (desktop only)
   */
  label:string

  /**
   *
   */
  tw?:string
}
