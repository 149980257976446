import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { CtaCard } from '~/components/panels/cards/CtaCard'
import { useHasCurrentUserGadEvaluation, useHasCurrentUserPhqEvaluation } from '~/hooks/firestore.evaluations'
import { useCurrentUserMinder } from '~/hooks/minder'

type TestPsyCtaProps = Record<string, never>

export const TestPsyCta:FC<TestPsyCtaProps> = () => {
  const { t } = useTranslation(['home'])
  const { data: minder } = useCurrentUserMinder()
  const typeOfMinder = minder?.contractor?.minderType

  const gadEvaluationResponse = useHasCurrentUserGadEvaluation()
  const hasUserTakenGadForm = gadEvaluationResponse.data

  const phqEvaluationResponse = useHasCurrentUserPhqEvaluation()
  const hasUserTakenPhqForm = phqEvaluationResponse.data

  // we only want this Cta in case there is an assigned minder which is a psy, and both tests were not taken yet
  if (!minder || minder === undefined || typeOfMinder !== 'psy' || (hasUserTakenGadForm && hasUserTakenPhqForm)) {
    return null
  }

  const url = hasUserTakenGadForm ? '/evaluations/PHQ-9/new?redirect=/' : '/evaluations/GAD-7/new?redirect=/'
  const title = t('home:test_cta.titleDefault') // TODO: see if change of wording after the session is done
  const description = t('home:test_cta.descriptionDefault')

  return (
    <CtaCard url={url} title={title} description={description}/>
  )
}
