import AsyncStorage from '@react-native-async-storage/async-storage'
import { differenceInMinutes } from 'date-fns'
import { getAuth } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useAuth, useUser as useFirebaseUser } from 'reactfire'

import analytics from '~/analytics'
import { roleList } from '~/constants/firebase.auth'
import { User } from '~/definitions/firebase.auth.types'

/**
 * Listen for auth state changes
 */
export const useAuthStateChanged = () => {

  const auth = useAuth()

  /**
   * Hook on Firebase Auth State Changed
   */
  useEffect(() => {
    auth.onAuthStateChanged(async (usr: User | null) => {
      if (usr) {

        // console.log('-> authStateChanged with user', usr)
        // refresh token so that we get the latest claims
        usr.getIdToken()

        // only log signin if new sign in older than 3 hours on the same computer to avoid reload signin

        const lastSignInTime = await AsyncStorage.getItem('lastSigninTime') // , new Date())
        // console.log(`lastSignInTime`, lastSignInTime, typeof lastSignInTime)

        /**
         * New signin beyond 3h implies new session starting
         */
        const diffInMin = differenceInMinutes(new Date(lastSignInTime), new Date())
        if (!lastSignInTime || -diffInMin > 180) {
          // console.log('Effective Sign in')
          analytics.identify({ userId: usr.uid })
          analytics.track('login')
        }

        // update value
        await AsyncStorage.setItem('lastSigninTime', new Date())
      } else {
        // console.log('-> onAuthStateChanged: signedOut')
        analytics.track('logout')
        analytics.reset()
        AsyncStorage.removeItem('lastSigninTime')
        AsyncStorage.removeItem('user')
      }
    })
    // we only register the listener once hence the empty array
  }, [])

}

export const logout = async () => {
  // console.log('-> logout')
  const auth = getAuth()
  return auth.signOut()
}

/**
 * User roles are stored as custom claims in Firebase Auth
 * By convention they all start with hasRoleXXX
 */
export const useUserRoles = () => {
  const { data: user } = useFirebaseUser()

  const [loading, setLoading] = useState(false)
  const [roles, setRoles] = useState<any>()

  useEffect(() => {
    if (user && !roles) {

      const getRoles = async () => {
        setLoading(true)

        // transforms idToken into roles

        const idTokenResult = await user?.getIdTokenResult?.()
        // console.log('idTokenResult', idTokenResult)

        const rolesFromClaims = Object
          .keys(idTokenResult.claims)
          .filter((key) => key.startsWith('hasRole'))

        // console.log('rolesFromClaims', rolesFromClaims)

        // reidentify user with these custom traits
        const traits = roleList.reduce((acc, key) => ({
          ...acc,
          [key]: idTokenResult.claims[key] || false,
        }), {})
        // console.log('reidentify user with custom traits', traits)
        analytics.identify({ userId: user.uid, traits })

        // update local state
        setRoles(rolesFromClaims)

        setLoading(false)
      }

      getRoles()

    }

    // Exclude roles on purposes
  }, [user])

  return { data: roles, loading }

}
