import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import { openURL } from 'expo-linking'
import { openBrowserAsync } from 'expo-web-browser'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { AuthLayout } from '~/components/layouts/containers/AuthLayout'
import { Button, Text, View } from '~/elements'
import { NonAuthStackParamList } from '~/navigators/NonAuthStackParamList.d'

export type NotAllowedScreenProps = StackScreenProps<NonAuthStackParamList, 'NotAllowed'>

export const SignUpNotAllowedScreen: FC<NotAllowedScreenProps> = ({ route }) => {
  const linkTo = useLinkTo()
  const { t } = useTranslation()
  const {
    params: {
      email,
      origin,
    },
  } = route

  const isEmailAlreadyUsed = origin === 'email-already-used'

  const mainTranslationKey = isEmailAlreadyUsed
    ? 'emailAlreadyUsed'
    : 'notAllowed'

  const encodedEmail = encodeURIComponent(email)
  const linkToPath = isEmailAlreadyUsed
    ? `/auth/link?email=${encodedEmail}&origin=existing-user&alternate=true`
    : `/auth/register?email=${encodedEmail}`

  const onPressAuth = () => {
    linkTo(linkToPath)
  }

  const onPressContact = () => {
    if (isEmailAlreadyUsed) {
      openBrowserAsync('mailto:hello@mindday.com')
    } else {
      openURL('https://www.mindday.com/contact-us?role=employee')
    }
  }

  const variant = isEmailAlreadyUsed
    ? 'outline'
    : 'filled'

  return (
    <AuthLayout>
      <BasicSectionContainer tw="">

        <Text tw="font-serif text-xl">{t(`auth:${mainTranslationKey}.title`)}</Text>
        <Text category="p">{t(`auth:${mainTranslationKey}.description`, { email })}</Text>
        <View style={{ marginTop: 25 }}>
          <Text category="p">{t(`auth:${mainTranslationKey}.mistakeHint`)}</Text>
          <Button tw="mt-1" title={t(`auth:${mainTranslationKey}.mistakeCta`)} onPress={onPressAuth}/>
        </View>
        <View style={{ marginTop: 25 }}>
          <Text category="p">{t(`auth:${mainTranslationKey}.contactUsHint`)}</Text>
          <Button tw="mt-1" title={t(`auth:${mainTranslationKey}.contactUsCta`)} onPress={onPressContact} variant={variant}/>
        </View>
        {/* <View style={{ marginTop: 25 }}>
        <Text category="p">{t('auth:notAllowed.registerB2cHint')}</Text>
        <Button tw="mt-1" title={t('auth:notAllowed.registerB2cCta')} />
        <Text tw="text-sm text-center mt-1 text-gray-500">{t('auth:notAllowed.registerB2cHelper')}</Text>
      </View> */}
      </BasicSectionContainer>
    </AuthLayout>
  )
}
