import { library } from '@fortawesome/fontawesome-svg-core'
import { faSignalBarsSlash } from '@fortawesome/pro-solid-svg-icons'
import { FC, memo } from 'react'
import { useTranslationContext } from 'stream-chat-react'

import { Icon, Text, View } from '~/elements'

// import placeholder from '../../assets/str-chat__connection-error.svg'
import { LoadingChannels } from '../Loading/LoadingChannels'

library.add(faSignalBarsSlash)

export type ChatDownProps = {
  /** The type of error */
  type: string;
  /** The image url for this error */
  image?: string;
  /** The error message to show */
  text?: string;
};

const UnMemoizedChatDown: FC<ChatDownProps> = (props) => {
  const { text, type = 'Error' } = props

  const { t } = useTranslationContext('ChatDown')

  return (
    // className='str-chat__down'
    <View tw="" >
      <LoadingChannels />
      {/* str-chat__down-main */}
      <View tw=''>
        {/* <img testID='chatdown-img' src={image || placeholder} /> */}
        <Icon testID='chatdown-icon' name="signal-bars-slash" variant="solid" size="2x" tw="bg-base rounded-full"/>
        <Text tw="">{type}</Text>
        <Text tw="">{text || t('Error connecting to chat, refresh the page to try again.')}</Text>
      </View>
    </View>
  )
}

/**
 * A simple indicator that chat functionality isn't available, triggered when the Chat API is unavailable or your network isn't working.
 */
export const ChatDown = memo(UnMemoizedChatDown) as typeof UnMemoizedChatDown
