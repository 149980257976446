import { useHeaderHeight } from '@react-navigation/elements'
import {
  FC, ReactNode, useEffect, useState,
} from 'react'

import { BasicSectionContainer } from '~/components/layouts/column-sections/SectionContainer'
import { MainContainer } from '~/components/layouts/containers/MainContainer'
import {
  Image, View,
} from '~/elements'

import { EvaluationNavBar, NavProps } from './NavBar'

type ContentPageProps = NavProps & {
  resultCard: ReactNode,
  otherScoresCard: ReactNode,
  dimensionCard: ReactNode,
  illustration
}

export const EvaluationPlayerDimensionMobileScreen: FC<ContentPageProps> = ({
  resultCard,
  dimensionCard,
  illustration,
  ...props
}) => {
  // just header height drama
  const instantHeaderHeight = useHeaderHeight()
  const [headerHeight, setHeaderHeight] = useState(0)
  useEffect(() => {
    if (instantHeaderHeight > 0 && instantHeaderHeight !== headerHeight) {
      setHeaderHeight(instantHeaderHeight)
    }
  }, [instantHeaderHeight, headerHeight])

  return (
    <MainContainer scrollEnabled contentContainerTw="items-center bg-yellow-100 min-h-full" scrollToTop={true}>
      <BasicSectionContainer style={{ marginTop: headerHeight }}>
        <View tw="flex-grow content-between">

          {illustration && <View tw="w-full flex-row justify-center my-16"><Image {...illustration} tw="w-2/5 -my-8" /></View>}
          <View tw="rounded-xl">
            {dimensionCard}
          </View>

          <View tw="bg-surface-1 rounded-xl p-6">
            {resultCard}
          </View>

        </View>
        <EvaluationNavBar {...props} src="Idea" />
      </BasicSectionContainer>
    </MainContainer>
  )
}
