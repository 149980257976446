import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

// import { GetAffiliationBySlugQuery } from '~/__generated__/GetAffiliationBySlugQuery'
import { GET_AFFILIATION_BY_SLUG } from '~/queries/dato.affiliations'

export const useAffiliationBySlug = (slug: string) => {
  const { i18n } = useTranslation()

  return useQuery<any, {
    slug: string,
    locale: string
  }>(GET_AFFILIATION_BY_SLUG, {
    variables: {
      slug,
      locale: i18n.language,
    },
  })
}
