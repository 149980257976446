import { gql } from '@apollo/client'

// bad practice to separate the envs here but we had no choice as ${cioSegmentByEnv} was not accepted as a filtering Name syntax
export const GET_AUDIENCES_BY_SEGMENTS_DEV = gql`
query GetAudiencesBySegmentsDev($segmentsId: [String], $locale: SiteLocale) {
  allAudienceSegments(filter: {cioSegmentDev: {in: $segmentsId}}, locale: $locale) {
    id
  }
}
`
export const GET_AUDIENCES_BY_SEGMENTS = gql`
query GetAudiencesBySegments($segmentsId: [String], $locale: SiteLocale) {
  allAudienceSegments(filter: {cioSegment: {in: $segmentsId}}, locale: $locale) {
    id
  }
}
`

export const GET_EVENTS_BY_AUDIENCES = gql`
query GetEventsByAudiences($audiencesId: [ItemId], $locale: SiteLocale) {
  allConferenceInstances(locale: $locale, filter: {audience: {in: $audiencesId}}) {
    id
    livestormEventId
    model {
      slug
      description(locale: $locale)
      duration
      title(locale: $locale)
      illustration {
        id
        foreground {
          url
          format
          height
          width
        }
        background {
          url
          format
          height
          width
        }
        backgroundColor
      }
    }
    hosts {
      ... on ContractorRecord {
        minder {
          firstName
          lastName
        }
      }
    }
  }
  allWorkshopInstances(locale: $locale, filter: {audience: {in: $audiencesId}}) {
      id
      livestormEventId
      model {
        slug
        description(locale: $locale)
        duration
        title(locale: $locale)
        shortTitle(locale: $locale)
        objectives(locale: $locale)
        targetParticipants
        maxParticipants
        preRequisites
        illustration {
          id
          foreground {
            url
            format
            height
            width
          }
          background {
            url
            format
            height
            width
          }
          backgroundColor
        }
      }
      hosts {
        ... on ContractorRecord {
          minder {
            firstName
            lastName
          }
        }
      }
    }
  }
  `

export const GET_EVENT_BY_ID_BY_AUDIENCES = gql`
query GetEventByIdByAudiences($eventId: ItemId, $audiencesId: [ItemId], $locale: SiteLocale) {
  conferenceInstance(locale: $locale, filter: {id: {eq: $eventId}, audience: {in: $audiencesId}}) {
    id
    livestormEventId
    model {
      slug
      description(locale: $locale)
      duration
      title(locale: $locale)
      illustration {
        id
        foreground {
          url
          format
          height
          width
        }
        background {
          url
          format
          height
          width
        }
        backgroundColor
      }
    }
    hosts {
      ... on ContractorRecord {
        minder {
          firstName
          lastName
        }
      }
    }
  }
  workshopInstance(locale: $locale, filter: {id: {eq: $eventId}, audience: {in: $audiencesId}}) {
      id
      livestormEventId
      model {
        slug
        description(locale: $locale)
        duration
        title(locale: $locale)
        shortTitle(locale: $locale)
        objectives(locale: $locale)
        targetParticipants
        maxParticipants
        preRequisites
        illustration {
          id
          foreground {
            url
            format
            height
            width
          }
          background {
            url
            format
            height
            width
          }
          backgroundColor
        }
      }
      hosts {
        ... on ContractorRecord {
          minder {
            firstName
            lastName
          }
        }
      }
    }
  }
  `
