import { FC } from 'react'
import { SocialIcon as RnSocialIcon } from 'react-native-elements'

export type SocialIconProps = Record<string, unknown>

/**
 * See
 * https://reactnativeelements.com/docs/SocialIcon
 */
export const SocialIcon: FC<SocialIconProps> = (props) => <RnSocialIcon {...props} />
