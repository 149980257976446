import classNames from 'classnames'
import { BlurTint } from 'expo-blur'
import { FC } from 'react'

import { BlurView } from '~/elements/containers/BlurView'
import { getMarginRelatedClasses, getPositionRelatedClasses } from '~/utils/tailwind/tw.containers.helpers'

import { getIconBtnSizeTwClasses, IconButton, IconButtonProps } from '../IconButton'

const BLUR_RING_TW_WIDTH = 1

export type HeaderIconButtonProps = IconButtonProps & {
    blurTint?: BlurTint,
    blurIntensity?: number
}

/**
 * An IconButton wrapped with a blurview
 */
export const HeaderIconButton:FC<HeaderIconButtonProps> = ({
  tw = '', blurTint = 'default', blurIntensity = 80, size = 'xs', ...props
}) => {

  const sizeTw = getIconBtnSizeTwClasses(size, BLUR_RING_TW_WIDTH)

  // margin classes should apply to the container
  const { positionTw, nonPositionTw } = getPositionRelatedClasses(tw)
  const { marginTw, nonMarginTw: iconButtonTw } = getMarginRelatedClasses(nonPositionTw)

  const baseContainerTw = 'flex items-center justify-center rounded-full'

  const containerTw = classNames(baseContainerTw, positionTw, marginTw, sizeTw)
  //   console.log('containerTw', containerTw)
  //   console.log('iconButtonTw', iconButtonTw)

  return (
    <BlurView tint={blurTint} intensity={blurIntensity} tw={containerTw} >
      <IconButton color="control" tw={iconButtonTw} {...props} size={size}/>
    </BlurView>
  )
}
