import { useLinkTo } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { Text, View } from '~/elements'
import { useTimeout } from '~/hooks/time'

export const RegistrationConfirmedScreen = () => {
  const { t } = useTranslation(['onboarding'])
  const linkTo = useLinkTo()

  // redirect to onboarding checklist after 500ms
  useTimeout(() => {
    linkTo('/onboarding?details=complete')
  }, 2500)

  return (
    <View tw="flex-1 content-center justify-center bg-surface-1 p-4">
      <Text category="h1" tw="mb-6">{t('onboarding:registrationConfirmed.title')}</Text>
      <Text tw="mb-6">{t('onboarding:registrationConfirmed.description')}</Text>
      <Text>{t('onboarding:registrationConfirmed.errorMsg')}</Text>
    </View>
  )
}
