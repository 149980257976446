import { View, ViewProps } from 'dripsy'
import {
  Children, cloneElement, FC, ReactElement,
} from 'react'
import { useTailwind } from 'tailwind-rn'

import {
  Button,
} from '../buttons/Button'
import { ButtonColor, ButtonProps, ButtonVariant } from '../buttons/Button.d'
// import { View } from '..'

/**
 * See
 * https://reactnativeelements.com/docs/tabs
 * https://tailwindui.com/components/application-ui/navigation/tabs
 */
// export const Tab = (props) => {

//     // flex space-x-4

//     return <RnTab disableIndicator={true} {...props} />
// }

// export const TabItem = (props) => {

//     // hover:bg-opacity-10
//     // active:text-brand-100
//     const base = 'text-sm text-white font-medium rounded-md bg-surface-1 bg-opacity-0 px-3 py-2'

//     const tabItemStyle = tailwind(base)

//     return <RnTab.Item {...props} buttonStyle={tabItemStyle }/>
// }

export type TabItemProps = ButtonProps & {
  active?: boolean
  variant?: ButtonVariant
  status?: ButtonColor
  containerTw?: string
}

const TabItem: FC<TabItemProps> = ({ iconPosition = 'left', ...props }) => <Button iconPosition={iconPosition} {...props} />

export type TabProps = ViewProps & {
  value?: number
  onChange?: (value: number) => void
  variant?: ButtonVariant
  status?: ButtonColor
}

const TabContainer: FC<TabProps> = ({
  children, value, variant, status, onChange = () => true, ...props
}) => {
  const tailwind = useTailwind()
  const containerStyle = tailwind('flex flex-row')

  return (
    <View {...props} style={containerStyle}>
      {Children.map(children, (child, index) => cloneElement(child as ReactElement<TabItemProps>, {
        onPress: () => onChange(index),
        active: index === value,
        containerTw: 'mr-4',
        variant,
        color: status,
      }))}
    </View>
  )
}

interface TabType extends FC<TabProps> {
  Item: typeof TabItem
}

const Tab: TabType = Object.assign(TabContainer, {
  Item: TabItem,
})

export { Tab }
