import { StackScreenProps } from '@react-navigation/stack'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Carousel } from '~/components/carousel/Carousel'
import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { TaskListStackParamList } from '~/navigators/TaskListStackParamList'

import { OnboardingChecklist } from './OnboardingChecklist'

export type OnboardingChecklistScreenProps = StackScreenProps<TaskListStackParamList, 'OnboardingChecklist'>

export const hasSeenWelcomeCarouselKey = 'hasSeenWelcomeCarousel'

const SecondaryComponent = <Carousel tw="rounded-lg bg-gray-100 h-full" swiperKey="app-welcome"/>

export const OnboardingChecklistScreen: FC<OnboardingChecklistScreenProps> = () => {

  const { t } = useTranslation(['onboarding'])

  return (
    <FullOverlayContainer tw="items-center bg-surface-1">
      <TwoColGridSection Secondary={SecondaryComponent} padded>
        <OnboardingChecklist
          title={t('onboarding:main.title')}
          description={t('onboarding:main.description')}
        />
      </TwoColGridSection>
    </FullOverlayContainer>
  )
}
