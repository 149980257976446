import classNames from 'classnames'
import { FC } from 'react'

import { baseTwClasses, iconFamilyVariant, iconSize } from './headerButtons.helpers'
import { HeaderIconButton, HeaderIconButtonProps } from './HeaderIconButton'

type SettingsHeaderButtonProps = {
  tw?:string
} & Omit<HeaderIconButtonProps, 'icon'>

export const SettingsHeaderButton:FC<SettingsHeaderButtonProps> = ({ tw, ...props }) => (
  <HeaderIconButton
    {...props}
    tw={classNames(tw, baseTwClasses)}
    icon={{ variant: iconFamilyVariant, sizeNum: iconSize, name: 'gear' }} />
)
