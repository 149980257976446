import { openBrowserAsync } from 'expo-web-browser'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { cardBasicShadowStyle } from '~/components/panels/tiles/ContentTile'
import {
  Button, Overlay, View,
} from '~/elements'
import { logout } from '~/hooks/auth'

import { DeveloperInfo } from './DeveloperInfo'
import { SelectLanguage } from './SelectLanguage'

type PreferencesCoreProps = Record<string, unknown>

export const PreferencesCore:FC<PreferencesCoreProps> = () => {
  const [isModalVisible, setModalVisible] = useState(false)

  const { t } = useTranslation(['main', 'navigation'])
  // if(!ready) return <SplashScreen />

  return (
    <>
      <View tw="p-4 mt-4 w-full max-w-md bg-surface-1 rounded-2xl mb-16" style={cardBasicShadowStyle}>
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.help')}
          iconRight={false}
          icon={{ name: 'circle-question' }}
          onPress={() => {
            openBrowserAsync('mailto:hello@mindday.com')
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.language')}
          iconRight={false}
          icon={{ name: 'earth-americas' }}
          onPress={() => {
            setModalVisible(true)
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.instagram')}
          iconRight={false}
          icon={{ name: 'instagram', variant: 'brands' }}
          onPress={() => {
            openBrowserAsync('https://www.instagram.com/get.mindday/')
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.linkedin') }
          iconRight={false}
          icon={{ name: 'linkedin', variant: 'brands' }}
          onPress={() => {
            openBrowserAsync('https://www.linkedin.com/company/mindday/')
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.privacy') }
          iconRight={false}
          icon={{ name: 'lock' }}
          onPress={() => {
            openBrowserAsync('https://www.mindday.com/fr/legal/privacy-policy/')
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.terms')}
          iconRight={false}
          icon={{ name: 'shield-blank' }}
          onPress={() => {
            openBrowserAsync('https://www.mindday.com/fr/legal/terms-and-conditions/')
          }}
        />
        <Button
          variant="ghost"
          color="basic"
          justify="start"
          title={t('main:profile.log_out')}
          iconRight={false}
          icon={{ name: 'arrow-right-from-bracket' }}
          onPress={() => {
            logout()
          }}
        />
      </View>
      <DeveloperInfo />

      <Overlay
        onBackdropPress={() => setModalVisible(false)}
        isVisible={isModalVisible}
      >
        <Overlay.Title tw="py-6">
          { t('main:profile.languages.select')}
        </Overlay.Title>
        <Overlay.Content tw="py-6">
          <SelectLanguage hideOverlay={() => setModalVisible(false)} />
        </Overlay.Content>
      </Overlay>
    </>
  )
}
