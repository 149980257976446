import { createStackNavigator } from '@react-navigation/stack'
import * as Sentry from '@sentry/react'
import { Suspense, useCallback } from 'react'

import { SettingsHeaderButton } from '~/elements/buttons/headerButtons/SettingsHeaderButton'
import { MainLayoutHeader } from '~/elements/headers/MainLayoutHeader'
import { useMediaQuery } from '~/hooks/mediaQuery'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import { HomeScreen } from '~/screens/HomeScreen/HomeScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'

import { HomeStackParamList } from './HomeStackParamList'

const HomeStack = createStackNavigator<HomeStackParamList>()

export const HomeNavigator = () => {
  const { md } = useMediaQuery()
  const Fallback = useCallback((props) => <ErrorScreen {...props} />, [])
  const HeaderRight = useCallback(() => ((!md) ? <SettingsHeaderButton size="base" to="/preferences" tw="mt-12" /> : null), [md])
  return (
    <Sentry.ErrorBoundary
      fallback={<Fallback />}
      beforeCapture={(scope) => {
        scope.setTag('location', 'home')
      }}
    >
      <Suspense fallback={<LoadingScreen />}>
        <HomeStack.Navigator >
          <HomeStack.Screen
            name="Dashboard"
            component={HomeScreen}
            options={{
              headerShown: true,
              headerTransparent: true,
              headerRight: HeaderRight,
              header: MainLayoutHeader,
            }}
          />
        </HomeStack.Navigator>
      </Suspense>
    </Sentry.ErrorBoundary>
  )
}
