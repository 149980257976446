import { useLinkTo } from '@react-navigation/native'
import { StackScreenProps } from '@react-navigation/stack'
import cleanDeep from 'clean-deep'
import { Timestamp } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { compile } from 'react-native-handlebars'
import * as Yup from 'yup'

import { Wizard } from '~/components/forms/MultiStepWizard'
import { TwoColGridSection } from '~/components/layouts/column-sections/TwoColGridSection'
import { FullOverlayContainer } from '~/components/layouts/containers/FullOverlayContainer'
import { useWebFormByKey } from '~/hooks/dato.webForm'
import { useFunctions } from '~/hooks/firebase'
import { useUser } from '~/hooks/user.context'
import { TaskListStackParamList } from '~/navigators/TaskListStackParamList'
import { ErrorScreen } from '~/screens.shared/ErrorScreen/ErrorScreen'
import LoadingScreen from '~/screens/LoadingScreen/LoadingScreen'
import { getYear } from '~/utils/dateFns/common.helpers'

const formId = 'app-register'

export type RegistrationFormScreenProps = StackScreenProps<TaskListStackParamList, 'RegistrationForm'>

export const getNormalizedDomain = (email = '') => {
  const [, domain] = email.split('@')

  return domain?.toLowerCase()
}

/**
 *
 * TODO replace container with OverlayGridSectionTwoColContainer
 */
const RegistrationFormScreen: FC<RegistrationFormScreenProps> = () => {
  const { loading, error, data } = useWebFormByKey(formId)
  const linkTo = useLinkTo()
  // const { t } = useTranslation(['auth'])

  const functions = useFunctions()

  const { user } = useUser()
  // console.log('user', user)

  const { t } = useTranslation(['forms'])

  const currentYear = getYear(Timestamp.now())

  if (!user) {
    return <LoadingScreen />
  }

  if (loading) {
    return <LoadingScreen />
  }
  if (error) {
    return <ErrorScreen error={error} />
  }

  const { webForm } = data

  const handlebarData = {
    company_name: user.occupation?.company,
  }
  // console.log('webForm', webForm)

  // remove prefix from field keys and update handlebar templates in titles
  const fields = webForm.formFields.map((item) => {
    const arrName = item.key.split('-')
    arrName.shift()

    return {
      ...item,
      name: arrName.join('-'),
      label: compile(item.label)(handlebarData),
    }
  }) // .filter((item) => item.key === 'register-email')

  // console.log(`webForm`, omit(webForm, 'formFields'))
  // console.log('fields', fields)

  const initialValues = fields.reduce((acc, item) => ({ ...acc, [item.name]: item.defaultValue }), { metadata: {} })
  if (user.email) {
    initialValues.email = user.email
  }
  if (user.displayName) {
    initialValues.fullName = user.displayName
  }
  if (user.phoneNumber) {
    initialValues['phone-number'] = user.phoneNumber
  }

  // console.log('user', user)
  // console.log('initialValues', initialValues)

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    fullName: Yup.string().min(5, t('forms:string.too_short')).max(100, t('forms:string.too_long')).required(t('forms:string.required')),
    'birth-year': Yup.number().min(currentYear - 100, t('forms:age.too_old')).max(currentYear - 16, t('forms:age.too_young')).required(t('forms:string.required')), // max is inclusive
    'company-start-year': Yup.number().min(currentYear - 100, t('forms:companyDate.too_far')).max(currentYear, t('forms:companyDate.future')).required(t('forms:string.required')),
    'company-position': Yup.string().min(2, t('forms:string.too_short')).max(150, t('forms:string.too_long')),
    email: Yup.string().email(t('forms:email.invalid')).required(t('forms:string.required')),
    'phone-number': Yup.string(), // .matches(phoneRegExp, 'Phone number is not valid'),
  })

  const onSubmit = async (values) => {

    // console.log('onSubmit values', values)

    const newContext = cleanDeep({
      ...values.metadata,
      uid: user.uid,
      lang: 'fr',
      customerId: get(user, 'subscription.parent.parent.id'),
      campaignId: get(user, 'subscription.id'),
    })

    // console.log('context', newContext)

    // We directly send data to backend; it will be processed asyncronously
    await httpsCallable(functions, 'httpFormEndpoint')({ values: omit(values, 'metadata'), formId, context: newContext })

    // We still need to navigate to a "waiting screen" while data is acknowldged
    linkTo('/onboarding/complete')
  }

  return (
    <FullOverlayContainer tw="bg-surface-1">
      <TwoColGridSection >
        <Wizard
          initialValues={initialValues}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={onSubmit}
          tw="flex-col justify-start"
          style={{ minHeight: 250 }}
          labelTw="font-serif text-2xl font-semibold mb-4"
          steps={fields}
        />
      </TwoColGridSection>
    </FullOverlayContainer>
  )
}

export default RegistrationFormScreen
