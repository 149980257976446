import { useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { GetTypeformBySlugQuery } from '~/__generated__/GetTypeformBySlugQuery'
import { EvaluationType } from '~/definitions/firestore.evaluation.properties'
import { GET_TYPEFORM_BY_SLUG } from '~/queries/dato.typeforms'

export const useTypeformBySlug = (slug: EvaluationType) => {
  const { i18n } = useTranslation()

  return useQuery<GetTypeformBySlugQuery, { slug: string }>(GET_TYPEFORM_BY_SLUG, { variables: { slug, locale: i18n.language } })
}
