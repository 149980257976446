import classNames from 'classnames'
import { FC } from 'react'

import { View } from '~/elements'

import { DoubleSvgImageType } from './DoubleSvgImage/DoubleSvgImageType'
import { Landscape } from './DoubleSvgImage/Landscape'
import { Portrait } from './DoubleSvgImage/Portrait'

/**
 * A component that superposes a few elements into a responsive illustration
 */
export const DoubleSvgImage: FC<DoubleSvgImageType> = (props) => {
  // console.log('-> DoubleSvgImage')
  // console.log('Shadow?', props.shadow)

  const {
    aspectW, aspectH, backgroundColor, containerTw, shadow = true, background, illustration,
  } = props
  const containerAspectRatio = aspectW / aspectH
  // console.log('aspect ratio:', containerAspectRatio)

  if (!background && !illustration) {
    return null
  }

  let containerStyle = {
    aspectRatio: containerAspectRatio, // we force provided aspect ratio on resize
    backgroundColor: background && backgroundColor,
    position: 'relative',
    overflow: 'hidden',
  }

  if (shadow) {
    containerStyle = {
      ...containerStyle,
      shadowColor: background && backgroundColor,
      shadowOffset: {
        width: 0,
        height: 14,
      },
      shadowOpacity: 0.5,
      shadowRadius: 30,
    }
  }

  // console.log('DoubleSvgImage / containerStyle', containerStyle)

  const nextContainerTw = classNames('relative overflow-hidden', containerTw)
  // console.log('nextContainerTw', nextContainerTw)

  return (
    <View
      tw={nextContainerTw}
      style={containerStyle}
    >
      {containerAspectRatio > 1 ? <Landscape {...props} /> : <Portrait {...props} />}
    </View>
  )
}

DoubleSvgImage.defaultProps = {
  format: 'square',
}
