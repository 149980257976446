/**
 * Extract rounded-* fromm a tw string
 */
export const getRoundingClasses = (tw = '') => {
  const testString = 'rounded'

  return {
    roundedTw: tw.split(' ').filter((cn) => cn.startsWith(testString)).join(' '),
    nonRoundedTw: tw.split(' ').filter((cn) => !cn.startsWith(testString)).join(' '),
  }
}

export const getBorderClasses = (tw = '') => {
  const testString = 'border-'

  return {
    borderTw: tw.split(' ').filter((cn) => cn.startsWith(testString)).join(' '),
    nonBorderTw: tw.split(' ').filter((cn) => !cn.startsWith(testString)).join(' '),
  }
}
