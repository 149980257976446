import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import { FC } from 'react'

// import { View } from '..'
import { ButtonColor } from '../buttons/Button.d'
import { Pressable } from '../containers/Pressable'
import { PressableSvg } from '../containers/PressableSvg'
import { ListItemProps } from './ListItem.d'
import { ListItemContent } from './ListItemContent'
import { ListItemRightAccessory } from './ListItemRightAccessory'

library.add(faCheck)

type ActionListItemProps = ListItemProps & {
  status: 'todo' | 'awaiting' | 'completed'
  active: boolean
};

const completedIcon = {
  name: 'check',
  variant: 'solid',
}

// const incompletedIcon = {
//   name: 'circle',
//   variant: 'regular',
// }

const baseContainerTw = 'p-4 rounded-2xl mb-4 flex-row'

const baseTitleStyle = 'font-serif font-normal text-lg'
const baseIconStyle = 'text-brand-base'
const EventuallyPressableSvg = ({
  active, children, ...props
}) => {

  if (active) {
    return (<PressableSvg
      assetId="27630544"
      initDimensions={{ width: 448, height: 132 }}
      {...props}
    >{children}</PressableSvg>)
  }

  return <Pressable {...props} >{children}</Pressable>
}

/**
 * A special item for action lists
 */
export const ActionListItem: FC<ActionListItemProps> = (props) => {
  // console.log('-> ActionListItem', props)

  const {
    tw, status, title, description, active,
  } = props

  // to disable pointer so user does not think done list item is clickable
  const containerStyle = classNames(baseContainerTw, status !== 'todo' ? 'pointer-events-none' : '')

  // we add special classes to the item container if first or last item of the list
  let newTw = classNames(containerStyle, tw)

  // apply strikethrough if item is not to be done anymore
  const titleStyle = classNames(baseTitleStyle, status !== 'todo' ? 'line-through text-gray-500 font-serif' : '')

  let cta
  let ctaColor: ButtonColor = 'basic'
  let redirectTo
  if (status === 'awaiting') {
    // eslint-disable-next-line react/destructuring-assignment
    cta = props.await.cta
    ctaColor = 'control'
    // eslint-disable-next-line react/destructuring-assignment
    redirectTo = props.await?.to
  }
  if (status === 'todo') {
    redirectTo = props.todo?.to
  }
  if (status === 'todo' && active === true) {
    cta = props.todo.cta
  }
  // We add hover style if it redirects somewhere
  if (redirectTo) {
    newTw = classNames(newTw, 'hover:bg-gray-100')
  }

  if (active) {
    newTw = classNames(newTw, 'border-none font-medium overflow-hidden')
  } else {
    newTw = classNames(newTw, 'border border-gray-300')
  }

  // console.log('redirectTo', redirectTo)

  return (
    <EventuallyPressableSvg tw={newTw} to={redirectTo} active={active}>
      <ListItemContent
        title={title}
        titleStyle={titleStyle}
        description={active ? description : undefined}
        containerTw={active ? 'w-full' : 'flex-grow'}
        cta={active ? cta : undefined}
        ctaColor={ctaColor}
      />
      { status !== 'todo' && <ListItemRightAccessory
        icon={completedIcon}
        iconStyle={baseIconStyle}
      />}
    </EventuallyPressableSvg>
  )
}
