import { FC } from 'react'
import { Tile as RnTile, TileProps as RnTileProps } from 'react-native-elements'

export type TileProps = Record<string, unknown> & RnTileProps
/**
 * See
 * https://reactnativeelements.com/docs/Tile
 * https://necolas.github.io/react-native-web/docs/image-background/
 */
export const Tile: FC<TileProps> = (props) => <RnTile {...props} />
