import { FC } from 'react'
import { Platform } from 'react-native'

import { ImageHtml as WebImage } from './ImageHtml.web'
import { ImageProps } from './ImageProps'
import { ImageSvgNative as NativeImage } from './ImageSvg.native'

export const ImageSvg:FC<ImageProps> = (props) => {
  if (Platform.OS === 'web') {
    return <WebImage {...props} />
  }

  return <NativeImage {...props} />
}
